import './user-list.css';
import React  from 'react';

import { Button } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { UserGroupOwnersTable, UserGroupUsersTable, UserGroupViewersTable } from 'components/data-tables/user-data-tables';

export const UserGroupUserParticipants = ({ canEdit, userGroupId }) => {

	const history = useHistory();
	const { url } = useRouteMatch();

	const handleEdit = () => {
		history.push(`${url}/user-management`);
	}

	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

	const defaultCriteria = {
		id: userGroupId,
	}

	const fixedCriteria = {
		id: userGroupId,
	}

	return <>
		{ canEdit && <Button variant="contained" color="primary" onClick={ handleEdit }>Edit</Button> }
		<UserGroupUsersTable
			defaultCriteria={defaultCriteria}
			fixedCriteria={fixedCriteria}
			searchCriteria={searchCriteria}
			defaultSearchCriteria={defaultSearchCriteria} />
	</>
}

export const UserGroupOwnerParticipants = ({ canEdit, userGroupId }) => {

	const history = useHistory();
	const { url } = useRouteMatch();

	const handleEdit = () => {
		history.push(`${url}/owner-management`);
	}

	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

	const defaultCriteria = {
		id: userGroupId,
	}

	const fixedCriteria = {
		id: userGroupId,
	}

	return <>
		{ canEdit && <Button variant="contained" color="primary" onClick={ handleEdit }>Edit</Button> }
		<UserGroupOwnersTable
			defaultCriteria={defaultCriteria}
			fixedCriteria={fixedCriteria}
			searchCriteria={searchCriteria}
			defaultSearchCriteria={defaultSearchCriteria} />
	</>
}

export const UserGroupViewerParticipants = ({ canEdit, userGroupId }) => {

	const history = useHistory();
	const { url } = useRouteMatch();
	const handleEdit = () => {
		history.push(`${url}/viewer-management`);
	}

	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

	const defaultCriteria = {
		id: userGroupId,
	}

	const fixedCriteria = {
		id: userGroupId,
	}

	return <>
		{ canEdit && <Button variant="contained" color="primary" onClick={ handleEdit }>Edit</Button> }
		<UserGroupViewersTable
			defaultCriteria={defaultCriteria}
			fixedCriteria={fixedCriteria}
			searchCriteria={searchCriteria}
			defaultSearchCriteria={defaultSearchCriteria} />
	</>
}
