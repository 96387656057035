import _ from 'lodash';
import { axiosPostRequest, apiPost } from './api-request';

const USER_API_URL = `${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user`;

async function asyncPost(url, data) {
	const [, r] = axiosPostRequest(url, data);
	const d = await r;
	// todo: consider parsing d.result.errors for more details about the error
	if (_.isEqual(d.success, false)) {
		console.error(url, d);
		throw new Error(d.message ?? `Failed for unknown reason.`);
	}
	return d;
}

export async function authorize(username, password) {
	return await asyncPost(`${USER_API_URL}/authorize`, {
		email: username,
		password: password,
	})
}

export function users(data) {
	console.warn('users', { data });
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Users fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function user(id) {
	try {
		const body = {
			id: id
		}
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user/get`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export async function updateUserPassword(username, oldPassword, newPassword, authCallback) {
	await authCallback(username, oldPassword);
	await asyncPost(`${USER_API_URL}/update-password`, {
		password: newPassword,
	})
	return await authCallback(username, newPassword);
}

// !deprecated: use userCreate instead.
export function create(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user/create`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User create failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function deleteUser(data) {
	try {
		const body = { id: data };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user/delete`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User create failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function update(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user/update`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User update failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function update_role(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user/update-role`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User role update failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function getIdentity() {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user/get-identity`, {});
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User role update failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function getProfilePicture(id) {
	try {
		let body = {}
		if (id) { body['user_id'] = id } 
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user/get/picture`, body)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Profile picture fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function createProfilePicture(id, image) {
	try {
        let formData = new FormData();
        formData.append("user_image", image);
		if (id) { formData.append('user_id', id) } 
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user/create/picture`, formData)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Profile picture fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export async function userCreate(data) {
	console.warn('userCreate', {data})
	const response = await apiPost(`/v1/user/create`, data);
	return response?.result ?? null;
}

export async function userUpdate(data) {
	console.warn('userUpdate', {data})
	const response = await apiPost(`/v1/user/update`, data);
	return response?.result ?? null;
}

export async function userList(data) {
	console.warn('userList', {data})
	const response = await apiPost(`/v1/user`, data);
	return response?.result ?? null;
}

export async function userPasswordReset(data) {
	const { id, password } = data;
	return await userUpdate({ id, password });
}
