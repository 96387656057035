import { axiosPostRequest, apiPost } from './api-request';

export function trips(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/trip`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Trips fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export async function trip(id) {
	// console.warn('trip', id);
	try {
		return await apiPost(`/v1/trip/get`, { id })
	} catch (e) {
		if (e.response) {
			throw new Error(e.response.data.message || 'Trip fetch failed');
		} else {
			throw new Error('Something went wrong...');
		}
	}
}
