import { axiosPostRequest } from './api-request';

// Retrieves the current users course, or retrieves a specified users courses, or retrieves a specified user groups courses...
//	Not the best naming scheme
function getUserCourses(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/training/get/courses`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Courses fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

// Retrieves the current users lessons, or retrieves a specified users lessons, or retrieves a specified user groups lessons...
//	Not the best naming scheme
function getUserLessons(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/training/get/lessons`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Lessons fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export {
	getUserCourses,
	getUserLessons,
};
