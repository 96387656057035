import React from 'react';

import { ClientSideTable } from 'components/table/client-side-table';
import { useGoogleMap } from '@react-google-maps/api';

import { setSelectedEventData } from 'modules/useGlobalStore';

import { toDisplayTimeEvent, toDisplayTimeShort } from 'shared-functions/shared-functions';

const sortModelNormal = [
		{field: 'timestamp', sort: 'asc'}
];

const sortModelReversed = [
		{field: 'timestamp', sort: 'desc'}
];

export const EventTab = ({ eventList, isLive }) => {

	// const eventListReversed = isLive ? [...eventList].reverse() : eventList;
	// console.warn('EventTab', {isLive, eventList});

	const sortModel = isLive ? sortModelReversed : sortModelNormal;

	const live = React.useMemo(() => isLive ?? false, [isLive]);

	const handleRowClick = (row) => {
		const alert = row.data;
		// console.log('EventTab.handleRowClick', row);
    setSelectedEventData(alert);
	}

	const columns = React.useMemo(() => [
		{
			// https://trello.com/c/beaPyRFx
			// field: "index",
			// headerName: "Index",
			// type: 'number',
			// Date format should be human day (Tuesday) and 24 hour time format.
			field: "timestamp",
			headerName: "Time",
			// type: 'dateTime',
			width: live ? 120 : 160,
			valueFormatter: (params) => {
				// return live ? toDisplayTimeAbbr(params.value) : toDisplayTimeShort(params.value);
				return live ? toDisplayTimeEvent(params.value) : toDisplayTimeShort(params.value);
			},
		},
		// {
		// 	field: "timestamp",
		// 	headerName: "Timestamp",
		// 	width: 170,
		// 	type: 'dateTime',
		// },
		{
			field: "alert_title",
			headerName: "Event Type",
			width: 200,
		},
	], [live]);

	return <>
		<ClientSideTable
			columns={columns}
			sortModel={sortModel}
			rows={eventList}
			rowClick={ handleRowClick }
			/>
	</>
}
