import React from 'react';

import _ from 'lodash';

// https://v4.mui.com/components/autocomplete/
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { DrawerTextHelp, useClassesFormStyle } from 'theme/theme';

const getOptionSelected = (option, value) => {
	// console.warn('getOptionSelected', { option, value })
	return _.isEqual(option.value, value.value);
}

const getOptionLabel = (option, value) => {
	// console.warn('getOptionLabel', {option, value})
	return option.label
}

export const BaseSelect = ({id, label, defaultOption, options, onChange, value, help, severity, placeholder, autoFocus, required, ...props}) => {
	const classes = useClassesFormStyle();

  const [initialOption, setInitialOption] = React.useState(null);

  const [option, setOption] = React.useState(null);

	const _value = value ?? null;

	const _onChange = React.useCallback((event, data, reason) => {
		event?.preventDefault();
		setOption(data);
		if (!_.isNil(onChange)) {
			onChange(data);
		}
	}, [onChange, setOption]);

	// Set the initialOption as soon as a valid _value is provided.
	React.useEffect(() => {
		if (_.isNil(initialOption) && !_.isNil(_value)) {
			setInitialOption(_value);
		}
	}, [initialOption, _value]);

	// If a defaultOption was specified, set the value.
	// Do this once, to allow for select box to be clearable.
	React.useEffect(() => {
		if (!_.isNil(defaultOption)) {
			_onChange(null, defaultOption);
		}
	}, [defaultOption]);

	// If our option does not match the incoming _value, then notify the outside component.
	React.useEffect(() => {
		if (!_.isEqual(_value, option)) {
			_onChange(null, _value);
		}
	}, [_value, option, _onChange])

	// console.warn('BaseSelect', { initialOption })

	const _placeholder = placeholder ?? label;

	const _autoFocus = autoFocus ?? false;

	const _required = required ?? false;
	// const _required = false;

	// const _inputValue = option?.label ?? '';

	const _onHighlightChange = (event, option, reason) => {
		// console.warn('_onHighlightChange', { event, option, reason })
		// if (_.isEqual(reason, "clear")) {
		// }
	}

	const _onInputChange = (event, option, reason) => {
		// console.warn('_onInputChange', { event, option, reason })
		// if (_.isEqual(reason, "clear")) {
		// }
	}

	return (
		<Autocomplete
			getOptionSelected={getOptionSelected}
			getOptionLabel={getOptionLabel}
			selectOnFocus={false}
			value={option}
			// inputValue={_inputValue}
			onChange={_onChange}
			onHighlightChange={_onHighlightChange}
			onInputChange={_onInputChange}
			options={options}
			autoSelect={true}
			autoHighlight={true}
			autoComplete={true}
			fullWidth
			renderInput={(params) => (
				<>
					<TextField 
						className={classes.field} 
						{...params} 
						variant="outlined" 
						margin="normal"
						fullWidth
						id={id}
						label={label}
						name={id}
						placeholder={_placeholder}
						autoFocus={_autoFocus}
						required={_required}
					/>
					<DrawerTextHelp severity={severity}>{help}</DrawerTextHelp>
				</>
			)}
			{...props}
		/>
	)
}
