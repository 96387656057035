import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { EditView } from 'components/edit-view/edit-view';

import { vehicle, update } from 'services/vehicle';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';
import { useIsMounted } from 'shared-hooks/useIsMounted';

export const VehicleEdit = () => {

	const isMounted = useIsMounted();
	const vehicleUpdateCancelSourceRef = useRef(null);
	const { vehicleId } = useParams();
	const [vehicleData, setVehicleData] = useState();
	const { url } = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		if (!vehicleId) return;

		const [cancelSource, vehicleGetPromise] = vehicle(vehicleId)
		let isActive = true;
		vehicleGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;

				setVehicleData(response.result);
				history.replace(url, { [vehicleId]: `${response.result.name}`})
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err);
			});
		return () => { 
			isActive = false;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [vehicleId]);

	const handleEditSave = (data) => {
		if (vehicleUpdateCancelSourceRef.current) return;
		const [vehicleUpdateCancelSource, vehicleUpdatePromise] = update({ ...data, id: vehicleId })
		vehicleUpdateCancelSourceRef.current = vehicleUpdateCancelSource
		vehicleUpdatePromise
			.then((response) => {
				if (!isMounted()) return;
				vehicleUpdateCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((err) => {
				if (!isMounted()) return;
				vehicleUpdateCancelSourceRef.current = null;
				console.log(err)
			});
	}

	const handleEditCancel = () => {
		history.goBack();
	}

	const editSkeleton = [
		{ key: 0, property: "name", header: "Alias", required: true },
		{ key: 1, property: "make", header: "Make", required: true },
		{ key: 2, property: "model", header: "Model", required: true },
		{ key: 3, property: "registration_number", header: "Reg Nr.", required: true },
		// { key: 4, property: "company_name", header: "Company Name", required: true },
	];

	return (<>
		{
			vehicleData && <EditView
				skeleton={editSkeleton}
				data={vehicleData}
				confirmTitle='Edit Vehicle?'
				confirmDescription={`You are about to alter the information of ${vehicleData.name}.`}

				
				onSave={ handleEditSave }
				onCancel={ handleEditCancel } />
		}
	</>);
}
