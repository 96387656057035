import React, { useEffect, useState } from 'react';

import _ from 'lodash';

import { Badge, IconButton, Tooltip, Toolbar, makeStyles, createStyles, Drawer, Button, Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import FilterListIcon from '@material-ui/icons/FilterList';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CommuteIcon from '@material-ui/icons/Commute';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';

import Tab from '@material-ui/core/Tab';

import DeviceTabPanel from './device-tab-panel';

import { useMqttState } from 'modules/mqttHooks';

import { getEventTypeEntries, generateEventTypeFilters, toDisplayTimeShort, toDisplayTimeLong } from 'shared-functions/shared-functions';
import { EventInfoView, SelectedDeviceTripInfoView } from 'components/info-view/info-view';
import { useGoogleMap } from '@react-google-maps/api';
import { EventTab } from 'components/tabs/live-map-tabs/event-tab/event-tab';
import { MapAlert, MapEventSelected } from '../map-alerts/map-alert';
import { setSelectedEventData, useResolveEventMedia, useSelectedTripId, useSelectedDeviceSerial, useTokenIdentifier, useFocusDeviceTrip, setFocusDeviceTrip, setFocusTripEvent, useLiveStatus, toggleFocusDeviceTrip, useSelectedEventIndex, clearSelectedEventData, useSelectedEventFromList, useFocusFilterMenu, toggleFilterMenu, useLiveDeviceOffline } from 'modules/useGlobalStore';
import { maxRightDrawerWidth, maxLeftDrawerWidth } from 'theme/theme';

// NOTE:
//     All components defined here should only be used within a GoogleMap and MQTT component

const getVerticalPosition = (index) => {
  const min = 80;
  return index ? (index * min) : 10;
}

const MapControlButton = ({ badgeContent, verticalIndex, left, right, children, onClick }) => {

  const [controlStyle, setControlStyle] = useState({});

  // const classes = styles();

  useEffect(() => {
    setControlStyle({
      zIndex: 2,
      top: getVerticalPosition(verticalIndex),
      position: "absolute",
      backgroundColor: 'white',
      borderRadius: 2,
      ...((left !== null && left !== undefined) && {
        left: Number.isInteger(left) ? left : 10,
      }),
      ...((right !== null && right !== undefined) && {
        right: Number.isInteger(right) ? right : 10,
      })
    })
  }, [])

  const handleClick = (event) => {
    onClick && onClick(event)
  }

  return (
      <IconButton
        className="map-component"
        color="default"
        aria-controls="simple-menu"
        aria-haspopup="true"
        style={controlStyle}

        onClick={handleClick}
      >
        <Badge color="primary" badgeContent={badgeContent}>
          {children}
        </Badge>
      </IconButton>
  )
}

export const MqttConnectionStatus = () => {

  const { color, tooltip } = useLiveStatus();

  return (
    <div className={'connection-status-container'} >
      <div className={'map-component noselect connection-status'} >
        <div className={'connection-status-text'} >Connection Status:</div>
        <Tooltip title={tooltip}>
          <Brightness1Icon style={{ color: color }} />
        </Tooltip>
      </div>
    </div>
  );
}

export const EventFilterMapControl = ({ typeFilters, verticalIndex, onFilterChange }) => {

  const focusFilterMenu = useFocusFilterMenu();

  const handleMenuToggle = (event) => {
    toggleFilterMenu();
  }

  const badgeContent = React.useMemo(() => {
    // todo: count the number of false filters
    return _.size(Object.entries(typeFilters).filter(v => !v[1]));
    // return _.size(Object.entries(typeFilters).filter(v => !v[1])) > 0 ? "+" : null;
  }, [typeFilters]);

  return (
    <>
      <MapControlButton badgeContent={badgeContent} verticalIndex={verticalIndex} left onClick={handleMenuToggle} >
        <FilterListIcon />
      </MapControlButton>
      <FilterDrawer open={focusFilterMenu} handleSideListToggle={handleMenuToggle} onFilterChange={onFilterChange} typeFilters={typeFilters} />
    </>
  );
}

export const EventMapControl = ({ eventList }) => {


  const selectedEventIndex = useSelectedEventIndex();
  const selectedEvent = useSelectedEventFromList(eventList, selectedEventIndex);
  // console.log('EventMapControl', eventList, selectedEventIndex, selectedEvent)
  // const selectedEvent = useSelectedEvent();

  const handleEventClicked = (param) => {
    const event = param.alert;
    // console.log('EventMapControl.handleEventClicked', param);
    setSelectedEventData(event);
    setFocusDeviceTrip(true);
    setFocusTripEvent(true);
  }

  return (
    <>
      {
        eventList.map((value, _) => {
          return (
            <MapAlert
              key={value.id}
              position={value.markerPos}
              type={value.alert_type}
              alert={value}
              onClick={handleEventClicked} />
          )
        })
      }

      {
        selectedEvent &&
        <>
          <MapEventSelected type={selectedEvent.alert_type} position={selectedEvent.markerPos} />
        </>
      }
    </>
  );
}

const ShowAllFiltersControl = ({ onClick }) => (
  <Tooltip title='Show all event types'>
    <Button onClick={onClick} variant="text" startIcon={<SelectAllIcon />}>All</Button>
  </Tooltip>
);

const ClearAllFiltersControl = ({ onClick }) => (
  <Tooltip title='Hide all event types'>
    <Button onClick={onClick} variant="text" startIcon={<ClearAllIcon />}>None</Button>
  </Tooltip>
);

// const FilterToolbar = ({ onShowAll, onClearAll }) => {
//   const classes = styles();
//   return (
//     <Toolbar className={classes.toolbar} disableGutters variant="dense">
//       <ShowAllFiltersControl onClick={onShowAll} />
//       <ClearAllFiltersControl onClick={onClearAll} />
//     </Toolbar>
//   )
// }


const styles = makeStyles((theme) => createStyles({
  // https://v4.mui.com/api/data-grid/data-grid/#css
  dgRoot: {
    border: "0px solid red",
    border: 0,
    borderRadius: 0,
    // padding: 0,
    // margin: 0,
  },
  dgRow: {
    border: "0px solid red",
    border: 0,
    borderRadius: 0,
  },
  dgCell: {
    border: "0px solid red",
    border: 0,
    borderRadius: 0,
  },
  tabIcon: {
    minWidth: 60,
    width: 60,
    // maxHeight: 20,
    // height: 20,
    // height: 40,
    // maxHeight: 40,
    // minHeight: 40,
  },
  tabLabelIcon: {
    padding: 0,
    margin: 0,
    // height: 20,
    // width: 20,
  },
  tabFullWidth: {
    padding: 0,
    margin: 0,
    // height: 20,
    // maxHeight: 20,
    // minHeight: 40,
    // width: 20,
    // maxWidth: 20,
    minWidth: 60,
    width: 60,
  },
  tabRoot: {
    padding: 0,
    margin: 0,
    // height: 20,
    // width: 20,
  },
  tabPanel: {
    height: '80%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  tabPanelDense: {
    height: '80%',
    padding: 0,
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'center',
    // width: '100%',
  },
  rightDrawerPaper: {
    width: '100%',
    // width: '80%',
    // height: '100%',
    maxWidth: maxRightDrawerWidth,
  },
  leftDrawerPaper: {
    width: '100%',
    // width: '80%',
    // height: '100%',
    // maxWidth: 200,
    maxWidth: maxLeftDrawerWidth,
    // padding: 0,
    // margin: 0,
  },
  drawerContainer: {
    overflow: 'auto', // this shows scrollbars when the content doesn't fit in the provided space
    // height: '80vh',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: 0,
    margin: 0,
  }
}));

// DeviceTabPanel
const idxVehicles = '0';
const idxTrip = '1';
const idxEvents = '2';
const idxAlert = '3';
const idxClose = '4';

// FilterTabs
const idxFilterTabFilters = '0';
const idxFilterTabClose = '1';

const FilterMenu = ({onFilterChange, typeFilters}) => {

  const selectionModel = React.useMemo(() => {
    return Object.entries(typeFilters).filter(v => v[1]).map(v => v[0]);
  }, [typeFilters]);

  const rows = React.useMemo(() => {
    // https://trello.com/c/paFdVL7k
    return _.orderBy(getEventTypeEntries().map(([key, value]) => {
      return {
        id: key,
        name: value,
      }
    }), 'name');
  }, []);

	const columns = React.useMemo(() => [
		{ field: "name", headerName: "Visible Events", width: 200 },
	], []);

  const classes = styles();

  // https://v4.mui.com/api/data-grid/data-grid/#css
  const dgClasses = React.useMemo(() => ({
    root: classes.dgRoot,
    row: classes.dgRow,
    cell: classes.dgCell,
  }), [classes])

  const onSelectionModelChange = React.useCallback((model) => {
    const newTypeFilters = generateEventTypeFilters(false);
    _.forEach(model, (value, _) => {
      newTypeFilters[value] = true;
    })
    // console.log('onSelectionModelChange', newTypeFilters)
    onFilterChange(newTypeFilters);
  }, [onFilterChange]);

  return (
    <Box>
      <DataGrid 
        // components={{
        //   Toolbar: FilterToolbar
        // }}
        // disableSelectionOnClick={true}
        classes={dgClasses}
        onSelectionModelChange={onSelectionModelChange}
        rowHeight={40}
        headerHeight={40}
        autoHeight={true} 
        selectionModel={selectionModel}
        density="dense" 
        columnBuffer={0} 
        checkboxSelection={true} 
        rows={rows} 
        columns={columns} 
        disableColumnFilter={true}
        disableColumnMenu={true}
        hideFooter={true}
        showCellRightBorder={false}
        showColumnRightBorder={false}
      />
    </Box>
  );
}

const FilterTabs = ({ handleSideListToggle, onFilterChange, typeFilters }) => {

  const [tabIndex, setTabIndex] = React.useState(idxFilterTabFilters);

  const classes = styles();

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === idxFilterTabClose) {
      setTabIndex(tabIndex);
      handleSideListToggle();
    }
  };

  // https://v4.mui.com/api/tab/#css
  const tabClasses = React.useMemo(() => ({
    root: classes.tabRoot,
    labelIcon: classes.tabLabelIcon,
    fullWidth: classes.tabFullWidth,
  }), [classes])

  return (
    <TabContext value={tabIndex}>
      <TabList onChange={handleChange} variant={"fullWidth"}>
        <Tab classes={tabClasses} icon={<NotificationsActiveIcon fontSize="small" />} value={idxFilterTabFilters} />
        <Tab classes={tabClasses} icon={<CloseIcon fontSize="small" />} value={idxFilterTabClose} />
      </TabList>
      <TabPanel className={classes.tabPanelDense} value={idxFilterTabFilters}>
        <FilterMenu onFilterChange={onFilterChange} typeFilters={typeFilters} />
      </TabPanel>
    </TabContext>
  );
}

const HistoricTripTabs = ({ handleToggle, eventList, isLive }) => {

  const [tabIndex, setTabIndex] = React.useState(idxTrip);

  const classes = styles();

  const tripId = useSelectedTripId();
  const disabledTrip = React.useMemo(() => _.isNil(tripId), [tripId]);

  const totalEvents = React.useMemo(() => eventList?.length ?? 0, [eventList]);

  const selectedEventIndex = useSelectedEventIndex();
  const selectedEventDefault = useSelectedEventFromList(eventList, selectedEventIndex);
  const { isFetching, event: selectedEvent } = useResolveEventMedia(selectedEventDefault);

  const disabledAlert = React.useMemo(() => disabledTrip || _.isNil(selectedEvent), [selectedEvent, disabledTrip]);

  const handleChange = (event, newValue) => {
    // console.warn('HistoricTripTabs:handleChange', {
    //   newValue
    // });
    setTabIndex(newValue);
    if (newValue === idxClose) {
      // Close
      setTabIndex(tabIndex);
      handleToggle();
    } else if (newValue === idxVehicles) {
      // Vehicles
      // handleBack();
    } else if (newValue === idxTrip) {
    } else if (newValue === idxEvents) {
      // The user switched to the events list view, so deselect the previous
      // alert. Is the deselect stricly necessary? Currently yes, since
      // selecting an event on the event list view, triggers a switch to the
      // detail tab.
      clearSelectedEventData();
    }
  };

  const focusTrip = useFocusDeviceTrip();

  // Whenever a new valid event is selected, the event should always be viewed.
  React.useEffect(() => {
    if (!_.isNil(selectedEvent)) {
      // console.warn('HistoricTripTabs:useEffect move to alert');
      setTabIndex(idxAlert);
    }
  }, [selectedEvent]);

  // If there is no selected event, or an event is unselected, then view the
  // events tab, unless we are currently on the trip tab or already on the events tab.
  React.useEffect(() => {
    if (_.isEqual(tabIndex, idxTrip) || _.isEqual(tabIndex, idxEvents)) {
      return
    }
    if (_.isNil(selectedEvent)) {
      // console.warn('HistoricTripTabs:useEffect move to events');
      setTabIndex(idxEvents);
    }
  }, [selectedEvent, tabIndex]);

  const notificationsIcon = React.useMemo(() => (
    <Badge color="primary" badgeContent={totalEvents}>
      <NotificationsActiveIcon fontSize="small" />
    </Badge>
  ), [totalEvents])

  const map = useGoogleMap();

  React.useEffect(() => {
    if (!_.isNil(selectedEvent)) {
      if (!_.isNil(selectedEvent?.markerPos) && focusTrip) {
        map.panTo(selectedEvent.markerPos);
      }
    }
  }, [selectedEvent, focusTrip]);

  return (
    <TabContext value={tabIndex}>
      <TabList onChange={handleChange} variant={"fullWidth"}>
        <Tab className={classes.tabIcon} icon={<DriveEtaIcon fontSize="small" />} label="Trip" value={idxTrip} disabled={disabledTrip} />
        <Tab className={classes.tabIcon} icon={notificationsIcon} label="Events" value={idxEvents} disabled={disabledTrip} />
        <Tab className={classes.tabIcon} icon={<ImageSearchIcon fontSize="small" />} label="Alert" value={idxAlert} disabled={disabledAlert} />
        <Tab className={classes.tabIcon} icon={<CloseIcon fontSize="small" />} label="Close" value={idxClose} />
      </TabList>
      <TabPanel className={classes.tabPanel} value={idxTrip}>
        <SelectedDeviceTripInfoView isLive={false} />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={idxEvents}>
        <EventTab eventList={eventList} isLive={isLive} />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={idxAlert}>
        <EventInfoView loading={isFetching} event={selectedEvent} eventList={eventList} eventIndex={selectedEventIndex} />
      </TabPanel>
    </TabContext>
  );
}

// https://v4.mui.com/components/drawers/#drawer
const FilterDrawer = ({ open, handleSideListToggle, onFilterChange, typeFilters }) => {
  const classes = styles();
  return (
    <Drawer
      variant="persistent"
      // variant="temporary"
      // variant="permanent"
      classes={{
        paper: classes.leftDrawerPaper,
      }}
      anchor="left"
      open={open}
      onClose={handleSideListToggle}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <FilterTabs handleSideListToggle={handleSideListToggle} onFilterChange={onFilterChange} typeFilters={typeFilters} />
      </div>
    </Drawer>
  )
}

// https://v4.mui.com/components/drawers/#drawer
const DeviceDrawer = ({ open, selectedDeviceSerial, handleSideListToggle, handlePanToCurrentPosition, handleCabSnapshotRequest, handleRoadSnapshotRequest, eventList, isLive }) => {
  const classes = styles();
  return (
    <Drawer
      variant="persistent"
      classes={{
        paper: classes.rightDrawerPaper,
      }}
      anchor="right"
      open={open}
      onClose={handleSideListToggle}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <DeviceTabPanel selectedDeviceSerial={selectedDeviceSerial} handleSideListToggle={handleSideListToggle} handlePanToCurrentPosition={handlePanToCurrentPosition} handleCabSnapshotRequest={handleCabSnapshotRequest} handleRoadSnapshotRequest={handleRoadSnapshotRequest} eventList={eventList} isLive={isLive} />
      </div>
    </Drawer>
  )
}


// MapLiveTripButton is the clickable button on the map that expands or
// hides the trip control drawer.
export const MapLiveTripButton = ({ verticalIndex, onPanToCurrentPosition, isLive, eventList }) => {

  const selectedDeviceSerial = useSelectedDeviceSerial();

  const focusTrip = useFocusDeviceTrip();

  const { client } = useMqttState();

  const identifier = useTokenIdentifier();

  const handlePanToCurrentPosition = () => {
    // console.warn('MapLiveTripButton: handlePanToCurrentPosition')
    onPanToCurrentPosition && onPanToCurrentPosition();
  }

  const handleCabSnapshotRequest = () => {
    console.log(`Cab Snapshot Requested from to ${selectedDeviceSerial}`);
    selectedDeviceSerial && client.publish(`cmd/drivevue/devices/${selectedDeviceSerial}/live/snapshot/req`,
      JSON.stringify({
        "message_timestamp": new Date().toISOString(),
        "device_serial_number": selectedDeviceSerial,
        "message_category": "live",
        "message_detail": {
          "type": "snapshot",
          "snapshot_details": {
            "resolution": '360p',
            "camera": 'CAB',
            "uuid": identifier
          }
        }
      })
    )
  }

  const handleRoadSnapshotRequest = () => {
    console.log(`Road Snapshot Requested from ${selectedDeviceSerial}`);
    selectedDeviceSerial && client.publish(`cmd/drivevue/devices/${selectedDeviceSerial}/live/snapshot/req`,
      JSON.stringify({
        "message_timestamp": new Date().toISOString(),
        "device_serial_number": selectedDeviceSerial,
        "message_category": "live",
        "message_detail": {
          "type": "snapshot",
          "snapshot_details": {
            "resolution": '360p',
            "camera": 'ROAD',
            "uuid": identifier
          }
        }
      })
    )
  }

  const handleSideListToggle = () => {
    // setSideList((_state) => !_state);
    setFocusDeviceTrip(!focusTrip);
  }

  return (
    <>
      <MapControlButton verticalIndex={verticalIndex} right onClick={handleSideListToggle} >
        <CommuteIcon />
      </MapControlButton>
      <DeviceDrawer open={focusTrip} selectedDeviceSerial={selectedDeviceSerial} handleSideListToggle={handleSideListToggle} handlePanToCurrentPosition={handlePanToCurrentPosition} handleCabSnapshotRequest={handleCabSnapshotRequest} handleRoadSnapshotRequest={handleRoadSnapshotRequest} eventList={eventList} isLive={isLive} />
    </>
  );
}

// https://v4.mui.com/components/drawers/#drawer
const TripDrawer = ({ open, handleToggle, eventList }) => {
  const classes = styles();
  return (
    <Drawer
      className={classes.drawer}
      // variant="permanent"
      variant="persistent"
      // variant="temporary"
      classes={{
        paper: classes.rightDrawerPaper,
      }}
      anchor="right"
      open={open}
      onClose={handleToggle}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <HistoricTripTabs handleToggle={handleToggle} eventList={eventList} />
      </div>
    </Drawer>
  )
}

export const HistoricTripMapControl = ({ verticalIndex, eventList }) => {

  const totalEvents = React.useMemo(() => eventList?.length ?? 0, [eventList]);
  const focusTrip = useFocusDeviceTrip();

  return (
    <>
      <MapControlButton verticalIndex={verticalIndex} right onClick={toggleFocusDeviceTrip} >
        <Badge color="primary" badgeContent={totalEvents}>
          <NotificationsActiveIcon />
        </Badge>
      </MapControlButton>
      <TripDrawer open={focusTrip} handleToggle={toggleFocusDeviceTrip} eventList={eventList} />
    </>
  )
}
