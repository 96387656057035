import React from 'react';

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clipboard#examples
// https://stackabuse.com/how-to-copy-to-clipboard-in-javascript-with-the-clipboard-api/
// https://w3c.github.io/clipboard-apis/#navigator-clipboard
// https://w3c.github.io/clipboard-apis/#dom-clipboard-writetext
// https://github.com/w3c/clipboard-apis/pull/164
// https://github.com/w3c/clipboard-apis/issues/163
// https://usehooks-ts.com/react-hook/use-copy-to-clipboard
// https://github.com/juliencrn/usehooks-ts/blob/master/lib/src/useCopyToClipboard/useCopyToClipboard.ts
// https://usehooks-ts.com/react-hook/use-copy-to-clipboard
export default () => {

	// todo: implement a timeout that clears the copied contents (useful for secrets)

	const [copiedText, setCopiedText] = React.useState(null)

  const copy = async text => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

		// navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
		// 	if (result.state == "granted" || result.state == "prompt") {
		// 		navigator.clipboard.writeText(password);
		// 	}
		// });

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false
    }
  }

  return [copiedText, copy]
}
