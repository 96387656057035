import './user-trips.css';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useGoogleMap } from '@react-google-maps/api';

import { MapContainer } from 'components/map/map-container';
import { MapPath } from 'components/map/map-paths/map-path';
import { MapAlert } from 'components/map/map-alerts/map-alert';
import { TripTable } from 'components/data-tables/trip-event-data-tables';
import { useParams } from 'react-router-dom';
import { EventFilterMapControl, EventMapControl, HistoricTripMapControl } from 'components/map/map-controls/map-controls';
import { useFetchTrip, useFetchTripAlerts } from 'services/api';
import { useTripMapPath, useTripMapEventList, useEventMarkersFiltered, useStateEventTypeFilters, useTripMapPathStart, useTripMapPathFinish, useSelectedTripId } from 'modules/useGlobalStore';

export const UserTripTable = () => {

  const { userId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleRowClick = (params) => {
    history.push(`${url}/${params.data.id}`)
  };

  return (
    <>
      <TripTable
        onRowClick={handleRowClick}
        searchMode="driver"
        driverId={userId} />
    </>
  );
}

export const TripMap = ({}) => {

  return (
    <MapContainer height='95%' center={{ lat: -25.7468, lng: 28.2724 }} zoom={13} >
      <HistoricTripMapBody />
    </MapContainer>
  )
}

export const HistoricTripMapBody = ({}) => {

  const tripId = useSelectedTripId();

  const [typeFilters, setTypeFilters] = useStateEventTypeFilters();

  const { data: tripData } = useFetchTrip(tripId);
  const { data: eventData } = useFetchTripAlerts(tripId);
  // console.warn('HistoricTripMapBody', {eventData});

  const tripPath = useTripMapPath(tripData?.path ?? []);
  const eventList = useTripMapEventList(tripData, eventData?.alerts ?? []);
  const eventListFiltered = useEventMarkersFiltered(eventList, typeFilters);
  const tripStart = useTripMapPathStart(tripPath);
  const tripFinish = useTripMapPathFinish(tripPath);

  const handleEventFilterChanged = (param) => {
    setTypeFilters(param);
  }

  return (
    <>
      <MapPath path={tripPath} />
      <PanToStart start={tripStart} />
      <EventFilterMapControl typeFilters={typeFilters} onFilterChange={handleEventFilterChanged} />
      <HistoricTripMapControl verticalIndex={0} eventList={eventListFiltered} />
      <EventMapControl eventList={eventListFiltered} />
      {
        tripStart && <MapAlert trip={tripData} position={tripStart} type={'start'} />
      }
      {
        tripFinish && <MapAlert trip={tripData} position={tripFinish} type={'finish'} />
      }
    </>
  )
}

const PanToStart = ({ start }) => {

  const map = useGoogleMap();

  useEffect(() => {
    if (map && start) {
      map.panTo(start);
    }
  }, [map, start])

  return (<></>);
}
