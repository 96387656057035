import React from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import { DrawerActions, DrawerAlert, DrawerButton, DrawerButtonConfirm, DrawerConfirmMessage, DrawerHelp, DrawerSecretField, DrawerTextField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useCreateUser, useFetchCompany } from 'services/api';
import { CompanySelect } from 'components/autocomplete/company-select';

// const helpText = `To register a new administrator, please provide the relevant details.`;
const helpText = `Use this form to add a new company administrator.`;

const ConfirmRegister = ({email, company}) => {
	return (
		<DrawerConfirmMessage 
			alert={`User '${email}' will be able to perform administrative actions for company '${company}.'`}
			message={`Are you sure you want to register the company administrator?`}
			severity="warning" 
		/>
	)
}

export default ({companyId}) => {

	const classes = useClassesFormStyle();

	const history = useHistory();

  const [formData, setFormData] = React.useState({
		name: '',
		surname: '',
		email: '',
		password: '',
		confirmPassword: '',
		companyOption: null,
		alertSeverity: 'info',
		alertMessage: '',
		loading: false,
	});

	const isSubmitDisabled = React.useMemo(() => {
		return _.isEqual(formData.loading, true)
			|| _.isEmpty(formData.name.trim())
			|| _.isEmpty(formData.surname.trim())
			|| _.isEmpty(formData.email.trim())
			|| _.isEmpty(formData.password.trim())
			|| _.isEmpty(formData.confirmPassword.trim())
			|| _.isNil(formData.companyOption)
	}, [formData])

	const createAdmin = useCreateUser({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `Successfully registered new administrator '${p.email}' for company '${p.company_name}'.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not register administrator: ${p.message}`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const { data: companyData } = useFetchCompany(companyId);
	React.useEffect(() => {
		// console.warn('companyData', {companyData})
		if (_.isNil(companyData))
			return;

		setFormData(state => ({...state, companyOption: {
			value: companyId,
			label: companyData?.name,
		}}));
		
	}, [companyData])

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setFormData(state => ({
				...state, 
				alertMessage: 'Form incomplete. Please provide the missing information.', 
				alertSeverity: 'error'
			}));
			return
		}

		setFormData(state => ({
			...state, 
			loading: true, 
			alertMessage: 'Registering new administrator...', 
			alertSeverity: 'info'
		}));

		const role = 'ClientAdmin';

		createAdmin.mutate({
			name: formData.name,
			surname: formData.surname,
			email: formData.email,
			password: formData.confirmPassword,
			role: role,
			company_id: formData.companyOption.value,
		});
	}, [createAdmin, formData, isSubmitDisabled]);

	const onClickClose = () => {
		history.goBack();
	}

	const onInputFirstName = e => setFormData(state => ({...state, name: e.target.value}));
	const onInputLastName = e => setFormData(state => ({...state, surname: e.target.value}));
	const onInputEmail = e => setFormData(state => ({...state, email: e.target.value}));
	const onInputPassword = e => setFormData(state => ({...state, password: e.target.value}));
	const onInputConfirmPassword = e => setFormData(state => ({...state, confirmPassword: e.target.value}));

	const onChangeCompany = (data) => {
		setFormData(state => ({...state, companyOption: data}))
	};

	const companyName = React.useMemo(() => formData?.companyOption?.label ?? '', [formData]);

	const confirmTitle = React.useMemo(() => `Register ${companyName} company administrator?`, [companyName]);

	const passwordError = React.useMemo(() => !_.isEqual(formData.password, formData.confirmPassword), [formData]);

	const autoFocusCompany = React.useMemo(() => _.isNil(companyId), [companyId])
	const disableCompany = React.useMemo(() => !_.isNil(companyId), [companyId])
	const autoFocusEmail = React.useMemo(() => !_.isNil(companyId), [companyId])

	return (
		<form noValidate onSubmit={onSubmit} classes={classes.body} autoComplete="off">
			<DrawerTitle>Register Admin</DrawerTitle>
			<DrawerHelp alert={formData.alertMessage} severity={formData.alertSeverity}>{helpText}</DrawerHelp>
			{/* <DrawerAlert variant="filled" severity={formData.alertSeverity}>{formData.alertMessage}</DrawerAlert> */}
			<CompanySelect 
				autoFocus={autoFocusCompany}
				value={formData.companyOption} 
				onChange={onChangeCompany} 
				disabled={disableCompany}
			/>
			<DrawerTextField
				required
				autoFocus={autoFocusEmail}
				id="email"
				label="Email"
				autoComplete="off"
				value={formData.email}
				onInput={onInputEmail}
			/>
			<DrawerTextField
				required
				id="first_name"
				label="First Name"
				autoComplete="off"
				value={formData.name}
				onInput={onInputFirstName}
			/>
			<DrawerTextField
				required
				id="last_name"
				label="Last Name"
				autoComplete="off"
				value={formData.surname}
				onInput={onInputLastName}
			/>
			<DrawerSecretField
				required
				id="password"
				label="Password"
				value={formData.password}
				onInput={onInputPassword}
				onChange={onInputPassword}
				error={passwordError}
			/>
			<DrawerSecretField
				required
				id="confirm_password"
				label="Confirm Password"
				value={formData.confirmPassword}
				onInput={onInputConfirmPassword}
				onChange={onInputConfirmPassword}
				error={passwordError}
			/>
			<DrawerActions>
				<DrawerButtonConfirm details={<ConfirmRegister email={formData.email} company={companyName} />} title={confirmTitle} action="Register" color="primary" onClick={onSubmit} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} />
			</DrawerActions>
		</form>
	)
}
