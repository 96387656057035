// import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import { App } from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';
import { history } from 'modules/history';

// https://reactjs.org/docs/strict-mode.html
// https://reactjs.org/docs/strict-mode.html#warning-about-deprecated-finddomnode-usage
// https://github.com/mui/material-ui/issues/27039
// https://mui.com/customization/theming/#unstable-createmuistrictmodetheme-options-args-theme
// strict mode seems to cause issues in production (find a way to enable it during development but disable it during production)
ReactDOM.render(
	// <React.StrictMode>
		<Router history={history}>
			<App />
		</Router>
	// </React.StrictMode>
	, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
