// import './user-statistics.css';
import { MenuItem, Select } from '@material-ui/core';
import { ClientAdminCourseTable, ClientAdminLessonsTable } from 'components/data-tables/courses-tables';
import { BasicInfoItem } from 'components/info-view/info-view';
import { useRoleFeatureCheck } from 'modules/useGlobalStore';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { userStatsAverageEventsPerDistance, userStatsAverageEventsPerHour, userStatsPredominantEvents } from 'services/statistics';
import { convertToUnitSystem, getEventString, REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';
import { useDistanceUnit, useUnitSystem } from 'shared-hooks/useDistanceUnit';
import { useIsMounted } from 'shared-hooks/useIsMounted';

// User Trip and Event statistics

export const UserStatsPredominantEvents = ({ userId, startDate, endDate }) => {

  // console.log('UserStatsPredominantEvents', userId, startDate, endDate)

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null)

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
        }
        const [cancelSource, userStatsPredomEventsPromise] = userStatsPredominantEvents(userId, body)
        let isActive = true;
        userStatsPredomEventsPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                const stats = [];
                response.result.forEach((value, _) => {
                    getEventString(value.type) && stats.push({ num_alerts: parseInt(value.num_alerts), type: getEventString(value.type) })
                })
                setStatData(stats)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [startDate, endDate])

	return (
		<>
            <h3>Predominant Events</h3>
            {
                statData
                ?   <ResponsiveContainer width="50%" height={300}>
                        <BarChart
                            width={500}
                            height={300}
                            data={statData}
                            // margin={{
                            //     top: 5,
                            //     right: 30,
                            //     left: 20,
                            //     bottom: 5,
                            // }}
                        >
                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                            <XAxis dataKey="type" angle={-45} height={150} interval={0} textAnchor="end" />
                            <YAxis interval={1} dataKey="num_alerts" />
                            <Tooltip />
                            <Legend align="left" verticalAlign="left" width={70} />
                            <Bar dataKey="num_alerts" name="Total Alerts" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                :   "Nothing, sorry"
            }
        </>
	);
}

export const UserStatsPredominantEventsStatic = ({ userId, totalEvents, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null)

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
            n: totalEvents
        }
        const [cancelSource, userStatsPredomEventsPromise] = userStatsPredominantEvents(userId, body)
        let isActive = true;
        userStatsPredomEventsPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                const stats = [];
                response.result.forEach((value, _) => {
                    getEventString(value.type) && stats.push({ num_alerts: parseInt(value.num_alerts), type: getEventString(value.type) })
                })
                setStatData(stats);
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [startDate, endDate]);

	return (
		<>
            <BasicInfoItem
                header="Predominant Events"
                data={
                    statData && 
                    <ResponsiveContainer width="100%" height={250}>
                        <BarChart
                            width={500}
                            height={300}
                            data={statData}
                            // layout="vertical"
                            // margin={{
                            //     top: 5,
                            //     right: 30,
                            //     left: 20,
                            //     bottom: 5,
                            // }}
                        >
                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                            <XAxis dataKey="type" angle={-45} height={150} interval={0} textAnchor="end" />
                            <YAxis interval={1} dataKey="num_alerts" />
                            <Tooltip />
                            <Legend align="left" verticalAlign="left" width={70} />
                            <Bar dataKey="num_alerts" name="Total Alerts" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                } />
        </>
	);
}

export const UserStatsAverageEventsPerHour = ({ userId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null)

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
        }
        const [cancelSource, userStatsAvgEventsPromise] = userStatsAverageEventsPerHour(userId, body)
        let isActive = true;
        userStatsAvgEventsPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
        }
    }, [startDate, endDate])

	return (
		<>
            <BasicInfoItem header="Average Events per Hour" text={statData && statData.average_alerts_per_hour.toFixed(2)} />
        </>
	);
}

export const UserStatsAverageEventsPerDistance = ({ userId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null)
    const [distance, setDistance] = useState(100);
    const unit = useDistanceUnit();
    const unitSystem = useUnitSystem();

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
            distance: convertToUnitSystem(distance, unitSystem)
        }
        const [cancelSource, userStatsAvgEventsPromise] = userStatsAverageEventsPerDistance(userId, body)
        let isActive = true;
        userStatsAvgEventsPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [startDate, endDate, distance])

    useEffect(() => {console.log(statData)}, [statData])

    const handleDistanceChanged = (event) => {
        setDistance(event.target.value);
    }

	return (
		<>
            <BasicInfoItem
                headerData={
                    <h5 style={ {color: 'grey', margin: 0, fontWeight: 'normal'}}>Average Events per&nbsp;
                        <Select
                            style={{color: 'grey', fontSize:'small', fontWeight: 'normal'}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={distance}
                            onChange={handleDistanceChanged}
                        >
                            <MenuItem value={1}>{`1 ${unit}`}</MenuItem>
                            <MenuItem value={10}>{`10 ${unit}`}</MenuItem>
                            <MenuItem value={100}>{`100 ${unit}`}</MenuItem>
                        </Select>
                    </h5>
                } text={statData && statData.average_alerts_per_distance.toFixed(2) + ` events per ${distance} ${unit}`} />
        </>
	);
}

const generateFixedCriteria = (userId, userGroupId) => {
    let fixedCriteria = {};
    if (userId) {
        fixedCriteria['user_id'] = userId;
    } else if (userGroupId) {
        fixedCriteria['group_id'] = userGroupId;
    }
    return fixedCriteria;
}

export const TrainingStats = ({ userId, userGroupId }) => {

	// useEffectFeatureCheck('role_can_view_own_courses');

  const featureCheck = useRoleFeatureCheck('role_can_view_own_courses');

  const [courseFixedCriteria, setCourseFixedCriteria] = useState(generateFixedCriteria(userId, userGroupId));
  const history = useHistory();
  const { url } = useRouteMatch();

  useEffect(() => {
    setCourseFixedCriteria(generateFixedCriteria(userId, userGroupId))
  }, [userId, userGroupId])

  const handleRowClick = (params) => {
    history.push(`${url}/${params.data.id}`)
  }

  const defaultCriteria = {
  }

  const defaultSearchCriteria = {
  }

  const searchCriteria = {
  }

  const fixedSearchCriteria = {
  }

  const allowedColumns = [
  ]

  if (!featureCheck) {
    return null
  }

  return (
    <>
      <ClientAdminCourseTable
        defaultCriteria={defaultCriteria}
        fixedCriteria={courseFixedCriteria}
        defaultSearchCriteria={defaultSearchCriteria}
        searchCriteria={searchCriteria}
        fixedSearchCriteria={fixedSearchCriteria}
        allowedColumns={allowedColumns}

        onRowClick={handleRowClick} />
    </>
  );
}

export const LessonsStats = ({ userId, userGroupId }) => {

	// useEffectFeatureCheck('role_can_view_own_courses');
  const featureCheck = useRoleFeatureCheck('role_can_view_own_courses');
    
    const { courseId } = useParams();
    const [lessonFixedCriteria] = useState({ course_id: courseId, ...generateFixedCriteria(userId, userGroupId) });

	const defaultCriteria = {
	}

	const defaultSearchCriteria = {
	}

	const searchCriteria = {
	}

	const fixedSearchCriteria = {
	}

	const allowedColumns = [
	]

  if (!featureCheck) {
    return null
  }

    return (
        <ClientAdminLessonsTable
            defaultCriteria={defaultCriteria}
            fixedCriteria={lessonFixedCriteria}
            defaultSearchCriteria={defaultSearchCriteria}
            searchCriteria={searchCriteria}
            fixedSearchCriteria={fixedSearchCriteria}
            allowedColumns={allowedColumns} />
    )
}
