import React from 'react';

import _ from 'lodash';

import { useIsCurrentUser5DTAdmin, useIsCurrentUserClientAdmin, useIsCurrentUserDriver, useUserId } from 'modules/useTokenStore';

import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { DrawerActions, DrawerButton, DrawerButtonConfirm, DrawerButtonUpload, DrawerConfirmMessage, DrawerDetails, DrawerHelp, DrawerTextField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useChangePicture, useFetchUser, useUpdateUser } from 'services/api';
import { CompanySelect } from 'components/autocomplete/company-select';
import { ActivationStatusSelect } from 'components/autocomplete/activation-status-select';
import { RoleSelect } from 'components/autocomplete/role-select';

const helpTextOther = `Use this form to update user details.`;
const helpTextProfile = `Use this form to update your own details.`;

const SaveMessage = () => {
	return (
		<DrawerConfirmMessage 
			message="Please confirm that you want to update the user with the requested changes." 
			severity="warning" 
		/>
	)
}

const _EditUserViewName = ({formData, setFormData, ...props}) => {
	const onInput = e => setFormData(state => ({...state, name: e.target.value}));
	return (
		<DrawerTextField id="name" label="Name" autoComplete="off" value={formData?.name} onInput={onInput} {...props} />
	)
}

const _EditUserViewSurname = ({formData, setFormData, ...props}) => {
	const onInput = e => setFormData(state => ({...state, surname: e.target.value}));
	return (
		<DrawerTextField id="surname" label="Surname" autoComplete="off" value={formData?.surname} onInput={onInput} {...props} />
	)
}

const _EditUserViewEmail = ({formData, setFormData, ...props}) => {
	const onInput = e => setFormData(state => ({...state, email: e.target.value}));
	return (
		<DrawerTextField id="email" label="Email" autoComplete="off" value={formData?.email} onInput={onInput} {...props} />
	)
}

const _EditUserViewRole = ({formData, setFormData, profile, ...props}) => {
	const help = useRoleHelp();
	const onChange = (data) => {
		setFormData(state => ({...state, roleOption: data}))
	};
	if (_.isEqual(profile, true)) {
		return null
	}
	return (
		<RoleSelect help={help} value={formData?.roleOption} defaultOption={formData?.defaultRoleOption} onChange={onChange} {...props} />
	)
}

// import * as React from 'react';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
// import Stack from '@mui/material/Stack';

// export default function UploadButtons() {
//   return (
//     <Stack direction="row" alignItems="center" spacing={2}>
//       <Button variant="contained" component="label">
//         Upload
//         <input hidden accept="image/*" multiple type="file" />
//       </Button>
//       <IconButton color="primary" aria-label="upload picture" component="label">
//         <input hidden accept="image/*" type="file" />
//         <PhotoCamera />
//       </IconButton>
//     </Stack>
//   );
// }

const _EditUserViewChangePicture = ({profile, formData, setFormData}) => {

	const updatePicture = useChangePicture({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `Profile picture update successfully.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not update profile picture.`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const setMessageSubmitting = useSetMessageSubmitting(setFormData);

	const onClick = (event) => {
		event?.preventDefault();
		const name = event?.target?.name ?? null;
		const image = event?.target?.files[0] ?? null;
		setMessageSubmitting();
		if (!_.isNil(image)) {
			updatePicture.mutate({
				id: formData.id,
				image: image,
			});
		}
	}

	if (_.isEqual(profile, true)) {
		return (
			<DrawerActions>
				<DrawerButtonUpload action="Change Picture" color="secondary" onClick={onClick} />
			</DrawerActions>
		)
	}
	return null
}

const _EditUserViewResetPassword = ({profile}) => {

	const history = useHistory();

	const { url } = useRouteMatch();

	const onClickReset = (event) => {
		event?.preventDefault();
		history.push(`${url}/reset-password`);
	}

	const onClickChange = (event) => {
		event?.preventDefault();
		history.push(`${url}/change-password`);
	}

	if (_.isEqual(profile, true)) {
		return (
			<DrawerActions>
				<DrawerButton action="Change Password" color="secondary" onClick={onClickChange} />
			</DrawerActions>
		)
	}
	return (
		<DrawerActions>
			<DrawerButton action="Reset Password" color="secondary" onClick={onClickReset} />
		</DrawerActions>
	)
}

const _EditUserView5DTAdmin = ({formData, setFormData, userData, onClickClose, updateUser, loading, profile}) => {

	const companyHelp = useCompanyHelp(formData?.companyOption ?? null, userData?.company_id ?? null);

	const activationHelp = useActivationHelp(formData?.activationOption ?? null, userData?.activation_status ?? null);

	const isSubmitDisabled = React.useMemo(() => {
		return _.isEqual(loading, true) 
			|| _.isNil(formData.companyOption)
	}, [formData, loading])

	const setMessageIncomplete = useSetMessageIncomplete(setFormData);
	const setMessageSubmitting = useSetMessageSubmitting(setFormData);

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setMessageIncomplete();
			return
		}
		setMessageSubmitting();
		updateUser.mutate({
			id: formData.id,
			company_id: formData.companyOption.value,
			activation_status: formData.activationOption.value,
			role: profile ? null : formData.roleOption.value,
		});
	}, [profile, updateUser, formData, isSubmitDisabled, setMessageIncomplete, setMessageSubmitting]);

	const onChangeCompany = (data) => {
		setFormData(state => ({...state, companyOption: data}))
	};

	const onChangeActivation = (data) => {
		setFormData(state => ({...state, activationOption: data}))
	};

	return (
		<>
			<DrawerTextField id="full_name" label="Full Name" value={formData.fullName} disabled />
			<DrawerTextField id="email" label="Email" value={formData.email} disabled />
			<CompanySelect autoFocus={true} help={companyHelp} value={formData.companyOption} onChange={onChangeCompany} />
			<_EditUserViewRole profile={profile} formData={formData} setFormData={setFormData} required />
			<ActivationStatusSelect help={activationHelp} value={formData.activationOption} onChange={onChangeActivation} />
			<DrawerDetails>
				<DrawerTextField id="role" label="Role" value={formData.role} disabled />
				<DrawerTextField id="id" label="ID" value={formData.id} disabled />
			</DrawerDetails>
			<DrawerActions>
				<DrawerButtonConfirm details={<SaveMessage />} title="Save changes to user registration?" action="Save" color="primary" onClick={onSubmit} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} />
			</DrawerActions>
			<_EditUserViewResetPassword profile={profile} />
		</>
	)
}

const _EditUserViewClientAdmin = ({formData, setFormData, userData, onClickClose, updateUser, loading, profile}) => {

	// const activationHelp = useActivationHelp(formData?.activationOption ?? null, userData?.activation_status ?? null);

	const isSubmitDisabled = React.useMemo(() => {
		if (profile) {
		return _.isEqual(loading, true) 
			|| _.isNil(formData.name)
			|| _.isNil(formData.email)
		}
		return _.isEqual(loading, true) 
			|| _.isNil(formData.name)
			|| _.isNil(formData.email)
			|| _.isNil(formData.role)
	}, [formData, loading, profile])

	const setMessageIncomplete = useSetMessageIncomplete(setFormData);
	const setMessageSubmitting = useSetMessageSubmitting(setFormData);

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setMessageIncomplete();
			return
		}
		setMessageSubmitting();
		if (profile) {
			updateUser.mutate({
				id: formData.id,
				name: formData.name,
				surname: formData.surname,
				email: formData.email,
				// activation_status: formData.activationOption.value,
			});
		} else {
			updateUser.mutate({
				id: formData.id,
				name: formData.name,
				surname: formData.surname,
				email: formData.email,
				role: formData.roleOption.value,
				// activation_status: formData.activationOption.value,
			});
		}
	}, [updateUser, formData, isSubmitDisabled, setMessageIncomplete, setMessageSubmitting, profile]);

	// const onChangeActivation = (data) => {
	// 	setFormData(state => ({...state, activationOption: data}))
	// };

	return (
		<>
			<_EditUserViewName formData={formData} autoFocus={true} setFormData={setFormData} required />
			<_EditUserViewSurname formData={formData} setFormData={setFormData} />
			<_EditUserViewEmail formData={formData} setFormData={setFormData} required />
			<_EditUserViewRole profile={profile} formData={formData} setFormData={setFormData} required />

			{/* <ActivationStatusSelect help={activationHelp} value={formData.activationOption} onChange={onChangeActivation} /> */}
			<DrawerDetails>
				<DrawerTextField id="role" label="Role" value={formData.role} disabled />
				<DrawerTextField id="id" label="ID" value={formData.id} disabled />
			</DrawerDetails>
			<DrawerActions>
				<DrawerButtonConfirm details={<SaveMessage />} title="Save changes to user registration?" action="Save" color="primary" onClick={onSubmit} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} />
			</DrawerActions>
			<_EditUserViewResetPassword profile={profile} />
			<_EditUserViewChangePicture formData={formData} setFormData={setFormData} profile={profile} />
		</>
	)
}

const _EditUserViewDriver = ({formData, setFormData, userData, onClickClose, updateUser, loading, profile}) => {

	const isSubmitDisabled = React.useMemo(() => {
		return _.isEqual(loading, true) 
	}, [formData, loading])

	const setMessageIncomplete = useSetMessageIncomplete(setFormData);
	const setMessageSubmitting = useSetMessageSubmitting(setFormData);

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setMessageIncomplete();
			return
		}
		setMessageSubmitting();
		updateUser.mutate({
			id: formData.id,
		});
	}, [updateUser, formData, isSubmitDisabled, setMessageIncomplete, setMessageSubmitting]);

	return (
		<>
			<DrawerTextField id="full_name" label="Full Name" value={formData.fullName} disabled />
			<DrawerTextField id="email" label="Email" value={formData.email} disabled />
			<DrawerDetails>
				<DrawerTextField id="role" label="Role" value={formData.role} disabled />
				<DrawerTextField id="id" label="ID" value={formData.id} disabled />
			</DrawerDetails>
			<DrawerActions>
				<DrawerButtonConfirm details={<SaveMessage />} title="Save changes to user registration?" action="Save" color="primary" onClick={onSubmit} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} />
			</DrawerActions>
			<_EditUserViewResetPassword profile={profile} />
		</>
	)
}

const _EditUserViewSwitch = ({...props}) => {

	const iAm5DTAdmin = useIsCurrentUser5DTAdmin();
	const iAmClientAdmin = useIsCurrentUserClientAdmin();
	const iAmDriver = useIsCurrentUserDriver();

	if (iAm5DTAdmin) {
		return (
			<_EditUserView5DTAdmin {...props} />
		)
	}
	if (iAmClientAdmin) {
		return (
			<_EditUserViewClientAdmin  {...props} />
		)
	}
	if (iAmDriver) {
		return (
			<_EditUserViewDriver  {...props} />
		)
	}
	return (
		<_EditUserViewDriver  {...props} />
	)
}

const useCompanyHelp = (companyOption, companyId) => {
	return React.useMemo(() => {
		const newCompany = !_.isEqual(companyOption?.value ?? null, companyId ?? null);
		return newCompany ? `The user will be transferred to the selected company.` : null;
	}, [companyOption, companyId]);
}

const _roleHelp = `The user role will be changed.`;

const useRoleHelp = () => {
	return _roleHelp;
}

const useActivationHelp = (activationOption, activationStatus) => {
	return React.useMemo(() => {
		const nextActivation = activationOption?.value ?? null;
		const newActivation = !_.isEqual(nextActivation, activationStatus ?? null);
		if (!newActivation) {
			return null
		}
		if (_.isEqual(nextActivation, "enabled")) {
			return "The user will be re-enabled. All access restrictions will be lifted."
		}
		else if (_.isEqual(nextActivation, "disabled")) {
			return "The user will be disabled. Access restrictions will apply."
		}
		else if (_.isEqual(nextActivation, "retired")) {
			return "The user will be retired. It will no longer function, but historic data will be retained. The data will be eligible for deletion during maintenance tasks."
		}
		else {
			return null
		}
	}, [activationOption, activationStatus]);
}

const useSetMessageIncomplete = (setFormData) => React.useCallback(() => {
		setFormData(state => ({
			...state, 
			alertMessage: 'Form incomplete. Please provide the missing information.', 
			alertSeverity: 'error'
		}));
	}, [setFormData]);

const useSetMessageSubmitting = (setFormData) => React.useCallback(() => {
		setFormData(state => ({
			...state, 
			loading: true, 
			alertMessage: 'Updating user details...', 
			alertSeverity: 'info'
		}));
	}, [setFormData]);

const _EditUserView = ({userId}) => {

	const { id: urlUserId } = useParams();

	const _userId = userId ?? urlUserId;

	// const userRole = useUserRole();
	const currentUserId = useUserId();

	const profile = _.isEqual(_userId, currentUserId);

	const { data: userData, isFetching } = useFetchUser(_userId);

	const classes = useClassesFormStyle();

	const history = useHistory();

  const [formData, setFormData] = React.useState({
		id: '',
		fullName: '',
		name: '',
		surname: '',
		email: '',
		role: '',
		activationOption: null,
		companyOption: null,
		roleOption: null,
		defaultRoleOption: null,
		alertSeverity: 'info',
		alertMessage: '',
		loading: false,
	});

	const loading = React.useMemo(() => formData.loading || isFetching, [isFetching, formData.loading])

	React.useEffect(() => {
		// console.warn('admin-user-show', {userData})
		const companyOption = _.isNil(userData?.company_id) ? null : {
			value: userData?.company_id,
			label: userData?.company_name,
		};

		const activationStatus = userData?.activation_status ?? 'enabled';
		const activationOption = {
			value: activationStatus,
			label: _.startCase(activationStatus),
		};

		const roleOption = userData?.role ? {
			value: userData?.role,
			label: userData?.role,
		} : null;

		const role = userData?.role ?? 'None';

		const name = userData?.name ?? '';
		const surname = userData?.surname ?? '';
		const fullName = `${userData?.name ?? ''} ${userData?.surname ?? ''}`
		const email = userData?.email ?? 'None';
		setFormData(state => ({
				...state, 
				fullName,
				name,
				surname,
				email,
				role,
				roleOption,
				defaultRoleOption: roleOption,
				id: userData?.id ?? '',
				activationOption,
				companyOption,
			}))
	}, [userData])

	// todo: display confirmation dialog on submit, with warning clearly stating the effects of the change
	// - existing vehicles will be unlinked
	// - all trips will be moved to the new company

	const _updateUser = useUpdateUser({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `User successfully updated.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not update user: ${p.message}`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const onClickClose = (event) => {
		event?.preventDefault();
		history.goBack();
	}

	const _helpText = _.isEqual(profile, true) ? helpTextProfile : helpTextOther;

	const _titleText = _.isEqual(profile, true) ? 'Update Profile' : 'Edit User';

	return (
		<form noValidate classes={classes.body}>
			<DrawerTitle>{_titleText}</DrawerTitle>
			<DrawerHelp alert={formData.alertMessage} severity={formData.alertSeverity}>{_helpText}</DrawerHelp>
			<_EditUserViewSwitch formData={formData} setFormData={setFormData} userData={userData} onClickClose={onClickClose} updateUser={_updateUser} loading={loading} profile={profile} />
		</form>
	)
}

export const EditUserView = ({userId}) => {
	return (
		<_EditUserView userId={userId} />
	)
}
