import './vehicle-group-tabs.css';
import React, { useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';

import { VehicleGroupOwnerParticipants, VehicleGroupVehicleParticipants, VehicleGroupViewerParticipants } from './vehicle-list/vehicle-list';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

// TODO: this could be improved
function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		style={ {height: "calc(100% - 50px)", margin: 15 }}
		{...other}
	  >
		{value === index && 
			children
		}
	  </div>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
        height: '100%',
		// backgroundColor: theme.palette.background.paper,
	},
}));
export const VehicleGroupTabs = ({ vehicleGroup, vehicleGroupId }) => {

	const [value, setValue] = useState(0);
	const classes = useStyles();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			{/* <AppBar position="static"> */}
				<Tabs value={value} onChange={ handleChange } variant="scrollable" scrollButtons="on" aria-label="simple tabs">
					{/* <Tab className={"tab-buttons first-tab-button"} label="Statistics" {...a11yProps(0)} /> */}
					{/* <Tab className={"tab-buttons"} label="Event Statistics" {...a11yProps(1)} /> */}
					{/* <Tab className={"tab-buttons"} label="Trips" {...a11yProps(2)} /> */}
					{/* <Tab className={"tab-buttons"} label="Training" {...a11yProps(3)} /> */}
					<Tab className={"tab-buttons"} label="Vehicles" {...a11yProps(4)} />
					<Tab className={"tab-buttons"} label="Owners" {...a11yProps(5)} />
					<Tab className={"tab-buttons"} label="Viewers" {...a11yProps(6)} />
					{/* <Tab className={"tab-buttons"} label="Events" {...a11yProps(7)} /> */}
				</Tabs>
			{/* </AppBar> */}
			{/* <TabPanel value={value} index={0}>
				Statistics
			</TabPanel>
			<TabPanel value={value} index={1}>
				Event Statistics
			</TabPanel>
			<TabPanel value={value} index={2}>
				Trips
			</TabPanel>
			<TabPanel value={value} index={3}>
				Training
			</TabPanel> */}
			{/* <TabPanel value={value} index={4}> */}
			<TabPanel value={value} index={0}>
				<VehicleGroupVehicleParticipants canEdit={vehicleGroup && vehicleGroup.is_owner === 'true'} vehicleGroupId={vehicleGroupId} />
			</TabPanel>
			{/* <TabPanel value={value} index={5}> */}
			<TabPanel value={value} index={1}>
				<VehicleGroupOwnerParticipants canEdit={vehicleGroup && vehicleGroup.is_owner === 'true'} vehicleGroupId={vehicleGroupId} />
			</TabPanel>
			{/* <TabPanel value={value} index={6}> */}
			<TabPanel value={value} index={2}>
				<VehicleGroupViewerParticipants canEdit={vehicleGroup && vehicleGroup.is_owner === 'true'} vehicleGroupId={vehicleGroupId} />
			</TabPanel>
			{/* <TabPanel value={value} index={7}>
				Events
			</TabPanel> */}
		</div>
	);
}
