import "./users-page.css";
import _ from "lodash";
import React from 'react';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';

import { User } from './user/user';
import { Users } from "./users/users";
import { UserCreate } from "./user-create/user-create";
import { UserEventStatistics, UserStatistics } from "components/tabs/user-tabs/user-tabs";
import { LessonsStats, TrainingStats } from "components/statistics/user-statistics";
import { UserTripTable, TripMap } from "components/tabs/user-tabs/user-trips/user-trips";
import { UserAlerts } from "components/tabs/user-tabs/user-alerts/user-alerts";
import { clearSelectedEventData, setBreadcrumb, setFocusDeviceTrip, setFocusTripEvent, setSelectedDriverId, setSelectedEventData, setSelectedTripId } from "modules/useGlobalStore";
import { useFetchUser } from "services/api";
import { EditUserView } from "components/edit-view/edit-user";
import { CrudDrawer } from "components/drawers/crud-drawer";
import ChangePassword from "components/edit-view/change-password";
import ResetPassword from "components/edit-view/reset-password";

const TrainingStatsWrapped = () => {
	const { userId } = useParams();
	return (
		<TrainingStats userId={userId} />
	)
}

const UserEventStatisticsWrapped = () => {
	const { userId } = useParams();
	return (
		<UserEventStatistics userId={userId} />
	)
}

const UserStatisticsWrapped = () => {
	const { userId } = useParams();
	return (
		<UserStatistics userId={userId} />
	)
}

const UserEditWrapped = () => {
	const { userId } = useParams();
	return (
		<>
			<UserBreadcrumb userId={userId} />
			<CrudDrawer>
				<EditUserView userId={userId} />
			</CrudDrawer>
		</>
	)
}

const UserChangePasswordWrapped = () => {
	const { userId } = useParams();
	return (
		<>
			<UserBreadcrumb userId={userId} />
			<CrudDrawer>
				<ChangePassword userId={userId} />
			</CrudDrawer>
		</>
	)
}

const UserResetPasswordWrapped = () => {
	const { userId } = useParams();
	return (
		<>
			<UserBreadcrumb userId={userId} />
			<CrudDrawer>
				<ResetPassword userId={userId} />
			</CrudDrawer>
		</>
	)
}

const UserBreadcrumb = ({userId}) => {
	const { data } = useFetchUser(userId);

	React.useEffect(() => {
		if (_.isNil(userId) || _.isNil(data)) {
			return
		}
    setBreadcrumb(userId, `${data.name} ${data.surname}`);
	}, [userId, data]);

	return null;
}

const UserWrapped = () => {
	const { userId } = useParams();

	return (
		<>
			<UserBreadcrumb userId={userId} />
			<User userId={userId} />
		</>
	)
}

const UserTripMapWrapped = () => {
	const { userId, tripId } = useParams();
	React.useEffect(() => {
		// console.log('UserTripMapWrapped')
		setSelectedDriverId(userId);
		setSelectedTripId(tripId);
		clearSelectedEventData();
		setFocusDeviceTrip(false);
		setFocusTripEvent(false);
	}, [userId, tripId]);
	return (
		<TripMap />
	)
}

export const UsersPage = () => {

	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}`} exact>
				<Users />
			</Route>
			<Route path={`${path}/create`} exact >
				<UserCreate />
			</Route>
			<Route path={`${path}/:userId/statistics`} exact >
				<UserStatisticsWrapped />
			</Route>
			<Route path={`${path}/:userId/event-statistics`} exact >
				<UserEventStatisticsWrapped />
			</Route>
			<Route path={`${path}/:userId/training`} exact >
				<TrainingStatsWrapped />
			</Route>
			<Route path={`${path}/:userId/training/:courseId`} exact >
				<LessonsStats />
			</Route>
			<Route path={`${path}/:userId/trips`} exact >
				<UserTripTable />
			</Route>
			<Route path={`${path}/:userId/trips/:tripId`} exact >
				<UserTripMapWrapped />
			</Route>
			<Route path={`${path}/:userId/events`} exact >
				<UserAlerts />
			</Route>
			<Route path={`${path}/:userId`}>
				<UserWrapped />
				<Route path={`${path}/:userId/edit`} exact>
					<UserEditWrapped />
				</Route>
				<Route path={`${path}/:userId/edit/change-password`} exact>
					<UserChangePasswordWrapped />
				</Route>
				<Route path={`${path}/:userId/edit/reset-password`} exact>
					<UserResetPasswordWrapped />
				</Route>
			</Route>
		</Switch>
	);
}
