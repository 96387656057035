import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import _ from 'lodash';

import { createCriteria } from "components/data-tables/data-table-functions";
import { useFetchUsers } from "services/api";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';

import { DrawerActions, DrawerAlert, DrawerButton, DrawerHelp, DrawerTitle } from 'theme/theme';

const _UserRow = ({userData}) => {
	const primary = `${userData?.name} ${userData?.surname}`;
	const secondary = `${userData?.email}`;

	const { url } = useRouteMatch();
	const history = useHistory();

	const _onClick = () => {
		history.push(`${url}/${userData?.id}`);
	}

	return (
		<ListItem button onClick={_onClick}>
			<ListItemAvatar>
				<Avatar>
					<SupervisorAccount />
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={primary}
				secondary={secondary}
			/>
			{/* <ListItemSecondaryAction>
									<IconButton edge="end" aria-label="delete">
										<DeleteIcon />
									</IconButton>
								</ListItemSecondaryAction> */}
		</ListItem>
	)
}

// todo: 5DTAdmin should probably see all devices, even those marked as deleted/archived, so that they can be unarchived
const _AdminUserList = ({companyId}) => {

	const criteria = React.useMemo(() => (
		// createCriteria(1, Number.MAX_SAFE_INTEGER, false, "", { 
		createCriteria(1, 100000, false, "name", { 
			text: "",
			role: "ClientAdmin",
			company_id: companyId,
		}
	)), [companyId]);

	// const { path, url } = useRouteMatch();
	// const history = useHistory();

	// todo: 5DTAdmin should only return ClientAdmin roles
	const { userList } = useFetchUsers(criteria);

	if (_.isEmpty(userList)) {
		return (
			<DrawerAlert severity="warning">This company has no registered administrators.</DrawerAlert>
		)
	}

	return (
		<List dense={false}>
			{userList.map((d, i) => (
				<_UserRow key={`user-${i}`} userData={d} />
			))}
		</List>
	)
}

export default ({companyId}) => {
	const history = useHistory();
	const onClickClose = () => {
		history.goBack();
	}
	return (
		<>
			<DrawerTitle>Company Admins</DrawerTitle>
			<DrawerHelp>The list of company administrators.</DrawerHelp>
			<_AdminUserList companyId={companyId} />
			<DrawerActions>
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} />
			</DrawerActions>
		</>
	)
}
