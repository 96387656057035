import "./admin-companies.css";
import React, { useCallback, useState } from 'react';
import { useHistory, useRouteMatch, Route, Switch, useParams } from 'react-router-dom';

import { Button } from "@material-ui/core";

import { ServerSideTable } from 'components/table/table';
import { createCriteria } from "components/data-tables/data-table-functions";
import { useFetchCompanies } from "services/api";

import { AdminCompanyCreate } from 'admin-pages/admin-company-page/admin-company-create/admin-company-create';
import { AdminCompanyEdit } from 'admin-pages/admin-company-page/admin-company-edit/admin-company-edit';

import { AdminCompany } from 'admin-pages/admin-company-page/admin-company/admin-company';
import AdminUserCreate from 'admin-pages/admin-users-page/admin-user-create';
import AdminUserList from 'admin-pages/admin-users-page/admin-user-list2';
import AdminUserShow from 'admin-pages/admin-users-page/admin-user-show';

import { CrudDrawer } from "components/drawers/crud-drawer";
import AdminCompanyFeatures from "../admin-company-features";

const _AdminCompanyShow = () => {
	return (
		<>
			<AdminCompaniesDefault />
			<CrudDrawer>
				<AdminCompany />
			</CrudDrawer>
		</>
	)
}

const _AdminCompanyAddAdminUser = () => {
	const { companyId } = useParams();
	return (
		<>
			<AdminCompaniesDefault />
			<CrudDrawer>
				<AdminUserCreate companyId={companyId} />
			</CrudDrawer>
		</>
	)
}

const _AdminCompanyViewAdmins = () => {
	const { companyId } = useParams();
	return (
		<>
			<AdminCompaniesDefault />
			<CrudDrawer>
				<AdminUserList companyId={companyId} />
			</CrudDrawer>
		</>
	)
}

const _AdminCompanyViewFeatures = () => {
	const { companyId } = useParams();
	return (
		<>
			<AdminCompaniesDefault />
			<CrudDrawer>
				<AdminCompanyFeatures companyId={companyId} />
			</CrudDrawer>
		</>
	)
}

const _AdminCompanyEditAdmins = () => {
	const { companyId, userId } = useParams();
	return (
		<>
			<AdminCompaniesDefault />
			<CrudDrawer>
				<AdminUserShow userId={userId} />
			</CrudDrawer>
		</>
	)
}

export const AdminCompaniesDefault = () => {

	const [tableCriteria, setTableCriteria] = useState(
		createCriteria(1, 20, false, "", {
			text: "",
			serial_number: "",
			currently_online: "",
			company: "",
		}));

	const { url } = useRouteMatch();
	const history = useHistory();

	const { rowList, rowCount, isFetching } = useFetchCompanies(tableCriteria);

	const handleRowClick = (data) => {
		history.push(`${url}/${data.data.id}`, { [data.data.id]: `${data.data.name}`});
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const handleCreate = () => {
		history.push(`${url}/create`);
	}

	const columns = [
		{ field: "id", headerName: "ID", width: 200 },
		{ field: "name", headerName: "Name", width: 150 },
	];

	const searchCriteriaSkeleton = [
		// { field: "text", header: "Search" },
	];

	return (
		<div className={"company-table-container"}>
			<h1>Companies</h1>
			<Button variant="contained" color="primary" onClick={ handleCreate }>Register</Button>
			<ServerSideTable
				columns={columns}
				rows={rowList}
				loading={isFetching}
				total={rowCount}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const AdminCompanies = () => {
	const { path } = useRouteMatch();
	return (
		<>
			<Switch>
				<Route path={`${path}`} exact>
					<AdminCompaniesDefault />
				</Route>
				<Route path={`${path}/create`} exact >
					<AdminCompaniesDefault />
					<CrudDrawer>
						<AdminCompanyCreate />
					</CrudDrawer>
				</Route>
				<Route path={`${path}/:companyId`} exact >
					<_AdminCompanyShow />
				</Route>
				<Route path={`${path}/:companyId/edit`} exact >
					<AdminCompanyEdit />
				</Route>
				<Route path={`${path}/:companyId/admins`} exact >
					<_AdminCompanyViewAdmins />
				</Route>
				<Route path={`${path}/:companyId/features`} exact >
					<_AdminCompanyViewFeatures />
				</Route>
				<Route path={`${path}/:companyId/admins/:userId`} exact >
					<_AdminCompanyEditAdmins />
				</Route>
				<Route path={`${path}/:companyId/add-admin-user`} exact >
					<_AdminCompanyAddAdminUser />
				</Route>
			</Switch>
		</>
	)
}
