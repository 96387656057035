import './preferences.css';

import _ from 'lodash';

import { setDateFormat, setDriverIconSize, setEventIconSize, setPathSize, setStrokeSize, setUnitSystem, useDateFormat, useDriverIconSize, usePathSize, useStrokeSize, useUserEmail } from 'modules/useTokenStore';

import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Slider from '@material-ui/core/Slider';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useUnitSystem } from 'shared-hooks/useDistanceUnit';
import { useEventIconSize } from 'modules/useGlobalStore';
import { StaticMapContainer } from 'components/map/map-container';

const unitOptions = [
	{ value: 'metric', label: 'Metric' },
	{ value: 'imperial_us', label: 'Imperial (US)' },
]

const dateOptions = [
	{ value: 'MM/dd/yyyy', label: 'MM/dd/yyyy' },
	{ value: 'dd/MM/yyyy', label: 'dd/MM/yyyy' },
]

const useStyles = makeStyles((theme) =>
	createStyles({
		error: {
			color: theme.palette.error.main,
		},
		card: {
			// minWidth: 275,
			// minWidth: 300,
			// maxWidth: 300,
			// maxWidth: 620,
			height: '100%',
		},
		cardActions: {
			width: '100%',
			// textAlign: 'right',
			justifyContent: 'flex-end',
		},
		title: {
			fontSize: 14,
		},
		pos: {
			marginBottom: 12,
		},
		gridRoot: {
			display: "grid",
			gridGap: theme.spacing(1),
			gridTemplateColumns: "repeat(auto-fit, minmax(275px, 1fr))",
		},
		gridCell: {
			// width: '100%',
			// backgroundColor: "rgba(255, 255, 255, 0.8)",
			// border: "1px solid rgba(0, 0, 0, 0.8)",
			// padding: 20,
			// fontSize: 30,
			// textAlign: "center",
			// padding: theme.spacing(1),
		},
		gridMap: {
			// gridColumn: "1 / -1",
			// gridColumn: "3 / -1",
			// gridColumn: "-2",
			// gridColumn: "3 / -1",
			// gridColumn: "1 / -2",
		},
		gridField: {
			margin: theme.spacing(1, 0, 1, 0),
			width: '100%',
		},
	}),
);

const MapSettings = () => {
	const classes = useStyles();

	const eventIconSize = useEventIconSize();
	const driverIconSize = useDriverIconSize();
	const pathSize = usePathSize();
	const strokeSize = useStrokeSize();

	const handleEventIconSize = React.useCallback((event, value) => {
		setEventIconSize(value);
	}, [setEventIconSize]);

	const handleDriverIconSize = React.useCallback((event, value) => {
		setDriverIconSize(value);
	}, [setDriverIconSize]);

	const handlePathSize = React.useCallback((event, value) => {
		setPathSize(value);
	}, [setPathSize]);

	const handleStrokeSize = React.useCallback((event, value) => {
		setStrokeSize(value);
	}, [setStrokeSize]);

	return (
		<Card className={classes.card}>
			<CardHeader
				title="Map"
				subheader="These settings will affect your map display"
			/>
			<CardContent>
				<div className={classes.gridRoot}>
					<div className={classes.gridCell}>
						<Typography id="event-icon-size" gutterBottom>
							Event Icon Size:
						</Typography>
						<Slider
							aria-labelledby="event-icon-size"
							valueLabelDisplay="auto"
							marks
							onChange={handleEventIconSize}
							value={eventIconSize}
							step={5}
							min={20}
							max={100}
						/>
						<Typography id="driver-icon-size" gutterBottom>
							Driver Icon Size:
						</Typography>
						<Slider
							aria-labelledby="driver-icon-size"
							valueLabelDisplay="auto"
							marks
							onChange={handleDriverIconSize}
							value={driverIconSize}
							step={0.5}
							min={1}
							max={4}
						/>
						<Typography id="path-size" gutterBottom>
							Path Size:
						</Typography>
						<Slider
							aria-labelledby="path-size"
							valueLabelDisplay="auto"
							marks
							onChange={handlePathSize}
							value={pathSize}
							step={0.5}
							min={1}
							max={4}
						/>
						<Typography id="stroke-size" gutterBottom>
							Stroke Size:
						</Typography>
						<Slider
							aria-labelledby="stroke-size"
							valueLabelDisplay="auto"
							marks
							onChange={handleStrokeSize}
							value={strokeSize}
							step={0.5}
							min={1}
							max={4}
						/>
					</div>
					<div className={classes.gridCell}>
						<StaticMapContainer center={{ lat: -25.7470155, lng: 28.2725573 }} zoom={15} />
					</div>
				</div>
			</CardContent>
		</Card>
	);
}

const DisplaySettings = () => {
	const classes = useStyles();

	const storedUnitSystem = useUnitSystem();
	const storedDateFormat = useDateFormat();

	// https://github.com/JedWatson/react-select/discussions/4453
	const unitSystem = React.useMemo(() => _.find(unitOptions, (opt) => opt.value === storedUnitSystem), [storedUnitSystem, unitOptions]);
	const dateFormat = React.useMemo(() => _.find(dateOptions, (opt) => opt.value === storedDateFormat), [storedDateFormat, dateOptions]);

	const onSelectDateFormat = React.useCallback((event, data) => {
		setDateFormat(data.value);
	}, [setDateFormat]);

	const onSelectUnitSystem = React.useCallback((event, data) => {
		setUnitSystem(data.value);
	}, [setUnitSystem]);


	return (
		<Card className={classes.card}>
			<CardHeader
				title="General"
				subheader="These settings will affect how statistics are presented"
			/>
			<CardContent>
				<Autocomplete
					disableClearable
					getOptionLabel={(option) => option.label}
					value={unitSystem}
					onChange={onSelectUnitSystem}
					options={unitOptions}
					renderInput={(params) => <TextField className={classes.gridField} {...params} label="Unit System" variant="outlined" />}
				/>
				<Autocomplete
					disableClearable
					getOptionLabel={(option) => option.label}
					value={dateFormat}
					onChange={onSelectDateFormat}
					options={dateOptions}
					renderInput={(params) => <TextField className={classes.gridField} {...params} label="Date Format" variant="outlined" />}
				/>
			</CardContent>
		</Card>
	)
}

export const Preferences = () => {
	const classes = useStyles();
	return (
		<CssBaseline>
			<div className={classes.gridRoot}>
				<div className={classes.gridMap}>
					<MapSettings />
				</div>
				<div className={classes.gridCell}>
					<DisplaySettings />
				</div>
			</div>
		</CssBaseline>
	);
}

