import { useMediaQuery } from "react-responsive";

export const useScreenType = () => {
	const isWeb = useMediaQuery({ minWidth: 1336 });
	const isTablet = useMediaQuery({ minWidth: 1265 });
	const isPhone = useMediaQuery({ minWidth: 950 });

	if (isWeb) {
		// console.log("web")
		return "web";
	}
	if (isTablet) {
		// console.log("small-web")
		return "small-web";
	}
	if (isPhone) {
		// console.log("tablet")
		return "tablet";
	}

	// console.log("phone")
	return "phone";
};