import { axiosPostRequest } from './api-request';

function userGroups(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User Groups fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function userGroup(id) {
	try {
		const body = {
			id: id
		}
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group/get`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User Group fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function createUserGroup(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group/create`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User Group creation failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function deleteUserGroup(data) {
	try {
		const body = { id: data };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group/delete`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User Group deletion failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function updateUserGroup(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group/update`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'User Group deletion failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function manageGroupParticipants(groupId, addedUsers, removedUsers, type) {
	try {
		const body = {
			group_id: groupId,
			add: addedUsers,
			remove: removedUsers,
			type: type,
		}
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group/manage-participants`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || `User Group ${type} management failed`);
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function userGroupUsers(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group/get/users`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || `User fetch failed`);
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function userGroupOwners(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group/get/owners`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || `Owner fetch failed`);
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function userGroupViewers(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/user-group/get/viewers`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || `Viewer fetch failed`);
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export {
	userGroups,
	userGroup,
	createUserGroup,
	deleteUserGroup,
	updateUserGroup,
	manageGroupParticipants,
	userGroupUsers,
	userGroupOwners,
	userGroupViewers,
};
