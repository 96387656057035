import { apiGet, apiPost, axiosPostRequest } from './api-request';

export function deviceAll(data) {

	// todo: the api rejects certain searchCriteria by failing the request, so we try to handle those situations here
	const searchCriteria = {
		...(data?.searchCriteria ?? {}),
		// todo: implement support for company field in the backend
		company: data?.searchCriteria?.company || null,
		currently_online: data?.searchCriteria?.currently_online || null,
		serial_number: data?.searchCriteria?.serial_number || null,
		text: data?.searchCriteria?.text || null,
	}

	if (searchCriteria.currently_online) {
		// To handle the situation where the user types in text with numbers or something else.
		// todo: this should be handled by the api backend
		searchCriteria.currently_online = `${searchCriteria.currently_online === 'true'}`;
	}

	const criteria = {
		...data,
		searchCriteria,
	}
	// console.log('deviceAll', criteria);

	try {
		return axiosPostRequest(`${process.env.REACT_APP_EDGE_SERVICE_URL}/device`, criteria)
	} catch (e) {
		console.log("Error:", e)
    if (e.response) {
      throw new Error(e.response.data.message || 'Devices fetch failed');
    } else {
      throw new Error(`Devices fetch failed: ${e}...`);
    }
	}
}

export function deviceDelete(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_EDGE_SERVICE_URL}/device/delete`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Device deletion failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function deviceAssignCompany(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_EDGE_SERVICE_URL}/device/assign-company`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Device failed to be assign to company');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function deviceUnassignCompany(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_EDGE_SERVICE_URL}/device/unassign-company`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Device failed to be unassigned from company');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function deviceLinkVehicle(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/device-link/link`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Device failed to be unassigned from company');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function deviceUnlinkVehicle(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/device-link/unlink`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Device failed to be unassigned from company');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export async function fetchDevicesLive() {
	const response = await apiGet(`/v1/device/live`)
	const result = response?.result ?? null;
	console.warn('fetchDevicesLive', {
		result
	});
	return result;
}

export async function fetchDeviceBySerialNumber(serialNumber) {
	console.warn('fetchDeviceBySerialNumber', {serialNumber})
	const response = await apiPost(`/v1/device/get`, {
		serial_number: serialNumber
	})
	return response?.result ?? null;
}

export async function deviceCreate(data) {
	console.warn('deviceCreate', {data})
	const response = await apiPost(`/v1/device/create`, data);
	return response?.result ?? null;
}

export async function deviceUpdate(data) {
	console.warn('deviceUpdate', {data})
	const response = await apiPost(`/v1/device/update`, data);
	return response?.result ?? null;
}
