import './user-group.css';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { deleteUserGroup, userGroup } from 'services/user-group';

import { UserGroupInfoView } from 'components/info-view/info-view';
import { TinyInfoView } from 'components/info-view/tiny-info-view/tiny-info-view';
import { UserGroupTabs } from 'components/tabs/user-group-tabs/user-group-tabs';
import { MultiViewContainer } from 'components/view-container/view-container';
import { useIsMounted } from "shared-hooks/useIsMounted";
import { REQUEST_ABORT_REASON } from "shared-functions/shared-functions";

export const UserGroup = () => {

	const isMounted = useIsMounted();
	const deleteUserGroupCancelSourceRef = useRef(null);
	const [groupData, setGroupData] = useState();
	const [minified, setMinified] = useState(false);
	const history = useHistory();
	const { url } = useRouteMatch();
	const { userGroupId } = useParams();

	useEffect(() => {
		if (!userGroupId) return;
		const [cancelSource, userGroupGetPromise] = userGroup(userGroupId)
		let isActive = true;
		userGroupGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;
				setGroupData(response.result);
				history.replace(url, { [userGroupId]: `${response.result.name}`})
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err);
			});
		return () => {
			isActive = false;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [userGroupId]);

	const handleMinimize = (param) => {
		setMinified(param)
	}

	const handleEditUserGroup = () => {
		history.push(`${url}/edit`, { [userGroupId]: `${groupData.name}` });
	}

	const handleDeleteUserGroup = () => {
		if (!userGroupId) return;
        if (deleteUserGroupCancelSourceRef.current) return;
		const [cancelSource, deleteUserGroupPromise] = deleteUserGroup(userGroupId)
		deleteUserGroupCancelSourceRef.current = cancelSource;
		deleteUserGroupPromise
			.then((response) => {
				if (!isMounted()) return;
                deleteUserGroupCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((err) => {
				if (!isMounted()) return;
                deleteUserGroupCancelSourceRef.current = null;
				console.log(err);
			});
	}

	const viewTinySkeleton = [
		{ key: 1, property: "name", header: "NAME:" },
		{ key: 2, property: "company_name", header: "COMPANY:" },
		{ key: 3, property: "num_users", header: "NUMBER OF USERS:" },
	];

	return (
		<MultiViewContainer
			minified={minified}

			tinyChild={
				<TinyInfoView
					skeleton={viewTinySkeleton}
					data={groupData}
					confirmTitle='Delete User Group?'
					confirmDescription={`You are about to delete the group.`}
					
					onDelete={ handleDeleteUserGroup }
					onEdit={ handleEditUserGroup }
					onMaximize={ handleMinimize } />
			}
			viewChild={ <UserGroupInfoView data={groupData} allowEdit allowDelete onMinimize={ handleMinimize } /> }
			rightChild={<UserGroupTabs userGroup={groupData} userGroupId={userGroupId} />}/>
	);
}
