import DateFnsUtils from "@date-io/date-fns"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { usePreferenceStore } from "modules/useTokenStore"
import { useEffect, useState } from "react";

export const DateRange = ({ defaultStartDate, defaultEndDate, onStartDateChange, onEndDateChange }) => {

    const dateFormat = usePreferenceStore(store => store.preferences.date_format)
    const [format, setFormat] = useState("dd/MM/yyyy");
	const [now] = useState(new Date());
	const [startTime, setStartTime] = useState(defaultStartDate ? defaultStartDate : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
	const [endTime, setEndTime] = useState(defaultEndDate ? defaultEndDate : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));

    useEffect(() => {
        if (dateFormat) {
            setFormat(dateFormat);
        }
    }, [dateFormat])

	const handleStartDateChange = (start) => {
		setStartTime(start)
        onStartDateChange(start);
	}

	const handleEndDateChange = (end) => {
		setEndTime(end)
        onEndDateChange(end);
	}

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format={format}
                    margin="normal"
                    id="start-date"
                    label="Start Date"
                    value={startTime}
                    onChange={ handleStartDateChange }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format={format}
                    margin="normal"
                    id="end-date"
                    label="End Date"
                    value={endTime}
                    onChange={ handleEndDateChange }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
            </MuiPickersUtilsProvider>
        </>
    )
}