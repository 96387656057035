import './vehicle-group.css';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

import { vehicleGroup } from 'services/vehicle-group';

import { VehicleGroupInfoView } from 'components/info-view/info-view';
import { TinyInfoView } from 'components/info-view/tiny-info-view/tiny-info-view';
import { MultiViewContainer } from 'components/view-container/view-container';
import { VehicleGroupTabs } from 'components/tabs/vehicle-group-tabs/vehicle-group-tabs';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';
import { useIsMounted } from 'shared-hooks/useIsMounted';

export const VehicleGroup = () => {

	const isMounted = useIsMounted();
	const { vehicleGroupId } = useParams();
	const [groupData, setGroupData] = useState();
	const [minified, setMinified] = useState(false);
	const history = useHistory();
	const { url } = useRouteMatch();

	useEffect(() => {
		if (!vehicleGroupId) return;
		const [cancelToken, vehicleGroupGetPromise] = vehicleGroup(vehicleGroupId)
		let isActive = true;
		vehicleGroupGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;
				setGroupData(response.result);
				history.replace(url, { [vehicleGroupId]: `${response.result.name}`})
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err);
			});
		return () => {
			isActive = false;
			cancelToken.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [vehicleGroupId]);

	const handleEditUser = () => {
		history.push(`${url}/edit`, { [vehicleGroupId]: `${groupData.name}` });
	}

	const handleMinimize = (param) => {
		setMinified(param)
	}


	const viewTinySkeleton = [
		{ key: 1, property: "name", header: "NAME:" },
		{ key: 2, property: "company_name", header: "COMPANY:" },
		{ key: 3, property: "num_vehicles", header: "NUMBER OF VEHICLES:" },
	];

	return (
		<MultiViewContainer
			minified={minified}

			tinyChild={
				<TinyInfoView
					skeleton={viewTinySkeleton}
					data={groupData}
					confirmTitle='Delete Vehicle Group?'
					confirmDescription={`You are about to delete the Vehicle Group.`}

					onEdit={ handleEditUser }
					onMaximize={ handleMinimize } />
			}
			viewChild={ <VehicleGroupInfoView data={groupData} allowEdit allowDelete onMinimize={ handleMinimize } /> }
			rightChild={<VehicleGroupTabs vehicleGroup={groupData} vehicleGroupId={vehicleGroupId} />}/>
	);
}
