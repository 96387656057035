import { usePreferenceStore } from "modules/useTokenStore";
import { useEffect, useState } from "react";

export const useDistanceUnit = () => {

    const unitSystem = useUnitSystem();
    const [unit, setUnit] = useState("km");

    useEffect(() => {
        setUnit(unitSystem === "metric" ? "km" : "miles")
    }, [unitSystem])

    return unit;
}

export const useUnitSystem = () => usePreferenceStore(state => state.preferences.unit_system);
