import React from 'react';
import _ from 'lodash';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { Lesson } from './lesson/lesson';

import { Lessons } from "./lessons/lessons";
import { useEffectFeatureCheck, useRoleFeatureCheck } from 'modules/useGlobalStore';

export const TrainingPage = () => {

  const featureCheck = useRoleFeatureCheck('role_can_view_own_courses');

	// useEffectFeatureCheck('role_can_view_own_courses');

	const { path } = useRouteMatch();

	if (!featureCheck) {
		return null
	}

	return (
		<Switch>
			<Route path={`${path}`} exact>
				<Lessons />
			</Route>
			<Route path={`${path}/:courseId/:lessonId`} >
				<Lesson />
			</Route>
			{/* <Route path={`${path}/:deviceId/link-vehicle`} exact >
				<DeviceEdit />
			</Route> */}
			{/* <Route path={`${path}/:deviceId/edit`} exact >
				<DeviceEdit />
			</Route> */}
		</Switch>
	);
}
