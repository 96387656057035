import React, { useState } from 'react';
import _ from 'lodash';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { useTokenStore, useUserId, useUserInfo, useUserName, useUserStore } from 'modules/useTokenStore';

import { NavigationBar } from 'components/navigation-bar/navigation-bar';
import { QRPage } from './qr-page/qr-page';
import { TrainingPage } from './training-page/training-page';
import { Preferences } from 'pages/preferences/preferences';

import ReleaseNotes from 'pages/release-notes';
import { setBreadcrumb, useNavPaths } from 'modules/useGlobalStore';

// todo: this shouldn't be client-pages
import { User } from 'client-pages/users-page/user/user';
import { UserStatistics } from 'components/tabs/user-tabs/user-tabs';

const styles = makeStyles((theme) => ({
	root: {
		display: "flex",
		height: '80vh'
	},
	contentM: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 0, 2, 0),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(0, 2, 2, 2),
	},
}));

const getAltPaths = (path, role) => {
	const paths = {
		//'Profile': `${path}/profile`,
		'Preferences': `${path}/preferences`,
	};
	return paths
}

const UserBreadcrumb = () => {

	const userInfo = useUserInfo()

	React.useEffect(() => {
		if (_.isNil(userInfo)) {
			return
		}
    setBreadcrumb(userInfo.id, `${userInfo.name} ${userInfo.surname}`);
	}, [userInfo]);

	return null;
}

const Dashboard = () => {

	const userId = useUserId();

	const { path } = useRouteMatch();

	return (
		<>
			<Switch>
				<Route path={`${path}`} exact>
					<UserBreadcrumb />
					<User userId={userId} />
				</Route>
				<Route path={`${path}/statistics`}>
					<UserStatistics userId={userId} />
				</Route>
			</Switch>
		</>
	)
}

export const DriverEntry = () => {

	const token = useTokenStore(state => state.token);
	const role = useUserStore(state => state.role);
	const { path } = useRouteMatch();

	const paths = useNavPaths(path);

	const [altPaths] = useState(getAltPaths(path, role));
	const classes = styles();

	if (!role || !token || token.length < 1) {
		return (
			<Redirect to="/login" />
		)
	} else if (role === "5DTAdmin") {
		return (
			<Redirect to="/admin" />
		)
	} else if (role !== "Driver") {
		return (
			<Redirect to="/dashboard" />
		)
	}
	
	return (
		<div className={classes.root}>
			<NavigationBar paths={paths} altPaths={altPaths}/>
			<main className={classes.content}>
				<div className={classes.contentM} />
				<Switch>
					<Route path={`${path}`} exact >
						<Redirect to={`${path}/qr-code`} />
					</Route>
					<Route path={`${path}/qr-code`} >
						<QRPage />
					</Route>
					<Route path={`${path}/training`} >
						<TrainingPage />
					</Route>
					<Route path={`${path}/dashboard`} >
						<Dashboard />
					</Route>
					<Route path={`${path}/preferences`} >
						<Preferences />
					</Route>
					<Route path={`${path}/release-notes`} component={ReleaseNotes} />
					<Route>
						<h1>NOT FOUND</h1>
					</Route>
				</Switch>
			</main>
		</div>
	)
}
