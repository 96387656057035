import { createContext, useEffect, useCallback, useMemo, useContext, useRef, useState } from 'react';

import { connect, MqttClient } from 'mqtt';
import { matches } from 'mqtt-pattern';

const context = createContext({});

export const Connector = ({children, brokerUrl, parserMethod, options = {keepalive: 0}}) => {

	// Using a ref rather than relying on state because it is synchronous
	const clientValid = useRef(false);
	const [connectionStatus, setStatus] = useState('Offline');
	const [client, setClient] = useState(null);

	useEffect(() => {
		if (!client && !clientValid.current) {
			// This synchronously ensures we won't enter this block again
			// before the client is asynchronously set
			clientValid.current = true;
			setStatus('Connecting');
			console.log(`attempting to connect to ${brokerUrl}`);
			const mqtt = connect(brokerUrl, options);

			mqtt.on('connect', () => {
				console.debug('on connect');
				setStatus('Connected');
				// For some reason setting the client as soon as we get it from connect breaks things
				setClient(mqtt);
			});

			mqtt.on('reconnect', () => {
				console.debug('on reconnect');
				setStatus('Reconnecting');
			});

			mqtt.on('error', err => {
				console.log(`Connection error: ${err}`);
				setStatus(err.message);
			});

			mqtt.on('offline', () => {
				console.debug('on offline');
				setStatus('Offline');
			});

			mqtt.on('end', () => {
				console.debug('on end');
				setStatus('Offline');
			});
		}
	}, [client, clientValid, brokerUrl, options]);

	// Only do this when the component unmounts
	useEffect(()=>() => {
		if (client) {
			console.log('closing mqtt client');
			client.end(true);
			setClient(null);
			clientValid.current = false;
		}
	}, [client, clientValid]);

	// This is to satisfy
	// https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/jsx-no-constructed-context-values.md
	const value = useMemo(() => ({
			connectionStatus,
			client,
			parserMethod,
	}),[connectionStatus, client, parserMethod]);

	return <context.Provider value={value}>{children}</context.Provider>;
}

//https://github.com/VictorHAS/mqtt-react-hooks/blob/master/lib/useSubscription.tsx
export const useSubscription = (topic) => {

	const { client, connectionStatus, parserMethod } = useContext(context);
  
	const [message, setMessage] = useState(undefined);
  
	const subscribe = useCallback(async () => {
	  	client?.subscribe(topic, console.log);
	}, [client, topic]);
  
	const callback = useCallback((receivedTopic, receivedMessage) => {
		if ([topic].flat().some(rTopic => matches(rTopic, receivedTopic))) {
			setMessage({
				topic: receivedTopic,
				message: parserMethod?.(receivedMessage) || receivedMessage.toString()
			});
		}
	}, [parserMethod, topic]);
  
	useEffect(() => {
	  if (client?.connected) {
		console.log("Calling subscribe")
		subscribe();
  
		client.on('message', callback);
	  }
	  return () => {
		client?.off('message', callback);
	  };
	}, [callback, client, subscribe]);
  
	return {
	  client,
	  topic,
	  message,
	  connectionStatus,
	};
}

export const useMqttState = () => {
	const { connectionStatus, client, parserMethod } = useContext(context);
	
	return {
		connectionStatus,
		client,
		parserMethod,
	};
}
