import React, { useEffect, useState } from 'react';

import { Marker } from '@react-google-maps/api';
import { usePreferenceStore } from 'modules/useTokenStore';
import { getEventTypeIcon, toDisplayTimeLong, toDisplayTimeEvent } from 'shared-functions/shared-functions';
import { useEventIcon } from 'modules/useGlobalStore';

export const MapAlert = ({ position, bearing, type, alert, onClick, trip }) => {

	const typeLower = React.useMemo(() => `${type}`.toLowerCase(), [type]);

	const eventIcon = useEventIcon(typeLower, bearing);

	const handleClick = (param) => {
		onClick && onClick({ alert: alert, ...param })
	};


	const title = React.useMemo(() => 
		alert ? `${toDisplayTimeEvent(alert.timestamp)}\nEvent ${alert.index}\n${alert.alert_title}` : 
		trip ? `${type}\nStart Time: ${toDisplayTimeLong(trip.start_time)}\nEnd Time: ${toDisplayTimeLong(trip.end_time)}`: type, [alert, type, trip]);
	// console.log('MapAlert', eventIcon, typeLower, title, alert, trip);

	return (
		<Marker
			position={position} 
			icon={eventIcon}
			label={eventIcon.label}
			zIndex={ typeLower === "driver_location" ? 999 : 2 }
			title={title}
			onClick={ handleClick } />
	);
}

export const MapEventSelected = ({ position, type,  }) => {

	const eventIconSize = usePreferenceStore((state) => state.preferences.event_icon_size);
	const [size, setSize] = useState(40);
	const [selectedSize, setSelectedSize] = useState(50);

	const typeLower = React.useMemo(() => `${type}`.toLowerCase(), [type]);

	useEffect(() => {
		if (eventIconSize) {
			setSize(eventIconSize);
			setSelectedSize(eventIconSize+10*eventIconSize/40);
		} else {
			setSize(40);
			setSelectedSize(50);
		}
	}, [eventIconSize])

	return (
		<>
			<Marker
				position={position} 
				icon={
					{
						url: `${process.env.PUBLIC_URL}/assets/img/markers/selected.svg`,
						size: { width: selectedSize, height: selectedSize },
						origin: { x: 0, y: 0 },
						anchor: { x: selectedSize/2, y: selectedSize/2 },
						scaledSize: { width: selectedSize, height: selectedSize },
					}
				}
				zIndex={3} />
			<Marker
				position={position} 
				icon={
					{
						url: `${process.env.PUBLIC_URL}/assets/img/markers/${getEventTypeIcon(typeLower)}`,
						size: { width: size, height: size },
						origin: { x: 0, y: 0 },
						anchor: { x: size/2, y: size/2 },
						scaledSize: { width: size, height: size },
					}
				}
				zIndex={ 3 } />
		</>
	);	
}
