import './info-view.css';
import React, { useEffect, useState, useRef } from 'react';

import _ from 'lodash';

import clsx from 'clsx';
import cx from 'clsx';

import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, CircularProgress, Grid, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
import LinkIcon from '@material-ui/icons/Link';
import PersonIcon from '@material-ui/icons/Person';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PhotoIcon from '@material-ui/icons/Photo';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';


import Box from '@material-ui/core/Box';
// import Card from '@material-ui/core/Card';
// import CardMedia from '@material-ui/core/CardMedia';
import {
  Info,
  InfoCaption,
  InfoSubtitle,
  InfoTitle,
} from '@mui-treasury/components/info';
import { useGalaxyInfoStyles } from '@mui-treasury/styles/info/galaxy';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';

import { GenericDialog, MediaDialog } from 'components/dialogs/dialogs';
import { convertMetersPerSecToKmh, getEventStringDefault, formatSpeed, toDisplayTimeShort, toDisplayTimeAbbr, toDisplayTimeLong, toDisplayTimeRel } from 'shared-functions/shared-functions';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { deviceUnlinkVehicle } from 'services/device';
import { deleteUserGroup } from 'services/user-group';
import { deleteVehicleGroup } from 'services/vehicle-group';
import { deleteVehicle } from 'services/vehicle';
import { usePreferenceStore, useUserId, useUserRole } from 'modules/useTokenStore';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { useTripSummary } from 'modules/useGlobalStore';
import { EventToolbar } from 'components/toolbars/toolbars';
import { render } from '@testing-library/react';
import DeviceTripInfoView from './device-trip-info';

// https://v4.mui.com/styles/api/#makestyles-styles-options-hook
// https://v4.mui.com/styles/api/#createstyles-styles-styles
const useStyles = makeStyles((theme) => createStyles({
	cardRoot: {
    // borderRadius: 12,
    borderRadius: 5,
    // minWidth: 256,
    // textAlign: 'center',
		height: '100%',
		// width: '100%',
		// margin: "auto",
		// marginBottom: theme.spacing(2),
	},
	cardActionArea: {
		height: '100%',
	},
	cardContent: {
		display: "grid",
		// gridAutoFlow: "row",
		// gridAutoFlow: "column",
		gridGap: theme.spacing(1),
		// gridTemplateColumns: "repeat(auto-fit, minmax(275px, 1fr))",
		// gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
		gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
		// height: '100%',
	},
  cardDetails: {
		// height: '100%',
	},
  cardAvatar: {
    width: 100,
    height: 100,

		// centering
    margin: 'auto',
		marginBottom: theme.spacing(2),

		// margin: 0,
		// padding: theme.spacing(1),
		// flex: 1,
  },
	cardActions: {
		width: '100%',
		// height: '100%',
		// textAlign: 'right',
		justifyContent: 'flex-end',
	},
  imageLoading: {
    position: 'absolute',
		// position: 'relative',
    zIndex: 1,
    top: '50%',
    left: '50%',
    marginTop: -34, // size/2, e.g. 68/e
    marginLeft: -34, // size/2, e.g. 68/e
  },
	imageContentLoading: {
    filter: 'grayscale(100%)',
  },
	imageWrapper: {
		margin: 0,
		padding: 0,
		position: 'relative',
		display: 'inline-block'
		// width: '100%',
		// height: '100%',
	},
	imageContent: {
		width: '100%'
	},
	overlay: {
		position: 'absolute',
		left: 0,
		zIndex: 5
	  },
}));

export const InfoView = ({ skeleton, data, confirmTitle, confirmDescription, onEdit, onDelete, onMinimize }) => {

	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

	const handleEdit = () => {
		onEdit && onEdit();
	}

	const handleMinimize = () => {
		onMinimize && onMinimize(true);
	}

	const handleDelete = () => {
		setConfirmationDialogOpen(true);
	}

	const handleConfirmationDialogClosed = (value) => {
		if (value) {
			onDelete();
		}
		setConfirmationDialogOpen(false);
	}

	return (
		<>
			<GenericDialog
				open={confirmationDialogOpen}
				title={confirmTitle}
				info={confirmDescription}
				onClose={handleConfirmationDialogClosed} />
			<div style={{ minHeight: 100, minWidth: 300, width: 'calc(100% - 16px)', margin: '10px' }}>
				{
					onEdit &&
					<IconButton aria-label="edit" onClick={handleEdit}>
						<EditIcon />
					</IconButton>
				}
				{
					onDelete &&
					<IconButton aria-label="delete" onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				}
				{
					onMinimize &&
					<IconButton aria-label="minimize" onClick={handleMinimize}>
						<ExpandLessIcon />
					</IconButton>
				}
				{
					data
						? skeleton.map((obj, index) => {
							if (obj.properties) {
								return (
									<div key={`${obj.properties[0]}-${index}`} style={{ width: '100%' }}>
										{/* HEADER */}
										{
											obj.header ? <h6 className={"info-header"} >{obj.header}</h6> : <></>
										}
										{/* VALUE */}
										{
											obj.formatValue
												? obj.formatValue(data, obj.properties, index)
												: <>
													{
														obj.properties.map((prop, index) => {
															return (
																<div key={`${prop}-${index}`} style={{ marginLeft: "10px" }}>{data[prop]?.toString()}</div>
															);
														})
													}
												</>
										}
									</div>
								);
							}
							return (
								<div key={`${obj.property}-${index}`}>
									{/* HEADER */}
									{
										obj.header ? <h6 className={"info-header"} >{obj.header}</h6> : <></>
									}
									{/* VALUE */}
									{
										obj.formatValue
											? obj.formatValue(data[obj.property], index)
											: <div style={{ marginLeft: "10px" }}>{data[obj.property]?.toString()}</div>
									}
								</div>
							)
						})
						: skeleton.map((obj, index) => {
							return (
								<div key={`skeleton-${obj.property}-${index}`}>
									{/* HEADER */}
									{
										obj.header ? <h6 className={"info-header"} >{obj.header}</h6> : <></>
									}
									{/* VALUE */}
									{
										obj.skeleton
											? obj.skeleton(index)
											: <Skeleton />
									}
								</div>
							)
						})
				}
			</div>
		</>
	);
}

export const BasicInfoItem = ({ header, headerData, text, data, skeleton }) => {

	return (
		<>
			{
				header
					? <h5 className="basic-info-item-header">{header}</h5>
					: headerData
						? headerData
						: null
			}
			{
				text
					? <h3 className="basic-info-item-data">
						{
							text
						}
					</h3>
					: data
						? data
						: skeleton
							? skeleton
							: <Skeleton />
			}
		</>
	)
}

const generateDefaultSkeleton = (defaultInfo, allowedInfo) => {

	const defaultSkeleton = [];
	let count = 0;
	if (allowedInfo && allowedInfo.length > 0) {
		allowedInfo.map((value, _) => {
			(defaultInfo && defaultInfo[value]) && defaultSkeleton.push({ ...defaultInfo[value], key: count++ })
		})
	} else {
		Object.entries(defaultInfo).map(([key, value]) => {
			defaultSkeleton.push({ ...value, key: count++ });
		});
	}

	return defaultSkeleton;
}

export const EventMediaDialog = ({ onClose, open, event, loading, eventIndex, eventList, showVideo, toggleVideo, media, autoPlay, cameraName}) => {

	const unitSystem = usePreferenceStore(store => store.preferences.unit_system)

	const speed = event?.position ?
		formatSpeed(convertMetersPerSecToKmh(event?.position?.speed), unitSystem)
		: 'Speed unknown';

	const imgUrl = media?.snapshot? `${process.env.REACT_APP_EDGE_SERVICE_URL}/${media?.snapshot}` : null
	const videoUrl = media?.video? `${process.env.REACT_APP_EDGE_SERVICE_URL}/${media?.video}` : null

	const type = getEventStringDefault(event?.alert_type, 'Unknown Event')
	const timestamp = toDisplayTimeShort(event?.timestamp);
	const relTime = toDisplayTimeRel(event?.timestamp);
	// const subtitle = `${speed} • ${relTime} • ${timestamp}`;
	const subtitle = `${timestamp} • ${relTime} • ${speed} • ${cameraName}`;
	// todo: show time, speed, and rel time

	return (
		<MediaDialog onClose={onClose} open={open} alt={type} loading={loading} imgSrc={imgUrl} videoSrc={videoUrl} showVideo={showVideo} toggleVideo={toggleVideo} autoPlay={autoPlay}>
			<Typography align="center" variant="body2">{subtitle}</Typography>
			<EventToolbar event={event} eventList={eventList} eventIndex={eventIndex} />
		</MediaDialog>
	)
}

const EventSnapshotView = ({ event, loading, toggleDialog, showVideo, toggleVideo, media, autoPlay}) => {

	const classes = useStyles();

	const imgClass = clsx(classes.imageContent, {
		[classes.imageContentLoading]: loading,
	});

	const overlayClass = clsx(classes.overlay);
	
	const imgUrl = `${process.env.REACT_APP_EDGE_SERVICE_URL}/${media?.snapshot}`
	const videoUrl = `${process.env.REACT_APP_EDGE_SERVICE_URL}/${media?.video}`
	
	const type = getEventStringDefault(event?.alert_type, 'Unknown Event')

	return (
		<>
		<div className={classes.imageWrapper}>
		{
			showVideo?
			media?.video?
					<>
						<div className={overlayClass}>
						{
							media?.snapshot?
							<IconButton aria-label="play" className="btn btn-default pull-left" onClick={toggleVideo}>
							<PhotoIcon />
						</IconButton>
							: <></>
						}
						</div>
						<video controls playsInline autoPlay={autoPlay} muted
							onClick={e => {e.currentTarget.play(); toggleDialog();}}
							src={videoUrl} 
							className={imgClass}
							alt={type}
						/>		
					</>
				: <></>
			:
				media?.snapshot?
					<>
						<div className={overlayClass}>
						{
							media?.video?
								<IconButton aria-label="play" className="btn btn-default pull-left" onClick={toggleVideo}>
									<PlayArrowIcon />
								</IconButton>
							: <></>
						}
						</div>
						<img 
							className={imgClass} 
							src={imgUrl} 
							alt={type}
							onClick={toggleDialog}
							style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
						/>
					</>
				: <></>
		}
		{loading && <CircularProgress size={68} className={classes.imageLoading} />}
		</div>
		</>
	);
}

export const EventInfoView = ({ event, eventList, eventIndex, loading }) => {

	const unitSystem = usePreferenceStore(store => store.preferences.unit_system)
	const timestamp = toDisplayTimeShort(event?.timestamp);

	const maxFeeds = 2; 
	// const numFeeds = event?.media ? event?.media.length : 0;

	const [showDialogList, setShowDialogList] = useState(Array(maxFeeds).fill(false));

	const toggleDialog = React.useCallback((index) => {
		//Only one dialog open at a time, close the others
		let temp = Array(maxFeeds).fill(false)
		temp[index] = !showDialogList[index]
		setShowDialogList(temp);

		setAutoPlay(true)
	}, [setShowDialogList, showDialogList]);

	const [showVideoList, setShowVideoList] = useState(Array(maxFeeds).fill(false));

	const toggleVideo = React.useCallback((index, changingEvent=false) => {
		let temp = [...showVideoList]
		temp[index] = !temp[index]
		setShowVideoList(temp);

		//Enable autoplay when showing a video and not changing between events
		if(temp[index] && !changingEvent){
			setAutoPlay(true)
		}
	}, [setShowVideoList, showVideoList]);

	const [autoPlay, setAutoPlay] = useState(false);
	const toggleAutoPlay = React.useCallback((index) => {
		setAutoPlay(!autoPlay)
	}, [setAutoPlay, autoPlay]);
	
	React.useEffect(() => {
		//Prevent invalid states when switching between events
		if(event?.media){

			//Disable autoplay when switching between events
			setAutoPlay(false)

			let media = event?.media
			
			//Show snapshots for all media that have snapshots when switching
			media.forEach((cameraMedia, index) => {
				if(showVideoList[index] && cameraMedia.snapshot){
					toggleVideo(index, true)
				}
			});

			//If this feed is set to show snapshots but this event doesn't have a snapshot for the same feed, switch to video
			media.forEach((cameraMedia, index) => {
				if(!showVideoList[index] && !cameraMedia.snapshot){
					toggleVideo(index, true)
				}
			});
			
			//If a dialog was open before switching and this event doesn't have media for the same feed, switch to another
			let openDialogIndex = showDialogList.findIndex((element) => element == true)
			if(openDialogIndex > 0){
				if(media.length <= openDialogIndex){
					if(media.length > 0){
						toggleDialog(openDialogIndex - 1)
					}
				}
			}
		}

	}, [eventIndex, event?.media])

	const media = React.useMemo(() => {

		//Disable autoplay when media changes
		setAutoPlay(false)

		let result = []
		result.push(
			<Typography key={'mediaHeading'} gutterBottom variant="caption">Media</Typography>
		)

		let mediaGrids = event?.media?.map((cameraMedia, index) => {
			return (
				<Grid item key={`media${index}`}>
					<Typography gutterBottom variant="h6" component="h3">{cameraMedia.camera}</Typography>
					<EventSnapshotView toggleDialog={() => toggleDialog(index)} event={event} loading={loading} showVideo={showVideoList[index]} toggleVideo={() => toggleVideo(index)} media={cameraMedia} autoPlay={autoPlay && !showDialogList[index]}/>
					<EventMediaDialog loading={loading} onClose={() => toggleDialog(index)} open={showDialogList[index]} event={event} eventList={eventList} eventIndex={eventIndex} showVideo={showVideoList[index]} toggleVideo={() => toggleVideo(index)} media={cameraMedia} autoPlay={autoPlay} cameraName={cameraMedia.camera}/>
				</Grid>
			)
		})
		
		if(mediaGrids)
			result.push(...mediaGrids)
		return result
	}, [event?.media, showVideoList, showDialogList])

	const eventType = getEventStringDefault(event?.alert_type, 'Unknown Event');

	const eventSpeed = event?.position 
		? formatSpeed(convertMetersPerSecToKmh(event?.position?.speed), unitSystem)
		: 'No speed information';

	const eventDriver = `${event?.driver_name} ${event?.driver_surname}`;

	return (
		<>
			<EventToolbar event={event} eventList={eventList} eventIndex={eventIndex} />
			<div style={{ minHeight: 100, minWidth: 300, width: 'calc(100% - 16px)', margin: '10px' }}>
				<div>
					<Grid container direction="column">
						<Grid container>
							<Grid item xs={6}>
								<Typography gutterBottom variant="caption">Event Type</Typography>
								<Typography gutterBottom variant="h6" component="h3">{`${eventType}`}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography gutterBottom variant="caption">Speed</Typography>
								<Typography gutterBottom variant="h6" component="h3">{`${eventSpeed}`}</Typography>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={6}>
								<Typography gutterBottom variant="caption">Driver</Typography>
								<Typography gutterBottom variant="h6" component="h3">{`${eventDriver}`}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography gutterBottom variant="caption">Time</Typography>
								<Typography gutterBottom variant="h6" component="h3">{timestamp}</Typography>
							</Grid>
						</Grid>
						{media}
					</Grid>
				</div>
			</div>
		</>
	)
}

export const SelectedDeviceTripInfoView = DeviceTripInfoView;

export const DeviceInfoView = ({ data, allowedInfo, onMinimize }) => {

	console.log('DeviceInfoView', data);

	const isMounted = useIsMounted();
	const deviceUnlinkVehicleCancelSourceRef = useRef(null);
	const [deviceData, setDeviceData] = useState(data);
	const [skeleton] = useState(generateDefaultSkeleton({
		serial_number: { property: "serial_number", header: "SERIAL NR:" },
		last_online_time: {
			property: "last_online_time",
			header: "LAST ONLINE:",
			formatValue: (data, index) => {
				return <div style={{ marginLeft: "10px" }}>{data ? data : "Not yet online"}</div>
			},
		},
		currently_online: {
			property: "currently_online",
			header: "ONLINE:",
			formatValue: (data, index) => {
				return <div style={{ marginLeft: "10px" }}>{data ? 'true' : 'false'}</div>
			},
		},
		last_known_location: {
			property: "last_known_location",
			header: "LAST KNOWN LOCATION:",
			formatValue: (data, index) => {
				return <div style={{ marginLeft: "10px" }}>
					{
						data
							? <>
								<span>{`Lat: ${data.latitude}`}</span><br /><span>{`Lng: ${data.longitude}`}</span>
							</>
							: "No locations logged"
					}
				</div>
			},
		},
		linked_vehicle: {
			properties: ["id", "vehicle_name", "vehicle_make", "vehicle_model", "vehicle_registration_number", "vehicle_colour"],
			header: "LINKED VEHICLE:",
			formatValue: (data, properties, index) => {
				return (
					<div className="peepee-poopoo" >
						{/* HEADER */}
						{
							data.header ? <h6 className={"info-header"} >{data.header}</h6> : <></>
						}
						{/* VALUE */}
						{
							<>
								{
									data[properties[1]]
										? <>
											<div style={{ marginLeft: "10px" }}>
												Remove linked vehicle
												<IconButton aria-label="remove-link" onClick={handleDeviceUnlink}>
													<ClearIcon />
												</IconButton>
											</div>

										</>
										: <>
											{"Device not linked to vehicle"}
											<IconButton aria-label="remove-link" onClick={() => { handleDeviceLink(data.id) }}>
												<LinkIcon />
											</IconButton>
										</>
								}
								{
									properties.map((prop, index) => {
										if (index === 0) return;
										return (
											<div key={`${prop}-${index}`} style={{ marginLeft: "10px" }}>{data[prop]?.toString()}</div>
										);
									})
								}
							</>
						}
					</div>
				)
			},
		},
	}, allowedInfo))
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const history = useHistory();
	const { url } = useRouteMatch();

	useEffect(() => {
		setDeviceData(data);
	}, [data])

	const handleConfirmationDialogClosed = (value) => {
		if (value) {
			if (!data.id) return;
			if (deviceUnlinkVehicleCancelSourceRef.current) return;
			const [cancelSource, deviceUnlinVehiclePromise] = deviceUnlinkVehicle({ serial_number: data.serial_number })
			deviceUnlinkVehicleCancelSourceRef.current = deviceUnlinVehiclePromise
			deviceUnlinVehiclePromise
				.then((response) => {
					if (!isMounted()) return;
					deviceUnlinkVehicleCancelSourceRef.current = null;
					if (!response.success) return;
					setDeviceData((_device) => {
						return {
							..._device,
							vehicle_id: null,
							vehicle_name: null,
							vehicle_make: null,
							vehicle_model: null,
							vehicle_registration_number: null,
							vehicle_colour: null,
						}
					});
				})
				.catch((err) => {
					if (!isMounted()) return;
					deviceUnlinkVehicleCancelSourceRef.current = null;
					console.log(err);
				});
		}
		setConfirmationDialogOpen(false)
	}

	const handleDeviceUnlink = () => {
		setConfirmationDialogOpen(true);
	}

	const handleDeviceLink = (id) => {
		id && history.push(`${url}/${id}/link-vehicle`);
	}

	return (
		<>
			<GenericDialog
				open={confirmationDialogOpen}
				title='Unlink vehicle?'
				info="You are about to unlink the vehicle from the current device."

				onClose={handleConfirmationDialogClosed} />
			<InfoView
				skeleton={skeleton}
				data={deviceData}

				onMinimize={onMinimize} />
		</>
	)
}

export const UserGroupInfoView = ({ data, allowedInfo, allowEdit, allowDelete, onMinimize }) => {

	const isMounted = useIsMounted();
	const deleteUserGroupCancelSourceRef = useRef(null);
	const [skeleton] = useState(generateDefaultSkeleton({
		name: { property: "name", header: "NAME:" },
		company_name: { property: "company_name", header: "COMPANY:" },
		num_owners: { property: "num_owners", header: "NUMBER OF OWNERS:" },
		num_users: { property: "num_users", header: "NUMBER OF USERS:" },
		num_viewers: { property: "num_viewers", header: "NUMBER OF VIEWERS:" },
		your_group_permission: {
			properties: ["is_owner", "is_viewer"],
			header: "YOUR GROUP PERMISSION:",
			formatValue: (data, properties, index) => {
				return (
					<div style={{ marginLeft: "10px" }}>
						{
							data.is_owner === "true"
								? 'Owner'
								: data.is_viewer === "true"
									? 'Viewer'
									: 'None'
						}
					</div>
				)
			}
		},
	}, allowedInfo))
	const history = useHistory();
	const { url } = useRouteMatch();

	const handleEditUserGroup = () => {
		allowEdit && history.push(`${url}/edit`);
	}

	const handleDeleteUserGroup = () => {
		if (allowDelete) {
			if (!data.id) return;
			const [cancelSource, deleteUserGroupPromise] = deleteUserGroup(data.id)
			deleteUserGroupCancelSourceRef.current = cancelSource;
			deleteUserGroupPromise
				.then((response) => {
					if (!isMounted()) return;
					deleteUserGroupCancelSourceRef.current = null;
					if (!response.success) return;
					history.goBack();
				})
				.catch((err) => {
					if (!isMounted()) return;
					deleteUserGroupCancelSourceRef.current = null;
					console.log(err);
				});
		}
	}

	return (
		<InfoView
			skeleton={skeleton}
			data={data}
			confirmTitle='Delete User Group?'
			confirmDescription={`You are about to delete the group.`}

			onDelete={allowDelete && handleDeleteUserGroup}
			onEdit={allowEdit && handleEditUserGroup}
			onMinimize={onMinimize} />
	)
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    // width: 151,
    // width: "100%",
		// height: "100%"
		// height: 140
		width: 150,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export const UserInfoView = ({ data, editPath }) => {

	const classes = useStyles();

	const shadowStyles = useFadedShadowStyles();

	const currentUserRole = useUserRole();

	const userEmail = React.useMemo(() => data?.email ?? <Skeleton />, [data]);
	const userRole = React.useMemo(() => data?.role ?? <Skeleton />, [data]);
	const userFullName = React.useMemo(() => {
		if (_.isNil(data) || _.isNil(data?.name)) {
			return (
				<Skeleton />
			)
		}
		return `${data.name} ${data.surname}`;
	}, [data]);

	const userLetters = React.useMemo(() => `${_.nth(data.name, 0)}${_.nth(data.surname, 0)}`, [data]);

	const userImage = React.useMemo(() => {
		if (_.isNil(data) || _.isNil(data.profile_image)) {
			return null
		}
		return `data:image/jpeg;base64,${data.profile_image}`;
	}, [data]);

	// https://mui-treasury.com/components/
	// https://deprecate.mui-treasury.com/brands/peapods
	// https://deprecate.mui-treasury.com/
	// https://mui-treasury.com/components/card

	const history = useHistory();

	const { url } = useRouteMatch();
	// console.warn('bla url', {url, path})
	// const toEdit = React.useCallback(() => history.push(`${url}/edit`), [url, history])
	const toEdit = (event) => {
		// console.warn('toEdit', {event})
		event?.preventDefault();
		history.push(`${url}/edit`);
	}

	const currentUserId = useUserId();

	// const editDisabled = React.useMemo(() => true, []);
	const editDisabled = React.useMemo(() => {
		if (_.isEqual(currentUserRole, 'ClientAdmin')) {
			return false;
		}
		if (_.isEqual(currentUserRole, 'Driver')) {
			return true;
		}
		if (_.isEqual(currentUserId, data.id)) {
			return false;
		}
		return true;
	}, [currentUserRole, data, currentUserId]);


	return (
		<Card className={cx(classes.cardRoot, shadowStyles.root)}>
			<CardActionArea disabled={editDisabled} className={classes.cardActionArea}>
				<CardContent className={classes.cardContent}>
					<div className={classes.cardDetails}>
						<Avatar className={classes.cardAvatar} src={userImage}>
							{userLetters}
						</Avatar>
						{!editDisabled && <Button disabled={editDisabled} onClick={toEdit} fullWidth color="primary">Update Profile</Button>}
					</div>
					<div className={classes.cardDetails}>
						<Typography gutterBottom variant="caption">Full Name</Typography>
						<Typography gutterBottom variant="h6" component="h2">{userFullName}</Typography>
						<Typography gutterBottom variant="caption">Email</Typography>
						<Typography gutterBottom variant="subtitle1">{userEmail}</Typography>
						<Typography gutterBottom variant="caption">Role</Typography>
						<Typography gutterBottom variant="subtitle1">{userRole}</Typography>
					</div>
				</CardContent>
			</CardActionArea>
    </Card>
	);
}

export const VehicleGroupInfoView = ({ data, allowedInfo, allowEdit, allowDelete, onMinimize }) => {

	const isMounted = useIsMounted();
	const vehicleGroupDeleteCancelSourceRef = useRef(null);
	const [skeleton] = useState(generateDefaultSkeleton({
		name: { property: "name", header: "NAME:" },
		company_name: { property: "company_name", header: "COMPANY:" },
		num_vehicles: { property: "num_vehicles", header: "NUMBER OF VEHICLES" },
		num_owners: { property: "num_owners", header: "NUMBER OF OWNERS:" },
		num_viewers: { property: "num_viewers", header: "NUMBER OF VIEWERS:" },
		your_group_permission: {
			properties: ["is_owner", "is_viewer"],
			header: "YOUR GROUP PERMISSION:",
			formatValue: (data, properties, index) => {
				return (
					<div style={{ marginLeft: "10px" }}>
						{
							data.is_owner === "true"
								? 'Owner'
								: data.is_viewer === "true"
									? 'Viewer'
									: 'None'
						}
					</div>
				)
			}
		},
	}, allowedInfo))
	const history = useHistory();
	const { url } = useRouteMatch();

	const handleEditUser = () => {
		history.push(`${url}/edit`);
	}

	const hanldeDeleteVehicleGroup = () => {
		if (!data.id) return;
		if (vehicleGroupDeleteCancelSourceRef.current) return;
		const [cancelSource, vehicleGroupDeletePromise] = deleteVehicleGroup(data.id)
		vehicleGroupDeleteCancelSourceRef.current = cancelSource
		vehicleGroupDeletePromise
			.then((response) => {
				if (!isMounted()) return;
				vehicleGroupDeleteCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((err) => {
				if (!isMounted()) return;
				vehicleGroupDeleteCancelSourceRef.current = null;
				console.log(err);
			});
	}

	return (
		<InfoView
			skeleton={skeleton}
			data={data}
			confirmTitle='Delete Vehicle Group?'
			confirmDescription={`You are about to delete the Vehicle Group.`}

			onDelete={allowDelete && hanldeDeleteVehicleGroup}
			onEdit={allowEdit && handleEditUser}
			onMinimize={onMinimize} />
	)
}

export const VehicleInfoView = ({ data, allowedInfo, allowEdit, allowDelete, onMinimize }) => {

	const isMounted = useIsMounted();
	const vehicleDeleteCancelSourceRef = useRef(null)
	const [skeleton] = useState(generateDefaultSkeleton({
		name: { property: "name", header: "NAME:" },
		type: { property: "type", header: "TYPE:" },
		make: { property: "make", header: "MAKE:" },
		model: { property: "model", header: "MODEL:" },
		colour: { property: "colour", header: "COLOUR:" },
		registration_number: { property: "registration_number", header: "REG NR:" },
		device: {
			property: "device",
			header: "DEVICE SERIAL:",
			formatValue: (data) => {
				return <>
					{
						// data && data.length > 0
						// ?	data.map((obj, index) => {
						// 		return (
						// 			<div key={`devices-${index}`} style={ {marginLeft: "10px"} }>{`${obj.device_name}`}</div>
						// 		)
						// 	})
						data
							? <div style={{ marginLeft: "10px" }}>{`${data.serial_number}`}</div>
							: <div style={{ marginLeft: "10px" }}>No device linked</div>
					}
				</>
			},
			skeleton: () => { return <> <Skeleton /><Skeleton /><Skeleton /> </> },
		},
		// drivers: {
		// 	property: "drivers",
		// 	header: "DRIVERS:",
		// 	formatValue: (data) => {
		// 		return <>
		// 		{
		// 			data && data.length > 0
		// 			?	data.map((obj, index) => {
		// 					return (
		// 						<div key={`drivers-${index}`} style={ {marginLeft: "10px"} }>{`${obj.name} ${obj.surname}`}</div>
		// 					)
		// 				})
		// 			: <div style={ {marginLeft: "10px"} }>No drivers</div>
		// 		}
		// 		</>
		// 	},
		// 	skeleton: () => { return <> <Skeleton /><Skeleton /><Skeleton /> </> },
		// },
		// driver_groups: {
		// 	property: "driver_groups",
		// 	header: "DRIVER GROUPS:",
		// 	formatValue: (data) => {
		// 		return <>
		// 		{
		// 			data && data.length > 0
		// 			?	data.map((obj, index) => {
		// 					return (
		// 						<div key={`driver-groups-${index}`} style={ {marginLeft: "10px"} }>{`${obj.group_name}`}</div>
		// 					)
		// 				})
		// 			: <div style={ {marginLeft: "10px"} }>No driver groups</div>
		// 		}
		// 		</>
		// 	},
		// 	skeleton: () => { return <> <Skeleton /><Skeleton /><Skeleton /> </> },
		// },
	}, allowedInfo))
	const history = useHistory();
	const { url } = useRouteMatch();

	const handleEditUser = () => {
		history.push(`${url}/edit`);
	}

	const handleDeleteVehicle = (param) => {
		if (!data.id) return;
		if (vehicleDeleteCancelSourceRef.current) return;
		const [vehicleDeleteCancelSource, vehicleDeletePromise] = deleteVehicle(data.id)
		vehicleDeleteCancelSourceRef.current = vehicleDeleteCancelSource;
		vehicleDeletePromise
			.then((response) => {
				if (!isMounted()) return;
				vehicleDeleteCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((err) => {
				if (!isMounted()) return;
				vehicleDeleteCancelSourceRef.current = null;
				console.log(err);
			});
	}

	return (
		<InfoView
			skeleton={skeleton}
			data={data}
			confirmTitle='Delete Vehicle?'
			confirmDescription={`You are about to delete the Vehicle.`}

			onDelete={allowDelete && handleDeleteVehicle}
			onEdit={allowEdit && handleEditUser}
			onMinimize={onMinimize} />
	)
}
