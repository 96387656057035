import "./data-tables.css";
import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { ServerSideTable } from 'components/table/table';

import { generateDefaultCriteria, generateDefaultSearchCriteria, generateDefaultSearchCriteriaSkeleton, generateCriteria, generateSearchCriteria, generateColumns } from "./data-table-functions";
import { alertsAll } from "services/alert";
import { formatDistance, formatDuration, getEventStringDefault, toDisplayTimeLong, WARN_USE_EFFECT, WARN_USE_EFFECT_CLEAN } from "shared-functions/shared-functions";
import { useIsMounted } from "shared-hooks/useIsMounted";

import { useFetchTrips } from 'services/api';
import { useUnitSystem } from "shared-hooks/useDistanceUnit";
// import { setSelectedTripId } from "modules/useGlobalStore";

// tripDefaultCriteria is kept outside the component, so that it won't cause
// re-evaluation of dependencies.
const tripDefaultCriteria = {
	orderBy: 'start_time',
	flipOrder: true,
}

// searchMode can be driver or vehicle
export const TripTable = ({ onRowClick, searchMode, vehicleId, driverId }) => {

	const fixedSearchCriteria = React.useMemo(() => searchMode === "vehicle" ? {
		vehicle_id: vehicleId
	} : searchMode === "driver" ? {
    driver_id: driverId
	} : {
	}, [searchMode, driverId]);

	const allowedColumns = React.useMemo(() => searchMode === "vehicle" ? [
		// "device_id",
		"start_time",
		"end_time",
		// "live",
		"driver_name",
		"driver_surname",
		"driver_email",
    "distance",
    "duration",
    "alert_count",
	] : searchMode === "driver" ? [
    // "device_id",
    "start_time",
    "end_time",
    // "live",
    "vehicle_name",
    "vehicle_registration_number",
    "distance",
    "duration",
    "alert_count",
	] : [
	], [searchMode]);

	const allowedSearchCriteria = React.useMemo(() => searchMode === "vehicle" ? {
		text: '',
		// driver_email: '',
	} : searchMode === "driver" ? {
		text: '',
		// vehicle_registration_number: '',
	} : {
	}, [searchMode]);

	const defaultSearchCriteria = React.useMemo(() => searchMode === "vehicle" ? {
		vehicle_id: '',
		vehicle_registration_number: '',
	} : searchMode === "driver" ? {
		driver_id: '',
		driver_email: '',
	} : {
	}, [searchMode]);

	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(tripDefaultCriteria, null),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});

	const tripCriteria = React.useMemo(() => ({
		...generateCriteria(tableCriteria, null),
		searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria, fixedSearchCriteria)
	}), [tableCriteria, allowedSearchCriteria, fixedSearchCriteria]);

	// console.log('TripTable', tableCriteria, tripCriteria);
	// https://trello.com/c/UvS3acmn/378-trip-searching-returns-a-search-over-all-trips-and-not-just-trips-for-vehicle-or-user
	const { tripList, tripCount, isFetching } = useFetchTrips(tripCriteria);

	// the api is returning vehicle_registration_number, but filtering using
	// vehicle_registration. The API should be fixed.

	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		start_time: { field: "start_time", header: "Start Time" },
		vehicle_id: { field: "vehicle_id", header: "Vehicle ID" },
		vehicle_registration_number: { field: "vehicle_registration_number", header: "Vehicle Reg Nr" },
		driver_email: { field: "driver_email", header: "Driver Email" },
		driver_id: { field: "driver_id", header: "Driver ID" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	const unitSystem = useUnitSystem();

	const [columns] = useState(generateColumns({
		// device_id: { field: "device_id", headerName: "Device Serial", width: 220 },
		vehicle_name: { field: "vehicle_name", headerName: "Vehicle Name", width: 200 },
		vehicle_registration_number: { 
			field: "vehicle_registration_number", 
			headerName: "Vehicle Reg Nr", 
			width: 150, 
		},
		driver_name: { field: "driver_name", headerName: "Driver Name", width: 150 },
		driver_surname: { field: "driver_surname", headerName: "Driver Surname", width: 170 },
		driver_email: { field: "driver_email", headerName: "Driver Email", width: 200 },
		// live: { field: "live", headerName: "Is Live", width: 100 },
		start_time: {
			field: "start_time",
			headerName: "Start Time",
			width: 220,
			valueFormatter: (params) => {
				// return toDisplayTimeShort(params.value);
				return toDisplayTimeLong(params.value);
			}
		},
		start_end: { field: "start_end", headerName: "End Time", width: 200 },
		distance: {
			field: "distance",
			headerName: "Distance",
			type: 'number',
			valueGetter: (params) => _.round(params.value / 1000.0, 1),
			width: 120,
			valueFormatter: (params) => {
				// return toDisplayTimeShort(params.value);
				// return toDisplayTimeLong(params.value);
				return formatDistance(params.value, unitSystem);
			}
		},
		duration: {
			field: "duration",
			headerName: "Duration",
			type: 'number',
			valueGetter: (params) => params.value,
			width: 150,
			valueFormatter: (params) => {
				// return toDisplayTimeShort(params.value);
				// return toDisplayTimeLong(params.value);
				return formatDuration(params.value);
			}
		},
		alert_count: {
			field: "alert_count",
			headerName: "Alerts",
			type: 'number',
			valueGetter: (params) => params.value,
			width: 100,
			valueFormatter: (params) => {
				// return toDisplayTimeShort(params.value);
				// return toDisplayTimeLong(params.value);
				// console.warn('alert_count', {params})
				return params.value
			}
		},
	}, allowedColumns));


	const handleRowClick = useCallback((data) => {
		// console.log('TripTable::handleRowClick', data);
		// setSelectedTripId(data.data.id);
		// When returning to liveView, this selected trip can no longer be selected
		onRowClick && onRowClick(data);
	}, [onRowClick]);

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={tripList}
				loading={isFetching}
				total={tripCount}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const EventTable = ({ defaultCriteria, allowedSearchCriteria, fixedSearchCriteria, defaultSearchCriteria, allowedColumns, onRowClick }) => {

	const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, null),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		name: { field: "alert_types", header: "Event Types" },
		company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));
	const [columns] = useState(generateColumns({
		// device_id: { field: "device_id", headerName: "Device Serial", width: 200 },
		alert_type: {
			field: "alert_type",
			headerName: "Event Type",
			width: 200,
			valueFormatter: (params) => {
				return getEventStringDefault(params.value, 'Unknown Event');
			}
		},
		vehicle_name: { field: "vehicle_name", headerName: "Vehicle Name", width: 200 },
		vehicle_registration_number: { field: "vehicle_registration_number", headerName: "Vehicle Reg Nr", width: 200 },
		driver_name: { field: "driver_name", headerName: "Driver Name", width: 150 },
		driver_surname: { field: "driver_surname", headerName: "Driver Surname", width: 200 },
		driver_email: { field: "driver_email", headerName: "Driver Email", width: 200 },
		timestamp: {
			field: "timestamp",
			headerName: "Date",
			width: 200,
			valueFormatter: (params) => {
				return toDisplayTimeLong(params.value);
			}
		},
	}, allowedColumns));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, null),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria, fixedSearchCriteria)
		};
		const [cancelSource, alertsAllPromise] = alertsAll(data)
		let isActive = true;
		alertsAllPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;
				setLoading(false);
				const alerts = response.result.alerts;
				setTotal(parseInt(response.result.count));
				setData(alerts);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
			});
		return () => {
			console.log(WARN_USE_EFFECT_CLEAN);
			// isActive = false
			// cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}
