import './edit-view.css';
import React, { useReducer, useRef, useState } from 'react';

import { Button, Checkbox, FormControlLabel, InputLabel, makeStyles, Select, TextField } from '@material-ui/core';
import { GenericDialog } from 'components/dialogs/dialogs';
import { useHistory } from 'react-router-dom';
import { create } from 'services/user';
import { useUserStore } from 'modules/useTokenStore';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { SingleViewContainer } from 'components/view-container/view-container';

import _ from 'lodash';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
	},
	subroot: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '300px',
		},
		'& .MuiInputLabel-root': {
			margin: theme.spacing(0, 0, 0, 1)
		},
		'& div': {
			margin: theme.spacing(1),
			width: '300px',
		},
  		'& .MuiButtonBase-root': {
			margin: theme.spacing(2),
	  	},
	},
	gridRootMore: {
		display: "grid",
		gridGap: theme.spacing(2),
		paddingTop: theme.spacing(2),
		// justifyItems: 'center',
		gridTemplateColumns: "repeat(auto-fit, minmax(275px, 1fr))",
		// gridTemplateColumns: "repeat(auto-fit, minmax(275px, 400px))",
	},
	gridRootSingle: {
		display: "grid",
		gridGap: theme.spacing(2),
		paddingTop: theme.spacing(2),
		justifyItems: 'center',
		gridTemplateColumns: "repeat(auto-fit, minmax(275px, 1fr))",
	},
	gridCell: {
		// width: '100%',
		// backgroundColor: "rgba(255, 255, 255, 0.8)",
		// border: "1px solid rgba(0, 0, 0, 0.8)",
		// padding: 20,
		// fontSize: 30,
		// textAlign: "center",
		// padding: theme.spacing(1),
	},
	gridField: {
		margin: theme.spacing(1, 0, 1, 0),
		width: '100%',
	},
	gridDetails: {
		// width: '100%',
		// height: '100%',
	},
	cardContent: {
		// width: '100%',
	},
	cardRoot: {
		// minWidth: 275,
		// minWidth: 300,
		// maxWidth: 300,
		// maxWidth: 620,
		// width: '100%',
		height: '100%',
	},
	cardActions: {
		width: '100%',
		// textAlign: 'right',
		justifyContent: 'flex-end',
	},
}));

const abcde = (data, skeleton) => {
	let newData = {};
	skeleton && skeleton.forEach((obj, _) => {
		newData[obj.property] = data[obj.property] ? data[obj.property] : "";
	})
	return newData;
}

export const EditView = ({ skeleton, fixedData, data, isDefaultData, confirmTitle, confirmDescription, onSave, onCancel }) => {

	const [d, setD] = useReducer((state, newState) => ({ ...state, ...newState }), abcde(data, skeleton));
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const classes = useStyles();

	const handleTextInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setD({ [name]: value});
	}

	const handleFileInput = (event) => {
		const name = event.target.name;
		const value = event.target.files[0];
		setD({ [name]: value});
	}

	const handleCheckboxInput = (event) => {
		const name = event.target.name;
		const value = event.target.checked;
		setD({ [name]: value});
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		if (isDefaultData) {
			let _newData = { ...d };
			if (fixedData) _newData = { ..._newData, ...fixedData };
			onSave(_newData);
		} else {
			setConfirmationDialogOpen(true);
		}
	}

	const handleConfirmationDialogClosed = (value) => {
		if (value) {
			let _newData = { ...d };
			if (fixedData) _newData = { ..._newData, ...fixedData };
			onSave(_newData);
		}
		setConfirmationDialogOpen(false);
	}

	return (
		<>
			<GenericDialog
				open={confirmationDialogOpen}
				title={confirmTitle}
				info={confirmDescription}
				onClose={ handleConfirmationDialogClosed } />
			<SingleViewContainer>
                <form className={classes.subroot} onSubmit={ handleSubmit }>
                    <Button variant="contained" color="primary" type="submit">{isDefaultData ? 'Create' : 'Save'}</Button>
                    <Button variant="contained" color="secondary" onClick={ onCancel }>Cancel</Button>
                    {
                        skeleton.map((obj, _) => {
                            return (
                                obj.type === "selection"
                                ?	<div key={ obj.property }>
                                        <InputLabel htmlFor={ obj.property }>{obj.header}</InputLabel>
                                        <Select
                                            native
                                            id={ obj.property }
                                            value={ d[obj.property] }
                                            inputProps={ { name: obj.property } }
                                            onChange={ handleTextInput }
                                            disabled={obj.disabled}
                                        >
                                            {/* <option aria-label="None" value="" /> */}
                                            {
                                                obj.values.map((opt, index) => {
                                                    return (
                                                        <option key={`${opt.value}-${index}`} value={ opt.value } >{opt.text}</option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </div>
                                : obj.type === "file"
                                ?	<div key={ obj.property }>
                                        <InputLabel htmlFor={ obj.property }>{obj.header}</InputLabel>
                                        <input type='file' name={obj.property} disabled={obj.disabled} onChange={ handleFileInput } />
                                    </div>
                                : obj.type === "checkbox"
                                ?	<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={ d[obj.property] }
                                                name={ obj.property }
                                                required={ !!obj.required }
                                                disabled={obj.disabled}

                                                onChange={ handleCheckboxInput } />
                                        }
                                        label={ obj.header }
                                    />
                                :	<TextField
                                        id={ obj.property }
                                        name={ obj.property }
                                        label={ obj.header }
                                        key={ obj.property }
                                        value={ d[obj.property] }
                                        type={ obj.type && obj.type }
                                        required={ !!obj.required }
                                        disabled={obj.disabled}
                                        helperText={ (obj.error && obj.error(d) && obj.errorText) && obj.errorText }
                                        error={ obj.error && obj.error(d) }
                                        onChange={ handleTextInput }
                                    />
                            )
                        })
                    }
                </form>
			</SingleViewContainer>
		</>
	);
}

const generateDefaultEditSkeleton = (defaultInfo, allowedInfo) => {

	const defaultSkeleton = [];
	let count = 0;
	if (allowedInfo && allowedInfo.length > 0) {
		allowedInfo.map((value, _) => {
			(defaultInfo && defaultInfo[value]) && defaultSkeleton.push({ ...defaultInfo[value], key: count++ })
		})
	} else {
		Object.entries(defaultInfo).map(([key, value]) => {
			defaultSkeleton.push({ ...value, key: count++ });
		});
	}
	return defaultSkeleton;
}

// todo: this should probably come from the API
const rolesMap = {
	Driver: { value: "Driver", text: "Driver" },
	HRUser: { value: "HRUser", text: "HR User" },
	OperationalUser: { value: "OperationalUser", text: "Operational User" },
	ClientAdmin: { value: "ClientAdmin", text: "Client Admin" },
	Owner: { value: "Owner", text: "Owner" },
	"5DTAdmin": { value: "5DTAdmin", text: "5DT Admin" },
}

// getRoleListForRole returns the list of roles that the given role may create.
// todo: this should probably come from the API
const getRoleListForRole = (currentRole) => {
	if (_.isNil(currentRole)) 
		return []

	// todo: this should probably come from the API
	const roleToValue = {
		Driver: 0,
		HRUser: 1,
		OperationalUser: 1,
		ClientAdmin: 2,
		Owner: 3,
		"5DTAdmin": 4,
	}

	// todo: this should probably come from the API
	const roles = {
		0: [rolesMap.Driver],
		1: [rolesMap.HRUser, rolesMap.OperationalUser],
		2: [rolesMap.ClientAdmin],
		3: [rolesMap.Owner],
		4: [rolesMap["5DTAdmin"]],
	}

	const currentRoleValue = roleToValue[currentRole]

	const retRoles = [];
	for (let i = 0; i <= currentRoleValue; ++i) {
		retRoles.push(...roles[i]);
	}
	return retRoles
}

const isRoleEditDisabled = (currentRole, userData) => {
	if (!currentRole || !userData || !userData.role) return false
	const roleToValue = {
		Driver: 0,
		HRUser: 1,
		OperationalUser: 1,
		ClientAdmin: 2,
		Owner: 3,
		"5DTAdmin": 4,
	}
	const currentRoleValue = roleToValue[currentRole]
	const userRoleValue = roleToValue[userData.role]

	return currentRoleValue <= userRoleValue
}

export const CreateCompanyView = ({ fixedData, allowedInfo }) => {

	const [createSkeleton] = useState(generateDefaultEditSkeleton(
		{
			name: { property: "name", header: "Name", required: true },
		}
	), allowedInfo);
	const history = useHistory();

	const handleCreate = (data) => {
	}

	const handleCreateCancel = () => {
		history.goBack();
	}

	return (
		<EditView
			skeleton={createSkeleton}
			data={ {name: ''} } 
			isDefaultData={true}
			fixedData={fixedData}

			onSave={ handleCreate }
			onCancel={ handleCreateCancel } />
	);
}


// todo: only ClientAdmin should be allowed to create users
export const CreateUserView = ({ fixedData, allowedInfo }) => {

	const isMounted = useIsMounted();
	const role = useUserStore(state => state.role);
	const createUserPromiseRef = useRef(null);
	const [createSkeleton] = useState(
		generateDefaultEditSkeleton(
			{
				image: { property: "profile_image", header: "Profile Picture", type: "file", required: false },
				name: { property: "name", header: "Name", required: true },
				surname: { property: "surname", header: "Surname", required: true },
				email: { property: "email", header: "Email", required: true },
				password: { property: "password", header: "Password", type: "password", required: true },
				confirm_password: {
					property: "confirm_password",
					header: "Confirm Password",
					type: "password",
					required: true,
					errorText: 'Passwords does not match',
					error: (data) => { return data.password !== data.confirm_password }
				},
				role: {
					property: "role",
					header: "Role",
					type: "selection",
					values: [
						...getRoleListForRole(role)
					],
					disabled: isRoleEditDisabled(role, null),
					required: true
				}
			}
			, allowedInfo));
	const history = useHistory();

	const handleCreate = (data) => {
		if ((data.password.length <= 0 || data.confirm_password.length <= 0) || data.password !== data.confirm_password) {
			return;
		}
		if (createUserPromiseRef.current) return;
		const [cancelSource, createUserPromise] = create(data)
		createUserPromiseRef.current = cancelSource;
		createUserPromise
			.then((response) => {
				if (!isMounted()) return;
				createUserPromiseRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((err) => {
				if (!isMounted()) return;
				createUserPromiseRef.current = null;
				console.log(err)
			});
	}

	const handleCreateCancel = () => {
		history.goBack();
	}

	return (
		<EditView
			skeleton={createSkeleton}
			data={{ name: '', surname: '', email: '', password: '', confirm_password: '', role: 'Driver' }}
			isDefaultData={true}
			fixedData={fixedData}

			onSave={handleCreate}
			onCancel={handleCreateCancel} />
	);
}
