import './vehicle-groups.css';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from "@material-ui/core";

import { VehicleGroupTable } from 'components/data-tables/vehicle-data-tables';

export const VehicleGroups = () => {

	const { url } = useRouteMatch();
	const history = useHistory();

	const handleRowClick = (data) => {
		history.push(`${url}/${data.data.id}`, { [data.data.id]: `${data.data.name}`})
	}
	
	const handleCreate = () => {
		history.push(`${url}/create`);
	}

	const defaultSearchCriteria = {
		name: '',
		// type: '',
		// make: '',
		// model: '',
		// registration_number: '',
		// company_name: '',
	}

	const searchCriteria = {
		name: '',
		// type: '',
		// make: '',
		// model: '',
		// registration_number: '',
		// company_name: '',
	}

	return (
		<div className={"vehicle-group-table-container"}>
			<h1>Vehicle Groups</h1>
			<Button variant="contained" color="primary" onClick={ handleCreate }>Create</Button>
			<VehicleGroupTable
				searchCriteria={searchCriteria}
				defaultSearchCriteria={defaultSearchCriteria}

				onRowClick={ handleRowClick } />
		</div>
	);
}
