import './live-view.css';
import React from 'react';

import { makeStyles, CssBaseline } from '@material-ui/core';

import { MapContainer } from 'components/map/map-container';
import { FilterLiveTopics } from 'components/map/live-global-events/live-global-events';


const styles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		width: '100%',
	},
}));

export const LiveView = () => {
	
	const classes = styles();

	return (
			<CssBaseline>
				<div className={classes.root}>
					<MapContainer center={{ lat: -25.7470155, lng: 28.2725573 }} zoom={15}>
						<FilterLiveTopics />
					</MapContainer>
				</div>
			</CssBaseline>
	);
}
