import _ from 'lodash';
import { DateTime, Duration } from 'luxon';

import humanizeDuration from 'humanize-duration';

// Event Stuff
// Add additional event types here, this should automatically add them
//      to all components that use this variable
const EVENT_TYPES = {
	speeding: { 
		title: 'Speeding',
		icon: 'Speeding_Orange.png',
	},
	aggressive_driving: { 
		title: 'Aggressive Driving',
		icon: "AggressiveDriving_Orange.png",
	},
	hard_braking: { 
		title: 'Hard Braking' ,
		icon: "Braking_Orange.png",
	},
	possible_collision: { 
		title: 'Possible Collision',
		icon: "Crash_Red.png",
	},
	seatbelt: { 
		title: 'Seatbelt Not Used',
		icon: "Seatbelt_Orange.png",
	},
	phone_in_hand: { 
		title: 'Phone in Hand',
		icon: "Texting_Orange.png",
	},
	phone_talking: { 
		title: 'Talking on Phone',
		icon: "PhoneTalking_Orange.png",
	},
	eyes_off_road: { 
		title: 'Eyes Off Road',
		icon: "EyesOfftheRoad_Orange.png",
	},
	drowsiness: { 
		title: 'Drowsiness',
		icon: "Drowsiness_Red.png",
	},
	following_distance: { 
		title: 'Unsafe Following Distance',
		icon: "FollowDistance_Orange.png",
	},
	// https://trello.com/c/zCGaUWQD/356-new-server-alerts
	no_driver: { 
		title: 'No Driver',
		icon: "NoDriver_Red.png",
	}, 
	// https://trello.com/c/zCGaUWQD/356-new-server-alerts
	// camera_covered: { 
	// 	title: 'Camera Covered',
	// 	icon: "StopSign_Grey.png",
	// }, 
	// lane_drift: 'lane_drift',
	// smoking: 'smoking',
	// cellphone: 'cellphone',
}

export const getEventTypeEntries = () => Object.entries(EVENT_TYPES).map(([key, value]) => [key, value.title]);

export const getEventType = (event_type) => EVENT_TYPES[event_type?.toLowerCase()];

export const getEventTypeTitle = (event_type) => getEventType(event_type)?.title;

export const getEventTypeIcon = (event_type) => getEventType(event_type)?.icon;

export const getEventStringDefault = (event_type, default_type) => getEventTypeTitle(event_type) ?? default_type;

export const getEventString = (event_type) => getEventStringDefault(event_type, event_type)

export const generateEventTypeFilters = (enabled) => {
	return Object.entries(EVENT_TYPES).reduce((result, item, index, array) => {
		result[item[0].toLowerCase()] = enabled ?? true;
		return result;
	}, {});
}

export const getEventTypeFilters = () => Object.entries(EVENT_TYPES).map(([key, value]) => key.toLowerCase() );

// generatePositionObject can receive a position from a livePosition or from a database
// entry.
// db.path[x]:
// accuracy: 22.512001037597656
// altitude: 1426.1480712890625
// bearing: 0
// latitude: -25.76073878
// location_time: "2022-05-06T06:56:02.916206"
// longitude: 28.26978086
// speed: 0
// live.gps_data:
// accuracy: 22.512001037597656
// altitude: 1426.1480712890625
// bearing: 0
// latitude: -25.76073878
// location_time: "2022-05-06T06:56:02.916206"
// longitude: 28.26978086
// speed: 0
export const generatePositionObject = (gps_data, key) => {
	// console.log('generatePositionObject', gps_data);
	if (_.isNil(gps_data)) {
		return null
	}
	// formatting:
	// start_time: 2022-05-05T17:42:03.125Z (i.e. with Z aka UTC)
	// path[x].location_time: "2022-05-05T17:42:03.125734" (i.e. without Z aka local - but this is a bug, it should be parsed as UTC)
	// console.log('fetchTrip', id, result);
  // dbFormat: path[x].location_time = 2022-05-05T17:26:39.594894 [Thu May 05 2022 17:26:39 GMT+0200 (South Africa Standard Time)]
  // liveFormat: gps_data?.message_timestamp/location_time = 2022-05-05T17:35:13.125Z [Thu May 05 2022 19:35:13 GMT+0200 (South Africa Standard Time)]
	// luxon: DateTime.fromISO('2016-05-25T09:08:34.123')
	const timestamp = !_.isNil(gps_data?.location_time) ? DateTime.fromISO(gps_data?.location_time, { zone: 'utc' }) : DateTime.now();
	return {
		...gps_data,
		id: gps_data.trip_id ?? key ?? null,
		key: gps_data.trip_id ?? key ?? null,
		// position is the map marker/path friendly format
		position: {
			lat: gps_data?.latitude ?? null,
			lng: gps_data?.longitude ?? null,
		},
		timestamp: timestamp,
		location_time: timestamp, // override gps_data.location_time
    bearing: gps_data?.bearing ?? 0,
	}
}

// generateEventObject can receive an alert from a message or from a database
// entry.
export const generateEventObject = (message, eventIndex) => {
	// console.log('generateEventObject', message);
	if (_.isNil(message)) {
		return null
	}
	const message_detail = message?.message_detail ?? message ?? null;
	const position_source = message_detail?.gps_data ?? message_detail?.position ?? null;
	let position = null;
	if (position_source) {
		position = {
			// This is the structure of the database position format:
			latitude: position_source.latitude,
			longitude: position_source.longitude,
			altitude: position_source.altitude,
			accuracy: position_source.accuracy,
			speed: position_source.speed,
			bearing: position_source.bearing,
			location_time: DateTime.fromISO(position_source.location_time, { zone: 'utc' }),
		};
	}
	const alert_type = message_detail?.event_type?.toLowerCase() ?? message_detail?.alert_type?.toLowerCase() ?? null;

	const device_id = message?.device_id ?? message?.device_serial_number ?? null;
	const device_serial_number = message?.device_serial_number ?? message?.device_id ?? null;

	// if message is from database, then message?.timestamp is iso string
	const timestampIso = message?.message_timestamp ?? message?.timestamp ?? null;
	const timestamp = !_.isNil(timestampIso) ? DateTime.fromISO(timestampIso, { zone: 'utc' }) : DateTime.now();

	// todo: let the device generate a uuidv4 for an alert (or any message for that matter)
	// todo: somehow we need to match an mqtt alert with an alert in the database without having its id
	// const id = message?.id ?? `${eventIndex ?? Date.now()}`
	const id = message?.id ?? `${message_detail?.event_id ?? Date.now()}`

	const driver_email = message?.trip_driver_email ?? message?.driver_email ?? "unknown";
	const driver_id = message?.trip_driver_id ?? message?.driver_id ?? null;
	const driver_name = message?.trip_driver_name ?? message?.driver_name ?? "unknown";
	const driver_surname = message?.trip_driver_surname ?? message?.driver_surname ?? "unknown";

	const trip_id = message?.trip_id ?? null;

	const vehicle_id = message?.trip_vehicle_id ?? message?.vehicle_id ?? null;
	const vehicle_name = message?.trip_vehicle_name ?? message?.vehicle_name ?? null;
	const vehicle_registration_number = message?.trip_vehicle_registration_number ?? message?.vehicle_registration_number ?? null;

	const snapshot = message_detail?.event_snapshot ?? message_detail?.snapshot ?? message?.snapshot ?? null;

	return {
		key: id,
		// dbFormat:
		id: id,
		alert_type,
		device_id,
		driver_email,
		driver_id,
		driver_name,
		driver_surname,
		position,
		timestamp,
		trip_id,
		vehicle_id,
		vehicle_name,
		vehicle_registration_number,
		// live message format:
		device_serial_number: device_serial_number,
		markerPos: position && position?.latitude && position?.longitude ? {
			lat: position.latitude,
			lng: position.longitude,
		} : null,
		details: message_detail?.event_details ?? message_detail,
		snapshot,
		alert_title: getEventStringDefault(alert_type, "Unknown Event"),
	}
}

/*
	Formatting Stuff
*/
export const formatTime = (sec) => {
	if (!sec) return 'None';
	let _days = 0;
	let _hours = 0;
	let _hours_last = false;
	let _minutes = 0;
	let _minutes_last = false;
	let _seconds = 0;
	let _seconds_last = false;
	if (sec / 86400 > 1) {
		_days = Math.floor(sec / 86400)
		sec = sec - (_days * 86400);
	}

	if (sec / 3600 > 1) {
		_hours = Math.floor(sec / 3600)
		sec = sec - (_hours * 3600);
	}
	if (_hours > 0 && _days > 0) {
		_hours_last = true;
		_minutes_last = false;
		_seconds_last = false;
	}

	if (sec / 60 > 1) {
		_minutes = Math.floor(sec / 60)
		sec = sec - (_minutes * 60);
	}
	if (_minutes > 0 && (_days > 0 || _hours > 0)) {
		_hours_last = false;
		_minutes_last = true;
		_seconds_last = false;
	}
	_seconds = Math.floor(sec);
	if (_seconds > 0 && (_days > 0 || _hours > 0 || _minutes > 0)) {
		_hours_last = false;
		_minutes_last = false;
		_seconds_last = true;
	}

	// eslint-disable-next-line no-useless-concat
	return `${_days > 0 ? `${_days} d ` : ''}` + `
				${_hours_last ? ' and ' : ''}` +
			`${_hours > 0 ? `${_hours} h ` : ''}` +
				`${_minutes_last ? ' and ' : ''}` +
			`${ _minutes > 0 ? `${_minutes} m ` : ''}` +
				`${_seconds_last ? ' and ' : ''}` +
			`${_seconds > 0 ? `${_seconds.toFixed(0)} s` : ''}`;
}

export const formatDuration = (secs) => {
	// https://moment.github.io/luxon/api-docs/index.html#durationtohuman
	// https://github.com/moment/luxon/issues/1134
	// return Duration.fromObject({seconds: secs}).toHuman();
	return humanizeDuration(secs*1000);
}

export const formatDistance = (km, unitSystem) => {
	if (km === undefined || km === null || km < 0) return 'None';
	const useMetric = unitSystem === 'metric'
	if (useMetric) {
		return `${km.toFixed(1)} km`
	}
	return `${convertToMph(km).toFixed(1)} miles`
}

export const formatSmallDistance = (m, unitSystem) => {
	if (m === undefined || m === null || m < 0) return 'None';
	const useMetric = unitSystem === 'metric'
	if (useMetric) {
		return `${m.toFixed(0)} m`
	}
	return `${convertToFt(m).toFixed(0)} ft`
}

export const formatSpeed = (kmph, unitSystem) => {
	if (!kmph && !(kmph >= 0)) return 'None';
	const useMetric = unitSystem === 'metric'
	if (useMetric) {
		return `${kmph.toFixed(1)} kmh`
	}
    return `${convertToMph(kmph).toFixed(1)} mph`
}

export const convertToMph = (kmph) => {
    return kmph * 0.6213711922;
}

export const convertToFt = (m) => {
    return m * 3.28084;
}

export const convertMetersPerSecToKmh = (meter_per_second) => {
    return meter_per_second * 3.6
}

export const convertMetersToFeet = (meters) => {
    return meters * 3.28084
}

export const convertToUnitSystem = (km, unitSystem) => {
    return unitSystem === 'metric' ? km : km * 0.6213711922;
}

/*
	Random Hash
*/
export const generateRandomHash = (len) => {
    const length = len ? len : 40;
    let arr = new Uint8Array(length);
    (window.crypto || window.msCrypto).getRandomValues(arr);
    return Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join('');
}

/*
	Request abort reason
*/
export const REQUEST_ABORT_REASON = {
    OTHER: { is_aborted: true, message: "Other" },
    CANCELLED: { is_aborted: true, message: "Request cancelled" },
}

export const WARN_USE_EFFECT = "useEffect: in progress (excessive logging may be caused by missing change detection or poor hook dependency management).";

export const WARN_USE_EFFECT_CLEAN = "useEffect: cleanup initiated";

export const logUseEffectCleanup = (logger) => {
	return () => {
		logger(WARN_USE_EFFECT_CLEAN)
	}
}

export const toDisplayTimeRel = (dateTime) => {
	// console.log('toDisplayTimeRel', dateTime, typeof(dateTime));
	const dt = _.isString(dateTime) ? DateTime.fromISO(dateTime, { zone: 'utc' }) : dateTime ?? DateTime.now();
	// return dt.toRelativeCalendar();
	// return dt.toRelative();
	return `${dt.toLocal().toRelativeCalendar()} (${dt.toLocal().toRelative()})`;
}

export const toDisplayTimeRelShort = (dateTime) => {
	// console.log('toDisplayTimeRelShort', dateTime, typeof(dateTime));
	const dt = _.isString(dateTime) ? DateTime.fromISO(dateTime, { zone: 'utc' }) : dateTime ?? DateTime.now();
	// return dt.toLocal().toRelativeCalendar();
	return dt.toLocal().toRelative();
	// return `${dt.toLocal().toRelative()}`;
}

// https://moment.github.io/luxon/api-docs/index.html
// https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
// const timestamp = DateTime.fromJSDate(field.value);
// return timestamp.toRelative();
// return timestamp.toRelativeCalendar();
// return timestamp.toFormat("yyyy LLL dd HH:mm");
// return timestamp.toFormat("HH:mm");
// return timestamp.toLocaleString(DateTime.TIME_24_SIMPLE);
// return timestamp.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
// return timestamp.toLocaleString(DateTime.TIME_SIMPLE);
// return timestamp.toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hourCycle: 'h23' });
// return timestamp.toLocaleString({ weekday: 'short', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
// https://trello.com/c/1TwQgk3m
// This excludes the year.
export const toDisplayTimeShort = (dateTime) => {
	// console.log('toDisplayTimeShort', dateTime, typeof(dateTime))
	const dt = _.isString(dateTime) ? DateTime.fromISO(dateTime, { zone: 'utc' }) : dateTime ?? DateTime.now();
	return dt.toLocal().toLocaleString({ 
		weekday: 'short', 
		month: 'short', 
		day: '2-digit', 
		hour: '2-digit', 
		minute: '2-digit', 
		second: '2-digit', 
		hourCycle: 'h23' 
	});
}

// https://trello.com/c/1TwQgk3m
// This includes the year.
export const toDisplayTimeLong = (dateTime) => {
	// console.log('toDisplayTimeLong', dateTime, typeof(dateTime));
	const dt = _.isString(dateTime) ? DateTime.fromISO(dateTime, { zone: 'utc' }) : dateTime ?? DateTime.now();
	return dt.toLocal().toLocaleString({ 
		weekday: 'short', 
		month: 'short', 
		year: 'numeric', 
		day: '2-digit', 
		hour: '2-digit', 
		minute: '2-digit', 
		second: '2-digit', 
		hourCycle: 'h23' 
	});
}

export const toDateTime = (dateTime) => {
	return _.isString(dateTime) ? DateTime.fromISO(dateTime, { zone: 'utc' }) : dateTime;
}

// https://trello.com/c/1TwQgk3m
// todo: consider following format for events: HH:MM:SS, Weekday, Month, Day, 
// todo: e.g., 20:56:27, Fri, Mar 25
export const toDisplayTimeAbbr = (dateTime) => {
	// console.log('toDisplayTimeAbbr', dateTime, typeof(dateTime))
	const dt = _.isString(dateTime) ? DateTime.fromISO(dateTime, { zone: 'utc' }) : dateTime ?? DateTime.now();
	return dt.toLocal().toLocaleString({ 
		weekday: 'short', 
		// month: 'short', 
		// year: 'numeric', 
		// day: '2-digit', 
		hour: '2-digit', 
		minute: '2-digit', 
		second: '2-digit', 
		hourCycle: 'h23' 
	});
}

// https://trello.com/c/1TwQgk3m
// todo: consider following format for events: HH:MM:SS, Weekday, Month, Day, 
// todo: e.g., 20:56:27, Fri, Mar 25
export const toDisplayTimeEvent = (dateTime) => {
	// console.log('toDisplayTimeEvent', dateTime, typeof(dateTime))
	const dt = _.isString(dateTime) ? DateTime.fromISO(dateTime, { zone: 'utc' }) : dateTime ?? DateTime.now();
	// https://moment.github.io/luxon/#/formatting?id=table-of-tokens
	return dt.toLocal().toFormat("HH:mm:ss, ccc");
}
