import React from 'react';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { useClassesReleaseNotes } from 'theme/theme';
import Particles from "react-particles";
import { loadConfettiPreset } from "tsparticles-preset-confetti";
import { useProductReleaseDateSimple, useProductVersion, useUpdateLocalVersion } from 'modules/useGlobalStore';
// import { loadFireworksPreset } from "tsparticles-preset-fireworks";

// https://particles.js.org/samples/presets/index.html
// https://www.npmjs.com/package/react-particles
// https://www.npmjs.com/package/react-confetti
// https://github.com/matteobruni/tsparticles#Presets
// https://github.com/matteobruni/tsparticles/blob/main/components/react/README.md
// https://github.com/matteobruni/tsparticles/blob/main/presets/confetti/README.md
// https://www.npmjs.com/package/tsparticles
// https://www.npmjs.com/package/react-sparkle
// https://www.npmjs.com/package/tsparticles-preset-confetti
// https://www.npmjs.com/package/tsparticles-preset-fireworks


// export class ParticlesContainer extends React.PureComponent<IProps> {
//   // this customizes the component tsParticles installation
//   async customInit(engine: Engine): Promise<void> {
//     // this adds the preset to tsParticles, you can safely use the
//     await loadFireworksPreset(engine);
//   }

//   render() {
//     const options = {
//       preset: "fireworks",
//     };

//     return <Particles options={options} init={this.customInit} />;
//   }
// }

async function customInit(engine) {
	// this adds the preset to tsParticles, you can safely use the
	await loadConfettiPreset(engine);
	// await loadFireworksPreset(engine);
}

const options = {
	preset: "confetti",
	// preset: "fireworks",
};


const PageTitle = () => {
	const classes = useClassesReleaseNotes();
	return (
		<Typography color="secondary" className={classes.title} variant="h3" gutterBottom>✨ What's New</Typography>
	)
}

const ReleaseTitle = ({date, children}) => {
	const classes = useClassesReleaseNotes();
	return (
		<div className={classes.version}>
			<Divider className={classes.divider} />
			<Typography color="secondary" variant="h3">{children}</Typography>
			<Typography variant="caption" display="block" gutterBottom>{date}</Typography>
		</div>
	)
}

const FeatureTitle = ({children}) => {
	const classes = useClassesReleaseNotes();
	return (
		<div className={classes.feature}>
			<Typography variant="h5" gutterBottom>{children}</Typography>
		</div>
	)
}

const FeatureBody = ({children}) => {
	const classes = useClassesReleaseNotes();
	return (
		<div className={classes.body}>
			<Typography variant="body1" gutterBottom>{children}</Typography>
		</div>
	)
}

const ReleaseBody = ({children}) => {
	const classes = useClassesReleaseNotes();
	return (
		<div className={classes.body}>
			<Typography variant="body1" gutterBottom>{children}</Typography>
		</div>
	)
}

const ReleaseNext = () => {
	// const productVersion = `next`;
	const productVersion = useProductVersion();
	// const productReleaseDate = `18 October 2022`;
	const productReleaseDate = useProductReleaseDateSimple();
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<ReleaseBody>This release adds more Driver dashboard functionality.</ReleaseBody>
			<FeatureTitle>Driver dashboard trip statistics</FeatureTitle>
			<FeatureBody>A Driver can now view their own trip statistics.</FeatureBody>
		</>
	)
}

// Copy this section and use it as the template for your next release notes.
// const ReleaseNext = () => {
// 	const productVersion = `1.1.0-next`;
// 	const productVersion = useProductVersion();
// 	const productReleaseDate = `7 September 2022`;
// const productReleaseDate = useProductReleaseDateSimple();
// 	return (
// 		<>
// 			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
// 			<ReleaseBody>This release xxx.</ReleaseBody>
// 			<FeatureTitle>Some feature</FeatureTitle>
// 			<FeatureBody>Some feature</FeatureBody>
// 		</>
// 	)
// }

const Release121 = () => {
	const productVersion = `1.2.1`;
	const productReleaseDate = `15 September 2022`;
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<ReleaseBody>This is a critical bug fix release.</ReleaseBody>
			<FeatureTitle>Backend performance improvements</FeatureTitle>
			<FeatureBody>The backend now responds faster to device and trip queries.</FeatureBody>
		</>
	)
}

const Release120 = () => {
	const productVersion = `1.2.0`;
	const productReleaseDate = `7 September 2022`;
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<ReleaseBody>This is a critical bug fix release.</ReleaseBody>
			<FeatureTitle>Device auth performance improvement</FeatureTitle>
			<FeatureBody>A performance issue affecting large databases has been fixed.</FeatureBody>
			<FeatureTitle>Drivers can now view their own contact information</FeatureTitle>
			<FeatureBody>Drivers can now use their dashboard to view their contact information</FeatureBody>
		</>
	)
}

const Release110 = () => {
	// const productVersion = useProductVersion();
	// const productReleaseDate = useProductReleaseDate();
	const productVersion = `1.1.0`;
	const productReleaseDate = `6 September 2022`;
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<ReleaseBody>This release focuses on security and company management functionality.</ReleaseBody>
			<FeatureTitle>👮⚠️Password security improvements</FeatureTitle>
			<FeatureBody>An important password security improvement has been made. <strong>Please reset your password for these improvements to take effect.</strong></FeatureBody>
			<FeatureTitle>🥼🧪Experimental driver dashboard</FeatureTitle>
			<FeatureBody>An experimental non-functional dashboard has been added for drivers.</FeatureBody>
			<FeatureTitle>5DTAdmin can now select company role features</FeatureTitle>
			<FeatureBody>This feature is now stable. It is now possible for 5DTAdmin to enable/disable features per company role. Go to the company edit screen and click <strong>Select Role Features</strong>.</FeatureBody>
			<FeatureTitle>5DTAdmin can now toggle training sections for company roles</FeatureTitle>
			<FeatureBody>Select the company role features to disable the training sections for certain company roles.</FeatureBody>
			<FeatureTitle>5DTAdmin can now toggle the trips section in the driver dashboard</FeatureTitle>
			<FeatureBody>Useful when companies don't want their drivers to have access to their own trips.</FeatureBody>
		</>
	)
}

const Release100 = () => {
	// const productVersion = useProductVersion();
	// const productReleaseDate = useProductReleaseDate();
	const productVersion = `1.0.0`;
	const productReleaseDate = `1 September 2022`;
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<FeatureTitle>Added a What's New page</FeatureTitle>
			<FeatureBody>The What's New page displays information about new features and bug fixes across updates. Access the page by clicking your profile icon, selecting About and then clicking on What's New.</FeatureBody>
			<FeatureTitle>Added a What's New notification</FeatureTitle>
			<FeatureBody>Now you will be notified when an update has been installed! Keep a look out for a new toast notification and a new notification badge on your profile icon whenever updates are installed.</FeatureBody>
			<FeatureTitle>Fixed incorrect calculation of trip duration</FeatureTitle>
			<FeatureBody>A regression that caused the trip duration calculation to produce incorrect results, has now been fixed.</FeatureBody>
		</>
	)
}

// https://gitlab.za.5dt.com/machine-learning/machine-learning/-/releases/drivevue-cloud%2F0.3.0
const Release030 = () => {
	const productVersion = `0.3.0`
	const productReleaseDate = `29 August 2022`;
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<FeatureTitle>5DTAdmin can now change user roles</FeatureTitle>
			<FeatureBody>This is useful when the ClientAdmin users for a company are unavailable.</FeatureBody>
			<FeatureTitle>5DTAdmin can now disable users</FeatureTitle>
			<FeatureBody>This is useful for security and billing purposes.</FeatureBody>
			<FeatureTitle>Device edit dialog now shows loading indicator</FeatureTitle>
			<FeatureBody>This gives useful feedback during poor performance periods.</FeatureBody>
			<FeatureTitle>Autocomplete select boxes now allow clearing</FeatureTitle>
			<FeatureBody>These boxes are now more intuitive and get out of your way.</FeatureBody>
			<FeatureTitle>5DTAdmin can now reset ClientAdmin passwords</FeatureTitle>
			<FeatureBody>This is useful when a ClientAdmin user is locked out of their account.</FeatureBody>
			<FeatureTitle>ClientAdmin can now change their own profile picture</FeatureTitle>
			<FeatureBody>Who doesn't like a nice profile pic.</FeatureBody>
			<FeatureTitle>ClientAdmin can now reset user passwords</FeatureTitle>
			<FeatureBody>This is useful when users are locked out of their accounts.</FeatureBody>
			<FeatureTitle>ClientAdmin can now update user roles</FeatureTitle>
			<FeatureBody>This is useful when a user needs to be promoted or demoted on the system.</FeatureBody>
			<FeatureTitle>ClientAdmin can now update user details</FeatureTitle>
			<FeatureBody>This is useful when the personal details of a user changes.</FeatureBody>
			<FeatureTitle>Improved formatting of vehicle description on live view</FeatureTitle>
			<FeatureBody>Numbers, punctuation and special characters are now handled better.</FeatureBody>
			<FeatureTitle>Fixed trip end calculation</FeatureTitle>
			<FeatureBody>A regression caused trip end calculations to be 30 minutes longer than they should be. This is now resolved.</FeatureBody>
			<FeatureTitle>Live view now allows panning while event is selected</FeatureTitle>
			<FeatureBody>Gone is that annoying re-centering of the screen while an event is selected.</FeatureBody>
			<FeatureTitle>Live view vehicle marker no longer performs deselection</FeatureTitle>
			<FeatureBody>Like to click the selected live vehicle marker over-and-over again, but don't like the trip toggling that comes with it? Now you can click that vehicle marker without any disruption.</FeatureBody>
			<FeatureTitle>Trips can now display more than 20 alerts</FeatureTitle>
			<FeatureBody>This is useful when you want to see more than 20 alerts...which is always.</FeatureBody>
		</>
	)
}

// https://gitlab.za.5dt.com/machine-learning/machine-learning/-/releases/drivevue-cloud%2F0.2.0
const Release020 = () => {
	const productVersion = `0.2.0`
	const productReleaseDate = `22 August 2022`
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<FeatureTitle>Improved update process</FeatureTitle>
			<FeatureBody>The update management process has been greatly improved.</FeatureBody>
			<FeatureTitle>Improved documentation</FeatureTitle>
			<FeatureBody>The documentation for the release process has been significantly improved.</FeatureBody>
			<FeatureTitle>5DTAdmin can now change the activation status of companies</FeatureTitle>
			<FeatureBody>Companies can now be enabled, disabled, or retired by the 5DTAdmin.</FeatureBody>
			<FeatureTitle>5DTAdmin has a new company edit screen</FeatureTitle>
			<FeatureBody>The company edit screen has been greatly improved.</FeatureBody>
			<FeatureTitle>5DTAdmin has a new company create screen</FeatureTitle>
			<FeatureBody>The company create screen has been greatly improved.</FeatureBody>
			<FeatureTitle>5DTAdmin has a new device edit screen</FeatureTitle>
			<FeatureBody>The device edit screen has been greatly improved, with support for changing device company, serial number and activation status.</FeatureBody>
			<FeatureTitle>Improved automated testing</FeatureTitle>
			<FeatureBody>Better automated testing, means less regressions and better stability.</FeatureBody>
			<FeatureTitle>Company can now be selected during device registration</FeatureTitle>
			<FeatureBody>Useful when you want to assign a device to a company during device registration.</FeatureBody>
			<FeatureTitle>ClientAdmin has an improved device vehicle linking screen</FeatureTitle>
			<FeatureBody>Device vehicle linking is now easier.</FeatureBody>
			<FeatureTitle>5DTAdmin now has a separate administrator users screen</FeatureTitle>
			<FeatureBody>Administrator users across companies can now be viewed in the Admins panel.</FeatureBody>
			<FeatureTitle>ClientAdmin can now create other ClientAdmin users</FeatureTitle>
			<FeatureBody>A ClientAdmin for a company can now create other ClientAdmin users for that company.</FeatureBody>
			<FeatureTitle>5DTAdmin can now edit company ClientAdmin users</FeatureTitle>
			<FeatureBody>A 5DTAdmin can now update the details for ClientAdmin users</FeatureBody>
			<FeatureTitle>5DTAdmin can now create company ClientAdmin users from the Admin users page</FeatureTitle>
			<FeatureBody>Another screen is now available for creating company ClientAdmin users</FeatureBody>
		</>
	)
}

// https://gitlab.za.5dt.com/machine-learning/machine-learning/-/releases/drivevue-cloud%2F0.1.0
const Release010 = () => {
	const productVersion = `0.1.0`
	const productReleaseDate = `29 July 2022`
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<FeatureTitle>Trip distance, duration and alert count is now displayed</FeatureTitle>
			<FeatureBody>These statistics provide a useful summary of trips.</FeatureBody>
			<FeatureTitle>Improved live view stability</FeatureTitle>
			<FeatureBody>Some issues causing live view instability have been resolved.</FeatureBody>
			<FeatureTitle>Caching optimizations</FeatureTitle>
			<FeatureBody>In some instances browsers cache the webapp too aggressively, preventing updates from propagating. The server now sends headers to tell browsers not to cache the webapp index page.</FeatureBody>
			<FeatureTitle>Improved live trip/device status</FeatureTitle>
			<FeatureBody>The calculation and display of live trip/device status has been improved.</FeatureBody>
			<FeatureTitle>Improved snapshot/event image and video capabilities</FeatureTitle>
			<FeatureBody>It is now possible to view snapshot and event videos, along with images.</FeatureBody>
			<FeatureTitle>ClientAdmin users table is now sorted by name</FeatureTitle>
			<FeatureBody>The sort order was previously undefined, but now defaults to name.</FeatureBody>
			<FeatureTitle>ClientAdmin users table state is now remembered</FeatureTitle>
			<FeatureBody>The user-defined items per page and sort order are now remembered between page switches.</FeatureBody>
		</>
	)
}

// https://gitlab.za.5dt.com/machine-learning/machine-learning/-/releases/drivevue-cloud%2F0.0.5
const Release005 = () => {
	const productVersion = `0.0.5`
	const productReleaseDate = `20 July 2022`
	return (
		<>
			<ReleaseTitle date={productReleaseDate}>{productVersion}</ReleaseTitle>
			<FeatureTitle>Maintenance Release</FeatureTitle>
			<FeatureBody>This release contains a number of bug fixes, stability improvements and security updates.</FeatureBody>
		</>
	)
}

export default () => {
	const classes = useClassesReleaseNotes();
	useUpdateLocalVersion();
	return (
		<div className={classes.root}>
			<Particles options={options} init={customInit} />
			<PageTitle />
			<ReleaseNext />
			<Release121 />
			<Release120 />
			<Release110 />
			<Release100 />
			<Release030 />
			<Release020 />
			<Release010 />
			<Release005 />
		</div>
	)
}
