// import './device-create.css';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { EditView } from 'components/edit-view/edit-view';

import { createCourse } from 'services/training-management';
import { useIsMounted } from 'shared-hooks/useIsMounted';

export const AdminCourseCreate = () => {

	const isMounted = useIsMounted();
    const courseCreateCancelSourceRef = useRef(null);
	const history = useHistory();

	const handleCreate = (data) => {
		console.log(data)
        if (courseCreateCancelSourceRef.current) return;
		const [cancelSource, courseCreatePromise] = createCourse(data.title, data.is_default, data.course)
        courseCreateCancelSourceRef.current = cancelSource;
		courseCreatePromise
			.then((response) => {
				if (!isMounted()) return;
                courseCreateCancelSourceRef.current = null;
				if (!response.success) return;
				console.log(response)
			})
			.catch((err) => {
				if (!isMounted()) return;
                courseCreateCancelSourceRef.current = null;
				console.log(err)
			});
	}

	const handleCreateCancel = () => {
		history.goBack();
	}

	const createSkeleton = [
		{ key: 1, property: "title", header: "Title" },
		{ key: 2, property: "is_default", header: "Is Default", type: 'checkbox' },
		{ key: 3, property: "course", header: "Course File", type: 'file' },
		// { key: 2, property: "company_id", header: "Company ID" },
	];

	return (
		<EditView
			skeleton={createSkeleton}
			data={ {serial_number: '', company_id: ''} } 
			defaultData={true}
			onSave={ handleCreate }
			onCancel={ handleCreateCancel } />
	);
}
