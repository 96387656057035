import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

import { deleteCourse, getCourse } from 'services/training-management';

import { InfoView } from 'components/info-view/info-view';
import { TinyInfoView } from 'components/info-view/tiny-info-view/tiny-info-view';
import { MultiViewContainer } from 'components/view-container/view-container';
import { CourseTabs } from 'components/tabs/course-tabs/course-tabs';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';

export const AdminCourse = () => {

	const isMounted = useIsMounted();
	const deleteCourseCancelSourceRef = useRef(null)
	const { courseId } = useParams();
	const [course, setCourse] = useState(false);
	const [minified, setMinified] = useState(false);
	const history = useHistory();
	const { url } = useRouteMatch();

	useEffect(() => {
		if (!courseId) return;
		const [cancelSource, courseGetPromise] = getCourse(courseId)
		let isActive = true;
		courseGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success) return;
				setCourse(response.result);
				history.replace(url, { [response.result.id]: `${response.result.title}`})
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err);
			});
		return () => {
			isActive = false;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [courseId]);

	// const handleEditDevice = () => {
	// 	history.push(`${url}/edit`);
	// }

	const handleMinimize = (param) => {
		setMinified(param)
	}

	const handleDeleteCourseClicked = (param) => {
		if (!courseId) return;
		if (deleteCourseCancelSourceRef.current) return;
		const [cancelSource, courseDeletePromise] = deleteCourse(courseId)
		deleteCourseCancelSourceRef.current = cancelSource
		courseDeletePromise
            .then((response) => {
				if (!isMounted()) return;
                deleteCourseCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((error) => {
				if (!isMounted()) return;
                deleteCourseCancelSourceRef.current = null;
				console.log(error)
			})
	}

	const viewSkeleton = [
		{ key: 0, property: "title", header: "TITLE:" },
		{ key: 1, property: "num_lessons", header: "TOTAL LESSONS:" },
		{ key: 2, property: "is_default", header: "DEFAULT COURSE:" },
		{ key: 3, property: "created_on", header: "CREATED ON:" },
	];

	const viewTinySkeleton = [
		{ key: 0, property: "title", header: "TITLE:" },
		{ key: 1, property: "num_lessons", header: "TOTAL LESSONS:" },
		{ key: 2, property: "is_default", header: "DEFAULT COURSE:" },
		{ key: 3, property: "created_on", header: "CREATED ON:" },
	];

	return (
		<MultiViewContainer
			minified={minified}

			tinyChild={
				<TinyInfoView
					skeleton={viewTinySkeleton}
					data={course}

					// onDelete={ handleDeleteCourseClicked }
					onMaximize={ handleMinimize } />
			}
			viewChild={
				<InfoView
					skeleton={viewSkeleton}
					data={course}
					confirmTitle='Delete Course?'
					confirmDescription='You are about to delete the course.'

					onDelete={ handleDeleteCourseClicked }
					onMinimize={ handleMinimize } />
			}
			rightChild={<CourseTabs />}/>
	);
}
