import './user-group-create.css';
import React from 'react';
import { useHistory } from 'react-router';

import { useCreateUserGroup } from 'services/api';

import { EditView } from 'components/edit-view/edit-view';

export const UserGroupCreate = () => {

	const history = useHistory();

	const handleCreateCancel = React.useCallback(() => {
		history.goBack();
	}, [history]);

	const mutation = useCreateUserGroup({
		onSuccess: () => handleCreateCancel()
	});

	const handleCreate = React.useCallback((data) => {
		mutation.mutate(data);
	}, [mutation]);

	const createSkeleton = [
		{ key: 1, property: "name", header: "Name", required: true },
		// { key: 2, property: "company_name", header: "Company" },
		// { key: 4, property: "users", header: "Users" },
		// { key: 4, property: "owners", header: "Owners" },
		// { key: 4, property: "viewers", header: "Viewers" },
	];

	return (
		<EditView
			skeleton={createSkeleton}
			data={ {
				name: "",
			}} 
			isDefaultData={true}
			onSave={ handleCreate }
			onCancel={ handleCreateCancel } />
	);
}
