// import './admin-device.css';
// import React, { useState } from 'react';
// import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

// import { InfoView } from 'components/info-view/info-view';
// import { TinyInfoView } from 'components/info-view/tiny-info-view/tiny-info-view';
// import { DeviceTabs } from 'components/tabs/device-tabs/device-tabs';
// import { MultiViewContainer } from 'components/view-container/view-container';

// export const AdminDevice = () => {

// 	const { id } = useParams();
// 	const [minified, setMinified] = useState(false);
// 	const history = useHistory();
// 	const { url } = useRouteMatch();

// 	const { data: deviceData } = useFetchDevice(id);

// 	console.log('AdminDevice', deviceData);

// 	const handleEditDevice = () => {
// 		history.push(`${url}/edit`);
// 	}

// 	const handleMinimize = (param) => {
// 		setMinified(param)
// 	}

// 	const viewSkeleton = [
// 		{ key: 0, property: "serial_number", header: "SERIAL NR:" },
// 		{
// 			key: 1,
// 			property: "last_online_time",
// 			header: "LAST ONLINE:",
// 			formatValue: (data, index) => {
// 				return <div style={ {marginLeft: "10px"} }>{data ? `${data}` : "Not yet online" }</div>
// 			},
// 		},
// 		{ key: 2, property: "currently_online", header: "ONLINE:" },
// 		{
// 			key: 3,
// 			property: "last_known_location",
// 			header: "LAST KNOWN LOCATION:",
// 			formatValue: (data, index) => {
// 				return <div style={ {marginLeft: "10px"} }>{data ? `Lat: ${data.latitude} Lng: ${data.longitude}` : "No locations logged" }</div>
// 			},
// 		},
// 		{
// 			key: 3,
// 			property: "vehicle_registration_number",
// 			header: "CURRENT VEHICLE REG NR:",
// 			formatValue: (data, index) => {
// 				return <div style={ {marginLeft: "10px"} }>{data ? `${data}` : "Device not linked to vehicle" }</div>
// 			},
// 		},
// 		{
// 			key: 4,
// 			property: "company_name",
// 			header: "COMPANY:",
// 			formatValue: (data, index) => {
// 				return <div style={ {marginLeft: "10px"} }>{data ? `${data}` : "None" }</div>
// 			},
// 		},
// 	];

// 	const viewTinySkeleton = [
// 		{ key: 0, property: "serial_number", header: "SERIAL NR:" },
// 		{
// 			key: 1,
// 			property: "last_online_time",
// 			header: "LAST ONLINE:",
// 			formatValue: (data, index) => {
// 				return <div style={ {marginLeft: "10px"} }>{data ? `${data}` : "Not yet online" }</div>
// 			},
// 		},
// 		{ key: 2, property: "currently_online", header: "ONLINE:" },
// 		{
// 			key: 3,
// 			property: "company_name",
// 			header: "COMPANY:",
// 			formatValue: (data, index) => {
// 				return <div style={ {marginLeft: "10px"} }>{data ? `${data}` : "None" }</div>
// 			},
// 		},
// 	];

// 	return (
// 		<MultiViewContainer
// 			minified={minified}

// 			tinyChild={
// 				<TinyInfoView
// 					skeleton={viewTinySkeleton}
// 					data={deviceData}
					
// 					onEdit={ handleEditDevice }
// 					onMaximize={ handleMinimize } />
// 			}
// 			viewChild={
// 				<InfoView
// 					skeleton={viewSkeleton}
// 					data={deviceData}
					
// 					onEdit={ handleEditDevice }
// 					onMinimize={ handleMinimize } />
// 			}
// 			rightChild={<DeviceTabs id={id} />}/>
// 	);
// }

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import _ from 'lodash';

import { DrawerActions, DrawerAlert, DrawerButton, DrawerButtonConfirm, DrawerButtonDanger, DrawerButtonWarning, DrawerConfirmMessage, DrawerDetails, DrawerHelp, DrawerTextField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useCreateDevice } from 'services/api';
import { CompanySelect } from 'components/autocomplete/company-select';
import { useFetchDevice } from 'services/api';
import { toDisplayTimeLong } from 'shared-functions/shared-functions';
import { ActivationStatusSelect } from 'components/autocomplete/activation-status-select';

// todo: when assigning a device to a new company, the previous trips made using the device should still be linked to the previous company.
const helpText = `Use this form to update an existing device registration.`;

const SaveMessage = () => {
	return (
		<DrawerConfirmMessage 
			// alert="If you are transferring the device to another company, then this will unlink the vehicle and driver currently linked to the device." 
			message="Please confirm that you want to update the device registration with the requested changes." 
			severity="warning" 
		/>
	)
}

// const DisableMessage = () => {
// 	return (
// 		<DrawerConfirmMessage 
// 			alert="NOT IMPLEMENTED. The device will no longer be able to record trips, and all data transmitted by the device will be ignored. Existing vehicles and drivers will be unlinked from the device. The device will not be available for linking." 
// 			message="The device will be disabled temporarily, but can be enabled again. Existing trip data will be retained." 
// 			severity="error" 
// 		/>
// 	)
// }

// const DeleteMessage = () => {
// 	return (
// 		<DrawerConfirmMessage 
// 			alert="NOT IMPLEMENTED. The device and all its history, trips and links will be completely deleted. Deleted devices can only be recovered by restoring a backup." 
// 			message="The device will be permanently and irrevocably deleted." 
// 			severity="error" 
// 		/>
// 	)
// }

export const AdminDevice = () => {

	const { id } = useParams();

	const { data: deviceData, isFetching } = useFetchDevice(id);

	// console.warn('AdminDevice: todo', { deviceData })

	const classes = useClassesFormStyle();

	const history = useHistory();

  const [formData, setFormData] = React.useState({
		deviceId: '',
		serialNumber: '',
		lastOnlineTime: '',
		onlineStatus: '',
		tripCount: '',
		linkedDriver: '',
		linkedVehicle: '',
		activationOption: null,
		companyOption: null,
		alertSeverity: 'info',
		alertMessage: '',
		loading: false,
	});

	const loading = React.useMemo(() => formData.loading || isFetching, [isFetching, formData.loading])

	const companyHelp = React.useMemo(() => {
		const newCompany = !_.isEqual(formData?.companyOption?.value ?? null, deviceData?.company_id ?? null);
		return newCompany ? `The device will be transferred to the selected company. It will be unlinked from its associated vehicle and driver.` : null;
	}, [formData.companyOption, deviceData]);

	const activationHelp = React.useMemo(() => {
		const nextActivation = formData?.activationOption?.value ?? null;
		const newActivation = !_.isEqual(nextActivation, deviceData?.activation_status ?? null);
		if (!newActivation) {
			return null
		}
		if (_.isEqual(nextActivation, "enabled")) {
			return "The device will be re-enabled. All device restrictions will be lifted."
		}
		else if (_.isEqual(nextActivation, "disabled")) {
			return "The device will be disabled. It will continue to function, but some restrictions will apply."
		}
		else if (_.isEqual(nextActivation, "retired")) {
			return "The device will be retired. It will no longer function, but historic data will be retained. The data will be eligible for deletion during maintenance tasks."
		}
		else {
			return null
		}
	}, [formData.activationOption, deviceData]);

	React.useEffect(() => {
		const companyOption = _.isNil(deviceData?.company_id) ? null : {
			value: deviceData?.company_id,
			label: deviceData?.company_name,
		};

		const activationStatus = deviceData?.activation_status ?? 'enabled';
		const activationOption = {
			value: activationStatus,
			label: _.startCase(activationStatus),
		};
		const linkedDriver = (deviceData?.driver_linked ?? false) ? deviceData?.driver_id : 'None';
		const linkedVehicle = (deviceData?.vehicle_linked ?? false) ? deviceData?.vehicle_id : 'None';
		setFormData(state => ({
				...state, 
				serialNumber: deviceData?.serial_number ?? '',
				deviceId: deviceData?.id ?? '',
				lastOnlineTime: toDisplayTimeLong(deviceData?.last_online_time) ?? '',
				onlineStatus: _.startCase(_.toLower(deviceData?.status ?? '')),
				tripCount: deviceData?.trip_count ?? 0,
				linkedDriver,
				linkedVehicle,
				activationOption,
				companyOption,
			}))
	}, [deviceData])

	// todo: display confirmation dialog on submit, with warning clearly stating the effects of the change
	// - existing vehicles will be unlinked
	// - all trips will be moved to the new company

	const isSubmitDisabled = React.useMemo(() => {
		return _.isEqual(loading, true) || _.isEmpty(formData.serialNumber.trim()) || _.isNil(formData.companyOption)
	}, [formData, loading])

	const createDevice = useCreateDevice({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `Device registration successfully updated.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not update device registration: ${p.message}`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setFormData(state => ({
				...state, 
				alertMessage: 'Form incomplete. Please provide the missing information.', 
				alertSeverity: 'error'
			}));
			return
		}

		setFormData(state => ({
			...state, 
			loading: true, 
			alertMessage: 'Updating device registration...', 
			alertSeverity: 'info'
		}));

		// console.warn('createDevice:onSubmit', {
		// 	formData
		// })

		// see dvapi/routes/v1/device.js (createDevice)
		createDevice.mutate({
			id: formData.deviceId,
			serial_number: formData.serialNumber,
			company_id: formData.companyOption.value,
			activation_status: formData.activationOption.value,
		});
	}, [createDevice, formData, isSubmitDisabled]);

	const onClickClose = () => {
		history.goBack();
	}

	// const onClickDelete = () => {
	// 	history.goBack();
	// }

	// const onClickDisable = () => {
	// 	history.goBack();
	// }

	const onInputSerialNumber = e => setFormData(state => ({...state, serialNumber: e.target.value}));

	const onChangeCompany = (data) => {
		setFormData(state => ({...state, companyOption: data}))
	};

	const onChangeActivation = (data) => {
		setFormData(state => ({...state, activationOption: data}))
	};

	return (
		<form noValidate classes={classes.body}>
			<DrawerTitle>Edit Device Registration</DrawerTitle>
			<DrawerHelp alert={formData.alertMessage} severity={formData.alertSeverity}>{helpText}</DrawerHelp>
			{/* <DrawerAlert variant="filled" severity={formData.alertSeverity}>{formData.alertMessage}</DrawerAlert> */}
			<DrawerTextField id="serial_number" label="S/N" autoFocus={true} autoComplete="off" required value={formData.serialNumber} onInput={onInputSerialNumber} />
			<CompanySelect help={companyHelp} value={formData.companyOption} onChange={onChangeCompany} />
			<ActivationStatusSelect help={activationHelp} value={formData.activationOption} onChange={onChangeActivation} />
			<DrawerDetails>
				<DrawerTextField id="last_online_time" label="Last Online" value={formData.lastOnlineTime} disabled />
				<DrawerTextField id="online_status" label="Online Status" value={formData.onlineStatus} disabled />
				<DrawerTextField id="trip_count" label="Trips" value={formData.tripCount} disabled />
				<DrawerTextField id="driver_id" label="Driver ID" value={formData.linkedDriver} disabled />
				<DrawerTextField id="vehicle_id" label="Vehicle ID" value={formData.linkedVehicle} disabled />
				<DrawerTextField id="id" label="ID" value={formData.deviceId} disabled />
			</DrawerDetails>
			<DrawerActions>
				<DrawerButtonConfirm details={<SaveMessage />} title="Save changes to device registration?" action="Save" color="primary" onClick={onSubmit} />
				{/* <DrawerButtonWarning details={<DisableMessage />} title="Disable the device?" action="Disable" color="primary" onClick={onClickDisable} disabled={loading} /> */}
				{/* <DrawerButtonDanger manualConfirmation={formData.serialNumber} details={<DeleteMessage />} title="Delete the device?" action="Delete" color="primary" onClick={onClickDelete} disabled={loading} /> */}
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} disabled={loading} />
			</DrawerActions>
		</form>
	)
}
