import _ from 'lodash';
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import queryString from 'query-string';

import { useHistory, useLocation } from 'react-router-dom';

import { useProductCompany, useProductCopyrightPrefix, useProductLink, useProductYear, callLogin } from 'modules/useGlobalStore';

// https://v4.mui.com/styles/api/#makestyles-styles-options-hook
const useStyles = makeStyles((theme) => ({
  containerRoot: {
  },
  paperRoot: {
    width: '100%',
    height: '100%',
    background: theme.palette.background.paper,
    opacity: 0.98,
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
  },
  paper: {
    // background: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    // padding: theme.spacing(8),
    // marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  forgot: {
    width: '100%',
    // color: 'gray',
    textAlign: 'center',
  },
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  alert: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  avatar: {
    width: '20%',
    height: '100%',
    // height: 100,
    // width: 100,
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    width: '100%',
    height: 50,
    // height: 70,
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // background: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 0,
    background: theme.palette.primary.mainGradientLogin,
  },
}));

const ErrorMessage = ({ classes, message }) => {
  const location = useLocation()

  const error = React.useMemo(() => {
    const result = {
      message: message ?? '',
      severity: 'error',
    };
    if (message) {
      return result
    }
    if (location && location.search) {
      const reason = queryString.parse(location.search).reason;
      if (_.isEqual(reason, "tk")) {
        result.message = "Your login session has expired. Please login again.";
      }
      else if (_.isEqual(reason, "pc")) {
        result.message = "Password change successful! Please login again.";
        result.severity = "success";
      }
    }
    if (_.isEmpty(result.message)) {
      return null
    }
    return result
  }, [location, message])

  if (_.isNil(error)) {
    return null
  }
  return (
    <Alert className={classes.alert} variant="standard" severity={error.severity}>{error.message}</Alert>
  )
}

const Copyright = () => {

  const copyPrefix = useProductCopyrightPrefix();
  const copyLink = useProductLink();
  const company = useProductCompany();
  const year = useProductYear();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {copyPrefix}
      <Link color="inherit" href={copyLink}>{company}</Link>{' '}
      {year}
    </Typography>
  );
}

const Background = () => {
  const classes = useStyles();
  return (
    <span className={classes.background}></span>
  )
}

// https://raw.githubusercontent.com/mui/material-ui/v4.12.3/docs/src/pages/getting-started/templates/sign-in/SignIn.js
// https://github.com/alan2207/react-query-auth
// https://www.reddit.com/r/reactjs/comments/jd5oxu/does_reactquery_make_sense_in_an_auth_provider/
// https://www.smashingmagazine.com/2022/01/building-real-app-react-query/#authentication
export const Login = () => {
  const classes = useStyles();

  // const version = useProductVersion();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage(null);
    callLogin(username, password)
      .then(r => {
        setLoading(false);
        history.push('/');
      })
      .catch(e => {
        // console.warn('handleSubmit', { e })
        if (_.isEqual(e.response.status, 401)) {
          setMessage('Invalid credentials.');
          // setMessage(`${e.message}`);
          // setMessage(`${e.response.data.message}`);
        } else {
          setMessage(`${e.message}`);
        }
        setLoading(false);
      })
      .finally(() => {
      })
  }

  const containerClasses = React.useMemo(() => ({
    root: classes.containerRoot
  }), [classes]);

  return (
    <CssBaseline>
      <Background />
      <Container classes={containerClasses} component="main" maxWidth="xs">
        <Paper className={classes.paperRoot} variant="outlined">
        <div className={classes.paper}>
          <Avatar variant="square" className={classes.avatar} src="/assets/logo/5DTLogo.png" />
          {/* <Typography className={classes.title} variant="h2">DriveVUE</Typography> */}
          <Typography component="h1" variant="h5">DriveVUE™</Typography>
          {/* <Typography variant="caption">{version}</Typography> */}
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={username}
              onInput={e => setUsername(e.target.value)}
            // onChange={e=>console.log(e) }
            // error={true} // this makes the helperText red
            // helperText={"bla"}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onInput={e => setPassword(e.target.value)}
            />
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <div className={classes.loginWrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Login
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">Forgot password?</Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Request access"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={2}>
          <Grid container>
            <ErrorMessage classes={classes} message={message} />
          </Grid>
        </Box>
        <Box mt={8}>
          <Copyright />
        </Box>
        </Paper>
      </Container>
    </CssBaseline>
  );
}
