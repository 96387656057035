import "./data-tables.css";
import "./driver-lessons-tables.css";
import React, { useEffect, useState } from 'react';

import { getUserCourses, getUserLessons } from "services/course";
import { Card, CardMedia, LinearProgress } from "@material-ui/core";
import { useIsMounted } from "shared-hooks/useIsMounted";
import { REQUEST_ABORT_REASON } from "shared-functions/shared-functions";

export const CourseTable = ({ onClick }) => {

	const isMounted = useIsMounted();
	const [courses, setCourses] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();

	useEffect(() => {
		console.log("CourseTable useEffect: IF YOU SEE ME MORE THAN ONCE DIRECTLY AFTER EACH OTHER OR INFINITELY, YOU ARE DOING SOMETHING WRONG!!!!!")
		setLoading(true);

		const [userCoursesGetCancelSource, userCoursesGetPromise] = getUserCourses()
		let isActive = true;
		userCoursesGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				if (!response.success || !response.result) return;

				setTotal(parseInt(response.result.length));
				setCourses(response.result);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err)
				setLoading(false);
			});
		return () => {
			isActive = false;
			userCoursesGetCancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [])

	const handleClick = (courseId, lessonId) => {
		onClick && onClick(courseId, lessonId);
	}

	return (
		<div className={"table-container"}>
			{
                (courses && courses.length > 0)
				?	courses.map((value, _) => {
						return (
							<div key={value.id}>
								<h2 style={ { textAlign: 'center', marginTop: 5, marginBottom: 5} } >{value.title}</h2>
								<div className='basic-grid'>
									<CourseLessonsTable courseId={value.id} onClick={ handleClick } />
								</div>
								<hr style={ { borderTop: "3px solid #bbb" }} />
							</div>
						)
					})
				: <h2>No courses assigned</h2>
            }
		</div>
	);
}

const CourseLessonsTable = ({ courseId, onClick }) => {

	const isMounted = useIsMounted()
	const [lessons, setLessons] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();

	useEffect(() => {
		if (!courseId) return
		setLoading(true);

		const [userLessonsGetCancelToken, userLessonsGetPromise] = getUserLessons({ course_id: courseId })
		let isActive = true;
		userLessonsGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				if (!response.success || !response.result) return;

				const lessons = response.result.lessons;
				setTotal(parseInt(lessons.length));
				setLessons(lessons);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err)
				setLoading(false);
			});
		return () => {
			isActive = false;
			userLessonsGetCancelToken.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [])

	const handleClick = (data) => {
		onClick && onClick(courseId, data);
	}

	return (
        <>
            {
                lessons && lessons.map((value, _) => {
                    return (
                        <CourseLessonCard key={value.id} courseId={courseId} lesson={value} onClick={ handleClick } />
                    )
                })
            }
        </>
	);
}

const CourseLessonCard = ({ courseId, lesson, onClick }) => {

	const [progress] = useState(lesson.progress !== null ? lesson.progress * 100 : 0);
	const [score] = useState(lesson.score !== null ? lesson.score * 100 : null);

	const handleClick = () => {
		onClick && onClick(lesson.id);
	}

	return (
        <Card key={lesson.id} className='card' onClick={ handleClick }>
			<CardMedia
				component="img"
				style={ { width: 'calc(100% - 10px)', height: 'auto', margin: 5 } }
				image={`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/courses/${courseId}/${lesson.id}/thumbnail.png`}
				alt="green iguana"
				/>
            {/* <CardContent>
                <img style={ { height: '100%', width: 'auto'} } src={`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/courses/${courseId}/${lesson.id}/thumbnail.png`} />
            </CardContent> */}
			<LinearProgress style={ { width: '95%' } } variant={"determinate"} value={progress} />
			<div style={ { height: 100, width: '95%' } }>
				<h5 style={ { marginTop: 10, marginBottom: 0, textAlign: 'center' } }>{score !== null ? `Score: ${score}%` : 'Lesson not completed'}</h5>
				<h3 style={ { marginTop: 10, marginBottom: 10, textAlign: 'center' } }>{lesson.title}</h3>
			</div>
				
        </Card>
	);
}
