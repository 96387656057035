import './breadcrumbs.css'
import React, { useCallback } from 'react';
import { Link, Route, useHistory, useLocation } from "react-router-dom";
import { Breadcrumbs, IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useBreadcrumbs } from 'modules/useGlobalStore';

const linkToName = {
  // Main pages
  "users": "Users",
  "user-groups": "User Groups",
  "vehicles": "Vehicles",
  "vehicle-groups": "Vehicle Groups",
  "devices": "Devices",
  "training": "Training",
  "training-management": "Training Management",
  "companies": "Companies",

  // Sub pages
  "edit": "Edit",
  "create": "Create New",
  "link-vehicle": "Link Vehicle to Device",
  "add-admin-user": "Add Admin User",
  "viewer-management": "Viewers",
  "owner-management": "Owners",
  "user-management": "Users",
  "statistics": "Statistics",
  "event-statistics": "Event Statistics",
  "trips": "Trips",
}

const humanFriendly = (segment, locationState, breadcrumbsMap, constMap) => {
  return breadcrumbsMap[segment] ? breadcrumbsMap[segment] : 
    (constMap[segment] ? constMap[segment] : (
      locationState && locationState[segment] ? locationState[segment] : segment))
}

// For breadcrumbs to work, the location.state variable needs to have a mapping
// from identifier to title, e.g:
// history.push(`${url}/${data.data.id}`, { [data.data.id]: `${data.data.title}`});
// Alternatively, not yet implemented, pages could push the desired breadcrumb
// title directly, e.g.: 
// history.push(`${url}/${data.data.id}`, { title: `${data.data.title}`});
// Another option is to have the breadcrumb retrieve the current breadcrumb path
// from the global store (useGlobalStore), e.g..
// useGlobalStore(state => state.breadcrumbs)
const BreadcrumbComp = () => {
  const location = useLocation();
  const history = useHistory();

  const breadcrumbsMap = useBreadcrumbs();

  let segments = location.pathname.split('/').filter((x) => x);
  let segmentsFiltered = segments.filter((x, index) => index > 0);
  // console.log('Breadcrumb', location, segments, segmentsFiltered);

  if (segmentsFiltered[segmentsFiltered.length - 1] === 'link-vehicle') {
    segmentsFiltered = [...segmentsFiltered.slice(0, segmentsFiltered.length - 2), 'link-vehicle'];
    segments = [...segments.slice(0, segments.length - 1)];
  }
  const breadcrumbs = segmentsFiltered.length > 1 ? segmentsFiltered : null

  // This is what gets called when the user presses on the back arrow to the
  // left of the breadcrumb trail. What gets called when the user clicks on a
  // breadcrumb segment? Look at the Link component's 'to' property.
  const handleNavigateBack = () => {
    const backPath = `/${segments.slice(0, segments.length - 1).join('/')}`;
    // console.log('handleNavigateBack', backPath);
    history.push(backPath)
  }

  // console.log('Breadcrumbs', breadcrumbs);

  const breadcrumbChildren = !breadcrumbs ? null : breadcrumbs.map((value, index) => {
    // For every breadcrumb (value), try and resolve it to a friendly name.
      const last = index === breadcrumbs.length - 1;
      // todo: consider having the back path provided by the history.push location.state.
      const to = `/${segments.slice(0, index + 2).join('/')}`;
      // if (!location.state) {
      //   console.warn('Caller did not provide location.state, breadcrumb can not be resolved. Use history.push with state param, or Link component with to object that has state param.:', to);
      // }
      // console.log('children', to, location.state);
      // the last element is not clickable, but everything in between should be
      return last ? (<Typography color="textPrimary" key={to}>
          {humanFriendly(value, location?.state ?? null, breadcrumbsMap, linkToName)}
        </Typography>) : (<Link key={to} to={to} className={"breadcrumb"} >
          {humanFriendly(value, location?.state ?? null, breadcrumbsMap, linkToName)}
        </Link>)
    });

  return (
    <>
      {
        breadcrumbs &&
        <div style={{ marginTop: 5 }}>
          <Breadcrumbs aria-label="breadcrumb" separator="›" >
            <IconButton
              color="default"
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{ padding: 2, borderRadius: 2 }}

              onClick={handleNavigateBack}
            >
              <ArrowBackIcon />
            </IconButton>
            {breadcrumbChildren}
          </Breadcrumbs>
        </div>
      }
    </>
  )
}

export const BreadcrumbList = () => {


  // console.log('BreadcrumbList');

  return (
    <Route>
      <BreadcrumbComp />
    </Route>
  )
}
