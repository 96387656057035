// import './user-statistics.css';
import { MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { userGroupStatsAverageEventsPerDistance, userGroupStatsAverageEventsPerHour, userGroupStatsAvgTripData, userGroupStatsMaxTripData, userGroupStatsMinTripData, userGroupStatsPredominantEvents, userGroupStatsTotalTrips } from 'services/statistics';
import { convertToUnitSystem, formatDistance, formatTime, getEventString, REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';
import { useDistanceUnit, useUnitSystem } from 'shared-hooks/useDistanceUnit';
import { useIsMounted } from 'shared-hooks/useIsMounted';

export const UserGroupStatsPredominantEvents = ({ userGroupId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null)
    const [total, setTotal] = useState(3);

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
        }
        const [cancelSource, userGroupStatsPredomEventsPromise] = userGroupStatsPredominantEvents(userGroupId, body)
        let isActive = true;
        userGroupStatsPredomEventsPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                const stats = [];
                response.result.forEach((value, _) => {
                    getEventString(value.type) && stats.push({ num_alerts: parseInt(value.num_alerts), type: getEventString(value.type) })
                })
                setStatData(stats)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [userGroupId, startDate, endDate, total])

	return (
		<>
            <h3>Predominant Events</h3>
            {
                statData
                ?   <ResponsiveContainer width="50%" height={300}>
                        <BarChart
                            width={500}
                            height={300}
                            data={statData}
                            // margin={{
                            //     top: 5,
                            //     right: 30,
                            //     left: 20,
                            //     bottom: 5,
                            // }}
                        >
                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                            <XAxis dataKey="type" angle={-45} height={150} interval={0} textAnchor="end" />
                            <YAxis interval={1} dataKey="num_alerts" />
                            <Tooltip />
                            <Legend align="left" verticalAlign="left" width={70} />
                            <Bar dataKey="num_alerts" name="Total Alerts" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                :   "Nothing, sorry"
            }
        </>
	);
}

export const UserGroupStatsAverageEventsPerHour = ({ userGroupId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null)
    const [total, setTotal] = useState(10);

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
            n: total
        }
        const [cancelSource, userGroupStatsAvgEventPromise] = userGroupStatsAverageEventsPerHour(userGroupId, body)
        let isActive = true;
        userGroupStatsAvgEventPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [userGroupId, startDate, endDate, total])

	return (
		<>
            <h3>Avg Events per Hour</h3>
            {
                statData
                ?   statData.average_alerts_per_hour.toFixed(2)
                :   "Nothing, sorry"
            }
        </>
	);
}

export const UserGroupStatsAverageEventsPerDistance = ({ userGroupId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null)
    const [distance, setDistance] = useState(100);
    const unit = useDistanceUnit();
    const unitSystem = useUnitSystem();

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
            distance: convertToUnitSystem(distance, unitSystem)
        }
        const [cancelSource, userGroupStatsAvgEventsPromise] = userGroupStatsAverageEventsPerDistance(userGroupId, body)
        let isActive = true;
        userGroupStatsAvgEventsPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err);
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [userGroupId, startDate, endDate, distance])

    const handleDistanceChanged = (event) => {
        setDistance(event.target.value);
    }

	return (
		<>
            <h3>Avg Events per &nbsp;
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={distance}
                onChange={handleDistanceChanged}
            >
                <MenuItem value={1}>{`1 ${unit}`}</MenuItem>
                <MenuItem value={10}>{`10 ${unit}`}</MenuItem>
                <MenuItem value={100}>{`100 ${unit}`}</MenuItem>
            </Select></h3>
            {
                statData
                ?   statData.average_alerts_per_distance.toFixed(2) + ` events per ${distance} ${unit}`
                :   "Nothing, sorry"
            }
        </>
	);
}

export const UserGroupStatsTotalTrips = ({ userGroupId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null)

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
        }
        const [cancelSource, userGroupStatsTotalTripsPromise] = userGroupStatsTotalTrips(userGroupId, body)
        let isActive = true;
        userGroupStatsTotalTripsPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [userGroupId, startDate, endDate])

	return (
		<>
            <h3>Total Trips</h3>
            {
                statData
                ?   statData.total_trips
                :   "Nothing, sorry"
            }
        </>
	);
}

export const UserGroupStatsAverageTripMetrics = ({ userGroupId , startDate, endDate}) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null);
    const unitSystem = useUnitSystem();

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
        }
        const [cancelSource, userGroupStatsAvgTripPromise] = userGroupStatsAvgTripData(userGroupId, body)
        let isActive = true;
        userGroupStatsAvgTripPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [userGroupId, startDate, endDate])

	return (
		<>
            <h3>Avg Trip Data</h3>
            {
                statData
                ?   <div>
                        <div>{`Avg Time: ${formatTime(statData.average_time)}`}</div>
                        <div>{`Avg Distance: ${formatDistance(statData.average_distance, unitSystem)}`}</div>
                    </div>
                :   "Nothing, sorry"
            }
        </>
	);
}

export const UserGroupStatsMinimumTripMetrics = ({ userGroupId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null);
    const unitSystem = useUnitSystem();

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
        }
        const [cancelSource, userGroupStatsMinTripPromise] = userGroupStatsMinTripData(userGroupId, body)
        let isActive = true;
        userGroupStatsMinTripPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [userGroupId, startDate, endDate])

	return (
		<>
            <h3>Minimum Trip Data</h3>
            {
                statData
                ?   <div>
                        <div>{`Minimum Time: ${formatTime(statData.minimum_time)}`}</div>
                        <div>{`Minimum Distance: ${formatDistance(statData.minimum_distance, unitSystem)}`}</div>
                    </div>
                :   "Nothing, sorry"
            }
        </>
	);
}

export const UserGroupStatsMaximumTripMetrics = ({ userGroupId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null);
    const unitSystem = useUnitSystem();

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
        }
        const [cancelSource, userGroupStatsMaxTripPromise] = userGroupStatsMaxTripData(userGroupId, body)
        let isActive = true;
        userGroupStatsMaxTripPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [userGroupId, startDate, endDate])

	return (
		<>
            <h3>Maximum Trip Data</h3>
            {
                statData
                ?   <div>
                        <div>{`Maximum Time: ${formatTime(statData.maximum_time)}`}</div>
                        <div>{`Maximum Distance: ${formatDistance(statData.maximum_distance, unitSystem)}`}</div>
                    </div>
                :   "Nothing, sorry"
            }
        </>
	);
}

export const UserGroupStatsAverageTrips = ({ userGroupId, startDate, endDate }) => {

    const isMounted = useIsMounted();
    const [statData, setStatData] = useState(null);
    const unitSystem = useUnitSystem();

    useEffect(() => {
        const body = {
            start_time: startDate,
            end_time: endDate,
        }
        const [cancelSource, userGroupStatsMaxTripPromise] = userGroupStatsMaxTripData(userGroupId, body)
        let isActive = true;
        userGroupStatsMaxTripPromise
            .then((response) => {
                if (!isMounted() || !isActive) return;
                if (!response.success) return;

                setStatData(response.result)
            })
            .catch((err) => {
                if (!isMounted() || !isActive) return;
                console.log(err)
            });
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
        }
    }, [userGroupId, startDate, endDate])

	return (
		<>
            <h3>Maximum Trip Data</h3>
            {
                statData
                ?   <div>
                        <div>{`Maximum Time: ${formatTime(statData.maximum_time)}`}</div>
                        <div>{`Maximum Distance: ${formatDistance(statData.maximum_distance, unitSystem)}`}</div>
                    </div>
                :   "Nothing, sorry"
            }
        </>
	);
}
