import React, { useState, useEffect } from 'react';

import _ from 'lodash';

import clsx from 'clsx';

import cbor from 'cbor'

import MyLocationIcon from '@material-ui/icons/MyLocation';
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import CameraRearIcon from '@material-ui/icons/CameraRear';

import Typography from '@material-ui/core/Typography';

import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CloseIcon from '@material-ui/icons/Close';
import CommuteIcon from '@material-ui/icons/Commute';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import { useDeepCompareEffect } from 'react-use';

import { Badge, Tooltip, Toolbar, makeStyles, createStyles, Button } from '@material-ui/core';

import Tab from '@material-ui/core/Tab';

import { useGoogleMap } from '@react-google-maps/api';

import { useQueryClient } from 'react-query';

import { useSubscription } from 'modules/mqttHooks';

import { DeviceLiveTripTab } from 'components/tabs/live-map-tabs/driver-tab/driver-tab';

import { EventInfoView, SelectedDeviceTripInfoView } from 'components/info-view/info-view';

import { EventTab } from 'components/tabs/live-map-tabs/event-tab/event-tab';

import {ImageDialog} from 'components/dialogs/dialogs';

import { toDisplayTimeShort } from 'shared-functions/shared-functions';


import { useResolveEventMedia, useSelectedDeviceSerial, useTokenIdentifier, useFocusDeviceTrip, useSelectedEventIndex, clearSelectedEventData, useSelectedEventFromList, useLiveDeviceOffline, clearSelectedTrip, useLiveDeviceVehicleRegNum } from 'modules/useGlobalStore';

const maxRightDrawerWidth = 420;
const maxLeftDrawerWidth = 300;

const idxVehicles = '0';
const idxTrip = '1';
const idxEvents = '2';
const idxAlert = '3';
const idxClose = '4';

const styles = makeStyles((theme) => createStyles({
  // https://v4.mui.com/api/data-grid/data-grid/#css
  dgRoot: {
    border: "0px solid red",
    border: 0,
    borderRadius: 0,
    // padding: 0,
    // margin: 0,
  },
  dgRow: {
    border: "0px solid red",
    border: 0,
    borderRadius: 0,
  },
  dgCell: {
    border: "0px solid red",
    border: 0,
    borderRadius: 0,
  },
  tabIcon: {
    minWidth: 60,
    width: 80,
    maxWidth: 80,
    // maxHeight: 20,
    // height: 20,
    // height: 40,
    // maxHeight: 40,
    // minHeight: 40,
  },
	tabHide: {
		// display: 'none',
		// https://stackoverflow.com/questions/45923371/keep-same-width-even-if-icon-is-hidden
		visibility: 'hidden',
	},
  tabLabelIcon: {
    padding: 0,
    margin: 0,
    // height: 20,
    // width: 20,
  },
	crumbLink: {
    display: 'flex',
  },
	crumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  tabFullWidth: {
    padding: 0,
    margin: 0,
    // height: 20,
    // maxHeight: 20,
    // minHeight: 40,
    // width: 20,
    // maxWidth: 20,
    minWidth: 60,
    width: 60,
  },
  tabRoot: {
    padding: 0,
    margin: 0,
    // height: 20,
    // width: 20,
  },
  tabPanel: {
    height: '80%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  tabPanelDense: {
    height: '80%',
    padding: 0,
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'center',
    // width: '100%',
  },
  rightDrawerPaper: {
    width: '100%',
    // width: '80%',
    // height: '100%',
    maxWidth: maxRightDrawerWidth,
  },
  leftDrawerPaper: {
    width: '100%',
    // width: '80%',
    // height: '100%',
    // maxWidth: 200,
    maxWidth: maxLeftDrawerWidth,
    // padding: 0,
    // margin: 0,
  },
  drawerContainer: {
    overflow: 'auto', // this shows scrollbars when the content doesn't fit in the provided space
    // height: '80vh',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: 0,
    margin: 0,
  }
}));

const PanMapControl = ({ onClick }) => (
  <Tooltip title='Pan to latest location'>
    <Button onClick={onClick} variant="text" startIcon={<MyLocationIcon />}>Pan</Button>
  </Tooltip>
);

const CapSnapshotControl = ({ onClick }) => {
  const isDeviceOffline = useLiveDeviceOffline();
  // console.warn('CapSnapshotControl: todo (test this)', { isDeviceOffline })
  // todo: consider hiding this instead
  return isDeviceOffline ? null : (
    <Tooltip title='Request Cab snapshot'>
      <Button disabled={isDeviceOffline} onClick={onClick} variant="text" startIcon={<CameraFrontIcon />}>Cab</Button>
    </Tooltip>
  );
}

const RoadSnapshotControl = ({ onClick }) => {
  const isDeviceOffline = useLiveDeviceOffline();
  // console.warn('RoadSnapshotControl: todo (test this)', { isDeviceOffline })
  return isDeviceOffline ? null : (
    <Tooltip title='Request Road snapshot'>
      <Button disabled={isDeviceOffline} onClick={onClick} variant="text" startIcon={<CameraRearIcon />}>Road</Button>
    </Tooltip>
  );
}

const DeviceToolbar = ({ classes, handlePanToCurrentPosition, handleCabSnapshotRequest, handleRoadSnapshotRequest }) => {
  return (
    <Toolbar className={classes.toolbar} disableGutters variant="dense">
      <PanMapControl onClick={handlePanToCurrentPosition} />
      <CapSnapshotControl onClick={handleCabSnapshotRequest} />
      <RoadSnapshotControl onClick={handleRoadSnapshotRequest} />
    </Toolbar>
  )
}

const Snapshot = ({ deviceSerialNumber, identifier }) => {

  const { message } = useSubscription(`cmd/drivevue/devices/${deviceSerialNumber}/live/snapshot/res`)
  const [snapshot, setSnapshot] = useState(null);

	const [showDialog, setShowDialog] = useState(false);
  
	const toggleDialog = React.useCallback(() => {
		setShowDialog(!showDialog);
	}, [setShowDialog, showDialog]);

	const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    if (message) {
      const decoded = cbor.decodeAllSync(message.message)[0]
      const event = JSON.parse(decoded[0].toString());
      const binaryPayload = decoded[1]

      setSubtitle(`${event?.message_detail?.snapshot_details?.camera?.toLowerCase()} • ${toDisplayTimeShort(event?.message_timestamp)}`)

      console.log('Snapshot received', event);
      if (event.device_serial_number !== deviceSerialNumber) {
        console.log('Device Serial mismatch. Aborting');
        return
      }
      if (event.message_category !== 'live' && event.message_detail.type !== 'topic') {
        console.log('Incorrect message. Aborting.');
        return
      }
      if (event.message_detail.snapshot_details.uuid !== identifier) {
        console.log('Incorrect message found. Ignoring.');
        return
      }
      if (binaryPayload) {
        //I dont think this is the best way to do this, but it's the only way I could get it working
        var binaryData = [];
        binaryData.push(binaryPayload);
        const objectUrl =  URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
        setSnapshot(objectUrl);
      }
    }
  }, [message]);

  return (
    <>
      {
        snapshot
          ? 
            <>
              <img src={snapshot} style={{ width: '100%' }} onClick={toggleDialog} />
  					  <ImageDialog loading={false} onClose={toggleDialog} open={showDialog} src={snapshot}>
        			<Typography align="center" variant="body2" style={{ textTransform: 'capitalize' }}>{subtitle}</Typography>
              </ImageDialog>
            </>
          : null
      }
    </>
  )
}

// todo: deprecated selectedDeviceSerial in favour of deviceSerial
export default ({ selectedDeviceSerial, handleSideListToggle, handlePanToCurrentPosition, handleCabSnapshotRequest, handleRoadSnapshotRequest, eventList, isLive }) => {

  // todo: if a device serial is selected, then first state should be 1
  const [tabIndex, setTabIndex] = React.useState(idxVehicles);

  const queryClient = useQueryClient();

  const identifier = useTokenIdentifier();

  const classes = styles();

  // todo: should deviceSerial hook or selectedDeviceSerial prop be preferred? 
  const deviceSerial = useSelectedDeviceSerial();
  // const deviceSerial = selectedDeviceSerial;

  const disabledTrip = React.useMemo(() => _.isNil(deviceSerial), [deviceSerial]);

  const totalEvents = React.useMemo(() => eventList?.length ?? 0, [eventList]);

  const disabledEvents = React.useMemo(() => _.isEqual(totalEvents, 0), [totalEvents]);

  const selectedEventIndex = useSelectedEventIndex();
  const selectedEventDefault = useSelectedEventFromList(eventList, selectedEventIndex);
  const { isFetching, event: selectedEvent } = useResolveEventMedia(selectedEventDefault);
  // console.log('selectedEventIndex', selectedEventIndex);

  const disabledAlert = React.useMemo(() => disabledTrip || _.isNil(selectedEvent), [selectedEvent, disabledTrip]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    if (newValue === idxClose) {
      // Close
      setTabIndex(tabIndex);
      handleSideListToggle();
    } else if (newValue === idxVehicles) {
      // Vehicles
      clearSelectedTrip(queryClient);
    } else if (newValue === idxTrip) {
      clearSelectedEventData();
    } else if (newValue === idxEvents) {
      clearSelectedEventData();
    }
  };

  const focusTrip = useFocusDeviceTrip();

  // This effect updates the tab index based on incoming changes.
  // If we have a new valid deviceSerial, then open idxTrip.
  // If we have a new invalid deviceSerial, then open idxVehicles.
  // If we have a new valid selectedEvent, then open idxAlert.
  // If we have a new invalid selectedEvent, then open idxEvents.
  React.useEffect(() => {
    if (_.isNil(deviceSerial)) {
      setTabIndex(idxVehicles);
    } else if (_.isNil(selectedEvent)) {
      if (_.isEqual(tabIndex, idxVehicles)) {
        setTabIndex(idxTrip);
      } else if (_.isEqual(tabIndex, idxTrip)) {
        setTabIndex(idxTrip);
      } else {
        setTabIndex(idxEvents);
      }
    } else {
      setTabIndex(idxAlert);
    }
  }, [selectedEvent, deviceSerial]);

  React.useEffect(() => {
    if (_.isEqual(tabIndex, idxTrip)) {
      handlePanToCurrentPosition();
    }
  }, [tabIndex])

  const notificationsIcon = React.useMemo(() => (
    <Badge color="primary" badgeContent={totalEvents}>
      <NotificationsActiveIcon fontSize="small" />
    </Badge>
  ), [totalEvents])

  // todo: don't auto pan
  // https://trello.com/c/F1vemZF5
  const panPos = React.useMemo(() => {
    if (!_.isNil(selectedEvent) && !_.isNil(selectedEvent?.markerPos) && focusTrip) {
      return selectedEvent.markerPos
    }
    // to overcome useDeepCompareEffect warning
    return {}
  }, [selectedEvent, focusTrip])

  const map = useGoogleMap();

  // useCustomCompareEffect should not be used with dependencies that are all primitive values
  // useDeepCompareEffect` should not be used with dependencies that are all primitive values. Use React.useEffect instead.
  // Following the above recommendation, but somehow React.useEffect didn't work.
  // However, useDeepCompareEffect spams the log causing a slow down.
  // https://github.com/streamich/react-use/issues/1606
  // https://github.com/streamich/react-use/issues/2227
  useDeepCompareEffect(() => {
  // useDeepCompareEffectNoCheck(() => {
  // React.useEffect(() => {
    if (!_.isEmpty(panPos)) {
      // console.warn('useEffect', {panPos});
      map.panTo(panPos);
    }
  }, [panPos]);

  const vehRegNumLive = useLiveDeviceVehicleRegNum();
  const vehRegNum = vehRegNumLive ?? 'Vehicle';

	const tabIconVehicles = clsx(classes.tabIcon, {
		// [classes.tabHide]: _.isNil(vehRegNumLive),
	});

	const tabIconVehicle = clsx(classes.tabIcon, {
		[classes.tabHide]: _.isNil(vehRegNumLive),
	});

	const tabIconEvents = clsx(classes.tabIcon, {
		// [classes.tabHide]: _.isNil(vehRegNumLive) || disabledTrip,
		[classes.tabHide]: disabledEvents,
	});

	const tabIconAlert = clsx(classes.tabIcon, {
		// [classes.tabHide]: _.isNil(vehRegNumLive) || disabledAlert,
		[classes.tabHide]: disabledAlert,
	});

	const variant = "fullWidth";

  return (
    <TabContext value={tabIndex}>
      <TabList onChange={handleChange} variant={variant}>
        <Tab className={tabIconVehicles} icon={<CommuteIcon fontSize="small" />} label="Vehicles" value={idxVehicles} wrapped={false} />
        <Tab className={tabIconVehicle} icon={<DriveEtaIcon fontSize="small" />} label={vehRegNum} value={idxTrip} disabled={disabledTrip} wrapped={false} />
        <Tab className={tabIconEvents} icon={notificationsIcon} label="Events" value={idxEvents} disabled={disabledEvents} wrapped={false} />
        <Tab className={tabIconAlert} icon={<ImageSearchIcon fontSize="small" />} label="Alert" value={idxAlert} disabled={disabledAlert} wrapped={false} />
        <Tab className={classes.tabIcon} icon={<CloseIcon fontSize="small" />} label="Close" value={idxClose} wrapped={false} />
      </TabList>
      <TabPanel className={classes.tabPanel} value={idxVehicles}>
        <DeviceLiveTripTab />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={idxTrip}>
        <DeviceToolbar classes={classes} handlePanToCurrentPosition={handlePanToCurrentPosition} handleCabSnapshotRequest={handleCabSnapshotRequest} handleRoadSnapshotRequest={handleRoadSnapshotRequest} />
        <SelectedDeviceTripInfoView isLive={true} />
        <Snapshot identifier={identifier} deviceSerialNumber={selectedDeviceSerial} />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={idxEvents}>
        <EventTab eventList={eventList} isLive={isLive} />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={idxAlert}>
        <EventInfoView loading={isFetching} event={selectedEvent} eventList={eventList} eventIndex={selectedEventIndex} />
      </TabPanel>
    </TabContext>
  );
}
