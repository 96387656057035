import "./admin-devices-page.css";
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AdminDevices from "./admin-devices/admin-devices";

export const AdminDevicesPage = () => {

	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}`}>
				<AdminDevices />
			</Route>
		</Switch>
	);
}
