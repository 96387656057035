import './client-side-table.css';
import React, { useState, useEffect } from 'react';

import _ from 'lodash';

import { DataGrid } from '@material-ui/data-grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { formatDistance, formatDuration, toDisplayTimeLong, toDisplayTimeRel, toDisplayTimeRelShort } from 'shared-functions/shared-functions';
import { useUpdateBookmark } from 'modules/useTokenStore';
import { useUnitSystem } from 'shared-hooks/useDistanceUnit';

// https://v4.mui.com/api/data-grid/data-grid/
export const ClientSideTable = ({ bookmarkId, sortModel, getRowClassName, classes, className, columns, rows, loading, total, searchCriteriaSkeleton, defaultTableCriteria, setTableCriteria, rowClick, criteriaChanged }) => {

	const _onRowClick = (param) => {
    // console.log('ClientSideTable._onRowClick', param)
		rowClick({ data: param.row });
	}

	const [outSortModel, setSortModel] = useState(sortModel);

	const _onSortModelChange = (newSortModel) => {
    // console.warn('ClientSideTable: _onSortModelChange', {
    //   newSortModel,
    //   outSortModel
    // })
    setSortModel(newSortModel);
	}

	useUpdateBookmark(bookmarkId, outSortModel);

	return (
		<div style={ { height: '100%', width: '100%' } } >
			<div style={ { display: 'flex', height: '100%', width: '100%' } } >
				<div style={{ flexGrow: 1 }} >
					<DataGrid 
            getRowClassName={getRowClassName}
            classes={classes}
            sortModel={outSortModel}
            className={className}
						rows={rows}
						columns={columns}
						density={"compact"}
						paginationMode={"client"}
						sortingMode={"client"}
						filteringMode={"client"}
						rowsPerPageOptions={ [1, 5, 10, 100] }
						disableColumnMenu={true}

						onRowClick={_onRowClick}
						// onPageChange={ handlePageChange }
						// onPageSizeChange={ handlePageSizeChange } 
						// onFilterModelChange={ handleFilterModelChange } 
						onSortModelChange={_onSortModelChange}
					/>
				</div>
			</div>
		</div>
	);
}

const defaultValueGetter = (p) => p.value

export const SimpleClientSideTable = ({ columns, rows, loading, rowClick }) => {

  const unitSystem = useUnitSystem();

  const rowsExt = React.useMemo(() => rows?.map(r => {

    // https://v4.mui.com/components/data-grid/columns/#column-types
    // We mimick some of the data types supported by material-ui data-grid.
    const colsExt = columns?.map((c, index) => {
      const field = c.field;
      const key = `${field}-${r.id}`;
      const type = c?.type ?? 'string';
      const valueGetter = c?.valueGetter ?? defaultValueGetter;
      const data = valueGetter({
        value: r[field]
      });

      let align = 'inherit';
      let dataFormatted = data;
      if (type === 'dateTime') {
        dataFormatted = toDisplayTimeLong(data);
      } else if (type === 'dateTimeRel') {
        dataFormatted = toDisplayTimeRelShort(data);
      } else if (type === 'distance') {
        dataFormatted = formatDistance(data, unitSystem);
      } else if (type === 'duration') {
        dataFormatted = formatDuration(data);
      } else if (type === 'number') {
        dataFormatted = data;
        align = 'right';
      }

      // https://v4.mui.com/api/table-cell/#props
      return {
        key,
        data: dataFormatted,
        align: align,
      }
    }) ?? [];

    return {
      ...r,
      innerCols: colsExt,
    }
  }) ?? [], [rows, columns, unitSystem]);

  return (
    <TableContainer component={Paper}>
      <Table className={""} style={{ minWidth: 450 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {
              columns.map((column, index) => {
                return (
                  <TableCell key={column.field}>
                    <strong>{column.headerName}</strong>
                  </TableCell>
                )
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rowsExt?.map((row) => (
              <TableRow key={row.id} onClick={() => { rowClick(row) }}>
                {
                  row.innerCols.map((column) => {
                    return (
                      <TableCell key={column.key} align={column.align} component="th" scope="row">
                        {column.data}
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
