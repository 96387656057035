import './filter.css';
import React from 'react';

import { TextField } from '@material-ui/core';
import { useDebounce } from 'shared-hooks/useDebounce';
import { usePrevious } from 'shared-hooks/usePrevious';

export const Filter = ({ searchCriteria, defaultSearchCriteria, setSearchCriteria }) => {
	// const [criteria, setCriteria] = React.useReducer((state, newState) => ({ ...state, ...newState }), defaultSearchCriteria);
	const [criteria, setCriteria] = React.useState(defaultSearchCriteria ?? {});

	// prevCriteria must not be a dependency of useEffect/useDebounce.
	const prevCriteria = usePrevious({ criteria });

	// notifyCriteriaChanged will only call the parent if the criteria has changed.
	const notifyCriteriaChanged = React.useCallback(() => {
		if (JSON.stringify(prevCriteria?.criteria??{}) !== JSON.stringify(criteria)) {
			setSearchCriteria(criteria)
		}
	}, [setSearchCriteria, criteria]);

	// call notifyCriteriaChanged after one second
	useDebounce(notifyCriteriaChanged, 1000);

	// Set the local criteria cache, that will be used for parent notification.
	const handleFilterTextInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setCriteria(prevState => ({ 
			...prevState, 
			[name]: value 
		}));
	}

	return (
		<div className={"todo-please-replace-me"}>
			{/* filter component here */}
			{
				searchCriteria
				&& searchCriteria.map((obj, _) => {
					return (
						<TextField
							id={ obj.field }
							name={ obj.field }
							label={ obj.header }
							key={ obj.field }
							value={ criteria[obj.field] }
							onChange={ handleFilterTextInput }
						/>
					)
				})
			}
		</div>
	);
}
