// import './vehicle-group-edit.css';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { EditView } from 'components/edit-view/edit-view';

import { vehicleGroup, update } from 'services/vehicle-group';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';
import { useIsMounted } from 'shared-hooks/useIsMounted';

export const VehicleGroupEdit = () => {

	const isMounted = useIsMounted();
	const updateVehicleGroupCancelSourceRef = useRef(null);
	const { vehicleGroupId } = useParams();
	const [vehicleGroupData, setVehicleGroupData] = useState();
	const history = useHistory();
	const { url } = useRouteMatch();

	useEffect(() => {
		if (!vehicleGroupId) return;
		const [cancelSource, vehicleGroupGetPromise] = vehicleGroup(vehicleGroupId)
		let isActive = true;
		vehicleGroupGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;
				setVehicleGroupData(response.result);
				history.replace(url, { [vehicleGroupId]: `${response.result.name}`})
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err);
			});
		return () => {
			isActive = false;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [vehicleGroupId]);

	const handleEditSave = (data) => {
		if (updateVehicleGroupCancelSourceRef.current) return;
		const [cancelSource, updateVehicleGroupPromise] = update({ ...data, id: vehicleGroupId })
		updateVehicleGroupCancelSourceRef.current = cancelSource;
		updateVehicleGroupPromise
			.then((response) => {
				if (!isMounted()) return;
				updateVehicleGroupCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((err) => {
				if (!isMounted()) return;
				updateVehicleGroupCancelSourceRef.current = null;
				console.log(err)
			});
	}

	const handleEditCancel = () => {
		history.goBack();
	}

	const editSkeleton = [
		// { key: 0, property: "profile_image" },
		{ key: 1, property: "name", header: "Name", required: true },
	];

	return (<>
		{
			vehicleGroupData && <EditView
				skeleton={editSkeleton}
				data={vehicleGroupData}
				confirmTitle='Edit Vehicle Group?'
				confirmDescription={`You are about to alter the information of ${vehicleGroupData.name}.`}
				
				onSave={ handleEditSave }
				onCancel={ handleEditCancel } />
		}
	</>);
}
