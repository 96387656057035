import "./devices.css";
import React  from 'react';
import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';

import { DeviceTable } from 'components/data-tables/device-data-tables';

import { Device } from 'client-pages/devices-page/device/device';
import { CrudDrawer } from "components/drawers/crud-drawer";

const DeviceList = () => {

	const { path, url } = useRouteMatch();
	const history = useHistory();

	const handleRowClick = (data) => {
		// console.warn('DeviceList', { path, url, data })
		history.push(`${path}/${data.data.serial_number}`);
	}

	const defaultSearchCriteria = {
		serial_number: '',
		currently_online: '',
	}

	const searchCriteria = {
		serial_number: '',
		currently_online: '',
	}

	return (
		<div className={"device-table-container"}>
			<h1>Devices</h1>
			<DeviceTable
				searchCriteria={searchCriteria}
				defaultSearchCriteria={defaultSearchCriteria}

				onRowClick={ handleRowClick } />
		</div>
	);
}

// also see admin-devices.js
export default () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={path}>
				<DeviceList />
				<Route path={`${path}/:deviceSerial`} exact >
					<CrudDrawer>
						<Device />
					</CrudDrawer>
				</Route>
			</Route>
		</Switch>
	)
}
