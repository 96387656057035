import './vehicle-alerts.css';
import React  from 'react';

import { useParams } from 'react-router-dom';
import { EventTable } from 'components/data-tables/trip-event-data-tables';

export const VehicleAlerts = () => {

	const { vehicleId } = useParams();

	const handleRowClick = (data) => {

	}

	const defaultCriteria = {
		orderBy: 'timestamp',
		flipOrder: true,
	}

	const defaultSearchCriteria = {
		alert_types: '',
	}

	const searchCriteria = {
		alert_types: '',
	}

	const fixedSearchCriteria = {
		vehicle_id: vehicleId
	}

	const allowedColumns = [
		"timestamp",
		"alert_type",
		"driver_name",
		"driver_surname",
		"driver_email",
	]

	return (
		<EventTable
			defaultCriteria={defaultCriteria}
			defaultSearchCriteria={defaultSearchCriteria}
			searchCriteria={searchCriteria}
			fixedSearchCriteria={fixedSearchCriteria}
			allowedColumns={allowedColumns}

			rowClick={ handleRowClick } />
	)
}
