import './user-tabs.css';
import React, { useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';

import { CustomTabPanel } from '../tab-panel';
import { AdminLessonsTable } from 'components/data-tables/courses-tables';
import { useParams } from 'react-router-dom';


function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '100%',
		// backgroundColor: theme.palette.background.paper,
	},
}));

export const CourseTabs = () => {

	const { courseId } = useParams();
	const [value, setValue] = useState(0);
	const classes = useStyles();

	const handleChange = (event, newValue) => {
		value !== newValue && setValue(newValue);
	};

	return (
		<div className={classes.root}>
			{/* <AppBar position="static"> */}
				<Tabs value={value} onChange={ handleChange } variant="scrollable" scrollButtons="on" aria-label="simple tabs">
					<Tab label="Lessons" {...a11yProps(0)} />
				</Tabs>
			{/* </AppBar> */}
			<CustomTabPanel value={value} index={0}>
				<CourseLessonsTab courseId={courseId} />
			</CustomTabPanel>
		</div>
	);
}

const CourseLessonsTab = ({ courseId }) => {

	const handleRowClick = (data) => {

	}

	const fixedCriteria = {
		id: courseId
	}

	return (
		<AdminLessonsTable
			fixedCriteria={fixedCriteria}	
			// defaultSearchCriteria={defaultSearchCriteria}
			// searchCriteria={searchCriteria}
			// fixedSearchCriteria={fixedSearchCriteria}
			// allowedColumns={allowedColumns}

			onRowClick={ handleRowClick } />
	)
}
