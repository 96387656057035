import "./vehicles.css";
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from "@material-ui/core";

import { VehicleTable } from "components/data-tables/vehicle-data-tables";

export const Vehicles = () => {

	const { path } = useRouteMatch();
	const history = useHistory();

	const handleRowClick = (data) => {
		history.push(`${path}/${data.data.id}`, { [data.data.id]: `${data.data.name}`});
	}

	const handleCreate = () => {
		history.push(`${path}/create`);
	}

	const defaultSearchCriteria = {
		name: '',
		type: '',
		make: '',
		model: '',
		registration_number: '',
		// company_name: '',
	}

	const searchCriteria = {
		name: '',
		type: '',
		make: '',
		model: '',
		registration_number: '',
		// company_name: '',
	}

	return (
		<div className={"user-table-container"}>
			<h1>Vehicles</h1>
			<Button variant="contained" color="primary" onClick={ handleCreate }>Create</Button>
			<VehicleTable
				searchCriteria={searchCriteria}
				defaultSearchCriteria={defaultSearchCriteria}

				onRowClick={ handleRowClick } />
		</div>
	);
}
