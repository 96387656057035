import './tab-panel.css';
import React from 'react';

export const CustomTabPanel = (props) => {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		id={`simple-tabpanel-${index}`}
		hidden={index !== value}
		aria-labelledby={`simple-tab-${index}`}
		className={"tab-panel"}
		{...other}
	  >
		{index === value && children}
	  </div>
	);
}