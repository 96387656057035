import React, { useEffect } from 'react';
import _ from 'lodash';
import create from "zustand";
import { combine, persist } from "zustand/middleware";

// https://immerjs.github.io/immer/update-patterns/
import produce from 'immer';

const tokenKey = "token";
const userKey = "user";
const preferencesKey = "preferences";

const getDefaultTokenValues = () => {
	try {
		return {
			token: localStorage.getItem(tokenKey) || null,
		}
	} catch {}

	return {
		token: "",
	};
};

export const useTokenStore = create(
	combine(getDefaultTokenValues(), (set) => ({
		setTokens: (x) => {
			try {
				localStorage.setItem(tokenKey, x);
			} catch {}

			set(!!x
				? { token: x }
				: {}
			);
		},
	}))
);

export const useUserStore = create(persist(
  () => ({
		id: "",
		email: "",
		role: "",
		name: "",
		surname: "",
		activation_status: 'disabled',
		company_id: "",
		company_name: "",
		db_role: 'dvdb_guest',
		company_activation_status: 'disabled',
		role_level: 100,
		features: [],
		// groups: [],
		// preferences: [],
	}), {
		name: userKey,
		getStorage: () => localStorage,
	}));

export const setUser = (user) =>
  useUserStore.setState(produce(state => {
		console.warn('setUser', {user})
    state.id = user?.id ?? '';
    state.email = user?.email ?? '';
    state.role = user?.role ?? '';
    state.name = user?.name ?? '';
    state.surname = user?.surname ?? '';
    state.activation_status = user?.activation_status ?? 'disabled';
    state.company_id = user?.company_id ?? '';
    state.company_name = user?.company_name ?? '';
    state.db_role = user?.db_role ?? 'dvdb_guest';
    state.company_activation_status = user?.company_activation_status ?? 'disabled';
    state.role_level = user?.role_level ?? 100;
    state.features = user?.features ?? [];
  }));

const unitSystemList = ['imperial_us', 'metric'];

export const useUnitSystemList = () => unitSystemList;

// https://github.com/pmndrs/zustand#persist-middleware
// https://github.com/pmndrs/zustand/wiki/Persisting-the-store's-data
export const usePreferenceStore = create(persist(
  () => ({
		preferences: {
			unit_system: 'imperial_us',
			date_format: 'MM/dd/yyyy',
			event_icon_size: 40,
			driver_icon_size: 1.5,
			path_size: 2,
			stroke_size: 1,
		},
		bookmarks: {}
	}), {
		name: preferencesKey,
		getStorage: () => localStorage,
	}));

export const setPreferences = (preferences) =>
  usePreferenceStore.setState(produce(state => {
    state.preferences = {
			...state.preferences,
			...preferences,
		};
  }));

export const setUnitSystem = (unitSystem) =>
  usePreferenceStore.setState(produce(state => {
    state.preferences.unit_system = unitSystem
  }));

export const setDateFormat = (dateFormat) =>
  usePreferenceStore.setState(produce(state => {
    state.preferences.date_format = dateFormat
  }));

export const setEventIconSize = (eventIconSize) =>
  usePreferenceStore.setState(produce(state => {
    state.preferences.event_icon_size = eventIconSize
  }));

export const setDriverIconSize = (driverIconSize) =>
  usePreferenceStore.setState(produce(state => {
    state.preferences.driver_icon_size = driverIconSize
  }));

export const setPathSize = (pathSize) =>
  usePreferenceStore.setState(produce(state => {
    state.preferences.path_size = pathSize
  }));

export const setStrokeSize = (strokeSize) =>
  usePreferenceStore.setState(produce(state => {
    state.preferences.stroke_size = strokeSize
  }));

export const setBookmark = (bookmarkId, bookmark) =>
  usePreferenceStore.setState(produce(state => {
		if (!_.isNil(bookmarkId)) {
			// console.warn('setBookmark', { bookmarkId, bookmark })
			_.set(state.bookmarks, bookmarkId, bookmark)
		}
  }));

export const useBookmark = (bookmarkId, withDefault) => 
	usePreferenceStore(store => _.get(store.bookmarks, bookmarkId, withDefault));

export const useUpdateBookmark = (bookmarkId, bookmark) => 
	useEffect(() => {
		setBookmark(bookmarkId, bookmark)
	}, [bookmarkId, bookmark])

// export const useUnitSystem = () => usePreferenceStore(store => store.preferences.unit_system ?? 'imperial_us');
export const useDateFormat = () => usePreferenceStore(store => store.preferences.date_format ?? 'MM/dd/yyyy');
export const useDriverIconSize = () => usePreferenceStore(store => store.preferences.driver_icon_size ?? 1.5);
export const usePathSize = () => usePreferenceStore(store => store.preferences.path_size ?? 2);
export const useStrokeSize = () => usePreferenceStore(store => store.preferences.stroke_size ?? 1);

export const useUserEmail = () => useUserStore(state => state.email);
export const useUserRole = () => useUserStore(state => state.role);
export const useUserId = () => useUserStore(state => state.id);
export const useUserName = () => useUserStore(state => state.name);
export const useUserSurname = () => useUserStore(state => state.surname);
export const useUserInfo = () => useUserStore(state => ({ id: state.id, name: state.name, surname: state.surname }));

export const useUserRoleFeatures = () => useUserStore(state => state.features);

export const useIsCurrentUser5DTAdmin = () => {
	const userRole = useUserRole();
	return _.isEqual(userRole, "5DTAdmin")
}

export const useIsCurrentUserClientAdmin = () => {
	const userRole = useUserRole();
	return _.isEqual(userRole, "ClientAdmin")
}

export const useIsCurrentUserDriver = () => {
	const userRole = useUserRole();
	return _.isEqual(userRole, "Driver")
}

export const useCurrentUserCanViewCourses = () => {
	const userRoleFeatures = useUserRoleFeatures();
	return userRoleFeatures.includes('role_can_view_own_courses');
}

export const useUserRoot = () => {
	const userRole = useUserRole();
	return React.useMemo(() => {
		if (_.isEqual(userRole, "5DTAdmin")) {
			return "/admin"
		}
		if (_.isEqual(userRole, "Driver")) {
			return "/driver"
		}
		return "/dashboard"
	}, [userRole]);
}
