import useLocalStore from 'modules/localStore';

// https://immerjs.github.io/immer/update-patterns/
import produce from 'immer';

export const useLocalVersion = () =>
  useLocalStore(state => state.localVersion);

export const setLocalVersion = (localVersion) =>
  useLocalStore.setState(produce(state => {
    state.localVersion = localVersion
  }));
