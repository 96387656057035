import { axiosPostRequest } from './api-request';

function alertsAll(data) {
	try {
		// const source = axios.CancelToken.source()
		// return [
		// 	source,
		// 	MyAxiosInstance.post(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/trip-alert`, data, { cancelToken: source.token }).then(response => response.data)
		// ]
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/trip-alert`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Alerts fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function alertGet(data) {
	try {
		// const source = axios.CancelToken.source()
		// return [
		// 	source,
		// 	MyAxiosInstance.post(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/trip-alert/get`, data, { cancelToken: source.token }).then(response => response.data)
		// ]
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/trip-alert/get`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Alert fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export {
	alertsAll,
	alertGet
};
