// import './device.css';
// import React from 'react';
// import { useParams } from 'react-router-dom';

// import { DeviceInfoView } from 'components/info-view/info-view';
// import { SingleViewContainer } from 'components/view-container/view-container';
// import { useFetchDevice } from 'services/api';

// // also see admin-device.js
// export const Device = () => {

// 	const { deviceSerial } = useParams();
// 	const { data } = useFetchDevice(deviceSerial);

// 	return (
// 		<>
// 			<SingleViewContainer>
// 					<DeviceInfoView data={data} />
// 			</SingleViewContainer>
// 		</>
// 	);
// }

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import _ from 'lodash';

import { DrawerActions, DrawerAlert, DrawerButton, DrawerButtonConfirm, DrawerConfirmMessage, DrawerDetails, DrawerHelp, DrawerTextField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useFetchDevice, useUpdateDevice } from 'services/api';
import { toDisplayTimeLong } from 'shared-functions/shared-functions';
import { vehicleOptionFromDeviceData, VehicleSelect } from 'components/autocomplete/vehicle-select';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// todo: when assigning a device to a new company, the previous trips made using the device should still be linked to the previous company.
const _helpText = `Use this form to update an existing device registration.`;

const SaveMessage = () => {
	return (
		<DrawerConfirmMessage 
			// alert="If you are transferring the device to another company, then this will unlink the vehicle and driver currently linked to the device." 
			message="Please confirm that you want to update the device registration with the requested changes." 
			severity="warning" 
		/>
	)
}

export const Device = () => {

	const { deviceSerial } = useParams();

	const { data: deviceData, isFetching } = useFetchDevice(deviceSerial);

	// console.warn('Device: todo', { deviceSerial, deviceData })

	const classes = useClassesFormStyle();

	const history = useHistory();

  const [formData, setFormData] = React.useState({
		deviceId: '',
		serialNumber: '',
		lastOnlineTime: '',
		onlineStatus: '',
		tripCount: '',
		linkedDriver: '',
		linkedVehicle: '',
		activationStatus: '',
		vehicleOption: null,
		alertSeverity: 'info',
		// alertMessage: _helpText,
		alertMessage: '',
		loading: false,
	});

	const loading = React.useMemo(() => formData.loading || isFetching, [isFetching, formData.loading])

	React.useEffect(() => {
		const vehicleOption = vehicleOptionFromDeviceData(deviceData);

		const activationStatus = _.startCase(deviceData?.activation_status ?? 'enabled');
		const linkedDriver = (deviceData?.driver_linked ?? false) ? deviceData?.driver_id : 'None';
		const linkedVehicle = (deviceData?.vehicle_linked ?? false) ? deviceData?.vehicle_id : 'None';
		setFormData(state => ({
				...state, 
				serialNumber: deviceData?.serial_number ?? '',
				deviceId: deviceData?.id ?? '',
				lastOnlineTime: toDisplayTimeLong(deviceData?.last_online_time) ?? '',
				onlineStatus: _.startCase(_.toLower(deviceData?.status ?? '')),
				tripCount: deviceData?.trip_count ?? 0,
				linkedDriver,
				linkedVehicle,
				activationStatus,
				vehicleOption,
			}))
	}, [deviceData])

	const isSubmitDisabled = React.useMemo(() => {
		return _.isEqual(loading, true) || _.isEmpty(formData.serialNumber.trim()) || _.isNil(formData.vehicleOption)
	}, [formData, loading])

	const _updateDevice = useUpdateDevice({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `Device registration successfully updated.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not update device registration: ${p.message}`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setFormData(state => ({
				...state, 
				alertMessage: 'Form incomplete. Please provide the missing information.', 
				alertSeverity: 'error'
			}));
			return
		}

		setFormData(state => ({
			...state, 
			loading: true, 
			alertMessage: 'Updating device registration...', 
			alertSeverity: 'info'
		}));

		// console.warn('_updateDevice:onSubmit', {
		// 	formData
		// })

		// see dvapi/routes/v1/device.js (_updateDevice)
		_updateDevice.mutate({
			id: formData.deviceId,
			vehicle_id: formData.vehicleOption.value,
		});
	}, [_updateDevice, formData, isSubmitDisabled]);

	const onClickClose = () => {
		history.goBack();
	}

	// const onClickDelete = () => {
	// 	history.goBack();
	// }

	// const onClickDisable = () => {
	// 	history.goBack();
	// }

	const vehicleHelp = React.useMemo(() => {
		const linkedVehicleId = _.isEqual(deviceData?.vehicle_linked ?? false, true) ? deviceData?.vehicle_id ?? null : null;
		const newVehicle = !_.isEqual(formData?.vehicleOption?.value ?? null, linkedVehicleId);
		if (newVehicle) {
			if (!_.isNil(linkedVehicleId)) {
				return `The device will be linked to the selected vehicle and unlinked from ${deviceData?.vehicle_name} (${deviceData?.vehicle_registration_number}).`;
			}
		}
		return null;
	}, [formData.vehicleOption, deviceData]);

	const onChangeVehicle = (data) => {
		setFormData(state => ({...state, vehicleOption: data}))
	};

	const alertMessage = React.useMemo(() => isFetching ? 'Loading, please wait...' : formData.alertMessage, [formData, isFetching])
	const alertSeverity = React.useMemo(() => isFetching ? 'warning' : formData.alertSeverity, [formData, isFetching])

	return (
		<form noValidate classes={classes.body}>
			<Backdrop className={classes.backdrop} open={isFetching}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<DrawerTitle>Edit Device</DrawerTitle>
			<DrawerHelp alert={alertMessage} severity={alertSeverity}>{_helpText}</DrawerHelp>
			{/* <DrawerAlert variant="filled" severity={formData.alertSeverity}>{formData.alertMessage}</DrawerAlert> */}
			<DrawerTextField id="serial_number" label="S/N" value={formData.serialNumber} disabled />
			<DrawerTextField id="activation_status" label="Activation Status" value={formData.activationStatus} disabled />
			<VehicleSelect label="Linked Vehicle" autoFocus={true} help={vehicleHelp} value={formData.vehicleOption} onChange={onChangeVehicle} placeholder="Link Vehicle..." />
			<DrawerDetails>
				<DrawerTextField id="last_online_time" label="Last Online" value={formData.lastOnlineTime} disabled />
				<DrawerTextField id="online_status" label="Online Status" value={formData.onlineStatus} disabled />
				<DrawerTextField id="trip_count" label="Trips" value={formData.tripCount} disabled />
				<DrawerTextField id="driver_id" label="Driver ID" value={formData.linkedDriver} disabled />
				<DrawerTextField id="vehicle_id" label="Vehicle ID" value={formData.linkedVehicle} disabled />
				<DrawerTextField id="id" label="ID" value={formData.deviceId} disabled />
			</DrawerDetails>
			<DrawerActions>
				<DrawerButtonConfirm details={<SaveMessage />} title="Save changes to device registration?" action="Save" color="primary" onClick={onSubmit} />
				{/* <DrawerButtonWarning details={<DisableMessage />} title="Disable the device?" action="Disable" color="primary" onClick={onClickDisable} disabled={loading} /> */}
				{/* <DrawerButtonDanger manualConfirmation={formData.serialNumber} details={<DeleteMessage />} title="Delete the device?" action="Delete" color="primary" onClick={onClickDelete} disabled={loading} /> */}
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} disabled={loading} />
			</DrawerActions>
		</form>
	)
}
