import "./data-tables.css";
import React, { useCallback, useEffect, useState } from 'react';

import { useIsMounted } from "shared-hooks/useIsMounted";
import { WARN_USE_EFFECT, WARN_USE_EFFECT_CLEAN } from 'shared-functions/shared-functions';

import { userGroupOwners, userGroupUsers, userGroupViewers } from "services/user-group";

import { useFetchUsers, useFetchUserGroups } from 'services/api';

import { ServerSideTable } from 'components/table/table';

import { generateDefaultCriteria, generateDefaultSearchCriteria, generateDefaultSearchCriteriaSkeleton, generateCriteria, generateSearchCriteria } from "./data-table-functions";
import { useClassesFormStyle } from "theme/theme";

export const UserTable = ({ bookmarkId, defaultCriteria, fixedSearchCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const classes = useClassesFormStyle();

	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, null),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});

	const userCriteria = React.useMemo(() => ({
			...generateCriteria(tableCriteria, null),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria, fixedSearchCriteria)
	}), [tableCriteria, allowedSearchCriteria, fixedSearchCriteria]);

	const { userList, userCount, isFetching } = useFetchUsers(userCriteria);

	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		// name: { field: "name", header: "Name" },
		// surname: { field: "surname", header: "Surname" },
		// email: { field: "email", header: "Email" },
		// role: { field: "role", header: "Role" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));


	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
		{ field: "role", headerName: "Role", width: 150 },
		{ field: "email", headerName: "Email", width: 250 },
		{ field: "company_name", headerName: "Company", width: 250 },
		{ field: "activation_status", headerName: "Activation", width: 200 },
	];

	return (
		<div className={classes.tableRoot}>
			<ServerSideTable
				bookmarkId={bookmarkId}
				columns={columns}
				rows={userList}
				loading={isFetching}
				total={userCount}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}
				rowClick={handleRowClick}
				criteriaChanged={handleCriteriaChanged}
				className={classes.tableRoot}
			/>
		</div>
	);
}

export const UserGroupTable = ({ defaultCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, null),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});

	const userCriteria = React.useMemo(() => ({
			...generateCriteria(tableCriteria, null),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
	}), [tableCriteria, allowedSearchCriteria]);

	const { userGroupList, userGroupCount, isFetching } = useFetchUserGroups(userCriteria);

	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		group_name: { field: "group_name", header: "Name" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 250 },
		{ field: "num_users", headerName: "Total Users", width: 160 },
		{ field: "num_owners", headerName: "Total Owners", width: 160 },
		{ field: "num_viewers", headerName: "Total Viewers", width: 160 },
		{ field: "is_owner", headerName: "Owner", width: 100 },
		{ field: "is_viewer", headerName: "Viewers", width: 120 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={userGroupList}
				loading={isFetching}
				total={userGroupCount}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}
				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const UserGroupUsersTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

    const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		group_name: { field: "group_name", header: "Name" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [cancelSource, userGroupUsersPromise] = userGroupUsers(data)
        let isActive = true;
        userGroupUsersPromise
			.then((response) => {
                if (!isMounted() || !isActive) return;
				setLoading(false);
                if (!response.success || !response.result) return;
				const users = response.result.users;
				setTotal(parseInt(response.result.count));
				setData(users);
			})
			.catch((err) => {
                if (!isMounted() || !isActive) return;
				setLoading(false);
                console.log(err);
			});
        return () => {
					console.log(WARN_USE_EFFECT_CLEAN)
					// isActive = false;
					// cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
        }
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const UserGroupOwnersTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

    const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		group_name: { field: "group_name", header: "Name" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		console.log("UserGroupOwners:", data);
		const [cancelSource, userGroupOwnersPromise] = userGroupOwners(data)
        let isActive = true;
        userGroupOwnersPromise
			.then((response) => {
                if (!isMounted() || !isActive) return;
				setLoading(false);
                if (!response.success || !response.result) return;
				const users = response.result.owners;
				setTotal(parseInt(response.result.count));
				setData(users);
			})
			.catch((err) => {
                if (!isMounted() || !isActive) return;
				setLoading(false);
                console.log(err)
			});
        return () => {
					console.log(WARN_USE_EFFECT_CLEAN)
					// isActive = false;
					// cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
        }
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
		// { field: "role", headerName: "Role", width: 150 },
		// { field: "email", headerName: "Email", width: 250 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const UserGroupViewersTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

    const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		group_name: { field: "group_name", header: "Name" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [cancelSource, userGroupViewersPromise] = userGroupViewers(data)
        let isActive = true;
        userGroupViewersPromise
			.then((response) => {
                if (!isMounted || !isActive) return;
				setLoading(false);
                if (!response.success || !response.result) return;
				const users = response.result.viewers;
				setTotal(parseInt(response.result.count));
				setData(users);
			})
			.catch((err) => {
                if (!isMounted || !isActive) return;
				setLoading(false);
                console.log(err);
			});
        return () => {
						console.log(WARN_USE_EFFECT_CLEAN)
            // isActive = false;
            // cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
        }
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
		// { field: "role", headerName: "Role", width: 150 },
		// { field: "email", headerName: "Email", width: 250 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}
