import './user.css';
import React from 'react';

import { UserInfoView } from 'components/info-view/info-view';
import { TrainingMini, TripsMini, UserEventStatisticsMini, UserStatisticsMini } from 'components/tabs/user-tabs/user-tabs';
import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useFetchUser, useFetchUserPicture } from 'services/api';
import { useRoleCanViewOwnCourses, useRoleCanViewOwnTrips } from 'modules/useGlobalStore';
import { useIsCurrentUserDriver } from 'modules/useTokenStore';

const useStyles = makeStyles((theme) => createStyles({
  gridRoot: {
    width: '100%',
    // height: '100%',
    padding: 0,
    margin: 0,
  },
  gridBox: {
    // height: 325,
    height: '100%',
    // maxHeight: 300,
    // minHeight: 300,
  },
  gridPaper: {
    height: '100%',
  },
}));

const _Training = ({ userId }) => {
  const classes = useStyles();

  const featureCheck = useRoleCanViewOwnCourses();
  if (!featureCheck) {
    return null
  }

  return (
    <Grid item xs={12} md={6} lg={4} >
      <Box className={classes.gridBox}>
        <Paper className={classes.gridPaper}>
          <TrainingMini userId={userId} />
        </Paper>
      </Box>
    </Grid>
  )
}

const _UserTripsCard = ({ userId }) => {
  const classes = useStyles();
  const isDriver = useIsCurrentUserDriver();
  if (isDriver) {
    return null
  }
  return (
    <Grid item xs={12} md={6} lg={4} >
      <Box className={classes.gridBox}>
        <Paper className={classes.gridPaper}>
          <TripsMini userId={userId} />
        </Paper>
      </Box>
    </Grid>
  )
}

const _UserEventStatisticsCard = ({ userId }) => {
  const classes = useStyles();
  const isDriver = useIsCurrentUserDriver();
  if (isDriver) {
    return null
  }
  return (
    <Grid item xs={12} md={6} lg={4} >
      <Box className={classes.gridBox}>
        <Paper className={classes.gridPaper}>
          <UserEventStatisticsMini userId={userId} />
        </Paper>
      </Box>
    </Grid>
  )
}

// this is effectively a User dashboard with mini widgets
export const User = ({ userId }) => {

  const { data: userDataBasic } = useFetchUser(userId);
  const { data: userPicture } = useFetchUserPicture(userId);

  const classes = useStyles();

  const userData = React.useMemo(() => ({
    ...(userDataBasic ?? {}),
    profile_image: userPicture?.image?.user_image_file ?? null,
  }), [userDataBasic, userPicture]);

  // console.log('User', userData, userPicture, userDataBasic);
  // history.replace(url, { [userId]: `${response.result.name} ${response.result.surname}`})

  return (
    <Grid className={classes.gridRoot} container spacing={1} direction="row">
      <Grid item xs={12} md={6} lg={4} >
        <UserInfoView data={userData} />
      </Grid>
      <Grid item xs={12} md={6} lg={4} >
        <Box className={classes.gridBox}>
          <Paper className={classes.gridPaper}>
            <UserStatisticsMini userId={userId} />
          </Paper>
        </Box>
      </Grid>
      <_UserEventStatisticsCard userId={userId} />
      <_UserTripsCard userId={userId} />
      <_Training userId={userId} />
    </Grid>
  );
}
