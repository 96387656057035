import './vehicle-tabs.css';
import React, { useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';

import { VehicleAlerts } from './vehicle-alerts/vehicle-alerts';
import { VehicleTripContainer } from './vehicle-trips/vehicle-trips';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

// TODO: this could be improved
function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		id={`simple-tabpanel-${index}`}
		hidden={index !== value}
		aria-labelledby={`simple-tab-${index}`}
		className={"tab-panel"}
		{...other}
	  >
		{index === value && children}
	  </div>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		// backgroundColor: theme.palette.background.paper,
	},
}));

export const VehicleTabs = ({ vehicleId }) => {

	const [value, setValue] = useState(0);
	const classes = useStyles();

	const handleChange = (event, newValue) => {
		event !== newValue && setValue(newValue);
	};

	return (
		<div className={classes.root}>
			{/* <AppBar position="static"> */}
				<Tabs value={value} onChange={ handleChange } variant="scrollable" scrollButtons="on" aria-label="simple tabs">
					<Tab className={"tab-buttons"} label="Trips" {...a11yProps(0)} />
					<Tab className={"tab-buttons"} label="All Events" {...a11yProps(1)} />
				</Tabs>
			{/* </AppBar> */}
			<TabPanel value={value} index={0}>
				<VehicleTripContainer vehicleId={vehicleId} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<VehicleAlerts vehicleId={vehicleId} />
			</TabPanel>
		</div>
	);
}
