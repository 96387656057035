import axios from 'axios';
import MyAxiosInstance from './api-setup';
import _ from 'lodash';

 export function axiosPostRequestRaw(url, data) {
    // console.debug('axiosPostRequest', url, data);
    const source = axios.CancelToken.source()
    return [
      source,
      MyAxiosInstance.post(url, data, { 
        cancelToken: source.token 
      })
    ]
}

/**
 * Returns an array where index 0 is the cancel token source for this call, and index 1 is the axios promise
 * @param   {string}  url     The url being called
 * @param   {any}     data    Data being passed to the endpoint
 */
 export function axiosPostRequest(url, data) {
    // console.debug('axiosPostRequest', url, data);
    const source = axios.CancelToken.source()
    return [
        source,
        MyAxiosInstance.post(url, (data || {}), { cancelToken: source.token }).then(response => response.data)
    ]
}

 export function axiosGetRequestRaw(url, opts) {
    const source = axios.CancelToken.source()
    return [
        source,
        MyAxiosInstance.get(url, { 
          cancelToken: source.token,
          ...(opts ?? {})
        })
    ]
}

/**
 * Returns an array where index 0 is the cancel token source for this call, and index 1 is the axios promise
 * @param   {string}  url     The url being called
 * @param   {any}     data    Data being passed to the endpoint
 */
 export function axiosGetRequest(url) {
    const source = axios.CancelToken.source()
    return [
        source,
        MyAxiosInstance.get(url, { cancelToken: source.token }).then(response => response.data)
    ]
}

function apiError(data) {
  if (_.isNil(data) || _.isNil(data?.success) || _.isEqual(data?.success, true)) {
    return
  }

  // At this point we know we have data, and that a field called success exists
  // and is false.

  // This is the form of the data:
  // data.result
  // data.message
  // data.success

  const detail = _.join(_.defaultTo(data?.result?.errors, []).map(e => JSON.stringify(e)), ', ')
  // console.warn('apiError', { data: data, bla: JSON.stringify({message: data?.message, detail})})

  throw new Error(`${data?.message} (${detail})`)
}

async function doApiRequest(request) {
  let r = null;
  try {
    r = await request;
  } catch (e) {
    apiError(e?.response?.data);
    throw e;
  }
  return r.data;
}

// todo: this is the recommended function for future api post requests (refactor old requests to use this interface instead)
export async function apiPost(relUrl, data) {
  const url = `${process.env.REACT_APP_WEB_SERVICE_URL}${relUrl}`;
  // console.warn(url, data);
	const [, request] = axiosPostRequestRaw(url, data);
  return await doApiRequest(request);
}

// todo: this is the recommended function for future api get requests (refactor old requests to use this interface instead)
export async function apiGet(relUrl, opts) {
  const url = `${process.env.REACT_APP_WEB_SERVICE_URL}${relUrl}`;
  // console.warn(url, data);
	const [, request] = axiosGetRequestRaw(url, opts);
  return await doApiRequest(request);
}
