import './vehicle.css';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useScreenType } from 'shared-hooks/useScreenTypes';
import { useIsMounted } from 'shared-hooks/useIsMounted';

import { deleteVehicle, vehicle } from 'services/vehicle';

import { InfoView, VehicleInfoView } from 'components/info-view/info-view';
import { TinyInfoView } from 'components/info-view/tiny-info-view/tiny-info-view';
import { VehicleTabs } from 'components/tabs/vehicle-tabs/vehicle-tabs';
import { MultiViewContainer } from 'components/view-container/view-container';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';

const styles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		'& > .tiny-info-view': {
			margin: theme.spacing(1),
		},
	},
	subroot: {
		display: 'flex',
		flexDirection: 'row',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

export const Vehicle = () => {

	const isMounted = useIsMounted();
	const { vehicleId } = useParams();
	const screenType = useScreenType();
	const [vehicleData, setVehicleData] = useState();
	const [minified, setMinified] = useState(false);
	const [showTiny, setShowTiny] = useState('tiny-info-shown');
	const [showInfo, setShowInfo] = useState('');
	const [showAdditionalInfo, setShowAdditionalInfo] = useState('additional-info-shown');
	const [additionInfoHeight, setAdditionInfoHeight] = useState('additional-info-normal');
	const history = useHistory();
	const { url } = useRouteMatch();
	const classes = styles();

	useEffect(() => {
		if (screenType !== "phone" && !minified) {
			setShowTiny('tiny-info-hidden');
			setShowInfo('info-shown');
			setShowAdditionalInfo('additional-info-shown');
			setAdditionInfoHeight('additional-info-normal');
		} else if (screenType === "phone" && !minified) {
			setShowTiny('tiny-info-hidden');
			setShowInfo('info-shown');
			setShowAdditionalInfo('additional-info-hidden');
			setAdditionInfoHeight('additional-info-shorter');
		} else {
			setShowTiny('tiny-info-shown');
			setShowInfo('info-hidden');
			setShowAdditionalInfo('additional-info-shown');
			setAdditionInfoHeight('additional-info-shorter');
		}
	}, [screenType, minified]);

	useEffect(() => {
		if (!vehicleId) return;

		const [cancelSource, vehiclePromise] = vehicle(vehicleId)
		let isActive = true;
		vehiclePromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;

				setVehicleData(response.result);
				history.replace(url, { [vehicleId]: `${response.result.name}`})
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err);
			});
		return () => {
			isActive = false
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [vehicleId]);

	const handleEditUser = () => {
		history.push(`${url}/edit`, { [vehicleId]: `${vehicleData.name}`});
	}

	const handleMinimize = (param) => {
		setMinified(param)
	}

	const viewTinySkeleton = [
		{ key: 0, property: "name", header: "NAME:" },
		{ key: 1, property: "type", header: "TYPE:" },
		{ key: 3, property: "make", header: "MAKE:" },
		{ key: 4, property: "model", header: "MODEL:" },
		{ key: 5, property: "colour", header: "COLOUR:" },
		{ key: 6, property: "registration_number", header: "REG NR:" },
	];

	return (
		<MultiViewContainer
			minified={minified}

			tinyChild={
				<TinyInfoView
						skeleton={viewTinySkeleton}
						data={vehicleData}
						
						onEdit={ handleEditUser }
						onMaximize={ handleMinimize } />
			}
			viewChild={ <VehicleInfoView data={vehicleData} allowEdit allowDelete onMinimize={ handleMinimize } /> }
			rightChild={ <VehicleTabs vehicleId={vehicleId} /> }/>
	);
}
