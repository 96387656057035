import React from 'react';
import { BaseSelect } from './base-select';

// todo: consider retrieving the possible states from the database
const options = [
	{ value: "enabled", label: "Enabled" },
	{ value: "disabled", label: "Disabled" },
	{ value: "retired", label: "Retired" },
];

const defaultOption = options[0];

// https://english.stackexchange.com/questions/12958/status-vs-state
// https://www.merriam-webster.com/dictionary/life%20cycle
// https://english.stackexchange.com/questions/68230/life-cycle-life-cycle-or-lifecycle
// https://www.igi-global.com/dictionary/operating-status/72373
export const ActivationStatusSelect = ({onChange, value, help}) => {
	return (
		<BaseSelect 
			disableClearable
			id="activation_status" 
			label="Activation Status" 
			severity="warning" 
			defaultOption={defaultOption} 
			options={options} 
			value={value} 
			onChange={onChange}
			help={help}
		/>
	)
}
