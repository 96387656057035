import { apiGet, apiPost, axiosPostRequest } from './api-request';

export async function companyAll(data) {
	console.warn('companyAll', {data})
	const response = await apiPost(`/v1/company`, data);
	return response?.result ?? null;
}

export async function companyGet(companyId) {
	console.warn('companyGet', {companyId})
	const response = await apiPost(`/v1/company/get`, {
    id: companyId
  });
	return response?.result ?? null;
}

export async function companyFeatures(companyId) {
	console.warn('companyFeatures', {companyId})
	const response = await apiGet(`/v1/company-features`, {
    params: {
			id: companyId
		}
  });
	return response?.result ?? null;
}

export async function companyCreate(data) {
	console.warn('companyCreate', {data})
	const response = await apiPost(`/v1/company/create`, data);
	return response?.result ?? null;
}

export async function companyUpdate(data) {
	console.warn('companyUpdate', {data})
	const response = await apiPost(`/v1/company/update`, data);
	return response?.result ?? null;
}
