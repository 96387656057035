import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import _ from 'lodash';

import { DrawerActions, DrawerButton, DrawerButtonConfirm, DrawerConfirmMessage, DrawerDetails, DrawerHelp, DrawerTextField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useFetchCompany, useUpdateCompany } from 'services/api';
import { ActivationStatusSelect } from 'components/autocomplete/activation-status-select';

const helpText = `Use this form to update a company.`;

const SaveMessage = () => {
	return (
		<DrawerConfirmMessage 
			message="Please confirm that you want to update the company with the requested changes." 
			severity="warning" 
		/>
	)
}

// export default () => {
export const AdminCompany = () => {

	const { companyId } = useParams();

	const { data: companyData, isFetching } = useFetchCompany(companyId);

	// console.warn('AdminCompany', {companyData});

	const classes = useClassesFormStyle();

	const history = useHistory();

  const [formData, setFormData] = React.useState({
		id: '',
		name: '',
		activationOption: null,
		alertSeverity: 'info',
		alertMessage: '',
		loading: false,
	});

	const loading = React.useMemo(() => formData.loading || isFetching, [isFetching, formData.loading])

	const activationHelp = React.useMemo(() => {
		const nextActivation = formData?.activationOption?.value ?? null;
		const newActivation = !_.isEqual(nextActivation, companyData?.activation_status ?? null);
		if (!newActivation) {
			return null
		}
		if (_.isEqual(nextActivation, "enabled")) {
			return "The company will be re-enabled. All access restrictions will be lifted."
		}
		else if (_.isEqual(nextActivation, "disabled")) {
			return "The company will be disabled. Access restrictions will apply."
		}
		else if (_.isEqual(nextActivation, "retired")) {
			return "The company will be retired. It will no longer function, but historic data will be retained. The data will be eligible for deletion during maintenance tasks."
		}
		else {
			return null
		}
	}, [formData.activationOption, companyData]);

	React.useEffect(() => {
		// console.warn('admin-company', {companyData})

		const activationStatus = companyData?.activation_status ?? 'enabled';
		const activationOption = {
			value: activationStatus,
			label: _.startCase(activationStatus),
		};
		const name = `${companyData?.name ?? ''}`
		const id = companyData?.id;
		setFormData(state => ({
				...state, 
				id,
				name,
				activationOption,
			}))
	}, [companyData])

	// todo: display confirmation dialog on submit, with warning clearly stating the effects of the change
	// - existing vehicles will be unlinked
	// - all trips will be moved to the new company

	const isSubmitDisabled = React.useMemo(() => {
		return _.isEqual(loading, true) 
			|| _.isNil(formData.name)
	}, [formData, loading])

	const updateCompany = useUpdateCompany({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `Company successfully updated.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not update company: ${p.message}`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setFormData(state => ({
				...state, 
				alertMessage: 'Form incomplete. Please provide the missing information.', 
				alertSeverity: 'error'
			}));
			return
		}

		setFormData(state => ({
			...state, 
			loading: true, 
			alertMessage: 'Updating company...', 
			alertSeverity: 'info'
		}));

		// console.warn('updateCompany:onSubmit', {
		// 	formData
		// })

		updateCompany.mutate({
			id: formData.id,
			name: formData.name,
			activation_status: formData.activationOption.value,
		});
	}, [updateCompany, formData, isSubmitDisabled]);

	const { url, path } = useRouteMatch();

	const onClickClose = () => {
		history.goBack();
	}

	const onClickCreate = () => {
		history.push(`${url}/add-admin-user`);
	}

	const onClickManage = () => {
		history.push(`${url}/admins`);
	}

	const onClickFeatures = () => {
		history.push(`${url}/features`);
	}

	// const onClickDelete = () => {
	// 	history.goBack();
	// }

	// const onClickDisable = () => {
	// 	history.goBack();
	// }

	const onChangeActivation = (data) => {
		setFormData(state => ({...state, activationOption: data}))
	};

	const onInputName = e => setFormData(state => ({...state, name: e.target.value}));

	return (
		<form noValidate classes={classes.body} autoComplete="off">
			<DrawerTitle>Edit Company</DrawerTitle>
			<DrawerHelp alert={formData.alertMessage} severity={formData.alertSeverity}>{helpText}</DrawerHelp>
			<DrawerTextField 
				id="name" 
				label="Company Name" 
				value={formData.name} 
				autoFocus={true} 
				autoComplete="off"
				onInput={onInputName}
				required 
			/>

			<ActivationStatusSelect 
				help={activationHelp} 
				value={formData.activationOption} 
				onChange={onChangeActivation} 
			/>
			<DrawerDetails>
				<DrawerTextField id="id" label="ID" value={formData.id} disabled />
			</DrawerDetails>
			<DrawerActions>
				<DrawerButtonConfirm details={<SaveMessage />} title="Save changes to company registration?" action="Save" color="primary" onClick={onSubmit} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} disabled={loading} />
			</DrawerActions>
			<DrawerActions>
				<DrawerButton action="Register Admin" color="secondary" onClick={onClickCreate} disabled={loading} />
			</DrawerActions>
			<DrawerActions>
				<DrawerButton action="View Admins" color="secondary" onClick={onClickManage} disabled={loading} />
			</DrawerActions>
			<DrawerActions>
				<DrawerButton action="Select Role Features" color="secondary" onClick={onClickFeatures} disabled={loading} />
			</DrawerActions>
		</form>
	)
}
