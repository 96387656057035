import "./devices-page.css";
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Devices from "./devices/devices";

export const DevicesPage = () => {

	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}`}>
				<Devices />
			</Route>
		</Switch>
	);
}
