// NEW VERSION 1.1

import './live-global-events.css';
import React, { useState } from 'react';

import { OverlayView, Marker, useGoogleMap } from '@react-google-maps/api';

import { MapPath } from '../map-paths/map-path';
import { EventFilterMapControl, EventMapControl, MapLiveTripButton } from 'components/map/map-controls/map-controls';
import { useLiveViewInit, useCaptureLiveDeviceTrips, setSelectedDeviceTrip, useSelectedDeviceTripMapPosition, useLiveDeviceTripMarkerList, useLiveDeviceEventMarkers, useLiveDeviceTripPath, setFocusDeviceTrip, setFocusTripEvent, useDriverIcon, useStateEventTypeFilters, useDriverPulseClass, useDriverTooltip } from 'modules/useGlobalStore';

// https://trello.com/c/GnHclbAU
// If the page is refreshed while live trips are in progress, then the data
// before the refresh should still be displayed.
// If the live view is opened on another browser in the middle of existing
// trips, then the entire trip information for the active trips must be
// displayed.
export const FilterLiveTopics = () => {

	useLiveViewInit();

	useCaptureLiveDeviceTrips();

  const selectedDeviceTripMapPosition = useSelectedDeviceTripMapPosition();

  const [typeFilters, setTypeFilters] = useStateEventTypeFilters();

  const [historicalLastPathPoint, setHistoricalLastPathPoint] = useState(null);
  const map = useGoogleMap();

  const eventList = useLiveDeviceEventMarkers(typeFilters);

  const handleEventFilterChanged = (param) => {
    setTypeFilters(param);
  }

  const handlePanToCurrentPosition = () => {
    // console.warn('FilterLiveTopics: handlePanToCurrentPosition', {
      // selectedDeviceTripMapPosition,
      // historicalLastPathPoint,
    // });
    if (selectedDeviceTripMapPosition) {
      map.panTo(selectedDeviceTripMapPosition);
    } else if (historicalLastPathPoint) {
      map.panTo(historicalLastPathPoint);
    }
  }

  const handleHistoricalPathUpdated = (pathPoint) => {
    setHistoricalLastPathPoint(pathPoint);
  }

  return (<>

    {/* Event Filter Map Control */}
    <EventFilterMapControl verticalIndex={1} typeFilters={typeFilters} onFilterChange={handleEventFilterChanged} />

    {/* Driver Filter Map Control */}
    <MapLiveTripButton
      verticalIndex={1}
      onPanToCurrentPosition={handlePanToCurrentPosition}
      onHistoricalPathUpdated={handleHistoricalPathUpdated}
      eventList={eventList}
      isLive={true}
    />

    {/* Driver Event Map Control */}
    <EventMapControl eventList={eventList} />

    {/* Topics */}
    <LiveDeviceTripMapPathAndMarkers />
  </>);
};

const LiveDriverMarkerList = ({ markers }) => {
  // console.log('LiveDriverMarkerList', markers);
  return (
    <>
      {markers.map((value, index) => {
        const key = value.key;
        return (
          <LiveDriverMarker
            key={key}
            position={value.position}
            data={value}
            bearing={value.bearing}
          />
        )
      })}
    </>
  );
}

const LiveDeviceTripMapPathAndMarkers = () => {
  const liveMarkers = useLiveDeviceTripMarkerList(true);
  const liveTripPath = useLiveDeviceTripPath();
  return (
    <>
      <LiveDriverMarkerList markers={liveMarkers} />
      {
        liveTripPath && <MapPath path={liveTripPath} />
      }
    </>
  )
}

// todo: profile LiveDriverMarker performance, because we can't even seem to support a handful of them on screen
export const LiveDriverMarker = ({ position, bearing, data }) => {

  const title = useDriverTooltip(data);

  // console.warn('LiveDriverMarker', { data })

  const deviceStatus = data?.status;

  const tripStatus = data?.trip_status;

  const handleClick = React.useCallback(param => {
    setSelectedDeviceTrip(
      data?.device_serial_number ?? null,
      data?.trip_id ?? null,
      data?.driver_id ?? null,
      data?.vehicle_id ?? null,
    );
    setFocusTripEvent(false);
    setFocusDeviceTrip(true); // activate this line to open the trip control side panel
  }, [data]);

  const driverIcon = useDriverIcon(bearing, deviceStatus, tripStatus);

  // This Marker should pulse. This is achieved by live-global-events.css, the
  // pulse class and the driver_location_online class.
  const pulseClass = useDriverPulseClass(deviceStatus, tripStatus);

  // console.log('LiveDriverMarker', driverIcon, pulseClass)
  // console.log('LiveDriverMarker', position, bearing, data);

  // https://github.com/JustFly1984/react-google-maps-api/blob/develop/packages/react-google-maps-api/src/components/drawing/Marker.tsx
  // https://github.com/JustFly1984/react-google-maps-api/issues/701
  // https://react-google-maps-api-docs.netlify.app/#overlayview
  // https://trello.com/c/lsuk3s7Q
  return (
    <>
      <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div className={pulseClass} />
      </OverlayView>
      <Marker
        title={title}
        position={position}
        icon={driverIcon}
        zIndex={999}
        onClick={handleClick} />
    </>
  );
}
