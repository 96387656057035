import React from 'react';

import _ from 'lodash';

import { useFetchRoleOptions } from 'services/api';
import { BaseSelect } from './base-select';
import { useCurrentRoleOption, useCurrentRoleOptionList } from 'modules/useGlobalStore';

const getOptionDisabled = (option) => {
	return _.isEqual(option?.value, '5DTAdmin');
}

export const RoleSelect = ({onChange, value, help, placeholder, autoFocus, label, defaultOption, ...props}) => {
	// const { rowOptions: options } = useFetchRoleOptions();
	const { rowOptions: options } = useCurrentRoleOptionList();
	const defaultRole = useCurrentRoleOption();
	const _defaultOption = defaultOption ?? defaultRole;
	const _label = label ?? "Role";
	const _help = _.isEqual(_defaultOption?.value ?? null, value?.value ?? null) ? null : help;
	// console.warn('RoleSelect', { options, _defaultOption, value, _help })
	return (
		<BaseSelect 
			id="role_name" 
			label={_label}
			severity="warning" 
			defaultOption={_defaultOption} 
			getOptionDisabled={getOptionDisabled}
			options={options} 
			value={value} 
			onChange={onChange}
			help={_help}
			placeholder={placeholder}
			autoFocus={autoFocus}
			{...props}
		/>
	)
}
