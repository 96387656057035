import axios from "axios"

import { toast } from 'react-toastify';
import { getAuthHeader } from "./headers";
import { history } from 'modules/history';

/*
	Create Axios instance
*/
const MyAxiosInstance = axios.create()

/*
	Add Axios request interceptor
*/
MyAxiosInstance.interceptors.request.use(
	(request) => {
		if (!request.url.includes('/v1/user/authorize')) {
			request.headers = { ...request.header, ...getAuthHeader()}
		}
		return request;
	},
	(error) => {
		console.warn('MyAxiosInstance.interceptors.request: auth request intercept error', {
			error: JSON.stringify(error)
		})
		return Promise.reject(error)
	}
);

/*
	Add Axios response interceptor
*/
MyAxiosInstance.interceptors.response.use(
	(response) => {
		// https://www.npmjs.com/package/axios#handling-errors
		// https://www.npmjs.com/package/axios#interceptors
		// https://stackoverflow.com/questions/72674766/how-to-throw-new-error-in-axios-when-response-data-code-is-not-200
		// console.log('interceptors response', response)
		// if (!response.data.success) {
			// console.warn('MyAxiosInstance.interceptors.response: data success is false', { response })
			// toast.error(`${response.data.message}`, {
			// 	position: "top-center",
			// 	autoClose: true,
			// 	hideProgressBar: false,
			// 	closeOnClick: true,
			// 	pauseOnHover: true,
			// 	draggable: true,
			// 	progress: 0,
			// })
		// }
		return response
	},
	(error) => {
		// Any status codes that falls outside the range of 2xx cause this function
		// to trigger
		console.warn('MyAxiosInstance.interceptors.response: axios response error incoming', {
			data: JSON.stringify({
				error, 
				response: error?.response ?? null,
				data: error?.response?.data ?? null,
				result: error?.response?.data?.result ?? null
			})
		})
		// console.warn('MyAxiosInstance.interceptors.response: Unauthorized', { error })
		if (error.response && error.response.status === 401 && error.response.data && (error?.response?.data?.result?.valid_token ?? true) === false) {
			// todo: a logout should be performed during invalid auth or when the token is no longer valid
			// todo: not necessarily for all 401 Unauthorized events?
			// console.warn('MyAxiosInstance.interceptors.response: Unauthorized', { error })
			history.push('/login?reason=tk')
		} else {
			if (error.response && error.response.data) {
				// toast.error(`${error.response.data.message}`, {
				// 	position: "top-center",
				// 	autoClose: true,
				// 	hideProgressBar: false,
				// 	closeOnClick: true,
				// 	pauseOnHover: true,
				// 	draggable: true,
				// 	progress: 0,
				// })
			} else if (error.toString().includes('Network Error')) {
				// todo: consider handling toast errors as a component on the root layout
				// todo: add a hook that says useErrorMessage and then toast that.
				toast.error(`A network error occured`, {
					position: "top-center",
					autoClose: true,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: 0,
				})
			} else if (error.message && error.message.is_aborted) {
				// Do not show a toast if the request was cancelled
				console.log("MyAxiosInstance.interceptors.response: Request aborted via CancelToken", { error: JSON.stringify(error) })
			} else {
				toast.error(`An unexpected error occured`, {
					position: "top-center",
					autoClose: true,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: 0,
				})
			}
		}
		return Promise.reject(error);
	}
)

export default MyAxiosInstance
