import "./users.css";
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from "@material-ui/core";

import { UserTable } from "components/data-tables/user-data-tables";
import { useBookmark } from "modules/useTokenStore";

const defaultCriteria = {
	orderBy: 'name',
}

const defaultSearchCriteria = {
	name: '',
	surname: '',
	email: '',
	role: '',
	company_name: '',
}

const searchCriteria = {
	name: '',
	surname: '',
	email: '',
	role: '',
}

// bookmarkId is a unique id for this component: https://www.uuidgenerator.net/
const bookmarkId = '4d36324d-2b81-446e-8c1b-245561862945';

export const Users = () => {

	const history = useHistory();
	const { url } = useRouteMatch();

	const handleRowClick = React.useCallback((data) => {
		history.push(`${url}/${data.data.id}`)
	}, [url, history]);

	const handleCreate = React.useCallback(() => {
		history.push(`${url}/create`);
	}, [url, history]);

	const bookmarkCriteria = useBookmark(bookmarkId, defaultCriteria);

	return (
		<div className={"user-table-container"}>
			<h1>Users</h1>
			{/* <Typography component="h1" variant="h6">Users</Typography> */}
			<Button variant="contained" color="primary" onClick={ handleCreate }>Create</Button>
			<UserTable
				searchCriteria={searchCriteria}
				defaultSearchCriteria={defaultSearchCriteria}
				defaultCriteria={bookmarkCriteria}
				bookmarkId={bookmarkId}

				onRowClick={ handleRowClick } />
		</div>
	);
}
