import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useCallbackToggleDistance, useTripSummary } from 'modules/useGlobalStore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
	actionText: {
    // display: 'flex',
    // alignItems: 'center',
    // flexWrap: 'wrap',

		// verticalAlign: 'middle',
    // display: 'inline-flex'
	},
}));

export default ({isLive}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

	const isLiveNormal = React.useMemo(() => isLive ?? false, [isLive]);

	const tripData = useTripSummary(isLiveNormal);

	// only displayed for isLiveNormal:
	const deviceStatus = tripData.deviceStatus;
	const tripStatus = tripData.tripStatus;
	const tripDescription = tripData.tripDescription;
	const lastSeen = tripData.deviceLastSeen;

	// displayed for isLiveNormal and not isLiveNormal:
	const tripDistance = tripData.tripDistance;
	const tripAlertCount = tripData.tripAlertCount;
	const tripDuration = tripData.tripDuration;
	const tripId = tripData.tripId;
	const deviceSn = tripData.deviceSn;
	const driverId = tripData.driverId;
	const vehicleId = tripData.vehicleId;
	const driverFullName = tripData.driverFullName;
	const driverName = tripData.driverName;
	const driverSurname = tripData.driverSurname;
	const vehicleAlias = tripData.vehicleAlias;
	const vehicleRegistrationNumber = tripData.vehicleRegistrationNumber;
	const vehicleMake = tripData.vehicleMake;
	const vehicleModel = tripData.vehicleModel;
	const vehicleColour = tripData.vehicleColour;
	const vehicleDescription = tripData.vehicleDescription;
	const tripStartTime = tripData.tripStartTime;
	const tripEndTime = tripData.tripEndTime;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

	const toggleDistance = useCallbackToggleDistance();

	// console.warn('device-trip-info.js', {
	// 	tripData
	// })

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading} variant="h6">Trip</Typography>
          <Typography className={classes.secondaryHeading} variant="h6">{tripDescription}</Typography>
        </AccordionSummary>
        <AccordionDetails>
					<Grid container>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Distance</Typography>
							<Tooltip title="Click to toggle unit system" placement="top-start">
								<Link onClick={toggleDistance} gutterBottom variant="h6" component="h3" className={classes.actionText}>
									{tripDistance}
								</Link>
							</Tooltip>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Duration</Typography>
							<Typography gutterBottom variant="h6" component="h3">{tripDuration}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Start Time</Typography>
							<Typography gutterBottom variant="h6" component="h3">{tripStartTime}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">End Time</Typography>
							<Typography gutterBottom variant="h6" component="h3">{tripEndTime}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Status</Typography>
							<Typography gutterBottom variant="h6" component="h3">{tripStatus}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Alerts</Typography>
							<Typography gutterBottom variant="h6" component="h3">{tripAlertCount}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom variant="caption">ID</Typography>
							<Typography gutterBottom variant="h6" component="h3">{tripId}</Typography>
						</Grid>
					</Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading} variant="h6">Driver</Typography>
          <Typography className={classes.secondaryHeading} variant="h6">{driverFullName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
					<Grid container>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">First Name</Typography>
							<Typography gutterBottom variant="h6" component="h3">{driverName}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Last Name</Typography>
							<Typography gutterBottom variant="h6" component="h3">{driverSurname}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom variant="caption">ID</Typography>
							<Typography gutterBottom variant="h6" component="h3">{driverId}</Typography>
						</Grid>
					</Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading} variant="h6">Vehicle</Typography>
          <Typography className={classes.secondaryHeading} variant="h6">{vehicleDescription}</Typography>
        </AccordionSummary>
        <AccordionDetails>
					<Grid container>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Alias</Typography>
							<Typography gutterBottom variant="h6" component="h3">{`${vehicleAlias}`}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Registration Number</Typography>
							<Typography gutterBottom variant="h6" component="h3">{`${vehicleRegistrationNumber}`}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Make</Typography>
							<Typography gutterBottom variant="h6" component="h3">{`${vehicleMake}`}</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="caption">Colour</Typography>
							<Typography gutterBottom variant="h6" component="h3">{`${vehicleColour}`}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom variant="caption">Model</Typography>
							<Typography gutterBottom variant="h6" component="h3">{`${vehicleModel}`}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom variant="caption">ID</Typography>
							<Typography gutterBottom variant="h6" component="h3">{vehicleId}</Typography>
						</Grid>
					</Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading} variant="h6">Device</Typography>
          <Typography className={classes.secondaryHeading} variant="h6">{deviceStatus}</Typography>
        </AccordionSummary>
        <AccordionDetails>
					<Grid container>
						<Grid item xs={12}>
							<Typography gutterBottom variant="caption">Last Seen</Typography>
							<Typography gutterBottom variant="h6" component="h3">{lastSeen}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom variant="caption">Serial Number</Typography>
							<Typography gutterBottom variant="h6" component="h3">{deviceSn}</Typography>
						</Grid>
					</Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
