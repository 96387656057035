import './App.css';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ClientEntry } from 'client-pages/client-entry';
import { AdminEntry } from 'admin-pages/admin-entry';
import { DriverEntry } from 'driver-pages/driver-entry';
import { ToastContainer } from 'react-toastify';

// https://github.com/nfl/react-helmet
import { Helmet } from "react-helmet";

import { useUserRoot, useUserStore } from 'modules/useTokenStore';

import { Login } from 'pages/login/login';


import {
	QueryClient,
	QueryClientProvider
} from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';

import { createTheme, responsiveFontSizes  } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from 'theme/theme';
// import { useProductVersion } from 'modules/useGlobalStore';

const queryClient = new QueryClient();

// https://dev.to/chanvin/cache-control-for-react-app-with-nginx-2oef
const AppHelmet = () => {
	// const version = useProductVersion();
	const title = `5DT DriveVue`;
	return (
		<Helmet>
				<title>{title}</title>
		</Helmet>
	)
}

export const App = () => {

	const role = useUserStore(state => state.role);

	const roleRoot = useUserRoot();
	
	return (
		<>
			<AppHelmet />
			<ThemeProvider theme={theme}>
			<CssBaseline />
			<QueryClientProvider client={queryClient}>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				/>
			<Switch>
				<Route path="/login" component={Login} exact/>
				<Route path="/dashboard" >
					<ClientEntry />
				</Route>
				<Route path="/admin" >
					<AdminEntry />
				</Route>
				<Route path="/driver" >
					<DriverEntry />
				</Route>
				<Route path="/" exact >
					<Redirect to={roleRoot} />
				</Route>
				<Route>
					<h1>NOT FOUND</h1>
				</Route>
			</Switch>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
		</ThemeProvider>
		</>
	)
}
