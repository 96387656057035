import './user-group-diff.css';
import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';

import { manageGroupParticipants, userGroupOwners, userGroupUsers, userGroupViewers } from 'services/user-group';

import { ClientSideTable } from 'components/table/client-side-table';
import { useHistory, useParams } from 'react-router-dom';
import { UserTable } from 'components/data-tables/user-data-tables';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';

const styles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

export const UserGroupUserManagement = () => {

	const isMounted = useIsMounted();
	const manageGroupParticipantsCancelSourceRef = useRef(null);
	const [currentUsers, setCurrentUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState({});
    const [removeUsers, setRemoveUsers] = useState({});
    const classes = styles();
	const { userGroupId } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!userGroupId) return;
		const [cancelSource, userGroupUsersPromise] = userGroupUsers({
			s: 1,
			n: 999,
			id: userGroupId,
		})
		let isActive = true;
		userGroupUsersPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success) return;
				setCurrentUsers(response.result.users);
			})
			.catch((error) => {
				if (!isMounted() || !isActive) return;
				console.log(error);
			});
		return () => {
			isActive = true;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [userGroupId]) // eslint-disable-line

	const handleUserAdd = (row) => {
		const addedUserId = row.data.id;
		setRemoveUsers((_state) => { return {..._state, [addedUserId]: undefined} })
		setAddedUsers((_state) => { return {..._state, [addedUserId]: true} })
		for (let i = 0; i < currentUsers.length; ++i) {
			let user = currentUsers[i];
			if (user.id === addedUserId) return;
		}
		setCurrentUsers((_users) => [row.data, ..._users]);
	}

	const handleUserRemove = (row) => {
		const removedUserId = row.data.id;
		setAddedUsers((_state) => { return {..._state, [removedUserId]: undefined} })
		setRemoveUsers((_state) => { return {..._state, [removedUserId]: true} })
		for (let i = 0; i < currentUsers.length; ++i) {
			let user = currentUsers[i];
			if (user.id === removedUserId) {
				setCurrentUsers((_users) => {
					const index = _users.findIndex((element) => { return element.id === removedUserId});
					if (index > 0)
						return [..._users.slice(0, index), ..._users.slice(index+1)];
					else if (index === 0) 
						return [..._users.slice(index+1)];
				});
			}
		}
	}

	const handleSaveChanges = () => {
		if (manageGroupParticipantsCancelSourceRef.current) return;
		const _add = [];
		const _remove = [];
		Object.entries(addedUsers).map(([key, value]) => {
			value && _add.push(key);
		})
		Object.entries(removeUsers).map(([key, value]) => {
			value && _remove.push(key);
		})
		const [cancelSource, manageGroupPartsPromise] = manageGroupParticipants(userGroupId, _add, _remove, 'user')
		manageGroupParticipantsCancelSourceRef.current = cancelSource;
		manageGroupPartsPromise
			.then((response) => {
				if (!isMounted()) return;
				manageGroupParticipantsCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((error) => {
				if (!isMounted()) return;
				manageGroupParticipantsCancelSourceRef.current = null;
				console.log(error);
			})
	}

	const handleDiscardChanges = () => {
		history.goBack();
	}

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
	];

	const searchCriteriaSkeleton = [
		// { field: "text", header: "Search" },
		{ field: "name", header: "Name" },
		{ field: "surname", header: "Surname" },
		{ field: "company_name", header: "Company Name" },
	];

	// Searching Users variables
	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}
	const allowedSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

    return (
        <div className={classes.root}>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"search"} >
                <h1>Search Users</h1>
				<UserTable
					searchCriteria={searchCriteria}
					defaultSearchCriteria={defaultSearchCriteria}
					allowedSearchCriteria={allowedSearchCriteria}

					onRowClick={ handleUserAdd } />
            </Box>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"diff"} >
                <h1>Users</h1>
				<Button variant="contained" color="primary" onClick={ handleSaveChanges }>Save</Button> 
				<Button variant="contained" color="primary" onClick={ handleDiscardChanges }>Discard</Button> 
                <ClientSideTable
                    columns={columns}
                    rows={currentUsers}
                    searchCriteriaSkeleton={searchCriteriaSkeleton}
                    defaultTableCriteria={{}}
                    rowClick={ handleUserRemove } />
            </Box>
        </div>
    );
}

export const UserGroupOwnerManagement = () => {

    const isMounted = useIsMounted();
    const manageGroupParticipantsCancelSourceRef = useRef(null);
	const [currentUsers, setCurrentUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState({});
    const [removeUsers, setRemoveUsers] = useState({});
    const classes = styles();
	const { userGroupId } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!userGroupId) return;
        const [cancelSource, userGroupOwnersPromise] = userGroupOwners({
			s: 1,
			n: 999,
			id: userGroupId,
		})
        let isActive = true;
        userGroupOwnersPromise
			.then((response) => {
                if (!isMounted() || !isActive) return;
				if (!response.success) return;
				setCurrentUsers(response.result.owners);
			})
			.catch((error) => {
                if (!isMounted() || !isActive) return;
				console.log(error);
			});
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
        }
	}, [])


	const handleUserAdd = (row) => {
		const addedUserId = row.data.id;
		setRemoveUsers((_state) => { return {..._state, [addedUserId]: undefined} })
		setAddedUsers((_state) => { return {..._state, [addedUserId]: true} })
		for (let i = 0; i < currentUsers.length; ++i) {
			let user = currentUsers[i];
			if (user.id === addedUserId) return;
		}
		setCurrentUsers((_users) => [row.data, ..._users]);
	}

	const handleUserRemove = (row) => {
		const removedUserId = row.data.id;
		setRemoveUsers((_state) => { return {..._state, [removedUserId]: true} })
		setAddedUsers((_state) => { return {..._state, [removedUserId]: undefined} })
		for (let i = 0; i < currentUsers.length; ++i) {
			let user = currentUsers[i];
			if (user.id === removedUserId) {
				setCurrentUsers((_users) => {
					const index = _users.findIndex((element) => { return element.id === removedUserId});
					if (index > 0)
						return [..._users.slice(0, index), ..._users.slice(index+1)];
					else if (index === 0) 
						return [..._users.slice(index+1)];
				});
			}
		}
	}

	const handleSaveChanges = () => {
		const _add = [];
		const _remove = [];
		Object.entries(addedUsers).map(([key, value]) => {
			value && _add.push(key);
		})
		Object.entries(removeUsers).map(([key, value]) => {
			value && _remove.push(key);
		})
        if (manageGroupParticipantsCancelSourceRef.current) return;
		const [cancelSource, manageGroupParticipantsPromise] = manageGroupParticipants(userGroupId, _add, _remove, 'owner')
        manageGroupParticipantsCancelSourceRef.current = cancelSource;
        manageGroupParticipantsPromise
			.then((response) => {
                if (!isMounted())
				if (!response.success) return;
				history.goBack();
			})
			.catch((error) => {
                if (!isMounted())
				console.log(error);
			})
	}

	const handleDiscardChanges = () => {
		history.goBack();
	}

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
	];

	const searchCriteriaSkeleton = [
		// { field: "text", header: "Search" },
		{ field: "name", header: "Name" },
		{ field: "surname", header: "Surname" },
		{ field: "company_name", header: "Company Name" },
	];

	// Searching Users variables
	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}
	const allowedSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

    return (
        <div className={classes.root}>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"search"}  >
                <h1>Search Users</h1>
				<UserTable
					searchCriteria={searchCriteria}
					defaultSearchCriteria={defaultSearchCriteria}
					allowedSearchCriteria={allowedSearchCriteria}

					onRowClick={ handleUserAdd } />
            </Box>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"diff"}  >
                <h1>Owners</h1>
				<Button variant="contained" color="primary" onClick={ handleSaveChanges }>Save</Button> 
				<Button variant="contained" color="primary" onClick={ handleDiscardChanges }>Discard</Button> 
                <ClientSideTable
                    columns={columns}
                    rows={currentUsers}
                    searchCriteriaSkeleton={searchCriteriaSkeleton}
                    defaultTableCriteria={{}}
                    rowClick={ handleUserRemove } />
            </Box>
        </div>
    );
}

export const UserGroupViewerManagement = () => {

    const isMounted = useIsMounted();
    const manageGroupParticipantsCancelSourceRef = useRef(null);
	const [currentUsers, setCurrentUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState({});
    const [removeUsers, setRemoveUsers] = useState({});
    const classes = styles();
	const { userGroupId } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!userGroupId) return;
        const [cancelSource, userGroupViewersPromise] = userGroupViewers({
			s: 1,
			n: 999,
			id: userGroupId,
		})
        let isActive = true;
        userGroupViewersPromise
			.then((response) => {
                if (!isMounted() || !isActive) return;
				if (!response.success) return;
				setCurrentUsers(response.result.viewers);
			})
			.catch((error) => {
                if (!isMounted() || !isActive) return;
				console.log(error);
			});
        return () => {
            isActive = true;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
        }
	}, [])

	const handleUserAdd = (row) => {
		const addedUserId = row.data.id;
		removeUsers[addedUserId] && delete removeUsers[addedUsers];
		setRemoveUsers((_state) => { return {..._state, [addedUserId]: undefined} })
		for (let i = 0; i < currentUsers.length; ++i) {
			let user = currentUsers[i];
			if (user.id === addedUserId) return;
		}
		setAddedUsers((_state) => { return {..._state, [addedUserId]: true} })
		setCurrentUsers((_users) => [row.data, ..._users]);
	}

	const handleUserRemove = (row) => {
		const removedUserId = row.data.id;
		setRemoveUsers((_state) => { return {..._state, [removedUserId]: true} })
		setAddedUsers((_state) => { return {..._state, [removedUserId]: undefined} })
		for (let i = 0; i < currentUsers.length; ++i) {
			let user = currentUsers[i];
			if (user.id === removedUserId) {
				setCurrentUsers((_users) => {
					const index = _users.findIndex((element) => { return element.id === removedUserId});
					if (index > 0)
						return [..._users.slice(0, index), ..._users.slice(index+1)];
					else if (index === 0) 
						return [..._users.slice(index+1)];
				});
			}
		}
	}

	const handleSaveChanges = () => {
		const _add = [];
		const _remove = [];
		Object.entries(addedUsers).map(([key, value]) => {
			value && _add.push(key);
		})
		Object.entries(removeUsers).map(([key, value]) => {
			value && _remove.push(key);
		})
        if (manageGroupParticipantsCancelSourceRef.current) return;
		const [cancelSource, manageGrouParticipantsPromise] = manageGroupParticipants(userGroupId, _add, _remove, 'viewer')
        manageGroupParticipantsCancelSourceRef.current = cancelSource;
        manageGrouParticipantsPromise
			.then((response) => {
                if (!isMounted()) return;
				if (!response.success) return;
				history.goBack();
			})
			.catch((error) => {
                if (!isMounted()) return;
				console.log(error);
			})
	}

	const handleDiscardChanges = () => {
		history.goBack();
	}

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
	];

	const searchCriteriaSkeleton = [
		// { field: "text", header: "Search" },
		{ field: "name", header: "Name" },
		{ field: "surname", header: "Surname" },
		{ field: "company_name", header: "Company Name" },
	];

	// Searching Users variables
	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}
	const allowedSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

    return (
        <div className={classes.root}>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"search"}  >
                <h1>Search Users</h1>
				<UserTable
					searchCriteria={searchCriteria}
					defaultSearchCriteria={defaultSearchCriteria}
					allowedSearchCriteria={allowedSearchCriteria}

					onRowClick={ handleUserAdd } />
            </Box>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"diff"}  >
                <h1>Viewers</h1>
				<Button variant="contained" color="primary" onClick={ handleSaveChanges }>Save</Button> 
				<Button variant="contained" color="primary" onClick={ handleDiscardChanges }>Discard</Button> 
                <ClientSideTable
                    columns={columns}
                    rows={currentUsers}
                    searchCriteriaSkeleton={searchCriteriaSkeleton}
                    defaultTableCriteria={{}}
                    rowClick={ handleUserRemove } />
            </Box>
        </div>
    );
}
