import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import _ from 'lodash';

import { createCriteria } from "components/data-tables/data-table-functions";
import { useFetchCompanyFeatures, useFetchUsers, useUpdateCompany } from "services/api";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';

import { DrawerActions, DrawerAlert, DrawerButton, DrawerButtonConfirm, DrawerConfirmMessage, DrawerHelp, DrawerTitle, useClassesLists } from 'theme/theme';

const SaveMessage = () => {
	return (
		<DrawerConfirmMessage 
			message="Are you sure you want to update the company role features?" 
			severity="warning" 
		/>
	)
}

const _ListRow = ({data, onClick}) => {
	const primary = `${data?.title}`;
	const secondary = `${data?.description}`;

	const _onClick = React.useCallback((event) => {
		// console.warn('_ListRow: _onClick', {event, data})
		onClick(event, data);
	}, [data, onClick]);

	const _id = `feature-item-${data?.id}`;

	const _checked = data?.enabled ?? true;

	const _color = React.useMemo(() => _checked ? "secondary" : "textPrimary", [_checked])

	const classes = useClassesLists();

	const _role = data?.role ?? null;

	const _icon = React.useMemo(() => {
		return _.isEqual(_role, 'ClientAdmin') ? (
			<SupervisorAccount />
		) : (
			<AccountCircle />
		)
	}, [_role])

	return (
		<ListItem dense={true} button onClick={_onClick}>
			{/* <ListItemAvatar>
				<Avatar>
					<SupervisorAccount />
				</Avatar>
			</ListItemAvatar> */}
			{/* <ListItemIcon>
				{_icon}
			</ListItemIcon> */}
			<ListItemText
				className={classes.itemText}
				primary={primary}
				secondary={secondary}
				id={_id}
				primaryTypographyProps={{color: _color, variant: 'h6'}}
			/>
			{/* <ListItemSecondaryAction>
									<IconButton edge="end" aria-label="delete">
										<DeleteIcon />
									</IconButton>
								</ListItemSecondaryAction> */}
			<ListItemSecondaryAction>
				<Switch
					// className={classes.action}
					edge="end"
					onChange={_onClick}
					checked={_checked}
					inputProps={{ 'aria-labelledby': _id }}
				/>
			</ListItemSecondaryAction>
		</ListItem>
	)
}


const createFeatures = (featureList) => {
	// this is the initial list
	// it will be overwritten by information we retrieve about a company
	const _featureList = featureList ?? [];
	// see db-design role_feature_id for these constant values
	return [{
		id: 'dvdb_admin_can_view_own_courses',
		title: 'Courses',
		description: 'Toggles capability to view own training courses.',
		enabled: _featureList.includes('dvdb_admin_can_view_own_courses'),
		role: 'ClientAdmin',
	}, {
		id: 'dvdb_driver_can_view_own_trips',
		title: 'Trips',
		description: 'Toggles capability to view own trips.',
		enabled: _featureList.includes('dvdb_driver_can_view_own_trips'),
		role: 'Driver',
	}, {
		id: 'dvdb_driver_can_view_own_courses',
		title: 'Courses',
		description: 'Toggles capability to view own training courses.',
		enabled: _featureList.includes('dvdb_driver_can_view_own_courses'),
		role: 'Driver',
	}].map((f, i) => ({...f, idx: i}))
}

const useCompanyFeatures = (companyId) => {

	const { data, isFetching } = useFetchCompanyFeatures(companyId);
	// console.warn('useCompanyFeatures', { data });

	const [features, setFeatures] = React.useState(createFeatures());

  const [alertData, setAlertData] = React.useState({
		severity: 'info',
		message: '',
		loading: false
	});

	const loading = React.useMemo(() => isFetching || alertData?.loading, [alertData, isFetching]);

	React.useEffect(() => {
		if (!_.isNil(data) && !_.isNil(data?.role_feature_list)) {
			// console.warn('useCompanyFeatures: continue here by calling setFeatures', {data})
			setFeatures(createFeatures(data?.role_feature_list));
		}
	}, [data])

	// todo: let this post to the api without  needing a save button
  const handleToggle = React.useCallback((event, data) => {
		// console.warn('useCompanyFeatures: handleToggle', {event, data})
		// event?.preventDefault();
    const newChecked = [...features];
		newChecked[data?.idx] = {
			...data,
			enabled: !data?.enabled,
		}
    setFeatures(newChecked);
  }, [companyId, features]);

	const updateCompany = useUpdateCompany({
		onSuccess: (p) => {
			setAlertData(state => ({...state, message: `Company role features successfully updated.`, severity: "success"}));
		},
		onError: (p) => {
			setAlertData(state => ({...state, message: `Could not update company role features: ${p.message}`, severity: "error"}));
		},
		onSettled: () => {
			setAlertData(state => ({...state, loading: false}));
		}
	});

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();

		setAlertData(state => ({
			...state, 
			loading: true, 
			message: 'Updating company role features...', 
			severity: 'info'
		}));

		const featureIds = features.filter(f => f.enabled).map(f => f.id);

		updateCompany.mutate({
			id: companyId,
			role_feature_list: featureIds,
		});
	}, [features, companyId]);

	return { handleToggle, features, onSubmit, loading, alertData }
}

// todo: 5DTAdmin should probably see all devices, even those marked as deleted/archived, so that they can be unarchived
const _CompanyFeatureList = ({features, handleToggle}) => {

	const classes = useClassesLists();

	// todo: consider having a matrix instead, i.e. two rows (Trips, Courses) with two columns (ClientAdmin, Driver)
	// todo: the matrix can be a table consisting of checkboxes or switches

	return (
		<List dense={false}>
			<ListSubheader className={classes.subheader}>ClientAdmin Features</ListSubheader>
			{features.filter(d => d.role === 'ClientAdmin').map((d, i) => (
				<_ListRow key={`feature-${i}-${d.id}`} data={d} onClick={handleToggle} />
			))}
			<ListSubheader className={classes.subheader}>Driver Features</ListSubheader>
			{features.filter(d => d.role === 'Driver').map((d, i) => (
				<_ListRow key={`feature-${i}-${d.id}`} data={d} onClick={handleToggle} />
			))}
		</List>
	)
}

export default ({companyId}) => {
	const history = useHistory();
	const onClickClose = () => {
		history.goBack();
	}

	const { features, handleToggle, onSubmit, alertData } = useCompanyFeatures(companyId);

	return (
		<>
			<DrawerTitle>Company Role Features</DrawerTitle>
			<DrawerHelp alert={alertData.message} severity={alertData.severity}>Select the features that should be available across company user roles.</DrawerHelp>
			<_CompanyFeatureList features={features} handleToggle={handleToggle} />
			<DrawerActions>
				<DrawerButtonConfirm details={<SaveMessage />} title="Save changes to company role features?" action="Save" color="primary" onClick={onSubmit} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} />
			</DrawerActions>
		</>
	)
}
