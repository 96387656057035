import './vehicle-groups-page.css';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { VehicleGroup } from './vehicle-group/vehicle-group';
import { VehicleGroups } from './vehicle-groups/vehicle-groups';
import { VehicleGroupCreate } from './vehicle-group-create/vehicle-group-create';
import { VehicleGroupEdit } from './vehicle-group-edit/vehicle-group-edit';
import { VehicleGroupOwnerManagement, VehicleGroupVehicleManagement, VehicleGroupViewerManagement } from './vehicle-group-manage-participants/vehicle-group-manage-participants';

export const VehicleGroupsPage = () => {

	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}`} exact>
				<VehicleGroups />
			</Route>
			<Route path={`${path}/create`} exact >
				<VehicleGroupCreate />
			</Route>
			<Route path={`${path}/:vehicleGroupId`} exact >
				<VehicleGroup />
			</Route>
			<Route path={`${path}/:vehicleGroupId/edit`} exact >
				<VehicleGroupEdit />
			</Route>
			<Route path={`${path}/:vehicleGroupId/vehicle-management`} exact >
				<VehicleGroupVehicleManagement />
			</Route>
			<Route path={`${path}/:vehicleGroupId/owner-management`} exact >
				<VehicleGroupOwnerManagement />
			</Route>
			<Route path={`${path}/:vehicleGroupId/viewer-management`} exact >
				<VehicleGroupViewerManagement />
			</Route>
		</Switch>
	);
}
