import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { UserGroup } from './user-group/user-group';
import { UserGroups } from './user-groups/user-groups';
import { UserGroupEdit } from './user-group-edit/user-group-edit';
import { UserGroupCreate } from './user-group-create/user-group-create';
import { UserGroupOwnerManagement, UserGroupUserManagement, UserGroupViewerManagement } from './user-group-diff/user-group-diff';

export const UserGroupsPage = () => {

	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}`} exact>
				<UserGroups />
			</Route>
			<Route path={`${path}/create`} exact >
				<UserGroupCreate />
			</Route>
			<Route path={`${path}/:userGroupId`} exact >
				<UserGroup />
			</Route>
			<Route path={`${path}/:userGroupId/edit`} exact >
				<UserGroupEdit />
			</Route>
			<Route path={`${path}/:userGroupId/user-management`} exact >
				<UserGroupUserManagement />
			</Route>
			<Route path={`${path}/:userGroupId/owner-management`} exact >
				<UserGroupOwnerManagement />
			</Route>
			<Route path={`${path}/:userGroupId/viewer-management`} exact >
				<UserGroupViewerManagement />
			</Route>
		</Switch>
	);
}
