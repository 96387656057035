import React from 'react';

import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import { DrawerActions, DrawerButton, DrawerButtonConfirm, DrawerConfirmMessage, DrawerHelp, DrawerSecretField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useUpdatePassword } from 'modules/useGlobalStore';

const _titleText = `Change Password`;
const _helpText = `Use this form to update your password.`;

const SaveMessage = () => {
	return (
		<DrawerConfirmMessage 
			message="Are you sure you want to update your password." 
			severity="warning" 
		/>
	)
}

const _EditPassword = ({formData, setFormData, ...props}) => {
	const onInput = e => setFormData(state => ({...state, oldPassword: e.target.value}));
	return (
		<DrawerSecretField id="current_password" label="Current Password" value={formData?.oldPassword} onInput={onInput} onChange={onInput} required {...props} />
	)
}

const _EditNewPassword = ({formData, setFormData, ...props}) => {
	const onInput = e => setFormData(state => ({...state, newPassword: e.target.value}));
	return (
		<DrawerSecretField id="new_password" label="New Password" value={formData?.newPassword} onInput={onInput} onChange={onInput} required {...props} />
	)
}

const _EditConfirmPassword = ({formData, setFormData, ...props}) => {
	const onInput = e => setFormData(state => ({...state, confirmPassword: e.target.value}));
	const error = React.useMemo(() => !_.isEqual(formData.newPassword, formData.confirmPassword), [formData]);
	return (
		<DrawerSecretField id="confirm_password" label="Confirm Password" value={formData?.confirmPassword} onInput={onInput} onChange={onInput} error={error} required {...props} />
	)
}

const _ChangePassword = ({userId}) => {

	const classes = useClassesFormStyle();

  const [formData, setFormData] = React.useState({
		userId: userId,
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
		alertSeverity: 'info',
		alertMessage: '',
		loading: false,
	});

	const history = useHistory();

	const disabled = React.useMemo(() => formData.loading 
		|| _.isEmpty(formData.oldPassword)
		|| _.isEmpty(formData.newPassword)
		|| _.isEmpty(formData.confirmPassword)
		|| !_.isEqual(formData.newPassword, formData.confirmPassword), [formData])

	const { status, updatePassword } = useUpdatePassword(formData.oldPassword, formData.newPassword, formData.confirmPassword);

	React.useEffect(() => setFormData(state => ({...state, alertMessage: status.message, alertSeverity: status.error ? 'error' : 'success' })), [status])

	const onClickClose = (event) => {
		event?.preventDefault();
		history.goBack();
	}

	const onSubmit = (event) => {
		event?.preventDefault();
		updatePassword()
	}

	return (
		<form noValidate classes={classes.body}>
			<DrawerTitle>{_titleText}</DrawerTitle>
			<DrawerHelp alert={formData.alertMessage} severity={formData.alertSeverity}>{_helpText}</DrawerHelp>
			<_EditPassword formData={formData} setFormData={setFormData} autoFocus={true} />
			<_EditNewPassword formData={formData} setFormData={setFormData} />
			<_EditConfirmPassword formData={formData} setFormData={setFormData} />
			<DrawerActions>
				<DrawerButtonConfirm details={<SaveMessage />} title="Update password?" action="Update Password" color="primary" onClick={onSubmit} disabled={disabled} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} />
			</DrawerActions>
		</form>
	)
}

export default ({userId}) => {
	return (
		<_ChangePassword userId={userId} />
	)
}
