import "./vehicles-page.css";
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Vehicle } from "./vehicle/vehicle";
import { Vehicles } from "./vehicles/vehicles";
import { VehicleEdit } from "./vehicle-edit/vehicle-edit";
import { VehicleCreate } from "./vehicle-create/vehicle-create";

export const VehiclesPage = () => {
	
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}`} exact>
				<Vehicles />
			</Route>
			<Route path={`${path}/create`} exact >
				<VehicleCreate />
			</Route>
			<Route path={`${path}/:vehicleId`} exact >
				<Vehicle />
			</Route>
			<Route path={`${path}/:vehicleId/edit`} exact >
				<VehicleEdit />
			</Route>
		</Switch>
	);
}
