import "./data-tables.css";
import React, { useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';

import { vehicleGroupOwners, vehicleGroups, vehicleGroupVehicles, vehicleGroupViewers } from "services/vehicle-group";

import { ServerSideTable } from 'components/table/table';

import { useFetchVehicleGroups, useFetchVehiclesFull } from 'services/api';

import { makeStyles } from '@material-ui/core';

import { generateDefaultCriteria, generateDefaultSearchCriteria, generateDefaultSearchCriteriaSkeleton, generateCriteria, generateSearchCriteria } from "./data-table-functions";
import { WARN_USE_EFFECT, WARN_USE_EFFECT_CLEAN } from "shared-functions/shared-functions";
import { useIsMounted } from "shared-hooks/useIsMounted";

// https://v4.mui.com/components/data-grid/style/#styling-cells
const onlineCellClassname = (online, classes) =>
	clsx(classes.onlineStatus, {
		[classes.onlineStatusYes]: online,
		[classes.onlineStatusNo]: !online,
	});

const styles = makeStyles((theme) => ({
	onlineStatus: {
	},
	onlineStatusYes: {
		backgroundColor: 'lightgreen',
	},
	onlineStatusNo: {
		// backgroundColor: 'red',
	},
}));

export const VehicleTable = ({ defaultCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const classes = styles();

	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, null),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});

	const vehicleCriteria = React.useMemo(() => ({
			...generateCriteria(tableCriteria, null),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
	}), [tableCriteria, allowedSearchCriteria]);

	const { vehicleList, vehicleCount, isFetching } = useFetchVehiclesFull(vehicleCriteria);

	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		// name: { field: "name", header: "Name" },
		// type: { field: "type", header: "Type" },
		// make: { field: "make", header: "Make" },
		// model: { field: "model", header: "Model" },
		// registration_number: { field: "registration_number", header: "Reg Nr" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "device_status", headerName: "Online", 
			// https://v4.mui.com/components/data-grid/columns/#column-types
			type: "boolean" ,
			// https://v4.mui.com/components/data-grid/columns/#value-getter
			valueGetter: (params) => params.value === 'online',
			cellClassName: (params) => onlineCellClassname(params.value, classes)
		},
		{ field: "name", headerName: "Name", width: 200 },
		// { field: "type", headerName: "Type", width: 100 },
		{ field: "registration_number", headerName: "Reg Nr", width: 100 },
		{ field: "make", headerName: "Make", hide: true, width: 250 },
		{ field: "model", headerName: "Model", hide: true, width: 250 },
		{ field: "description", headerName: "Description", width: 250,
			valueGetter: (params) => `${params.getValue(params.id, 'make')} ${params.getValue(params.id, 'model')}`,
		},
		// { field: "company_name", headerName: "Company Name", width: 250 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={vehicleList}
				loading={isFetching}
				total={vehicleCount}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}
				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const VehicleGroupTable = ({ defaultCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, null),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});

	const vehicleGroupCriteria = React.useMemo(() => ({
			...generateCriteria(tableCriteria, null),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
	}), [tableCriteria, allowedSearchCriteria]);

	const { vehicleGroupList, vehicleGroupCount, isFetching } = useFetchVehicleGroups(vehicleGroupCriteria);

	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		group_name: { field: "group_name", header: "Name" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 250 },
		{ field: "num_vehicles", headerName: "Total Vehicles", width: 150 },
		{ field: "num_owners", headerName: "Total Owners", width: 140 },
		{ field: "num_viewers", headerName: "Total Viewers", width: 150 },
		{ field: "is_owner", headerName: "Is Owner", width: 120 },
		{ field: "is_viewer", headerName: "Is Viewer", width: 120 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={vehicleGroupList}
				loading={isFetching}
				total={vehicleGroupCount}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const VehicleGroupVehiclesTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		name: { field: "name", header: "Name" },
		registration_number: { field: "registration_number", header: "Reg Nr" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [cancelSource, vehicleGroupVehiclesPromise] = vehicleGroupVehicles(data)
		let isActive = true;
		vehicleGroupVehiclesPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				if (!response.success || !response.result) return;
				const users = response.result.vehicles;
				setTotal(parseInt(response.result.count));
				setData(users);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
			});
		return () => {
			console.log(WARN_USE_EFFECT_CLEAN);
			// isActive = false;
			// cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		// { field: "type", headerName: "Type", width: 200 },
		// { field: "make", headerName: "Make", width: 150 },
		// { field: "model", headerName: "Model", width: 250 },
		{ field: "registration_number", headerName: "Reg Nr", width: 250 },
		// { field: "company_name", headerName: "Company Name", width: 250 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const VehicleGroupOwnersTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		name: { field: "name", header: "Name" },
		surname: { field: "surname", header: "Surname" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [cancelSource, vehicleGroupOwnersPromise] = vehicleGroupOwners(data)
		let isActive = true;
		vehicleGroupOwnersPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				if (!response.success || !response.result) return;
				const users = response.result.owners;
				setTotal(parseInt(response.result.count));
				setData(users);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
			});
		return () => {
			console.log(WARN_USE_EFFECT_CLEAN);
			// isActive = false;
			// cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
		// { field: "role", headerName: "Role", width: 150 },
		// { field: "email", headerName: "Email", width: 250 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const VehicleGroupViewersTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		name: { field: "name", header: "Name" },
		surname: { field: "surname", header: "Surname" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [cancelSource, vehicleGroupViewersPromise] = vehicleGroupViewers(data)
		let isActive = true;
		vehicleGroupViewersPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				if (!response.success || !response.result) return;
				const users = response.result.viewers;
				setTotal(parseInt(response.result.count));
				setData(users);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				console.log(err);
			});
		return () => {
			console.log(WARN_USE_EFFECT_CLEAN);
			// isActive = false;
			// cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
		// { field: "role", headerName: "Role", width: 150 },
		// { field: "email", headerName: "Email", width: 250 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}
