import "./data-tables.css";
import React, { useCallback, useState } from 'react';

import _ from 'lodash';

import { ServerSideTable } from 'components/table/table';

import { useFetchDevices } from 'services/api';

import { generateDefaultCriteria, generateDefaultSearchCriteria, generateDefaultSearchCriteriaSkeleton, generateCriteria, generateSearchCriteria } from "./data-table-functions";

import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import { toDisplayTimeLong } from "shared-functions/shared-functions";

// https://v4.mui.com/api/data-grid/grid-col-def/#main-content
// https://v4.mui.com/components/data-grid/columns/#render-cell
// https://v4.mui.com/components/data-grid/columns/#column-types
// const renderOnline = (params) => <h1>{JSON.stringify(params.value)}</h1>;

// https://v4.mui.com/components/data-grid/columns/#value-formatter
// const formatOnline = (params) => valueFormatter.format(Boolean(params.value));


// https://v4.mui.com/components/data-grid/style/#styling-cells
const onlineCellClassname = (online, classes) => {
	// console.log('onlineCellClassname', online)
	return clsx(classes.onlineStatus, {
		[classes.onlineStatusYes]: online,
		[classes.onlineStatusNo]: !online,
	});
}

const styles = makeStyles((theme) => ({
	onlineStatus: {
	},
	onlineStatusYes: {
		backgroundColor: 'lightgreen',
	},
	onlineStatusNo: {
		// backgroundColor: 'red',
	},
}));

export const DeviceTable = ({ defaultCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const classes = styles();

	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, null),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});

	const deviceCriteria = React.useMemo(() => ({
			...generateCriteria(tableCriteria, null),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
	}), [tableCriteria, allowedSearchCriteria]);

	const { deviceList, deviceCount, isFetching } = useFetchDevices(deviceCriteria);

	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		// serial_number: { field: "serial_number", header: "Serial Number" },
		// currently_online: { field: "currently_online", header: "Currently Online" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);


	const columns = [
		{ field: "status", headerName: "Online",
			// renderCell: (params) => renderOnline(params),
			// https://v4.mui.com/components/data-grid/columns/#column-types
			type: 'boolean',
			// https://v4.mui.com/components/data-grid/columns/#value-getter
			valueGetter: (params) => params.value === 'online',
			// valueFormatter: (params) => formatOnline(params),
			cellClassName: (params) => onlineCellClassname(params.value, classes)
		},
		{ field: "serial_number", headerName: "Serial Number", width: 300 },
		{ field: "last_online_time", headerName: "Last Online", width: 220,
			valueFormatter: (params) => {
				return toDisplayTimeLong(params.value);
			},
		 },

		{ field: "vehicle_linked", headerName: "Vehicle Linked", type: "boolean", hide: true },
		{ field: "vehicle_name", headerName: "Vehicle Name", hide: true },
		{ field: "vehicle_registration_number", headerName: "Vehicle Reg Nr", hide: true },
		{ field: "vehicle_description", headerName: "Vehicle", width: 250, 
			valueGetter: (params) => {
				const vehicle_linked = params.getValue(params.id, 'vehicle_linked') ?? false;
				const vehicle_name = params.getValue(params.id, 'vehicle_name');
				const vehicle_registration_number = params.getValue(params.id, 'vehicle_registration_number');
				// console.warn('vehicle_linked', { vehicle_linked, vehicle_name, vehicle_registration_number })
				return _.isEqual(vehicle_linked, true) ? `${vehicle_name} (${vehicle_registration_number})` : '';
			},
		},
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={deviceList}
				loading={isFetching}
				total={deviceCount}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}
