import "./admin-courses.css";
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from "@material-ui/core";
import { AdminCourseTable } from "components/data-tables/courses-tables";

export const AdminCourses = () => {

	const { url } = useRouteMatch();
	const history = useHistory();

	const handleRowClick = (data) => {
		history.push(`${url}/${data.data.id}`, { [data.data.id]: `${data.data.title}`});
	}

	const handleCreate = () => {
		history.push(`${url}/create`);
	}

	const defaultSearchCriteria = {
		title: '',
	}

	const searchCriteria = {
		title: '',
	}

	return (
		<div className={"course-table-container"}>
			<h1>Courses</h1>
			<Button variant="contained" color="primary" onClick={ handleCreate }>Create</Button>
			<AdminCourseTable
				searchCriteria={searchCriteria}
				defaultSearchCriteria={defaultSearchCriteria}

				onRowClick={ handleRowClick } />
		</div>
	);
}
