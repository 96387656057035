import React, { useCallback, useState } from 'react';
import { useHistory, useRouteMatch, Route, Switch, useParams } from 'react-router-dom';

import Button from "@material-ui/core/Button";

import AdminUserCreate from '../admin-user-create';

import { CrudDrawer } from "components/drawers/crud-drawer";
import ResetPassword from "components/edit-view/reset-password";
import { EditUserView } from "components/edit-view/edit-user";
import { UserTable } from "components/data-tables/user-data-tables";
import { useBookmark } from "modules/useTokenStore";

const defaultCriteria = {
	orderBy: 'name',
}

const defaultSearchCriteria = {
	name: '',
	surname: '',
	email: '',
	role: '',
	company_name: '',
}

const searchCriteria = {
	name: '',
	surname: '',
	email: '',
	role: '',
}

// const columns = [
// 	{ field: "email", headerName: "Email", width: 230 },
// 	{ field: "description", headerName: "Full Name", width: 300, 
// 		valueGetter: (params) => {
// 			const name = params.getValue(params.id, 'name');
// 			const surname = params.getValue(params.id, 'surname');
// 			return `${name} (${surname})`;
// 		},
// 	},
// 	// { field: "status", headerName: "Status", width: 150 },
// 	{ field: "company_name", headerName: "Company", width: 150 },
// ];

// bookmarkId is a unique id for this component: https://www.uuidgenerator.net/
const bookmarkId = 'ac57757e-e7a8-43e9-9007-ece319530dc6';

// todo: 5DTAdmin should probably see all devices, even those marked as deleted/archived, so that they can be unarchived
const AdminUserList = () => {
	// console.warn('AdminUserList')

	const { path, url } = useRouteMatch();
	const history = useHistory();

	const bookmarkCriteria = useBookmark(bookmarkId, defaultCriteria);

	const handleRowClick = React.useCallback((data) => {
		history.push(`${url}/${data.data.id}/edit`)
	}, [url, history]);

	const handleCreate = React.useCallback(() => {
		history.push(`${url}/create`);
	}, [url, history]);

	return (
		<>
			<h1>Users</h1>
			<Button variant="contained" color="primary" onClick={handleCreate}>Register</Button>
			<UserTable
				searchCriteria={searchCriteria}
				defaultSearchCriteria={defaultSearchCriteria}
				defaultCriteria={bookmarkCriteria}
				bookmarkId={bookmarkId}
				onRowClick={handleRowClick} />
		</>
	);
}

const _EditUser = () => {
	const { id } = useParams();
	return (
		<>
			<CrudDrawer>
				<EditUserView userId={id} />
			</CrudDrawer>
		</>
	)
}

const _ResetPassword = () => {
	const { id } = useParams();
	return (
		<>
			<CrudDrawer>
				<ResetPassword userId={id} />
			</CrudDrawer>
		</>
	)
}

export default () => {
	const { path } = useRouteMatch();
	// console.warn('admin-user-list', {
	// 	path, url
	// })

	return (
		<>
			<Switch>
				<Route path={path}>
					<AdminUserList />
					<Route path={`${path}/create`} exact>
						<CrudDrawer>
							<AdminUserCreate />
						</CrudDrawer>
					</Route>
					<Route path={`${path}/:id/edit`} exact>
						<_EditUser />
					</Route>
					<Route path={`${path}/:id/edit/reset-password`} exact>
						<_ResetPassword />
					</Route>
				</Route>
			</Switch>
		</>
	)
}
