import { axiosPostRequest } from './api-request';

function createCourse(title, is_default, course) {
	try {
        let formData = new FormData();
        formData.append("title", title);
        formData.append("is_default", is_default);
        formData.append("course", course);
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/course/create`, formData);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Course creation failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function deleteCourse(data) {
	try {
        const body = { id: data };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/course/delete`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Course deletion failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function getCourseLessons(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/course/get/lessons`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Lessons fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function getCourse(data) {
	try {
        const body = { id: data };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/course/get`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Course fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function getCourses(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/course`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Courses fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export {
	createCourse,
	deleteCourse,
    getCourseLessons,
    getCourse,
    getCourses,
};
