import _ from 'lodash';

// https://www.npmjs.com/package/zustand
// https://github.com/pmndrs/zustand/wiki/Splitting-the-store-into-separate-slices
// https://github.com/pmndrs/zustand/wiki/Flux-inspired-practice
// https://github.com/pmndrs/zustand/wiki/Gotchas
// https://github.com/pmndrs/zustand/wiki/3rd-Party-Libraries
// https://github.com/pmndrs/zustand/wiki/Practice-with-no-store-actions
// https://github.com/pmndrs/zustand/wiki/Updating-nested-state-object-values
import create from "zustand";

import { persist } from "zustand/middleware";

// During development, we often make changes to the hooks specified in
// useLocalCacheStore.js. By moving the store in its own file, we keep it safe from
// hot-reload that re-instantiates the store.
export default create(persist(
  () => ({
    localVersion: '0.0.0',
  }), {
		name: 'local',
		getStorage: () => localStorage,
	}));
