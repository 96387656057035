import './navigation-bar.css';
import React, { useEffect, useState } from 'react';
import { useHistory, NavLink, useLocation } from 'react-router-dom';

import { AppBar, CssBaseline, Drawer, IconButton, List, ListItem, ListItemText, makeStyles, Menu, MenuItem, Toolbar, Typography, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Refresh from '@material-ui/icons/Refresh';
import Badge from '@material-ui/core/Badge';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { useScreenType } from 'shared-hooks/useScreenTypes';
import clsx from 'clsx';
import { setUser, useTokenStore, useUserStore } from 'modules/useTokenStore';

import { useQueryClient } from 'react-query'
import { newLiveId, doShowAbout, useLiveStatus, useFocusNavBar, hideNavBar, toggleNavBar, useVersionUpdateInfo, useGetNoticeCount, useVersionToast } from 'modules/useGlobalStore';
import { AboutDialog } from 'components/dialogs/dialogs';
import _ from 'lodash';

// https://stackoverflow.com/questions/67435111/how-to-change-title-in-material-ui-toolbar-on-page-navigation-in-react-js-web-ap
// https://trello.com/c/JlLuA5e8
const titles = {
  "/": "DriveVUE",
  "/dashboard": "DriveVUE",
  "/dashboard/live-view": "Live View",
  "/dashboard/users": "Users",
  "/dashboard/user-groups": "User Groups",
  "/dashboard/vehicles": "Vehicles",
  "/dashboard/vehicle-groups": "Vehicle Groups",
  "/dashboard/devices": "Devices",
  "/dashboard/qr-code": "QR-Code",
  "/dashboard/training": "Training",
};

const badgeAnchorOrigin = {
	vertical: 'top',
	// horizontal: 'left',
	horizontal: 'right',
};

const StyledBadge = withStyles((theme) => ({
  // badge: {
		// left: 20,
		// width: '100%',
    // right: -3,
		// left: 'auto',
    // top: 13,
		// margin: theme.spacing(1),
		// padding: theme.spacing(1),
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0px 4px',
    // padding: theme.spacing(0, 1, 1, 0),
  // },
	badge: {
    backgroundColor: theme.palette.primary.badge,
    // color: theme.palette.text.primary,
    // color: theme.palette.secondary.main,
    color: theme.palette.background.paper,
    // color: '#44b700',
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    boxShadow: `0 0 0 2px ${theme.palette.primary.badge}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
			// border: `1px solid ${theme.palette.text.primary}`,
			border: `1px solid ${theme.palette.primary.badge}`,
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);
 
const drawerWidth = 240;
const styles = makeStyles((theme) => ({
	// https://github.com/mui/material-ui/issues/21639
	item: {
		overflow: 'visible',
	},
	itemText: {
		padding: theme.spacing(1),
		// margin: theme.spacing(1),
	},
	root: {
		display: "flex"
	},
	// todo: let the appBar be red when logged in as 5dtadmin
	appBar: {
		// backgroundColor: "yellow",
		// background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
		background: theme.palette.primary.mainGradientAppBar,
    // background: styledBy('color', {
    //   default: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    //   blue: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    // }),
    // borderRadius: 3,
    border: 0,
    color: 'white',
    // height: 48,
    // padding: '0 30px',
		// boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    // boxShadow: styledBy('color', {
    //   default: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //   blue: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    // }),

		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: 36
	},
	hide: {
		display: "none"
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap"
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: "hidden",
		width: 0,
		[theme.breakpoints.up("sm")]: {
			width: 0
		}
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar
	},
	contentM: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 0, 2, 0),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(0, 2, 2, 2),
	},
	navLink: {
		textDecoration: "none",
		color: "black",
	},
	account: {
		right: 20,
		position: "absolute",
	},
	refresh: {
		right: 60,
		position: "absolute",
	},
	status: {
		right: 100,
		position: "absolute",
	},
	// https://www.w3schools.com/colors/colors_names.asp
	statusOnline: {
		// color: "lightgreen",
		// color: "greenyellow",
		// color: "chartreuse",
		// color: "forestgreen",
		color: "limegreen",
	}
}));


const _AboutMenuItem = React.forwardRef(({key, onClose}, ref) => {

	// https://stackoverflow.com/questions/62645556/react-warning-function-components-cannot-be-given-refs

	// const MyLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

	const handleAbout = (event) => {
		// console.log('handleAbout', event)
		event?.preventDefault();
		onClose();
		doShowAbout();
	}

	const { newVersion } = useVersionUpdateInfo();
	const classes = styles();

	return (
			<MenuItem className={classes.item} key={key} onClick={handleAbout} ref={ref}>
				<StyledBadge overlap="circular" anchorOrigin={badgeAnchorOrigin} variant="dot" color="primary" invisible={!newVersion}>
					<Typography className={classes.itemText}>
						About
					</Typography>
				</StyledBadge>
			</MenuItem>
	);
});

const _ProfileButton = ({onClick}) => {
	const classes = styles();
	const notifications = useGetNoticeCount();
	useVersionToast();
	return (
		<Tooltip title="Profile">
				<IconButton
					// color="inherit"
					aria-label="profile"
					aria-controls="simple-menu"
					aria-haspopup="true"
					className={classes.account}
					onClick={onClick}
				>
				<StyledBadge color="primary" badgeContent={notifications}>
					<AccountCircleIcon />
				</StyledBadge>
			</IconButton>
		</Tooltip>
	)
}

export const NavigationBar = ({ paths, altPaths }) => {

	// todo: detect admin page, then show red header and disable mqtt status reporting

	const screenType = useScreenType();

	const drawerState = useFocusNavBar();
	const [menuState, setMenuState] = useState(null);
	const classes = styles();
	const history = useHistory();

	// const { tooltip, status, connected } = useCaptureLiveDeviceTrips();

  const { tooltip, status, connected } = useLiveStatus();

	// Get QueryClient from the context
	const queryClient = useQueryClient()

	const drawerType = React.useMemo(() => screenType === "phone" ? "temporary" : "persistent", [screenType]);

	const handleDrawerToggle = () => {
		toggleNavBar();
	}

	const handleDrawerClose = () => {
		hideNavBar();
	}

	const handleStatusClicked = async (event) => {
		console.log('handleStatusClicked', status);
		if (status === 'offline') {
			newLiveId();
		}
	};

	const handleRefreshClicked = React.useCallback((event) => {
		queryClient.invalidateQueries();
	}, [queryClient]);

	const handleMenuOpen = (event) => {
		setMenuState(event.currentTarget);
	}

	const handleMenuClose = React.useCallback(() => {
		setMenuState(null);
	}, [setMenuState]);

	const loadPage = React.useCallback((url) => {
		setMenuState(null);
		history.push(url);
	}, [setMenuState, history])

	const handleLogout = () => {
		useTokenStore.getState().setTokens("");
		setUser(null);
		history.push("/login");
	}

	const handleIsLinkActive = (match, location) => {
		return match && location.pathname.indexOf(match.url) === 0;
	}

	// https://stackoverflow.com/questions/67435111/how-to-change-title-in-material-ui-toolbar-on-page-navigation-in-react-js-web-ap
	const location = useLocation();
	const [title, setTitle] = useState(titles["/"]);
	useEffect(() => {
		const newTitle = titles[location.pathname] ?? titles["/"];
		setTitle(newTitle);
	}, [location.pathname]);

  // Warning: useMemo received a final argument during this render, but not
  // during the previous render. Even though the final argument is optional, its
  // type cannot change between renders
	const altMenuItems = React.useMemo(() => {
		if (_.isNil(altPaths)) {
			return null
		}
		return Object.entries(altPaths).map(([key, value], index) => {
			return (
				<MenuItem className={classes.item} onClick={e => loadPage(value)} key={key}><Typography className={classes.itemText}>{key}</Typography></MenuItem>
			)
		})
	}, [altPaths, classes, handleMenuClose, loadPage])

	return (<>
		<CssBaseline />
		<AppBar
			position="fixed"
			className={classes.appBar}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={ handleDrawerToggle }
					edge="start"
					className={classes.menuButton}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" noWrap>
					{title}
				</Typography>
        <Tooltip title={tooltip}>
					<IconButton
						// color="inherit"
						aria-label="status"
						aria-controls="simple-menu"
						aria-haspopup="true"
						// className={classes.status}
						className={clsx(classes.status, {
							[classes.statusOnline]: connected,
						})}
						onClick={ handleStatusClicked }
					>
						<Brightness1Icon />
					</IconButton>
        </Tooltip>
        <Tooltip title="Refetch">
					<IconButton
						// color="inherit"
						aria-label="refresh"
						aria-controls="simple-menu"
						aria-haspopup="true"
						className={classes.refresh}
						onClick={ handleRefreshClicked }
					>
						<Refresh />
					</IconButton>
        </Tooltip>
				<_ProfileButton onClick={handleMenuOpen} />
				<Menu
					anchorEl={menuState}
					keepMounted
					getContentAnchorEl={null}
					open={Boolean(menuState)}
					// anchorOrigin={{
					// 	vertical: 'bottom',
					// 	horizontal: 'center',
					// }}
					// transformOrigin={{
					// 	vertical: 'top',
					// 	horizontal: 'center',
					// }}

					onClose={ handleMenuClose } 
				>
					<_AboutMenuItem key="about" onClose={handleMenuClose} />
					{altMenuItems}
					<MenuItem className={classes.item} key="logout" onClick={handleLogout}><Typography className={classes.itemText}>Logout</Typography></MenuItem>
				</Menu>
				<AboutDialog />
			</Toolbar>
		</AppBar>
		<Drawer
			variant={ drawerType }
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: drawerState,
				[classes.drawerClose]: !drawerState
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: drawerState,
					[classes.drawerClose]: !drawerState
				})
			}}
			open={drawerState}

			onClose={ handleDrawerClose }
			// onOpen={ handleDrawerOpen }
		>
			{
				<div className={classes.toolbar} />
			}
			<List>
				{
					Object.entries(paths).map(([key, value]) => {
						return (
							<NavLink key={key} to={value} className={classes.navLink} activeStyle={ {color: 'black', backgroundColor: 'lightgray'} } activeClassName="active" isActive={ handleIsLinkActive }>
								<ListItem key={key}>
									<ListItemText primary={key} />
								</ListItem>
							</NavLink>
						)
					})
				}
			</List>
		</Drawer>
	</>)
}
