
function getAuthHeader() {
    return {
        Authorization: 'Bearer ' + localStorage.token
    }
}

export {
	getAuthHeader
}
