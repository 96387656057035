import './vehicle-trips.css';
import React, { useEffect, useState } from 'react';

import { MapContainer } from 'components/map/map-container';
import { MapPath } from 'components/map/map-paths/map-path';
import { MapAlert } from 'components/map/map-alerts/map-alert';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useParams } from 'react-router-dom';
import { TripTable } from 'components/data-tables/trip-event-data-tables';
import { EventFilterMapControl, EventMapControl, HistoricTripMapControl } from 'components/map/map-controls/map-controls';
import { useStateEventTypeFilters, useTripMapEventList, useTripMapPath, useEventMarkersFiltered, useTripMapPathStart, useTripMapPathFinish, useSelectedTripId, setSelectedHistoricTrip } from 'modules/useGlobalStore';
import { useFetchTrip, useFetchTripAlerts } from 'services/api';

export const VehicleTripContainer = () => {

	const [state, setState] = useState('list');
	const [map, setMap] = useState(null);
	const { vehicleId } = useParams();

	const selectedTripId = useSelectedTripId();

  const [typeFilters, setTypeFilters] = useStateEventTypeFilters();

  const { data: tripData } = useFetchTrip(selectedTripId)
  const { data: eventData } = useFetchTripAlerts(selectedTripId);
	// console.warn('VehicleTripContainer', {eventData})

  const tripPath = useTripMapPath(tripData?.path ?? []);
  const eventListDefault = useTripMapEventList(tripData, eventData?.alerts ?? []);
  const eventList = useEventMarkersFiltered(eventListDefault, typeFilters);
  const tripStart = useTripMapPathStart(tripPath);
  const tripFinish = useTripMapPathFinish(tripPath);

	useEffect(() => {
		map && map.panTo(tripStart);
	}, [map, tripStart])

	
	const handleEventFilterChanged = (param) => {
		setTypeFilters(param);
	}

	const handleRowClick = (params) => {
		setState('map');
		setSelectedHistoricTrip(params.data.id);
	};

	const handleMapLoad = (param) => { 
		setMap(param);
	}

	const handleExitTripView = () => {
		setState('list')
	}


	return <>
		{
			state === 'list'
			?	<TripTable
					onRowClick={ handleRowClick }
					searchMode="vehicle"
					vehicleId={vehicleId} />
			:
			<>
				<IconButton
					color="default"
					aria-controls="simple-menu"
					aria-haspopup="true"
					// style={ { float: 'left', borderRadius: 2 } }
					// className={'closebtn'}
					
					onClick={ handleExitTripView }
				>
					<ArrowBackIcon />
				</IconButton>
				<MapContainer height='calc(100% - 50px)' center={ tripStart } zoom={13} onLoad={ handleMapLoad }>
					<MapPath path={tripPath} /> 
						<EventFilterMapControl typeFilters={typeFilters} onFilterChange={ handleEventFilterChanged } />
						<HistoricTripMapControl verticalIndex={0} eventList={eventList} />
						<EventMapControl eventList={eventList} />
						{ tripFinish && <MapAlert position={tripFinish} type={'finish'} /> }
						{ tripStart && <MapAlert position={tripStart} type={'start'} /> }
				</MapContainer>
			</>
		}
	</>
}
