import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import Typography from '@material-ui/core/Typography';

import semverDiff from 'semver-diff';

import _ from 'lodash';

// https://github.com/streamich/react-use/blob/master/docs/useThrottle.md
// https://github.com/streamich/react-use/blob/master/docs/useDebounce.md
// https://github.com/streamich/react-use/blob/master/docs/useTimeout.md
// https://github.com/streamich/react-use/blob/master/docs/useTimeoutFn.md
// https://github.com/streamich/react-use/blob/master/docs/useInterval.md
// https://github.com/streamich/react-use/blob/master/docs/useHarmonicIntervalFn.md
// https://github.com/alibaba/hooks
import { useShallowCompareEffect, useDeepCompareEffect, useDebounce, useInterval, useHarmonicIntervalFn, useThrottle, useThrottleFn } from 'react-use';

import { DateTime, Duration, Interval, Settings } from "luxon";

// https://immerjs.github.io/immer/update-patterns/
import produce from 'immer';

// The mqtt-react-hooks library is really poor quality.
// Trying mqtt-reactjs-hooks library to see if it resolves some of the reconnection issues.
// mqtt-reactjs-hooks does not have parserMethod prop for Connector component.
// https://github.com/VictorHAS/mqtt-react-hooks/blob/52340c09b16570e399ac3b23d2c4e461d5155d20/lib/Connector.tsx#L12
// https://github.com/VictorHAS/mqtt-react-hooks/blob/c4d1fdc82ec27dfb33807817abe91d4eb9368f66/lib/Connector.tsx
// The creator of the 'mqtt-react-hooks' needs to fix 'connectionStatus' not updating
// when the status changes
// https://github.com/VictorHAS/mqtt-react-hooks/issues/39
// https://github.com/VictorHAS/mqtt-react-hooks/issues/36
// https://github.com/VictorHAS/mqtt-react-hooks/issues/20
// https://github.com/VictorHAS/mqtt-react-hooks/issues/14
// https://github.com/VictorHAS/mqtt-react-hooks/issues/46
// https://github.com/VictorHAS/mqtt-react-hooks/issues/13
// https://www.npmjs.com/package/mqtt-react-hooks
// https://www.npmjs.com/package/mqtt-reactjs-hooks
// https://www.npmjs.com/package/mqtt
// https://github.com/mqttjs/MQTT.js
// https://www.npmjs.com/package/mqtt-reactjs-hooks
// https://github.com/hadifikri/mqtt-reactjs-hooks
// https://github.com/VictorHAS/mqtt-react-hooks
// https://github.com/VictorHAS/mqtt-react-hooks/blob/c4d1fdc82ec27dfb33807817abe91d4eb9368f66/lib/Connector.tsx
// https://github.com/mqttjs/async-mqtt
// https://github.com/VictorHAS/mqtt-react-hooks/commit/0ccf3e1ceeaf40358412b7ac9dd4879587ff5580
// git://github.com/VictorHAS/mqtt-react-hooks.git#0ccf3e1ceeaf40358412b7ac9dd4879587ff5580
// git+https://github.com/VictorHAS/mqtt-react-hooks.git#0ccf3e1ceeaf40358412b7ac9dd4879587ff5580
// https://github.com/VictorHAS/mqtt-react-hooks/tree/4840bd53959752cf25babf6e0f229acc40be685f
// todo: the latest mqtt-react-hooks solves reconnect problem but it forces react-18, which we don't want to use now
// todo: the mqtt-reactjs-hooks lib solves reconnect problem but it removes support for parserMethod, which we require
// todo: option 1: remove dependency on mqtt-react-hooks, and implement your own hooks
// todo: option 2: use current mqtt-react-hooks but accept its limitations and reconnect issues and implement a workaround
// Client web-app-028-admin@5dt.com already connected, closing old connection.
// 1657794626: New client connected from ::ffff:10.0.3.15 as web-app-028-admin@5dt.com (p2, c1, k60, u'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjIyZjEzODUwLTI2N2YtNGY3ZC05OTk5LTZlNzQ3YTk1YWY0ZiIsImVtYWlsIjoiYWRtaW5ANWR0LmNvbSIsInJvbGUiOiJDbGllbnRBZG1pbiIsImNvbXBhbnlfaWQiOiIxZjcyMDliZi1mNzE0LTQ5MDQtODcyMS0yMmM1OGMzYWZjYmEiLCJkYXRlX2dlbmVyYXRlZCI6MTY1Nzc5NDU4OTg4MCwiaWF0IjoxNjU3Nzk0NTg5LCJleHAiOjE2NTc4MjMzODl9.TSX3dNQgztXM6kkSwC3U6FLIbDe68OJxGHDxYQBK9rg').
// 1657794626: Socket error on client web-app-028-admin@5dt.com, disconnecting.
// import { useMqttState, useSubscription } from 'mqtt-react-hooks';
import { useMqttState, useSubscription } from 'modules/mqttHooks';

// https://react-query.tanstack.com/guides/parallel-queries
// https://react-query.tanstack.com/guides/dependent-queries
// https://react-query.tanstack.com/reference/useQueries
import { useQueryClient, useQueries } from 'react-query'

import sjcl from 'sjcl'

import { useHistory, useLocation, Link } from "react-router-dom";

import { generateEventObject, getEventTypeIcon, generateEventTypeFilters, generatePositionObject, toDisplayTimeAbbr, toDisplayTimeLong, formatDistance, formatDuration, toDateTime, toDisplayTimeRelShort, toDisplayDateShort } from 'shared-functions/shared-functions';

import { setUnitSystem, setUser, useCurrentUserCanViewCourses, usePreferenceStore, useStrokeSize, useTokenStore, useUnitSystemList, useUserEmail, useUserId, useUserRole, useUserRoleFeatures, useUserRoot } from 'modules/useTokenStore';

import { useFetchAlert, useFetchUser, updateUserProfilePicture, useFetchTripDetails, clearFetchTripDetails, useFetchDevicesLive, useFetchDeviceFocus, invalidateFetchDeviceFocus, useFetchRoleOptions } from 'services/api';

import { getDriverLocationIcon, mapIcons } from 'components/map/map-icons';

import useGlobalStore, { DEFAULT_EMPTY_LIST } from 'modules/globalStore';
import { authorize, updateUserPassword, userUpdate } from 'services/user';
import { useUnitSystem } from 'shared-hooks/useDistanceUnit';

import cbor from 'cbor'
import { setLocalVersion, useLocalVersion } from './useLocalStore';

const FOCUS_LEFT_NAV_BAR = "navBar";
const FOCUS_LEFT_FILTER_MENU = "filterMenu";
const FOCUS_LEFT_NONE = null;

// Production

// LIVE_MS_REFRESH_NO_DEVICE is how frequently the live view should be refreshed
// if no device is selected.
const LIVE_MS_REFRESH_NO_DEVICE = 1500;

export const useShowAbout = () =>
  useGlobalStore(state => state.showAbout);

export const useProductVersion = () =>
  useGlobalStore(state => state.productVersion);

export const useProductConfiguration = () =>
  useGlobalStore(state => state.productConfiguration);

export const useProductChangelog = () =>
  useGlobalStore(state => state.productChangelog);

export const useProductReleaseDate = () =>
  useGlobalStore(state => state.productReleaseDate);

export const useProductReleaseDateSimple = () => {
  const releaseDate = useProductReleaseDate();
	const dt = toDateTime(releaseDate);
  // https://moment.github.io/luxon/#/formatting?id=table-of-tokens
	return dt.toLocal().toFormat("d LLLL yyyy");
}

// export const useProductCopyright = () =>
//   useGlobalStore(state => state.productCopyright);

export const useProductCopyright = () => {
  const productYear = useProductYear();
  return React.useMemo(() => `Copyright © Fifth Dimension Technologies ${productYear}`, [productYear]);
}

export const useProductCopyrightPrefix = () =>
  React.useMemo(() => "Copyright © ", []);

export const useProductCompany = () =>
  React.useMemo(() => "Fifth Dimension Technologies", []);

export const useProductLink = () =>
  React.useMemo(() => "https://www.5dt.ai", []);

export const useProductYear = () => {
  const d = new Date();
  const currentYear = d.getFullYear();
  return React.useMemo(() => `${currentYear}`, [currentYear]);
}

export const setShowAbout = (showAbout) =>
  useGlobalStore.setState(produce(state => {
    state.showAbout = showAbout
  }));

export const doToggleAbout = () =>
  useGlobalStore.setState(produce(state => {
    state.showAbout = !state.showAbout
  }));

export const doHideAbout = () => setShowAbout(false);
export const doShowAbout = () => setShowAbout(true);

// If this value is changed, then the MQTT broker will reconnect.
export const setLiveId = (liveId) =>
  useGlobalStore.setState(produce(state => {
    state.liveId = liveId
  }));


// setLiveId('mqttjs_' + Math.random().toString(16).substr(2, 8))
// warning: this causes a page refresh, because it rerenders useMqttOptions
export const newLiveId = () =>
  setLiveId(`web-app-${_.padStart(_.random(99), 3, '0')}-${Date.now()}`)

export const setLiveStatus = (status) =>
  useGlobalStore.setState(produce(state => {
    // console.warn('setLiveStatus: new status', status)
    state.liveStatus = status
  }));

// deviceList is a list of "live" devices (online and offline, but not expired)
// this is the ground truth, it determines the list of live devices available to the frontend
// no other function should add to the list of live devices
// useUpdateLiveDeviceMqtt can be used to augment the data from this list
// if useUpdateLiveDeviceMqtt captured information for a device that is not in this list, then this list should be requested again
// todo: see _updateLiveDevice for a similar function that updates a single element in the device list based on an incoming mqtt message
const doUpdateLiveDeviceList = (deviceList) => {
  useGlobalStore.setState(produce(state => {

    const newDeviceStatusMap = _.chain(deviceList)
      .keyBy('serial_number')
      .mapValues(device => {
        const key = `${device.id}-${device.last_online_time.toISO()}`;
        return _createDeviceStatus({
          key, // a unique message key (possibly to avoid duplicate messages?)
          timestampKey: device.last_online_time,
          timestamp: device.last_online_time,
          deviceStatusText: device.status,
          statusTime: device.last_online_time,
          data: device,
          deviceDb: device,
          deviceId: device.serial_number,
          isPosition: !_.isNil(device.last_known_location),
          hasPosition: !_.isNil(device.last_known_location),
          tripId: device.trip_id,
          hasTripId: !_.isNil(device.trip_id),
          // todo: can we add last_known_bearing from the database?
          bearing: device.last_known_location?.bearing ?? 0,
          positionOk: hasValidGpsData(device.last_known_location),

          positionDb: generatePositionObject(device.last_known_location, device.trip_id ?? device.serial_number),

          tripStatusText: device.trip_status,
          // positionMarker: null,
          tripDb: _initDeviceTrip({
            id: device.trip_id,
            device_serial_number: device.serial_number,
            path: [],
            alerts: [],
          }),
        })
      })
      .value();

      state.liveDeviceStatusMap = newDeviceStatusMap;

      const newDeviceStatusList = _.values(newDeviceStatusMap)
      state.liveDeviceTripList = newDeviceStatusList.map(d => d?.tripId ?? null);
      state.liveDeviceStatusList = newDeviceStatusList;

      // console.warn('doUpdateLiveDeviceList', {
      //   deviceList, newDeviceStatusMap, newDeviceStatusList
      // });
  }));
}

// export const collectGarbage = () =>
//   useGlobalStore.setState(produce(state => {
//     // MAX_MESSAGES is the total number of messages that messageList may hold.
//     const MAX_MESSAGES = 1000;
//     recycleMessageList(state.messageList, MAX_MESSAGES);
//   }));

const _updateLiveTrip = (state, tripData) => {
  if (_.isNil(state.deviceStatus)) {
    return
  }

  if (_.isNil(tripData)) {
    console.warn('_updateLiveTrip: clearing device trip data');
  } else {
    console.warn('_updateLiveTrip: setting device trip data', {
      tripData
    });
  }

  // todo: see _updateLiveDeviceData
  state.deviceStatus.tripDb = _initDeviceTrip(tripData);
}

// todo: see _updateLiveTrip
const _updateLiveDeviceData = (state, deviceData) => {
  if (_.isNil(state.deviceStatus)) {
    return
  }

  if (!_.isNil(deviceData)) {
    console.warn('_updateLiveDeviceData: setting device data', {
      deviceData
    });
    // todo: update the other properties, e.g. tripId
    // todo: selectedTrip
    state.deviceStatus.deviceDb = deviceData;
    state.deviceStatus.tripId = deviceData?.trip_id;
    state.selectedTrip.id = deviceData?.trip_id;
  }
}

const updateLiveDeviceTripData = (tripData) => {
  useGlobalStore.setState(produce(state => {
    _updateLiveTrip(state, tripData);
  }));
}

const updateLiveDeviceData = (deviceData) => {
  useGlobalStore.setState(produce(state => {
    _updateLiveDeviceData(state, deviceData);
  }));
}

// dbAlertFromMessage generates an object that looks like this:
// alert_title: "Eyes Off Road"
// alert_type: "eyes_off_road"
// details:
// alert_type: "eyes_off_road"
// device_id: "1e2847ab-9386-4e9e-9a9e-ba17fb184849"
// driver_email: "tiaan.nel@5dt.com"
// driver_id: "d35483a4-4aa4-4b6a-809c-162ad4ae14a7"
// driver_name: "Tiaan"
// driver_surname: "Nell"
// id: "7db576f4-799e-4a43-b492-ffe7e63324dd"
// position:
// accuracy: 17.152000427246094
// altitude: 1429.8238525390625
// bearing: 218.8999938964844
// latitude: -25.7617159
// location_time: "2022-05-03T13:23:03.171661"
// longitude: 28.26875835
// speed: 7.480000019073486
// [[Prototype]]: Object
// timestamp: "2022-05-03T13:23:03.171Z"
// trip_id: "f74b045c-e7b4-4446-8be9-0a5f5fedada4"
// vehicle_id: "95cb2383-c2f3-4b8d-b7de-91b33c3f53c3"
// vehicle_name: "5DT Car"
// vehicle_registration_number: "JK03TYGP"
// [[Prototype]]: Object
// device_id: "1e2847ab-9386-4e9e-9a9e-ba17fb184849"
// device_serial_number: "1e2847ab-9386-4e9e-9a9e-ba17fb184849"
// driver_email: "tiaan.nel@5dt.com"
// driver_id: "d35483a4-4aa4-4b6a-809c-162ad4ae14a7"
// driver_name: "Tiaan"
// driver_surname: "Nell"
// id: "7db576f4-799e-4a43-b492-ffe7e63324dd"
// key: "7db576f4-799e-4a43-b492-ffe7e63324dd"
// markerPos:
// lat: -25.7617159
// lng: 28.26875835
// [[Prototype]]: Object
// position:
// accuracy: 17.152000427246094
// altitude: 1429.8238525390625
// bearing: 218.8999938964844
// latitude: -25.7617159
// location_time: Tue May 03 2022 13:23:03 GMT+0200 (South Africa Standard Time) {}
// longitude: 28.26875835
// speed: 7.480000019073486
// [[Prototype]]: Object
// snapshot: null
// timestamp: Tue May 03 2022 15:23:03 GMT+0200 (South Africa Standard Time) {}
// trip_id: "f74b045c-e7b4-4446-8be9-0a5f5fedada4"
// vehicle_id: "95cb2383-c2f3-4b8d-b7de-91b33c3f53c3"
// vehicle_name: "5DT Car"
// vehicle_registration_number: "JK03TYGP"
const dbAlertFromMessage = (message) => {
  if (_.isNil(message)) {
    return null
  }
  if (_.isEqual(message.isEvent, false)) {
    return null
  }
  // const gps_data = message?.data?.message_detail?.gps_data ?? {};
  // const trip_id = message?.data?.trip_id ?? null;
  return generateEventObject(message?.data);
}

// dbPositionFromMessage generates an object that looks like this:
// accuracy: 22.512001037597656
// altitude: 1426.1480712890625
// bearing: 0
// id: undefined
// latitude: -25.76073878
// location_time: "2022-05-03T13:22:14.171661"
// longitude: 28.26978086
// position: {lat: -25.76073878, lng: 28.26978086}
// speed: 0
// timestamp: Tue May 03 2022 13:22:14 GMT+0200 (South Africa Stand
// console.log('fetchTrip', id, result);
// dbFormat: path[x].location_time = 2022-05-05T17:26:39.594894 [Thu May 05 2022 17:26:39 GMT+0200 (South Africa Standard Time)]
// liveFormat: message?.message_timestamp/location_time = 2022-05-05T17:35:13.125Z [Thu May 05 2022 19:35:13 GMT+0200 (South Africa Standard Time)]
// luxon: DateTime.fromISO('2016-05-25T09:08:34.123')
// todo: for some reason, the time that comes from the db is not the same as the time that comes from mqtt
const dbPositionFromMessage = (message) => {
  if (_.isNil(message)) {
    return null
  }

  const position = getPosition(message?.data);
  if (_.isNil(position)) {
    return null
  }
  const trip_id = message?.tripId ?? null;
  const key = message?.key ?? null;
  return generatePositionObject({
    ...position,
    // This timestamp makes sorting more correct, since it is based on a stable
    // value and is the same as the db value.
    // timestamp: message.timestampKey,
    // timestamp: message.timestamp,
    // Extra info for debugging purposes:
    trip_id,
    key,
  }, key);
}

// todo: query the database for the status of live devices
// todo: deprecate this function or repurpose it to perform a refresh from database by invalidating the fetch query in useUpdateLiveDeviceList
// todo: consider using useDebounce, useThrottle, useThrottleFn to ensure that this function is only called at the right time
const _updateLiveDeviceListStatus = () => useGlobalStore.setState(produce(state => {
    // Update the status of the live devices.
    const statusTime = DateTime.now();
    // console.warn('_updateLiveDeviceListStatus', statusTime);

    _.forEach(state.liveDeviceStatusMap, (deviceStatus, deviceKey) => {
      deviceStatus.statusTime = statusTime;
    });
  }));

// https://trello.com/c/0IZ4Gjdx/296-update-breadcrumbs-with-human-readable-identifiers
export const setBreadcrumb = (id, alias) =>
  useGlobalStore.setState(produce(state => {
    state.breadcrumbs[id] = alias
  }));

// https://trello.com/c/0IZ4Gjdx/296-update-breadcrumbs-with-human-readable-identifiers
export const useBreadcrumbs = () => {
  return useGlobalStore(state => state.breadcrumbs);
}

const getPosition = (data) => {
  return data?.message_detail?.gps_data ?? null;
}

const hasValidGpsData = (position) => {
  if (_.isNil(position)) {
    return false;
  }
  if (_.isNil(position.latitude) || _.isNil(position.longitude)) {
    return false;
  }
  if (_.isNaN(position.latitude) || _.isNaN(position.longitude)) {
    return false;
  }
  // 0, 0 is a valid lat/lon
  // if (_.isEqual(position.latitude, 0) || _.isEqual(position.longitude, 0)) {
  //   return false;
  // }
  return true;
}

const hasValidPosition = (data) => {
  return hasValidGpsData(data?.message_detail?.gps_data)
}

// _updateLiveDevice uses the device represented by nextStatus and its
// lastStatus, to calculate the new status.
const _updateLiveDevice = (state, nextStatus, lastStatus, outStatus, queryClient) => {
  // console.warn('_updateLiveDevice', { nextStatus, lastStatus })

  // todo: currently we always display information about the most recent trip
  // todo: what position and path should we display if a device is not currently busy with a trip?


  // If the incoming trip is different from the current trip, then exit early
  // and wait for the data coming from the database to be refreshed. See
  // useFetchDeviceFocus. It will periodically request the latest info about a
  // device for heartbeat information and last seen data, as recorded by the
  // database. This data is the source of truth and the reference point for
  // further derived calculations. If nextStatus.tripId is nil, then we are
  // working with a device-related message. If nextStatus.tripId is valid but
  // not equal to lastStatus.tripId, then we are working with a new or old trip,
  // which we should ignore for now until we have caught up with the ground
  // truth, so let's exit early.
  if (!_.isNil(nextStatus.tripId) && !_.isEqual(nextStatus.tripId, lastStatus.tripId)) {
    console.warn('_updateLiveDevice: TRIP INFORMATION OUT OF DATE, WAITING FOR AUTOMATIC REFRESH', {
      nextTripId: nextStatus.tripId,
      lastTripId: lastStatus.tripId
    })
    return
  }

  outStatus.timestamp = nextStatus.timestamp;

  if (nextStatus.isSnapshot || nextStatus.isRecording) {
    const eventId = nextStatus.isSnapshot ? nextStatus.data?.message_detail?.snapshot_details?.uuid
      : nextStatus.data?.message_detail?.clip_details?.uuid
    if (eventId) {
      queryClient.invalidateQueries(['alert', eventId]);
    }
  }

  // Since messages could be received out of order, ensure that this message is
  // more recent than the current message.
  // Since we are flooded by position messages, ensure that we check for
  // duplicates to avoid unnecessary component updates.
  // We need an opportunity to re-order out-of-order messages. See do-dev-test
  // to verify.

  // If this message represents a position, then update the trip position data.
  if (nextStatus.positionOk) {
    const nextPositionDb = nextStatus.positionDb;

    outStatus.positionDb = nextPositionDb;
    outStatus.bearing = nextStatus.bearing ?? lastStatus.bearing ?? 0;

    // todo: it is possible to receive position data, even if there is no trip

    const lastPosition = _.last(lastStatus.tripDb.path);
    outStatus.tripDb.path.push(nextPositionDb);
    if (_.gt(lastPosition?.timestamp, nextPositionDb.timestamp)) {
      console.error('_updateLiveDevice: reordering path by timestamp', {
        nextPositionDb
      });
      outStatus.tripDb.path = _.orderBy(outStatus.tripDb.path, [m => m.timestamp], ['asc']);
    }

  }

  // If this message represents an event, then update the trip events.
  if (!_.isNil(nextStatus.eventDb)) {
    const nextAlertDb = nextStatus.eventDb;
    const lastAlert = _.last(lastStatus.tripDb.alerts);
    outStatus.tripDb.alerts.push(nextAlertDb);
    if (_.gt(lastAlert?.timestamp, nextAlertDb.timestamp)) {
      console.error('_updateLiveDevice: reordering alerts by timestamp', {
        nextAlertDb
      });
      outStatus.tripDb.alerts = _.orderBy(outStatus.tripDb.alerts, [m => m.timestamp], ['asc']);
    }
  }
}

// _createDeviceStatus creates the structure that is expected to be stored in values of liveDeviceStatusMap
const _createDeviceStatus = (deviceStatus) => {
  const timestamp = DateTime.now();
  return _.defaults(deviceStatus, {
    key: `${Date.now()}`,

    // timestampKey is typically stable, meaning that if the same message (e.g. a
    // gps sensor) is repeatedly provided, it will have the same timestampKey
    // (unless that message does not have a message_timestamp). Sometimes,
    // timestampKey is in the future, because of clock issues or generated data.
    // timestampClamped tries to "rewind" a future timestamp to match the current
    // time.
    timestampKey: timestamp,

    timestamp: timestamp,
    // timestampIn: null,
    // timestampClamped: null,
    deviceStatusText: 'online',

    statusTime: timestamp,

    // todo: deprecate data field
    data: null,
    message: null,
    topic: null,
    deviceId: null,
    category: null,

    // deviceAlive is the last valid alive value (if any)
    // deviceAlive null means that an alive status message has not yet been received
    deviceAlive: null,

    isTripStart: false,
    isTripStop: false,
    isTripDriver: false,

    index: 0,
    isEvent: false,
    isPosition: false,
    hasPosition: false,
    tripId: null,
    hasTripId: false,
    bearing: 0,
    positionOk: false,
    positionDb: false,

    // eventDb is details about an event as provided by the database.
    eventDb: null,

    // deviceDb is details about the device as provided by the database.
    deviceDb: null,

    // tripDb is details about the trip as provided by the database.
    tripDb: _initDeviceTrip(null),

    isTripStart: false,
    isTripStop: false,
    isTripDriverId: false,

    tripStatusText: 'stopped',


    positionMarker: null,
  })
}

// captureMessage ignores duplicate/expired messages (see isStatusRejected) by creating a desiredStatus and trying to upgrade it to an nextStatus.
// captureMessage then uses the nextStatus to update the status of devices.
const captureMessage = (message, queryClient) => {

  // Exit early if we don't have a message.
  if (_.isNil(message)) {
    return
  }

  // Exit early if the deviceStatus is empty, i.e. there is no live device
  // selected or the selected live device hasn't been set from the single source
  // of truth (i.e. the database).
  const lastStatus = useGlobalStore.getState().deviceStatus;
  if (_.isNil(lastStatus)) {
    return
  }

  // Determine if the message can be parsed.
  let messageParseSuccess = false
  let binaryPayload = null
  let data = null
  try {
    data = JSON.parse(message.message.toString());
    messageParseSuccess = true
  } catch (e) {
    try {
      let decoded = cbor.decodeAllSync(message.message)[0]
      data = JSON.parse(decoded[0].toString());
      binaryPayload = decoded[1]
      messageParseSuccess = true
    } catch (e) {
      console.error(e);
    }
  }

  // Exit early if the message could not be parsed.
  if (!messageParseSuccess) {
    return
  }

  // Example topics (see useSubscription):
  // dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/sensors/gps
  // dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/trip/start
  // dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/trip/stop
  // dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/trip/driver-id
  // dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/sensors/gps
  // dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/events/#
  // dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/status/connection
  const topicRegexp = /dt\/drivevue\/devices\/([^/]*)(.*)/g;
  // topicRegexp.exec("dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/status/connection")[2];
  // topicRegexp.exec("dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/sensors/gps")[2];
  // topicRegexp.exec("dt/drivevue/devices/5f1cc54b-07ff-4a50-bed8-b5ee908748d2/trip/driver-id")[2];
  const topicMatches = topicRegexp.exec(message.topic);
  const topicDeviceId = topicMatches[1]; // e.g. 5f1cc54b-07ff-4a50-bed8-b5ee908748d2
  const topicCategory = topicMatches[2]; // e.g. /sensors/gps, /trip/start, /trip/stop, /trip/driver-id, /status/connection

  // timestampKey is typically stable, meaning that if the same message (e.g. a
  // gps sensor) is repeatedly provided, it will have the same timestampKey
  // (unless that message does not have a message_timestamp). Sometimes,
  // timestampKey is in the future, because of clock issues or generated data.
  // timestampClamped tries to "rewind" a future timestamp to match the current
  // time.
  const timestampKey = _.isNil(data.message_timestamp) ? DateTime.now() : DateTime.fromISO(data.message_timestamp, { zone: 'utc' });

  // timestampIn is unstable, meaning that if the same message is repeatedly
  // provided, it will have a different timestampIn.
  const timestampIn = DateTime.now();

  // todo: can this clamp be stable, e.g. for the same timestampKey produce the same timestampClamped
  // timestampClamped is timestampKey, if timestampKey is in the past, otherwise
  // it is timestampIn
  const timestampClamped = timestampKey < timestampIn ? timestampKey : timestampIn;

  // statusTime is the time when the status was calculated
  // statusTime is not the time that was used to calculate the status
  const statusTime = timestampIn;

  const tripId = data?.trip_id;

  const bearing = data?.message_detail?.gps_data?.bearing ?? null;

  // This key uniquely identifies a message based on its tripId, timestamp and topic.
  const key = `${tripId}-${timestampKey.toISO()}-${message.topic}`;

  const isEvent = _.isEqual(data?.message_category, "events");
  const isPosition = _.isEqual(topicCategory, "/sensors/gps");
  const isRecording = _.isEqual(data?.message_category, "video");
  const isSnapshot = _.isEqual(data?.message_category, "snapshots");


  const hasPosition = !_.isNil(data?.message_detail?.gps_data ?? null);

  // hasTripId indicates whether the incoming message has a trip_id or not.
  const hasTripId = !_.isNil(data?.trip_id ?? null);

  const deviceAlive = data?.alive ?? null;

  const isTripStart = _.isEqual(topicCategory, "/trip/start");
  const isTripStop = _.isEqual(topicCategory, "/trip/stop");
  const isTripDriver = _.isEqual(topicCategory, "/trip/driver-id");

  // desiredStatus is the proposed status update for a device.
  const desiredStatus = _createDeviceStatus({
    key,
    timestampKey,
    timestamp: timestampClamped,
    // timestampIn: timestampIn,
    // timestampClamped: timestampClamped,
    statusTime,
    data,
    message: data,
    topic: message.topic,
    deviceId: topicDeviceId,
    category: topicCategory,
    deviceAlive,
    isEvent,
    isPosition,
    hasPosition,
    tripId,
    hasTripId,
    bearing: _.isNaN(bearing) || _.isNil(bearing) || _.isEqual(bearing, 0) ? null : bearing,
    positionOk: hasValidPosition(data),
    positionDb: null,
    eventDb: null,
    isTripStart,
    isTripStop,
    isTripDriver,
    isRecording,
    isSnapshot
  });

  desiredStatus.positionDb = dbPositionFromMessage(desiredStatus);
  desiredStatus.eventDb = dbAlertFromMessage(desiredStatus);

  // todo: https://trello.com/c/TxUzcxSv
  // todo: https://trello.com/c/jwGmJOul
  // todo: https://trello.com/c/zWPelMo9
  // todo: https://trello.com/c/EnF0vDIb


  useGlobalStore.setState(produce(state => {
    _updateLiveDevice(state, desiredStatus, lastStatus, state.deviceStatus, queryClient);
  }));
}

// useDeviceTripInfo only retrieves the essential information about a trip,
// without the trip path and alerts, etc.
const useDeviceTripInfo = () => useGlobalStore(state => state.deviceStatus?.deviceDb ?? null);

const useDeviceTripPath = () => useGlobalStore(state => state.deviceStatus?.tripDb?.path ?? state.defaultEmptyList);
const useDeviceTripAlerts = () => useGlobalStore(state => state.deviceStatus?.tripDb?.alerts ?? state.defaultEmptyList);

export const setSelectedTripId = (tripId) =>
  useGlobalStore.setState(produce(state => {
    state.selectedTrip.id = tripId
  }));

export const setSelectedDriverId = (driverId) =>
  useGlobalStore.setState(produce(state => {
    state.selectedDriver.id = driverId
  }));

export const setSelectedDeviceId = (deviceId) =>
  useGlobalStore.setState(produce(state => {
    state.selectedDevice.id = deviceId
  }));

export const setSelectedDeviceSerial = (serialNumber) =>
  useGlobalStore.setState(produce(state => {
    state.selectedDevice.serialNumber = serialNumber
  }));

export const setSelectedVehicleId = (vehicleId) =>
  useGlobalStore.setState(produce(state => {
    state.selectedVehicle.id = vehicleId
  }));

// https://trello.com/c/YoyyDJLg/398-devices-should-assign-uuid4-id-to-generated-alerts-not-database
// export const setSelectedEventId = (eventId) =>
//   // todo: if we set this id from an mqtt message, then eventId is DateTime.now() in int format, and not the real id that the database assigned to the event
//   // todo: the right solution is to have the device assign a uuid4 to the event, and to let the database use that id
//   // todo: for now we need to retrieve all alerts for a specific trip from the database, then try and match message_detail.event_snapshot or message_timestamp 
//   useGlobalStore.setState(produce(state => {
//     state.selectedEvent.id = eventId
//   }));

export const setSelectedEventData = (eventData) =>
  useGlobalStore.setState(produce(state => {
    // console.log('setSelectedEventData', eventData);
    state.selectedEvent.data = eventData;
    state.selectedEvent.id = eventData?.id;
    state.selectedEvent.index = eventData?.index;
  }));

export const clearSelectedEventData = () =>
  useGlobalStore.setState(produce(state => {
    // console.log('clearSelectedEventData');
    state.selectedEvent.data = null;
    state.selectedEvent.id = null;
    state.selectedEvent.index = null;
  }));

export const setSelectedEventLast = (eventList) =>
  useGlobalStore.setState(produce(state => {
    const nextData = _.nth(eventList ?? [], -1);
    if (!_.isNil(nextData)) {
      state.selectedEvent.data = nextData;
      state.selectedEvent.index = _.size(eventList) - 1;
    }
  }));

export const setSelectedEventFirst = (eventList) =>
  useGlobalStore.setState(produce(state => {
    const nextData = _.nth(eventList ?? [], 0);
    if (!_.isNil(nextData)) {
      state.selectedEvent.data = nextData;
      state.selectedEvent.index = 0;
    }
  }));

export const setSelectedEventNext = (eventList, eventIndex) =>
  useGlobalStore.setState(produce(state => {
    const finalEvents = eventList ?? [];
    const nextIndex = _.clamp(eventIndex + 1, 0, _.size(finalEvents) - 1);
    // console.log('setSelectedEventNext', nextIndex);
    const nextData = _.nth(finalEvents, nextIndex);
    if (!_.isNil(nextData)) {
      state.selectedEvent.data = nextData;
      state.selectedEvent.index = nextIndex;
    }
  }));

export const setSelectedEventPrev = (eventList, eventIndex) =>
  useGlobalStore.setState(produce(state => {
    const finalEvents = eventList ?? [];
    const nextIndex = _.clamp(eventIndex - 1, 0, _.size(finalEvents) - 1);
    // console.log('setSelectedEventPrev', nextIndex);
    const nextData = _.nth(finalEvents, nextIndex);
    if (!_.isNil(nextData)) {
      state.selectedEvent.data = nextData;
      state.selectedEvent.index = nextIndex;
    }
  }));

const _clearSelectedTrip = (state, queryClient) => {
  const tripId = state.selectedTrip?.id ?? state.deviceStatus?.tripDb?.id ?? null;
  console.warn('_clearSelectedTrip', { tripId });
  clearFetchTripDetails(queryClient, tripId);
  state.selectedDevice.serialNumber = null
  state.selectedDevice.id = null
  state.selectedDriver.id = null
  state.selectedEvent.id = null
  state.selectedEvent.data = null
  state.selectedEvent.index = null
  state.selectedTrip.id = null
  state.selectedVehicle.id = null
  state.selectedUser.id = null
  state.deviceStatus = null
}

// also see setSelectedDeviceTrip
export const clearSelectedTrip = (queryClient) =>
  useGlobalStore.setState(produce(state => {
    _clearSelectedTrip(state, queryClient);
  }));

export const toggleFocusDeviceTrip = () =>
  useGlobalStore.setState(produce(state => {
    state.selectedTrip.focus = !state.selectedTrip.focus
  }));

export const setFocusDeviceTrip = (focus) =>
  useGlobalStore.setState(produce(state => {
    state.selectedTrip.focus = focus
  }));

export const setFocusTripEvent = (focus) =>
  useGlobalStore.setState(produce(state => {
    state.selectedEvent.focus = focus
  }));

export const setSelectedHistoricTrip = (tripId) =>
  useGlobalStore.setState(produce(state => {
    // console.log('setSelectedHistoricTrip')
    // state.selectedDevice.serialNumber = deviceSerialNumber
    // state.selectedDevice.id = null
    // state.selectedDriver.id = driverId
    state.selectedTrip.id = tripId
    // state.selectedVehicle.id = vehicleId
    // state.selectedUser.id = driverId
    // state.selectedEvent.id = null
    // state.selectedEvent.data = null
  }));

const _initDeviceTrip = (init) => ({ 
  id: null, 
  device_serial_number: null, 
  path: [], 
  alerts: [], 
  ...(_.defaultTo(init, {})) 
});

// also see clearSelectedTrip and useSelectedTripId and useDeviceTripId
// setSelectedDeviceTrip selects a specific trip of a specific device.
// @note this is called for live and offline trips, so don't assume that live trip data is always available
export const setSelectedDeviceTrip = (deviceSerialNumber, tripId, driverId, vehicleId) =>
  useGlobalStore.setState(produce(state => {
    console.warn('setSelectedDeviceTrip', {deviceSerialNumber, tripId, driverId, vehicleId});
    if (_.isEqual(state.selectedDevice.serialNumber, deviceSerialNumber)) {
      // if the device is already selected, then toggle the selection off
      // https://trello.com/c/HvBDmaRX
      // _clearSelectedTrip(state);
      // todo: maybe the user clicks on the vehicle to pan it?
    } else {
      // todo: consider passing in isLive, to allow setting of live data
      // todo: or call useEffect in live message handler
      // clearFetchTripDetails
      state.selectedDevice.serialNumber = deviceSerialNumber
      state.selectedDevice.id = null
      // todo: selectedDriver, selectedTrip, selectedVehicle, selectedUser, selectedEvent should be calculated dynamically from selectedDevice.
      state.selectedDriver.id = driverId
      state.selectedTrip.id = tripId
      state.selectedVehicle.id = vehicleId
      state.selectedUser.id = driverId
      state.selectedEvent.id = null
      state.selectedEvent.data = null
      state.selectedEvent.index = null
    }
  }));

export const setFocusFilterMenu = (focus) =>
  useGlobalStore.setState(produce(state => {
    state.focusLeft = focus ? FOCUS_LEFT_FILTER_MENU : FOCUS_LEFT_NONE;
  }));

export const setFocusNavBar = (focus) =>
  useGlobalStore.setState(produce(state => {
    state.focusLeft = focus ? FOCUS_LEFT_NAV_BAR : FOCUS_LEFT_NONE;
  }));

export const hideNavBar = () =>
  useGlobalStore.setState(produce(state => {
    state.focusLeft = FOCUS_LEFT_NONE;
  }));

export const showNavBar = () =>
  useGlobalStore.setState(produce(state => {
    state.focusLeft = FOCUS_LEFT_NAV_BAR;
  }));

export const toggleNavBar = () =>
  useGlobalStore.setState(produce(state => {
    state.focusLeft = state.focusLeft === FOCUS_LEFT_NAV_BAR ? FOCUS_LEFT_NONE : FOCUS_LEFT_NAV_BAR;
  }));

export const toggleFilterMenu = () =>
  useGlobalStore.setState(produce(state => {
    state.focusLeft = state.focusLeft === FOCUS_LEFT_FILTER_MENU ? FOCUS_LEFT_NONE : FOCUS_LEFT_FILTER_MENU;
  }));

export const useFocusFilterMenu = () => useGlobalStore(state => state.focusLeft === FOCUS_LEFT_FILTER_MENU);
export const useFocusNavBar = () => useGlobalStore(state => state.focusLeft === FOCUS_LEFT_NAV_BAR);
export const useSelectedDeviceId = () => useGlobalStore(state => state.selectedDevice.id);
export const useSelectedDeviceSerial = () => useGlobalStore(state => state.selectedDevice.serialNumber);
export const useSelectedTripId = () => useGlobalStore(state => state.selectedTrip.id);
export const useFocusDeviceTrip = () => useGlobalStore(state => state.selectedTrip.focus ?? false);
export const useFocusTripEvent = () => useGlobalStore(state => state.selectedEvent.focus ?? false);
export const useSelectedVehicleId = () => useGlobalStore(state => state.selectedVehicle.id);
export const useSelectedDriverId = () => useGlobalStore(state => state.selectedDriver.id);
export const useSelectedEventId = () => useGlobalStore(state => state.selectedEvent.id);
export const useSelectedEventData = () => useGlobalStore(state => state.selectedEvent.data);

// https://trello.com/c/YoyyDJLg/398-devices-should-assign-uuid4-id-to-generated-alerts-not-database
// export const useSelectedEvent = () => {
//   return useSelectedEventData();
// }

export const useSelectedEventFromList = (eventList, eventIndex) => {
  // console.log('useSelectedEventFromList', eventList, eventIndex, _.nth(eventList, eventIndex));
  return React.useMemo(() => !_.isNil(eventIndex) && !_.isNil(eventList) ? (_.nth(eventList, eventIndex) ?? null) : null, [eventIndex, eventList])
}

export const useResolveEventMedia = (eventData) => {
  const eventId = React.useMemo(() => (eventData?.media ?? null) ? null : (eventData?.id ?? null), [eventData]);
  // console.log('useResolveEventMedia', eventData, eventId);
  const { data: alertData, isFetching } = useFetchAlert(eventId);
  // console.log('useResolveEventMedia', rest)
  const media = React.useMemo(() => eventData?.media ?? alertData?.media ?? null, [eventData, alertData])

  return React.useMemo(() => {
    // console.log('useResolveEventMedia', eventData, isFetching);
    return {
      event: eventData ? { ...eventData, media: media } : eventData,
      isFetching: isFetching,
    }
  }, [eventData, media, isFetching]);
}

export const useSelectedEventIndex = () =>
  useGlobalStore(state => state.selectedEvent.index);

export const useLiveStatus = () => useGlobalStore(state => state.liveStatus);

// useMqttStatus implements a fix for the incorrect mqtt connectionStatus being reported.
const useMqttStatus = () => {
  const { connectionStatus, client } = useMqttState();

  const clientId = useMqttClientId();

  // const disconnected = React.useMemo(() => client?.disconnected ?? true, [client]);
  // const disconnecting = React.useMemo(() => client?.disconnecting ?? true, [client]);
  const reconnecting = React.useMemo(() => client?.reconnecting ?? false, [client]);
  const connected = React.useMemo(() => client?.connected ?? false, [client]);
  return React.useMemo(() => {
    // console.warn('useMqttStatus', clientId, connectionStatus, reconnecting, connected)
    return {
      color: reconnecting ? 'orange' : (connected ? 'lightgreen' : 'white'),
      tooltip: reconnecting ? 'Connecting...' : (connected ? 'Connected' : 'Offline'),
      status: reconnecting ? 'connecting' : (connected ? 'connected' : 'offline'),
      connectionStatus: connectionStatus,
      reconnecting: reconnecting,
      connected: connected,
      clientId: clientId,
    }
  }, [reconnecting, connected, connectionStatus, clientId]);
}

// useCheckMqttAuth will perform a logout if mqtt authentication failed
const useCheckMqttAuth = () => {
  const history = useHistory();
  const { connectionStatus } = useMqttState();

  // This wil redirect the user to login if the user is not authorized, iff
  // this component is loaded.
  React.useEffect(() => {
    if (connectionStatus && connectionStatus === "Connection refused: Not authorized") {
      history.push("/login?reason=tk")
    }
  }, [connectionStatus]);
}

const useUpdateMqttStatus = () => {
  const liveStatus = useMqttStatus();
  useDeepCompareEffect(() => {
    setLiveStatus(liveStatus);
  }, [liveStatus])
}

export const useCaptureLiveStatus = () => {
  return useMqttStatus();
}

// todo: this must offer the same level of detail as the trip marker tooltips
const useLiveDeviceTripList = () => {
  const liveDeviceStatusArray = useLiveDeviceStatusArray();

  const unitSystem = useUnitSystem();

  // https://blog.bitsrc.io/when-using-usememo-is-a-really-bad-idea-a2bdeb909812
  // https://trello.com/c/068kphdN/404-live-view-displays-white-screen
  return React.useMemo(() => {
    // console.warn('useLiveDeviceTripList', liveDeviceStatusArray)
    return liveDeviceStatusArray.map((deviceStatus, index) => {
      const { deviceDb } = deviceStatus;

      // console.warn('useLiveDeviceTripList', {
      //   deviceStatus,
      // })

      // todo: add createSummaryInfoFromDeviceDb
      // todo: add createSummaryInfoFromTripDb
      return createSummaryInfo({
        liveView: true,
        unitSystem: unitSystem,
        tripStartTime: deviceDb?.trip_start_time ?? deviceDb?.trip_date_created,
        tripEndTime: deviceDb?.trip_end_time,
        driverName: deviceDb?.trip_driver_name ?? deviceDb?.driver_name,
        driverSurname: deviceDb?.trip_driver_surname ?? deviceDb?.driver_surname,
        vehicleAlias: deviceDb?.trip_vehicle_name ?? deviceDb?.vehicle_name,
        vehicleRegistrationNumber: deviceDb?.trip_vehicle_registration_number ?? deviceDb?.vehicle_registration_number,
        vehicleColour: deviceDb?.trip_vehicle_colour ?? deviceDb?.vehicle_colour,
        vehicleMake: deviceDb?.trip_vehicle_make ?? deviceDb?.vehicle_make,
        vehicleModel: deviceDb?.trip_vehicle_model ?? deviceDb?.vehicle_model,
        deviceStatus: deviceDb?.status,
        tripStatus: deviceDb?.trip_status,
        tripId: deviceDb?.trip_id,
        deviceLastSeen: deviceDb?.last_online_time ?? deviceDb?.trip_last_active_time,
        deviceSn: deviceDb?.serial_number,
        driverId: deviceDb?.trip_driver_id ?? deviceDb?.driver_id,
        vehicleId: deviceDb?.trip_vehicle_id ?? deviceDb?.vehicle_id,
        tripDistance: deviceDb?.trip_distance,
        tripDuration: deviceDb?.trip_duration,
        tripAlertCount: deviceDb?.trip_alert_count,
      });
    })
  }, [liveDeviceStatusArray, unitSystem]);
}

// https://v5.reactrouter.com/web/api/Link
// https://trello.com/c/0IZ4Gjdx/296-update-breadcrumbs-with-human-readable-identifiers
// todo: replace every history push with this function, at least at the top-level
export const historyPushBreadcrumb = (history, link, id, title) => {
  const extraState = id && title ? { [id]: title } : {};
  // console.log('historyPushBreadcrumb', link, id, title)
  if (extraState) {
    setBreadcrumb(id, title);
  }
  // history.push(link)
  history.push(link, {
    ...extraState
  });
}

// https://v5.reactrouter.com/web/api/Link
// https://trello.com/c/0IZ4Gjdx/296-update-breadcrumbs-with-human-readable-identifiers
// This approach is a bit manual, and requires every Link component to use it.
// Another good option is to use global state: whenever a page is entered, the
// page should set a value in a map, that can be used by the breadcrumb resolve
// function.
// The companion function for this hook, is historyPushBreadcrumb.
export const useLinkToWithBreadcrumb = (link, id, title) => {
  const location = useLocation();
  const state = location?.state ?? {};
  const extraState = id && title ? {
    [id]: title
  } : {}
  // console.log('useLinkToWithBreadcrumb', location);
  return {
    pathname: link,
    state: {
      ...state,
      ...extraState,
    },
  }
}

export const useLiveDeviceTripListSummary = () => useLiveDeviceTripList();

// todo: consider using useDebounce, useThrottle, useThrottleFn here to call _updateLiveDeviceListStatus
// todo: useThrottle can return a Date.now
// useUpdateLiveDeviceListStatus updates the status of every device in the live list
const useUpdateLiveDeviceListStatus = () =>
  useHarmonicIntervalFn(() => _updateLiveDeviceListStatus(), 1000*5)

// useUpdateLiveDeviceList fetches the list of recently live devices from the database
// this is necessary so that the user can select devices from the menu
export const useUpdateLiveDeviceList = () => {

  const deviceSerialNumber = useSelectedDeviceSerial();

  // if a device is selected, then don't update the live devices periodically
  // if a device is not selected, then update the live devices periodically
  const refetchInterval = _.isNil(deviceSerialNumber) ? LIVE_MS_REFRESH_NO_DEVICE : false;
	const { deviceList } = useFetchDevicesLive({
    refetchInterval
  });

  // const deviceStatus = useLiveDeviceStatus();
  // console.warn('useUpdateLiveDeviceList: deviceSerialNumber', {
  //   deviceSerialNumber, 
  //   deviceList, 
  //   refetchInterval
  // });

  // todo: consider useThrottle on the deviceList

  // useCustomCompareEffect should not be used with dependencies that are all primitive values
  // useShallowCompareEffect(() => {
  useDeepCompareEffect(() => {
    // only update the deviceList if a device is not selected
    if (_.isNil(deviceSerialNumber)) {
      doUpdateLiveDeviceList(deviceList);
    }
  }, [deviceList, deviceSerialNumber]);

  // update the status of every device in the list
  useUpdateLiveDeviceListStatus();

}

// useUpdateLiveDevice will update the selected live device. It can retrieve
// detailed information about the active device and its latest trip.
// it detects which device should be set as the active device, then makes a copy of its last data
const useUpdateLiveDevice = () => {

  const deviceSerialNumber = useSelectedDeviceSerial();

  // deviceStatus is the device that should become the active device
  // use this data to set the value that will eventually be returned by useLiveDeviceStatus
  const deviceStatus = useSelectedLiveDeviceStatus(); // todo: why are we using this if it is deprecated? shouldn't we be using useLiveDeviceStatus
  // const deviceStatus = useLiveDeviceStatus(); // todo: why are we not using this, if useSelectedLiveDeviceStatus is deprecated?
  // const deviceStatus = useGlobalStore.getState().deviceStatus;
  useUpdateLiveDeviceMqtt(deviceSerialNumber, deviceStatus);

  // tripId is the most recent tripId associated with a device 
  // this tripId can come from the latest mqtt message, and be different from
  // the cached trip id of a device
  const tripId = useDeviceTripId();

  // todo: if a new trip is detected, then update the selectedTrip.id value as well
  // todo: see setSelectedDeviceTrip (all those values need to be updated here)

  // todo: sometimes the live device does not have an associated trip
  // todo: sometimes the live device may have previously been associated with a trip, but no longer
  // todo: replace useFetchTripDetails with useFetchDeviceFocusTrip()
  const { data: tripData } = useFetchTripDetails(tripId);

  // useFetchDeviceFocus will periodically poll the database for the most recent
  // recorded information about a device. This is the ground truth of what will
  // be rendered on the map and info views. The trip provided by this
  // information, will be used for rendering.
  const { data: deviceData } = useFetchDeviceFocus(deviceSerialNumber);

  // useCustomCompareEffect should not be used with dependencies that are all primitive values
  // useShallowCompareEffect(() => {
  // useDeepCompareEffect(() => {
  React.useEffect(() => {
    updateLiveDeviceTripData(tripData);
  }, [tripData]);

  React.useEffect(() => {
    updateLiveDeviceData(deviceData);
  }, [deviceData]);

}

// useUpdateLiveDeviceMqtt will update the status of the selected live device
// based on mqtt messages. It also sets the active deviceStatus based on the
// currently selected device serial number.
const useUpdateLiveDeviceMqtt = (deviceSerialNumber, deviceStatus) => {

  // todo: connectionStatus is incorrect
  const mqttStatus = useMqttStatus();
  useDeepCompareEffect(() => {
    console.warn('useUpdateLiveDeviceMqtt: new mqttStatus', mqttStatus)
  }, [mqttStatus]);


  // todo: sometimes the selected device doesn't change, but the active trip for that device has changed
  // todo: it is important to update the device status even if the device remained the same and the trip changed
  React.useEffect(() => {
    useGlobalStore.setState(produce(state => {
      console.warn('useUpdateLiveDeviceMqtt: setting deviceStatus', {
        // deviceStatus
        deviceSerialNumber,
      })
      // state.deviceStatus = deviceStatus;
      state.deviceStatus = _.get(state.liveDeviceStatusMap, deviceSerialNumber ?? null);
    }));
  // }, [deviceStatus]);
  }, [deviceSerialNumber]);

  //const deviceId = useDeviceId();

  // todo: if start, stop, event, connection, or driver-id, then refetch device/trip info from the database?
  const topics = React.useMemo(()=>{
    return  _.isNil(deviceSerialNumber) ? [] : [
      // const topics = React.useCallback(() => _.isNil(deviceSerialNumber) ? [] : [
        // todo: future optimisations: subscribe per selected vehicle group
        `dt/drivevue/devices/${deviceSerialNumber}/trip/start`,
        `dt/drivevue/devices/${deviceSerialNumber}/trip/stop`,
        `dt/drivevue/devices/${deviceSerialNumber}/trip/driver-id`,
        // todo: future optimisations: have a topic for last-position
        `dt/drivevue/devices/${deviceSerialNumber}/sensors/gps`,
        // todo: future optimisations: consider subscribing to events per vehicle only when a vehicle is selected
        `dt/drivevue/devices/${deviceSerialNumber}/events/#`,
        // todo: future optimization: consider always subscribing to certain high priority alerts, like collisions, without needing to click on specific vehicles
        `dt/drivevue/devices/${deviceSerialNumber}/status/connection`,
        // `cmd/drivevue/devices/${deviceSerialNumber}/live/snapshot/res`
      ];
  }, [deviceSerialNumber])


  // console.warn('useUpdateLiveDeviceMqtt', { deviceSerialNumber, topics });

  // const { client, topic, message, connectionStatus } = useSubscription([
  // https://github.com/VictorHAS/mqtt-react-hooks/blob/master/lib/useSubscription.tsx
  // https://github.com/mqttjs/MQTT.js/blob/0a8e3b2fd244effc1e079d367b01fdb157f2e73f/types/lib/client-options.d.ts#L170
  // https://stackoverflow.com/questions/33480730/understanding-mqtt-subscriber-qos
  // note: this client can only subscribe to messages that the dvapi /mosquitto-aclcheck allows (as enforced by useMqttOptions)
  // note: Should Driver, ClientAdmin, etc all be able to subscribe to these messages? Maybe a Driver should only be able to subscribe to topics of its own device.
  // todo: get live devices from api, and once selected, start getting messages for the specific device
  // https://github.com/VictorHAS/mqtt-react-hooks/blob/859fbe5f316b8500abb0d59aa84114376ec18978/lib/useSubscription.tsx
  const { message } = useSubscription(topics);

  const queryClient = useQueryClient()

  React.useEffect(() => {
    captureMessage(message, queryClient);
  }, [message]); // eslint-disable-line

}

// https://github.com/victorHAS/mqtt-react-hooks#tips
// https://trello.com/c/GnHclbAU
// todo: see useLiveViewInit
export const useCaptureLiveDeviceTrips = () => {
  // const queryClient = useQueryClient();

  useCheckMqttAuth();
  useUpdateMqttStatus();

  useUpdateLiveDeviceList();

  useUpdateLiveDevice();

  return useLiveStatus();
}

// useLiveDeviceStatus returns the status information for the live/selected device
// Not to be confused with useSelectedLiveDeviceStatus
const useLiveDeviceStatus = () => useGlobalStore(state => state.deviceStatus);

// useSelectedLiveDeviceStatus is the most recent status information for the
// currently selected device from the device list.
// Not to be confused with useLiveDeviceStatus.
// todo: this is deprecated
const useSelectedLiveDeviceStatus = () => useGlobalStore(state => _.get(state.liveDeviceStatusMap, state.selectedDevice?.serialNumber ?? null));

// useDeviceTripId is the trip id of the currently selected device
// it is the most recently set tripId, probably via an mqtt message
// const useDeviceTripId = () => useGlobalStore(state => state.deviceStatus?.deviceDb?.trip_id ?? null);
const useDeviceTripId = () => useGlobalStore(state => state.deviceStatus?.tripId ?? null);

const useDeviceDb = () => useGlobalStore(state => state.deviceStatus?.deviceDb ?? null);

// useSelectedDeviceTripMapPosition is used during the device/trip pan
// operation. The last known trip position should be offered. This could also be
// the last known position of the device itself.
export const useSelectedDeviceTripMapPosition = () => {
  const status = useLiveDeviceStatus();
  const gps_data = status?.positionDb ?? null;
  const lat = gps_data?.latitude ?? null;
  const lng = gps_data?.longitude ?? null;
  // todo: this changes alot, should we perhaps remove useMemo
  // https://blog.bitsrc.io/when-using-usememo-is-a-really-bad-idea-a2bdeb909812
  return React.useMemo(() => {
    // console.warn('useSelectedDeviceTripMapPosition', {lat, lng});
    return _.isNil(lat) || _.isNil(lng) ? null : {
      lat,
      lng,
    }
  }, [lat, lng])
}

export const useTripSummary = (isLive) => useSelectedTripSummary(isLive);

// createSummaryInfo ensures that the correct fields are available for the
// info-view summary (see SelectedDeviceTripInfoView).
const createSummaryInfo = (opts) => {
  const res = _.defaults(opts, {
    id: null,
    key: null,
    liveView: false,
    unitSystem: null,
    tripStartTime: null,
    tripEndTime: null,
    driverName: null,
    driverSurname: null,
    driverFullName: 'N/A',
    vehicleAlias: null,
    vehicleRegistrationNumber: null,
    vehicleColour: null,
    vehicleMake: null,
    vehicleModel: null,
    vehicleDescription: null,
    tripDescription: 'N/A',
    deviceStatus: 'Offline',
    tripStatus: 'Stopped',
    tripId: 'N/A',
    // tripDistance in meters
    tripDistance: null,
    // tripDuration in seconds
    tripDuration: null,
    deviceLastSeen: null,
    deviceSn: null,
    driverId: null,
    vehicleId: null,
    tripAlertCount: null,
    lastActivity: null,
  })

  const tripStartTime = toDateTime(res.tripStartTime);
  const tripEndTime = toDateTime(res.tripEndTime);
  const deviceLastSeen = toDateTime(res.deviceLastSeen);
  const currentTime = DateTime.now();

  const tripAlertCount = _.chain(res.tripAlertCount).defaultTo(0).value();
  res.tripAlertCount = tripAlertCount;

  res.tripStatus = _.capitalize(_.defaultTo(res.tripStatus, 'stopped'));
  res.deviceStatus = _.capitalize(res.deviceStatus);
  res.deviceLastSeen = _.isNil(res.deviceLastSeen) ? 'N/A' : toDisplayTimeAbbr(res.deviceLastSeen);

  // https://trello.com/c/BAviZZYd
  const vehicleColour = _.chain(res.vehicleColour).defaultTo('none').toLower().startCase().value();
  // const vehicleColour = _.chain(res.vehicleColour).defaultTo('None').value();
	res.vehicleColour = vehicleColour;

  // https://trello.com/c/BAviZZYd
  // const vehicleAlias = _.chain(res.vehicleAlias).defaultTo('none').toLower().startCase().value();
  const vehicleAlias = _.chain(res.vehicleAlias).defaultTo('None').value();
  res.vehicleAlias = vehicleAlias;

  // https://trello.com/c/BAviZZYd
  // const vehicleMake = _.chain(res.vehicleMake).defaultTo('none').toLower().startCase().value();
  const vehicleMake = _.chain(res.vehicleMake).defaultTo('None').value();
  res.vehicleMake = vehicleMake;

  // https://trello.com/c/BAviZZYd
  // const vehicleModel = _.chain(res.vehicleModel).defaultTo('none').toLower().startCase().value();
  const vehicleModel = _.chain(res.vehicleModel).defaultTo('None').value();
  res.vehicleModel = vehicleModel;

  const vehicleRegistrationNumber = _.chain(res.vehicleRegistrationNumber).defaultTo('None').value();
  res.vehicleRegistrationNumber = vehicleRegistrationNumber;

	res.vehicleDescription = `${vehicleAlias}, ${vehicleRegistrationNumber}, ${vehicleColour} ${vehicleMake} ${vehicleModel}`;

  const driverName = res?.driverName ?? 'None';
  const driverSurname = res?.driverSurname ?? 'None';
	res.driverFullName = _.isNil(res.driverName) ? 'None' : `${res?.driverName ?? ''} ${res?.driverSurname ?? ''}`.trim() ?? 'None';
  res.driverName = driverName;
  res.driverSurname = driverSurname;

	// const dt = _.isString(dateTime) ? DateTime.fromISO(dateTime, { zone: 'utc' }) : dateTime ?? DateTime.now();

	res.tripStartTime = res.liveView ? `${toDisplayTimeAbbr(res?.tripStartTime)}` : `${toDisplayTimeLong(res?.tripStartTime)}`;
	res.tripEndTime = res.liveView ? `${toDisplayTimeAbbr(res?.tripEndTime)}` : `${toDisplayTimeLong(res?.tripEndTime)}`;

  const deviceSn = _.defaultTo(res.deviceSn, 'None');
  res.deviceSn = deviceSn;

  const tripId = _.defaultTo(res.tripId, 'None');
  res.tripId = tripId;

  res.driverId = _.defaultTo(res.driverId, 'None');
  res.vehicleId = _.defaultTo(res.vehicleId, 'None');
  res.unitSystem = _.defaultTo(res.unitSystem, 'metric');
  res.tripDistance = _.isNil(res.tripDistance) ? 'None' : formatDistance(_.defaultTo(res.tripDistance, 0) / 1000.0, res.unitSystem);


  const tripNow = _.chain(tripEndTime).defaultTo(deviceLastSeen).defaultTo(currentTime).value();
  const tripStart = _.chain(tripStartTime).defaultTo(deviceLastSeen).defaultTo(currentTime).value();

  const tripDuration = _.chain(res.tripDuration).defaultTo((tripNow-tripStart)/1000).defaultTo(null).value();
  res.tripDuration = _.isNil(tripDuration) ? 'N/A' : formatDuration(_.defaultTo(tripDuration, 0));

  // res.tripDescription = `${res.tripStatus} (${res.tripDuration})`
  // res.tripDescription = `${res.tripDuration} (${res.tripStatus})`
  res.tripDescription = `${res.tripStatus}, ${res.tripAlertCount} alerts, ${res.tripDistance}, ${res.tripDuration}`

  const id = deviceSn ?? tripId ?? `${Date.now()}`;
  res.id = id;
  res.key = id;

  const lastActivity = toDisplayTimeRelShort(_.chain(res.lastActivity).defaultTo(deviceLastSeen).value());
  res.lastActivity = lastActivity;

  // console.warn('createSummaryInfo', {
  //   tripDuration,
  // });
  return res
}

export const useLiveDeviceOffline = () =>
  useGlobalStore(state => _.isEqual(_.defaultTo(state.deviceStatus?.deviceDb?.status, 'offline'), 'offline'));

// useLiveDeviceVehicleRegNum returns the selected live device's last trip_vehicle_registration_number.
// todo: if the last trip is not in progress (i.e. stopped), consider returning the device's registered vehicle
export const useLiveDeviceVehicleRegNum = () =>
  useGlobalStore(state => state.deviceStatus?.deviceDb?.trip_vehicle_registration_number ?? state.deviceStatus?.deviceDb?.vehicle_registration_number ?? null);

// useSelectedDeviceSummary displays information about the live selected device.
const useSelectedDeviceSummary = () => {

  // const deviceStatus = useLiveDeviceStatus();
  const deviceDb = useDeviceDb();

  const unitSystem = useUnitSystem();

  return React.useMemo(() => {
    // if tripData is null, then it means there is no trip to view
    // in that case, disable the trip tab, or display an indicator that says no trip data
    // fall back to (or just show) the linked device data (vehicle registration etc) (via useFetchDevice or similar)
    // console.warn('useSelectedDeviceSummary: deviceDb', {deviceDb})
    return createSummaryInfo({
      liveView: true,
      unitSystem: unitSystem,
      tripStartTime: deviceDb?.trip_start_time ?? deviceDb?.trip_date_created,
      tripEndTime: deviceDb?.trip_end_time,
      driverName: deviceDb?.trip_driver_name ?? deviceDb?.driver_name,
      driverSurname: deviceDb?.trip_driver_surname ?? deviceDb?.driver_surname,
      vehicleAlias: deviceDb?.trip_vehicle_name ?? deviceDb?.vehicle_name,
      vehicleRegistrationNumber: deviceDb?.trip_vehicle_registration_number ?? deviceDb?.vehicle_registration_number,
      vehicleColour: deviceDb?.trip_vehicle_colour ?? deviceDb?.vehicle_colour,
      vehicleMake: deviceDb?.trip_vehicle_make ?? deviceDb?.vehicle_make,
      vehicleModel: deviceDb?.trip_vehicle_model ?? deviceDb?.vehicle_model,
      deviceStatus: deviceDb?.status,
      tripStatus: deviceDb?.trip_status,
      tripId: deviceDb?.trip_id,
      deviceLastSeen: deviceDb?.last_online_time ?? deviceDb?.trip_last_active_time,
      deviceSn: deviceDb?.serial_number,
      driverId: deviceDb?.trip_driver_id ?? deviceDb?.driver_id,
      vehicleId: deviceDb?.trip_vehicle_id ?? deviceDb?.vehicle_id,
      tripDistance: deviceDb?.trip_distance,
      tripDuration: deviceDb?.trip_duration,
      tripAlertCount: deviceDb?.trip_alert_count,
    })
  }, [deviceDb, unitSystem]);
}

const useSelectedTripDbSummary = () => {

  const tripId = useSelectedTripId();

  const { data: tripData} = useFetchTripDetails(tripId);

  const unitSystem = useUnitSystem();

  return React.useMemo(() => {
    // if tripData is null, then it means there is no trip to view
    // in that case, disable the trip tab, or display an indicator that says no trip data
    // fall back to (or just show) the linked device data (vehicle registration etc) (via useFetchDevice or similar)
    // console.warn('useSelectedTripDbSummary: tripData', {tripData})
    return createSummaryInfo({
      liveView: false,
      unitSystem: unitSystem,
      tripStartTime: tripData?.start_time ?? tripData?.trip_start_time ?? tripData?.trip_last_active_time ?? tripData?.last_online_time ?? null,
      tripEndTime: tripData?.end_time,
      driverName: tripData?.driver_name,
      driverSurname: tripData?.driver_surname,
      vehicleAlias: tripData?.vehicle_name,
      vehicleRegistrationNumber: tripData?.vehicle_registration_number,
      vehicleColour: tripData?.vehicle_colour,
      vehicleMake: tripData?.vehicle_make,
      vehicleModel: tripData?.vehicle_model,
      deviceStatus: null,
      tripStatus: tripData?.status,
      tripId: tripId,
      deviceSn: tripData?.device_serial_number,
      driverId: tripData?.driver_id,
      vehicleId: tripData?.vehicle_id,
      tripDistance: tripData?.distance,
      tripDuration: tripData?.duration,
      tripAlertCount: tripData?.alert_count,
    })
  }, [tripData, unitSystem]);
}

// useSelectedTripSummary provides summary information for a selected live
// device, selected live trip, or selected historic trip.
// if isLive, then treat this as useSelectedDeviceSummary
// if not isLive, then treat this as useSelectedTripDbSummary
const useSelectedTripSummary = (isLive) => {
  const deviceSummary = useSelectedDeviceSummary();
  const tripSummary = useSelectedTripDbSummary();
  return isLive ? deviceSummary : tripSummary;
}

// https://trello.com/c/TxUzcxSv
// https://trello.com/c/ipPHjAXT
// https://trello.com/c/TxUzcxSv

// useLiveDeviceTripMarkerList will return a map of a selected device to its
// marker. If there is no selected device then it will return all live device
// markers. If filterSelected is false, then it will always return all live
// device markers.
export const useLiveDeviceTripMarkerList = (filterSelected) => {

  const liveDeviceStatusArray = useLiveDeviceStatusArray();

  const liveDeviceStatusArrayLim = useThrottle(liveDeviceStatusArray, 500);

  // https://blog.bitsrc.io/when-using-usememo-is-a-really-bad-idea-a2bdeb909812
  // https://trello.com/c/068kphdN/404-live-view-displays-white-screen
  return React.useMemo(() => {
    // console.warn('useLiveDeviceTripMarkerList', {
    //   liveDeviceStatusArray,
    // });
    return liveDeviceStatusArrayLim.map((status, index, array) => {
      const lastPositionMarker = createMapPathMarker(status, index);
      if (lastPositionMarker && lastPositionMarker.position) {
        return lastPositionMarker;
      }
      return null;
    }).filter(d => !_.isNil(d))
  }, [liveDeviceStatusArrayLim]);
}

// useLiveDeviceTripPath combines database path with realtime path. If a user
// joins a live trip late, then they won't have the older realtime data, so it
// is necessary to first do a fetch from the database, and then augment those
// values with newer realtime data.
export const useLiveDeviceTripPath = () => {
  const tripPath = useDeviceTripPath();
  return React.useMemo(() => {
    // console.log('useLiveDeviceTripPath');
    return _useTripMapPath(tripPath)
  }, [tripPath]);
}

// useLiveDeviceEventMarkers combines database markers with realtime markers. If
// a user joins a live trip late, then they won't have the older realtime data,
// so it is necessary to first do a fetch from the database, and then augment
// those values with newer realtime data.
export const useLiveDeviceEventMarkers = (typeFilters) => {
  const deviceTrip = useDeviceTripInfo();
  const tripAlerts = useDeviceTripAlerts();
  const eventList = React.useMemo(() => {
    // console.log('useLiveDeviceEventMarkers', deviceTrip, tripAlerts);
    return _useTripMapEventList(deviceTrip, tripAlerts);
  }, [deviceTrip, tripAlerts]);
  return useEventMarkersFiltered(eventList, typeFilters);
}

export const useEventMarkersFiltered = (eventMarkers, typeFilters) => {
  return React.useMemo(() => {
    // console.log('useEventMarkersFiltered', eventMarkers, typeFilters);
    return eventMarkers.filter(e => typeFilters[e.alert_type]).map((e, i) => ({
      ...e,
      index: i,
    })) ?? DEFAULT_EMPTY_LIST
  }, [eventMarkers, typeFilters]);
}

export const useStateEventTypeFilters = () => {
  return React.useState(generateEventTypeFilters());
}


export const useTokenIdentifier = () => {
  const token = useTokenStore(state => state.token);
  return token ? sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(token)) : null;
}

export const useMqttClientId = () => {
  const liveId = useGlobalStore(state => state.liveId);
  const username = useUserEmail();

  // todo: also generate a newLiveId if the current liveId is null
  // todo: this can cause a white page or refresh loop if the dvmqtt server is offline
  // React.useEffect(() => {
  //   newLiveId();
  // }, [username]);

  // const liveId = `web-app-${_.padStart(_.random(99), 3, '0')}-${Date.now()}`
  return React.useMemo(() => `${liveId}-${username}`, [liveId, username]);
}

// these options will determine the permissions (i.e. which topics the client can subscribe to)
// ClientAdmins may only be able to subcribe to some topics
// Drivers may only be able to subcribe to some topics
// see dvapi /mosquitto-aclcheck for the rules
// warning: everytime useMqttOptions creates a new object, it will refresh the Connector, thereby refreshing the page
export const useMqttOptions = () => {
  const token = useTokenStore(state => state.token)
  const identifier = useTokenIdentifier();

  const clientId = useMqttClientId();

  // console.log('useMqttOptions', token, identifier, liveId);
  // the dvapi /mosquitto-aclcheck uses the clientId, username and password to determine which subscriptions are allowed by useSubscription
  // options are forwarded by mqtt-react-hooks to: https://www.npmjs.com/package/mqtt
  // https://github.com/mqttjs/MQTT.js#client
  // https://github.com/VictorHAS/mqtt-react-hooks/blob/master/lib/Connector.tsx
  // todo: rewrite mqtt-react-hooks Connector.tsx
  return React.useMemo(() => {
    console.warn('useMqttOptions', {clientId});
    return {
      _createdOn: Date.now(), // this can help us to detect options that are not working and to try other options
      clientId: clientId,
      username: token,
      // password: identifier, // this can be anything, so consider changing this instead of the clientId
      password: `${Date.now()}`, // this can be anything, so consider changing this instead of the clientId
      clean: true, // set to false to receive QoS 1 and 2 messages while offline
      keepalive: 0, // https://github.com/VictorHAS/mqtt-react-hooks/issues/13#issuecomment-767766496
      // keepalive: 30, // seconds, set to 0 to disable (if there is no activity on the websocket after this period, the websocket closes)
      reconnectPeriod: 1500, // milliseconds, interval between two reconnections. Disable auto reconnect by setting to 0.
      // reconnectPeriod: 0, // milliseconds, interval between two reconnections. Disable auto reconnect by setting to 0.
      // connectTimeout: 30 * 1000, // milliseconds, time to wait before a CONNACK is received
      connectTimeout: 3 * 1000, // milliseconds, time to wait before a CONNACK is received
      // connectTimeout: 3 * 1000, // milliseconds, time to wait before a CONNACK is received
      // queueQoSZero: true, // if connection is broken, queue outgoing QoS zero messages (default true)
      // resubscribe : // if connection is broken and reconnects, subscribed topics are automatically subscribed again (default true)
    }
  }, [token, identifier, clientId]);
}

export const useEventIconSize = () => usePreferenceStore((state) => state.preferences.event_icon_size ?? 40);

export const _useTripMapPath = (tripPath) => {
  // https://trello.com/c/068kphdN/404-live-view-displays-white-screen
  return tripPath && tripPath.filter(t => t.latitude && t.longitude).map(t => ({
    lat: t.latitude,
    lng: t.longitude
  }));
}

export const useTripMapPath = (tripPath) => {
  return _useTripMapPath(tripPath);
}

export const useTripMapPathStart = (tripPath) => {
  return React.useMemo(() => {
    const [pos] = tripPath.slice(0);
    return pos ?? { lat: -25.7470155, lng: 28.2725573 }
  }, [tripPath]);
}

export const useTripMapPathFinish = (tripPath) => {
  return React.useMemo(() => {
    const [pos] = tripPath.slice(-1);
    return pos ?? { lat: -25.7470155, lng: 28.2725573 }
  }, [tripPath]);
}


const _useTripMapEventList = (trip, tripEvents) => {
  // https://trello.com/c/068kphdN/404-live-view-displays-white-screen
  return tripEvents && tripEvents.filter(t => t.position?.latitude && t.position?.longitude).map((t, i) => createMapEventMarker(trip, t, i));
}

export const useTripMapEventList = (trip, tripEvents) => {
  // https://trello.com/c/068kphdN/404-live-view-displays-white-screen
  return React.useMemo(() => _useTripMapEventList(trip, tripEvents), [trip, tripEvents]);
}

// https://trello.com/c/EnF0vDIb/491-live-view-vehicle-trip-status
export const useDriverPulseClass = (deviceStatus, tripStatus) => {
  // This Marker should pulse. This is achieved by live-global-events.css, the
  // pulse class and the driver_location_online class.
  return React.useMemo(() => {
    return !_.isNil(deviceStatus) && _.isEqual(deviceStatus, 'online') && _.isEqual(tripStatus, 'active') ? `driver_location_online` : null
  }, [deviceStatus]);
}

export const useDriverIcon = (bearing, deviceStatus, tripStatus) => {
  const size = usePreferenceStore((state) => state.preferences.driver_icon_size);
  const strokeSize = useStrokeSize();
  return React.useMemo(() => {
    const knownIcon = getDriverLocationIcon(deviceStatus, tripStatus);
    return {
      path: knownIcon.path,
      fillColor: knownIcon.fillColor,
      fillOpacity: knownIcon.fillOpacity,
      scale: (size ?? 1.0) * knownIcon.scale,
      anchor: knownIcon.anchor,
      strokeWeight: _.defaultTo(knownIcon.strokeWeight, 1) * _.defaultTo(strokeSize, 1),
      strokeColor: knownIcon.strokeColor,
      strokeOpacity: knownIcon.strokeOpacity,
      rotation: bearing ?? 0
    }
  }, [bearing, size, strokeSize, deviceStatus, tripStatus]);
}

// useDriverTooltip provides information about a driver and its current trip.
// data is an item from the useLiveDeviceTripMarkerList (i.e. createMapPathMarker)
export const useDriverTooltip = (data) => {

  const device_status = `Device ${_.capitalize(data?.status ?? 'offline')}`;
  const trip_status = `Trip ${_.capitalize(data?.trip_status ?? 'stopped')}`;
  const status = `${device_status} (${trip_status})`;
  const driver_name = data?.trip_driver_name ?? data?.driver_name ?? '';
  const driver_surname = data?.trip_driver_surname ?? data?.driver_surname ?? '';
  const vehicle_name = data?.trip_vehicle_name ?? data?.vehicle_name ?? '';
  const vehicle_registration_number = data?.trip_vehicle_registration_number ?? data?.vehicle_registration_number ?? '';

  // console.warn('useDriverTooltip: todo: get the device details instead', {
  //   data
  // })

  // note: the formatting of the string literal is important here
  return _.isNil(data) ? 'No data' :
    `${status}
${vehicle_registration_number}
${vehicle_name}
${driver_name} ${driver_surname}
`.trim();
}

// https://developers.google.com/maps/documentation/javascript/examples/marker-modern
export const useEventIcon = (typeLower, bearing) => {
  const eventIconSize = useEventIconSize();
  return React.useMemo(() => {
    const eventTypeIcon = getEventTypeIcon(typeLower);
    const knownIcon = mapIcons[typeLower] ? mapIcons[typeLower] : mapIcons.unknown;
    return eventTypeIcon ? {
      url: `${process.env.PUBLIC_URL}/assets/img/markers/${eventTypeIcon}`,
      size: { width: eventIconSize, height: eventIconSize },
      origin: { x: 0, y: 0 },
      anchor: { x: eventIconSize / 2, y: eventIconSize / 2 },
      scaledSize: { width: eventIconSize, height: eventIconSize },
      label: null,
    } : {
      path: knownIcon.path,
      fillColor: knownIcon.fillColor,
      fillOpacity: knownIcon.fillOpacity,
      // fillWeight: 1,
      origin: knownIcon.origin,
      scale: knownIcon.scale,
      anchor: knownIcon.anchor,
      strokeWeight: knownIcon.strokeWeight,
      strokeColor: knownIcon.strokeColor,
      strokeOpacity: knownIcon.strokeOpacity,
      rotation: bearing && bearing,
      labelOrigin: knownIcon.labelOrigin,
      label: knownIcon.label,
    }
  }, [typeLower, eventIconSize, bearing]);
}

// createMarkerInfo creates a clickable marker that can be used for selecting trips or events.
// Fields required for click:
// data?.device_serial_number
// data?.trip_id
// data?.driver_id
// data?.vehicle_id
const createMarkerInfo = (tripOrDevice, index) => {
  // console.warn('createMarkerInfo', tripOrDevice, index);
  return {
    // key should not be null
    // key: key,
    device_serial_number: tripOrDevice?.serial_number ?? tripOrDevice?.device_serial_number ?? null,
    trip_id: tripOrDevice?.trip_id ?? tripOrDevice?.id ?? null,
    driver_id: tripOrDevice?.trip_driver_id ?? tripOrDevice?.driver_id ?? null,
    driver_name: tripOrDevice?.trip_driver_name ?? tripOrDevice?.driver_name ?? tripOrDevice?.driver?.name ?? "N/A",
    driver_surname: tripOrDevice?.trip_driver_surname ?? tripOrDevice?.driver_surname ?? tripOrDevice?.driver?.surname ?? null,
    driver_email: tripOrDevice?.trip_driver_email ?? tripOrDevice?.driver_email ?? tripOrDevice?.driver?.email ?? null,
    vehicle_id: tripOrDevice?.trip_vehicle_id ?? tripOrDevice?.vehicle_id ?? tripOrDevice?.vehicle?.id ?? null,
    vehicle_name: tripOrDevice?.trip_vehicle_name ?? tripOrDevice?.vehicle_name ?? tripOrDevice?.vehicle?.name ?? "N/A",
    vehicle_registration_number: tripOrDevice?.trip_vehicle_registration_number ?? tripOrDevice?.vehicle_registration_number ?? tripOrDevice?.vehicle?.registration_number ?? "N/A",
    index: index,
  }
}

// createMapEventMarker creates a marker that can be rendered on a map with
// supplementary information.
const createMapEventMarker = (trip, message, eventIndex) => {
  // console.log('createMapEventMarker', trip, message, eventIndex)
  return {
    ...generateEventObject(message, eventIndex),
    ...createMarkerInfo(trip, eventIndex),
  };
}

// createMapPathMarker creates a marker that can be rendered on a map with
// supplementary information.
// createMapPathMarker is clickable and needs a clickHandler
const createMapPathMarker = (status, positionIndex) => {

  if (_.isNil(status)) {
    console.warn('createMapPathMarker has no status data for context');
    return null
  }

  // const posObject = generatePositionObject(status?.data?.message_detail?.gps_data ?? null, trip?.id ?? status.tripId ?? status.key);
  // const posObject = generatePositionObject(status?.data?.message_detail?.gps_data ?? null, trip?.id ?? status.tripId ?? status.key);
  const positionDb = status?.positionDb;
  if (_.isNil(positionDb)) {
    return null
  }

  const deviceDb = _.get(status, 'deviceDb');
  if (_.isNil(deviceDb)) {
    console.warn('createMapPathMarker has no deviceDb data for context', {status});
    return null
  }

  // console.warn('createMapPathMarker:', {
  //   status,
  //   deviceDb,
  //   positionIndex,
  // });

  // const bearing = positionDb.bearing ?? previousBearing ?? status?.bearing ?? 0;
  const bearing = status?.bearing ?? 0;
  const key = deviceDb?.trip_id ?? deviceDb?.serial_number;
  const device_serial_number = deviceDb?.serial_number;
  const deviceStatusText = deviceDb?.status ?? status.deviceStatusText;
  const tripStatusText = deviceDb?.trip_status ?? status.tripStatusText;
  const res = {
    ...positionDb,
    ...createMarkerInfo(deviceDb, positionIndex),
    bearing: bearing,
    type: "driver_location",
    key,
    device_serial_number,
    status: deviceStatusText,
    trip_status: tripStatusText,
  };
  if (_.isNil(res.key)) {
    console.warn('createMapPathMarker: path marker has nil key', {status, deviceDb, res});
  }
  return res;
}

// https://trello.com/c/GnHclbAU
// See also useCaptureLiveDeviceTrips
export const useLiveViewInit = () => {
  const queryClient = useQueryClient();
  // todo: consider using useEffectOnce
  React.useEffect(() => {
    console.warn('useLiveViewInit: LiveView loaded. Clearing selected trip.')
    // setLiveDeviceStatus(null);
    clearSelectedTrip(queryClient);
    setFocusDeviceTrip(false);
    setFocusTripEvent(false);
    // hideNavBar();
  }, []);
}

export async function callLogin(username, password) {
  const d = await authorize(username, password)
  const r = d.result;
  useTokenStore.getState().setTokens(r.token);
  setUser(r.user);
  return r
}

export async function callUpdatePassword(username, oldPassword, newPassword) {
  return await updateUserPassword(username, oldPassword, newPassword, callLogin)
}

export function useUpdatePassword(oldPassword, newPassword, confirmPassword) {

  const [status, setStatus] = React.useState({
    error: false,
    message: '',
  });

  const [loading, setLoading] = React.useState(false);

  const username = useUserEmail();

  const history = useHistory();

  return {
    loading: loading,
    setLoading: setLoading,
    username: username,
    history: history,
    status: status,
    setStatus: setStatus,
    updatePassword: React.useCallback(() => {
      if (_.isEmpty(oldPassword)) {
        setStatus({
          error: true,
          message: 'Please enter your current password.',
        });
      }
      else if (_.isEmpty(newPassword)) {
        setStatus({
          error: true,
          message: 'Please enter your new password.',
        });
      }
      else if (_.isEmpty(confirmPassword)) {
        setStatus({
          error: true,
          message: 'Please confirm your new password.',
        });
      }
      else if (!_.isEqual(newPassword, confirmPassword)) {
        setStatus({
          error: true,
          message: 'Your password confirmation does not match.',
        });
      }
      else {
        setLoading(true);
        setStatus({
          error: false,
          message: '',
        });
        callUpdatePassword(username, oldPassword, newPassword)
          .then(r => {
            setStatus({
              error: false,
              message: 'Password successfully updated!',
            });
            history.push("/login?reason=pc") // pc = password change
          })
          .catch(e => {
            setStatus({
              error: true,
              message: `${e.message}`,
            });
          })
          .finally(() => {
            setLoading(false);
          })
      }
    }, [username, oldPassword, newPassword, confirmPassword]),
  }
}

export function useAlertMessage() {
  const [alertMessage, setAlertMessage] = React.useState(null);

  const setMessage = React.useCallback((severity, message) => setAlertMessage({
    severity: severity,
    message: message,
  }), [setAlertMessage]);

  const clearMessage = React.useCallback(() => setAlertMessage(null), [setAlertMessage]);

  return {
    alertMessage: alertMessage,
    setAlertMessage: setMessage,
    clearAlertMessage: clearMessage
  };
}

export function useUpdateUserForm(userId) {

  // userId param is the user that will be updated
  // currentUserId is the user performing the update
  const currentUserId = useUserId();

  const currentUserRole = useUserRole();

  const roleLabel = React.useCallback((option) => option?.label ?? '', []);

  const { data: userData } = useFetchUser(userId);

  const { alertMessage, setAlertMessage, clearAlertMessage } = useAlertMessage();

  const [name, setName] = React.useState('');
  const [surname, setSurname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [image, setImage] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const roleDisabled = React.useMemo(() => {
    return true
    // if (_.isEqual(currentUserRole, 'ClientAdmin')) {
    //   return false
    // }
    // if (_.isEqual(currentUserId, userId)) {
    //   return true
    // }
  }, [currentUserRole, currentUserId, userId]);

  const roleList = React.useMemo(() => {
    return [{
      value: 'Driver', label: 'Driver', level: 0,
    }, {
      value: 'HRUser', label: 'HR User', level: 1,
    }, {
      value: 'OperationalUser', label: 'Operational User', level: 1,
    }, {
      value: 'ClientAdmin', label: 'Client Admin', level: 2,
    }, {
      value: 'Owner', label: 'Owner', level: 3,
    }, {
      value: '5DTAdmin', label: '5DT Admin', level: 4,
    }]
  }, []);

  React.useEffect(() => {
    if (!_.isNil(userData)) {
      setName(userData?.name ?? '');
      setSurname(userData?.surname ?? '');
      setEmail(userData?.email ?? '');
      setRole(_.find(roleList, (opt) => opt.value === userData?.role ?? ''));
    }
  }, [userData, roleList]);

  const onSetRole = React.useCallback((event, data) => {
    setRole(data);
  }, [setRole]);

  const queryClient = useQueryClient()

  const updateUser = React.useCallback((payload) => {
    if (_.isEmpty(name)) {
      setAlertMessage("error", "A name is required.")
    } else if (_.isEmpty(surname)) {
      setAlertMessage("error", "A surname is required.")
    } else if (_.isEmpty(email)) {
      setAlertMessage("error", "An email address is required.")
    } else if (_.isEmpty(role)) {
      setAlertMessage("error", "A role is required.")
    } else {
      setLoading(true);
      clearAlertMessage();
      userUpdate(payload)
        .then(r => {
          queryClient.invalidateQueries(['user', userId]);
          setAlertMessage("success", "Successfully updated user details.")
        })
        .catch(e => {
          console.warn('updateUser', e);
          setAlertMessage("error", "Could not update user details.")
        })
        .finally(() => {
          setLoading(false);
        })

      if (!_.isNil(image)) {
        setLoading(true);
        updateUserProfilePicture(userId, image)
          .then(r => {
            queryClient.invalidateQueries(['user-picture', userId]);
            setAlertMessage("success", "Successfully updated user profile picture.")
          })
          .catch(e => {
            console.warn('updateUser', e);
            setAlertMessage("error", "Could not update user profile picture.")
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [setAlertMessage, userId, name, surname, email, image, role, queryClient]);

  const imageInputProps = React.useMemo(() => ({
    accept: "image/png, image/jpeg",
  }), []);

  return {
    alertMessage,
    setAlertMessage,
    clearAlertMessage,
    updateUser,
    name, setName,
    surname, setSurname,
    email, setEmail,
    image, setImage, imageInputProps,
    role, setRole: onSetRole, roleList, roleLabel, roleDisabled,
    loading, setLoading,
  }

}

export const useLiveDeviceStatusMap = () => useGlobalStore(state => state.liveDeviceStatusMap);

const _useLiveDeviceStatusArray = () => useGlobalStore(state => state.liveDeviceStatusList);

const useLiveDeviceStatusArray = () => {
  const liveDeviceStatus = useLiveDeviceStatus();
  const liveDeviceStatusArray = _useLiveDeviceStatusArray();
  return React.useMemo(() => {
    return _.isNil(liveDeviceStatus) ? _.defaultTo(liveDeviceStatusArray, []) : [ liveDeviceStatus ];
  }, [liveDeviceStatus, liveDeviceStatusArray])
}

// useLiveDeviceTripsArray does not perform filtering, so that we can ensure that indexes used with liveDeviceStatusMap are compatible with this array.
export const useLiveDeviceTripsArray = () => useGlobalStore(state => state.liveDeviceTripList);

export const useCallbackToggleDistance = () => {
  const unitSystem = useUnitSystem();
  const unitSystemList = useUnitSystemList();
  return React.useCallback(() => {
    const currentIndex = _.findIndex(unitSystemList, u => _.isEqual(_.toLower(u), _.toLower(unitSystem)));
    // https://dustinpfister.github.io/2021/12/17/lodash_clamp/
    const nextUnitSystem = _.get(unitSystemList, currentIndex+1, unitSystemList[0]);
    // console.warn('useCallbackToggleDistance', {
    //   unitSystem,
    //   unitSystemList,
    //   currentIndex,
    //   nextUnitSystem
    // })
    setUnitSystem(nextUnitSystem);
  }, [unitSystem, unitSystemList]);
}

export const useCurrentRoleOption = () => {
	const { rowOptions } = useFetchRoleOptions();
  const currentRole = useUserRole();
  return React.useMemo(() => _.find(rowOptions, (o) => _.isEqual(o.value, currentRole)), [rowOptions, currentRole]);
}

export const useCurrentRoleOptionList = () => {
  const currentRoleOption = useCurrentRoleOption();
	const { rowOptions } = useFetchRoleOptions();
	return React.useMemo(() => ({
		rowOptions: rowOptions.filter(o => currentRoleOption.level <= o.level),
	}), [rowOptions]);
}

export const useVersionUpdateInfo = () => {
  // https://www.npmjs.com/package/semver-diff
  // https://www.npmjs.com/package/semver-compare
  // https://www.npmjs.com/package/compare-versions
  const localVersion = useLocalVersion();
  const productVersion = useProductVersion();
  const diff = semverDiff(localVersion, productVersion);
  // console.warn('useVersionUpdateInfo', { localVersion, productVersion, diff })
  return React.useMemo(() =>({
    newVersion: !_.isNil(diff),
    versionDiff: diff,
    localVersion,
    productVersion
  }), [localVersion, productVersion, diff])
}

export const useGetNoticeCount = () => {
  // https://www.npmjs.com/package/semver-diff
  // https://www.npmjs.com/package/semver-compare
  // https://www.npmjs.com/package/compare-versions
	const { newVersion } = useVersionUpdateInfo();
  // todo: link this to an array of notifications
  return React.useMemo(() => newVersion ? 1 : 0, [newVersion])
}

export const useReleaseNotesUrl = () => {
  const roleRoot = useUserRoot();
  return `${roleRoot}/release-notes`;
}


export const useVersionToast = () => {
  const { newVersion, productVersion, versionDiff } = useVersionUpdateInfo();
  const releaseNotesUrl = useReleaseNotesUrl();
  const history = useHistory();
  const onClick = React.useCallback((event) => {
    event?.preventDefault();
    history.push(releaseNotesUrl);
  }, [releaseNotesUrl, history])

  React.useEffect(() => {
    if (newVersion) {
			toast(() => {
        return (
          <span style={{display: 'flex'}} onClick={onClick}>
            <span style={{flex: 1}}>
              <Typography variant="h6">New {versionDiff} update installed!</Typography>
              <Typography variant="body2">Click here to see what's new.</Typography>
            </span>
            <Typography variant="h3">🎉</Typography>
          </span>
        )
      }, {
        toastId: productVersion,
				position: "top-center",
				autoClose: false,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				// progress: 0,
			})
    }
  }, [onClick, newVersion, productVersion, versionDiff]);
}

export const useUpdateLocalVersion = () => {
  const productVersion = useProductVersion();
  React.useEffect(() => setLocalVersion(productVersion), [productVersion])
}

export const useRoleFeatureCheck = (feature) => {
  const userRoleFeatures = useUserRoleFeatures();
  return React.useMemo(() => userRoleFeatures.includes(feature), [userRoleFeatures, feature])
}

export const useRoleCanViewOwnTrips = () => {
  // todo: consider adding dvdb_admin_role_can_view_own_trips
  const featureCheck = useRoleFeatureCheck('role_can_view_own_trips');
  const userRole = useUserRole();
  return React.useMemo(() => featureCheck || _.isEqual(userRole, 'ClientAdmin'), [featureCheck, userRole])
}

export const useRoleCanViewOwnCourses = () => {
  const featureCheck = useRoleFeatureCheck('role_can_view_own_courses');
  return React.useMemo(() => featureCheck, [featureCheck])
}

export const useEffectFeatureCheck = (feature) => {

	const history = useHistory();

  const featureCheck = useRoleFeatureCheck(feature);

	React.useEffect(() => {
		if (!featureCheck) {
			history.goBack();
		}
	}, [featureCheck, history]);
}

export const useNavPaths = (path) => {
  // todo: if driver
  // todo: if clientadmin
  // todo: if 5dtadmin
  const userRole = useUserRole();
  const userRoleFeatures = useUserRoleFeatures();

	return React.useMemo(() => {
    const courses = userRoleFeatures.includes('role_can_view_own_courses') ? {
      'Training': `${path}/training`,
    } : {};
    if (_.isEqual(userRole, 'Driver')) {
      return ({
        'Dashboard': `${path}/dashboard`,
        'QR Code': `${path}/qr-code`,
        ...courses,
      })
    }
    if (_.isEqual(userRole, 'ClientAdmin')) {
      return ({
        'Live View': `${path}/live-view`,
        // 'Dashboard': `${path}/users/${userId}`,
        // 'Dashboard': `${path}/profile`,
        'Users': `${path}/users`,
        'User Groups': `${path}/user-groups`,
        'Vehicles': `${path}/vehicles`,
        'Vehicle Groups': `${path}/vehicle-groups`,
        'Devices': `${path}/devices`,
        'QR Code': `${path}/qr-code`,
        ...courses,
      })
    }
    if (_.isEqual(userRole, '5DTAdmin')) {
      return ({
        'Companies': `${path}/companies`,
        'Devices': `${path}/devices`,
        'Users': `${path}/users`,
        'Training Management': `${path}/training-management`,
      })
    }
    return ({
    })
	}, [userRole, userRoleFeatures, path]);
}
