import React from 'react';

import { useFetchCompanyOptions } from 'services/api';
import { BaseSelect } from './base-select';

const defaultOption = {
		value: "edb8c952-ebf1-51d7-807c-369e49dddcec",
		label: "None"
}

export const CompanySelect = ({onChange, value, autoFocus, help, ...props}) => {
	const { rowOptions: options } = useFetchCompanyOptions();
	return (
		<BaseSelect 
			disableClearable
			id="company_name" 
			label="Company Name" 
			severity="warning" 
			defaultOption={defaultOption} 
			options={options} 
			value={value} 
			onChange={onChange}
			help={help}
			autoFocus={autoFocus}
			{...props}
		/>
	)
}
