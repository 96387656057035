import './user-groups.css';
import React from 'react';
import {  useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from '@material-ui/core';
import { UserGroupTable } from 'components/data-tables/user-data-tables';

export const UserGroups = () => {
	
	const history = useHistory();
	const { path } = useRouteMatch();

	const handleRowClick = (data) => {
		history.push(`${path}/${data.data.id}`, { [data.data.id]: `${data.data.name}`})
	}
	
	const handleCreate = () => {
		history.push(`${path}/create`)
	}

	const defaultSearchCriteria = {
		name: '',
	}

	const searchCriteria = {
		name: '',
	}

	return (
		<div className={"user-group-table-container"}>
			<h1>User Groups</h1>
			<Button variant="contained" color="primary" onClick={ handleCreate }>Create</Button>
			<UserGroupTable
				searchCriteria={searchCriteria}
				defaultSearchCriteria={defaultSearchCriteria}

				onRowClick={ handleRowClick } />
		</div>
	);
}
