import './user-alerts.css';
import React  from 'react';

import { useParams } from 'react-router-dom';
import { EventTable } from 'components/data-tables/trip-event-data-tables';

export const UserAlerts = () => {

	const { userId } = useParams();

	const handleRowClick = (data) => {

	}

	const defaultCriteria = {
		orderBy: 'timestamp',
		flipOrder: true,
	}

	const defaultSearchCriteria = {
		vehicle_id: '',
		vehicle_registration_number: '',
	}

	const searchCriteria = {
		vehicle_id: '',
		vehicle_registration_number: '',
	}

	const fixedSearchCriteria = {
		driver_id: userId
	}

	const allowedColumns = [
		"device_id",
		"alert_type",
		"timestamp",
		"vehicle_name",
		"vehicle_registration_number",
	]

	return (
		<EventTable
			defaultCriteria={defaultCriteria}
			defaultSearchCriteria={defaultSearchCriteria}
			searchCriteria={searchCriteria}
			fixedSearchCriteria={fixedSearchCriteria}
			allowedColumns={allowedColumns}

			onRowClick={ handleRowClick } />
	)
}
