import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AdminUserList from "./admin-user-list";

export default () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}`}>
				<AdminUserList />
			</Route>
		</Switch>
	);
}
