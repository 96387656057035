import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AdminCompanies } from './admin-companies/admin-companies';

export const AdminCompanyPage = () => {

	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}`}>
				<AdminCompanies />
			</Route>
		</Switch>
	);
}
