import { axiosPostRequest } from './api-request';

function vehicleGroups(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'vehicle Groups fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function vehicleGroup(id) {
	try {
		const body = {
			id: id
		}
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group/get`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'vehicle Group fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function create(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group/create`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'vehicle Group creation failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function deleteVehicleGroup(data) {
	try {
		const body = { id: data };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group/delete`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'vehicle Group creation failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function update(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group/update`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'vehicle Group deletion failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function manageVehicleGroupParticipants(groupId, addedVehicles, removedVehicles, type) {
	try {
		const body = {
			group_id: groupId,
			add: addedVehicles,
			remove: removedVehicles,
			type: type,
		}
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group/manage-participants`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || `Vehicle Group ${type} management failed`);
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function vehicleGroupVehicles(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group/get/vehicles`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || `User fetch failed`);
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function vehicleGroupOwners(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group/get/owners`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || `Owner fetch failed`);
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function vehicleGroupViewers(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-group/get/viewers`, data);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || `Viewer fetch failed`);
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export {
	vehicleGroups,
	vehicleGroup,
	create,
	deleteVehicleGroup,
	update,
	manageVehicleGroupParticipants,
	vehicleGroupVehicles,
	vehicleGroupOwners,
	vehicleGroupViewers,
};
