import "./admin-training-management-page.css";
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AdminCourses } from "./admin-courses/admin-courses";
import { AdminCourseCreate } from "./admin-course-create/admin-course-create";
import { AdminCourse } from "./admin-course/admin-course";

export const AdminTrainingManagementPage = () => {

	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}`} exact>
				<AdminCourses />
			</Route>
			<Route path={`${path}/create`} exact >
				<AdminCourseCreate />
			</Route>
			<Route path={`${path}/:courseId`} exact >
				<AdminCourse />
			</Route>
			{/* <Route path={`${path}/:deviceId/link-vehicle`} exact >
				<DeviceEdit />
			</Route> */}
			{/* <Route path={`${path}/:deviceId/edit`} exact >
				<DeviceEdit />
			</Route> */}
		</Switch>
	);
}
