import React from 'react';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AssignmentIcon from '@material-ui/icons/Assignment';

import _ from 'lodash';

import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';

import { makeStyles, createStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';

import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import useCopyToClipboard from 'shared-hooks/useCopyToClipboard';
import { useCallbackToggleDistance } from 'modules/useGlobalStore';
import { formatDistance, formatDuration } from 'shared-functions/shared-functions';
import { useUnitSystem } from 'shared-hooks/useDistanceUnit';

const fontFamily1 = [
	// 'Chilanka',
	// 'Ubuntu Condensed',
	// 'Comfortaa',
	'Source Sans Pro',
	// 'Nunito',
	'Roboto',
	'"Helvetica Neue"',
	'Arial',
	'sans-serif'
].join(',');

const fontFamily2 = [
	// 'Poiret One',
	// 'Cinzel Decorative',
	// 'Gugi',
	// 'Sail',
	// 'Faster One',
	// 'Tourney',
	// 'Montserrat',
	// 'Pacifico',
	// 'Lora',
	// 'Rubik Puddles',
	// 'Grape Nuts',
	// 'Josefin Sans',
	'Exo',
	// 'Chilanka',
	// 'Ubuntu Condensed',
	'Source Sans Pro',
	// 'Nunito',
	'Roboto',
	'"Helvetica Neue"',
	'Arial',
	'sans-serif'
].join(',');

// fontFamily: [
//   '-apple-system',
//   'BlinkMacSystemFont',
//   '"Segoe UI"',
//   'Roboto',
//   '"Helvetica Neue"',
//   'Arial',
//   'sans-serif',
//   '"Apple Color Emoji"',
//   '"Segoe UI Emoji"',
//   '"Segoe UI Symbol"',
// ].join(','),

export const maxRightDrawerWidth = 420;
export const maxLeftDrawerWidth = 300;

export const useClassesDrawerStyle = makeStyles((theme) => createStyles({
	// https://stackoverflow.com/questions/53772429/mui-how-can-i-style-the-scrollbar-with-css-in-js
	'@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      // outline: '1px solid slategrey'
    }
  },
	rightDrawerPaper: {
		width: '100%',
		// width: '80%',
		// height: '100%',
		maxWidth: maxRightDrawerWidth,
	},
  drawerContainer: {
    // overflow: 'auto', // this shows scrollbars when the content doesn't fit in the provided space
    // overflow: 'visible',
    // overflow: 'hidden',
    // overflow: 'clip',
    overflow: 'scroll',
    // height: '80vh',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    margin: 0,
  }
}));

export const useClassesLists = makeStyles((theme) => createStyles({
	action: {
		padding: theme.spacing(1),
		margin: theme.spacing(1)
	},
	itemText: {
		padding: theme.spacing(1),
		margin: theme.spacing(1)
	},
	subheader: {
	}
}));

export const useClassesReleaseNotes = makeStyles((theme) => createStyles({
	title: {
	},
	version: {
		margin: theme.spacing(2)
	},
	feature: {
		margin: theme.spacing(2, 2, 2, 2),
		padding: theme.spacing(3, 0, 0, 0)
	},
	body: {
		margin: theme.spacing(2)
	},
	divider: {
		margin: theme.spacing(3)
	},
	root: {
		padding: theme.spacing(2),

		// width: '100vw',
		// height: '100vh',
		// padding: 0,
		// margin: theme.spacing(-9, -2, -2, -2)
	},
}));

export const useClassesFormStyle = makeStyles((theme) => createStyles({
	flexRoot: {
		display: 'flex'
	},
	flex1: {
		flex: 1
	},
	fullPage: {
		padding: 0,
		margin: theme.spacing(-9, -2, -2, -2)
	},
	backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  button: {
    margin: theme.spacing(1, 0, 1, 0),
    // padding: theme.spacing(0, 0, 0, 0),
  },
  submit: {
    margin: theme.spacing(1, 0, 1, 0),
    // padding: theme.spacing(0, 0, 0, 0),
  },
  cancel: {
    margin: theme.spacing(1, 0, 1, 0),
    // padding: theme.spacing(0, 0, 0, 0),
  },
  body: {
    // margin: theme.spacing(0, 0, 0, 0),
    width: '100%', // Fix IE 11 issue.
    // background: theme.palette.background.paper,
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(0, 0, 0, 0),
  },
	title: {
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 0, 0),
	},
	cell: {
		// padding: theme.spacing(1, 0, 1, 0),
		// padding: theme.spacing(1),
		margin: theme.spacing(1, 1, 1, 0),
	},
	field: {
		margin: theme.spacing(1, 0, 1, 0),
		width: '100%',
	},
	help: {
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(1, 0, 1, 0),
	},
  alert: {
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
	detailsRoot: {
		width: '100%',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 0, 0, 0),
	},
	details: {
		width: '100%',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 0, 0, 0),
	},
	actions: {
		width: '100%',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 0, 0, 0),
	},
	miniRoot: {
		height: '100%',
		// padding: theme.spacing(2),
		padding: 10,
	},
	tableRoot: {
		width: '100%',
		height: '100%',
		// height: 'calc(100vh - 10%)',
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(0, 0, 0, 0),
		// <div style={ {height: 'calc(80% - 150px) !important'} } className={"table-container"}>
	},
}));

export const DrawerTitle = ({children}) => {
	const classes = useClassesFormStyle();
	return (
		<Typography variant="h5" className={classes.title}>{children}</Typography>
	)
}

export const DrawerAlert = ({severity, children}) => {
	const classes = useClassesFormStyle();
	const variant = "standard"; // outlined, standard, filled
	return (
		<Alert className={classes.alert} variant={variant} severity={severity}>{children}</Alert>
	)
}

export const DrawerHelp = ({severity, alert, children}) => {
	const classes = useClassesFormStyle();
	return (
		<>
			<Typography variant="subtitle2" className={classes.help}>{children}</Typography>
			{!_.isEmpty(alert) && <DrawerAlert variant="filled" severity={severity}>{alert}</DrawerAlert>}
		</>
	)
}


export const DrawerTextHelp = ({id, severity, children}) => {
	const DrawerTextAlert = ({children}) => (<DrawerAlert severity={severity}>{children}</DrawerAlert>)
	if (_.isNil(children)) {
		return null
	}
	return (
		<FormHelperText variant="outlined" id={id} component={DrawerTextAlert}>{children}</FormHelperText>
	)
}

export const DrawerTextField = ({id, ...props}) => {
	const classes = useClassesFormStyle();
	return (
		<TextField
			className={classes.field} 
			variant="outlined"
			margin="normal"
			fullWidth
			id={id}
			name={id}
			{...props}
		/>
	)
}

export const DrawerSecretField = ({...props}) => {
	// const classes = useClassesFormStyle();
  const [showSecret, setShowSecret] = React.useState(false)
	const onClickShowSecret = (bla) => {
		// console.warn('onClickShowSecret', {bla})
		setShowSecret(state => !state);
  };

	const [copiedText, copyText] = useCopyToClipboard();

	const onClickCopyToClipboard = () => {
		copyText(document.getElementById(props?.id)?.value);
  };

	const onMouseDownShowPassword = (event) => {
    event.preventDefault();
  };
	const _type = showSecret ? 'text' : 'password';
	const _inputProps = {
		endAdornment: (
			<InputAdornment position="end">
				<Tooltip title="Toggle password visibility" aria-label="toggle">
				<IconButton
					tabIndex={-1}
					aria-label="toggle password visibility"
					onClick={onClickShowSecret}
					onMouseDown={onMouseDownShowPassword}
					edge="end"
				>
					{showSecret ? <Visibility /> : <VisibilityOff />}
				</IconButton>
				</Tooltip>
				<Tooltip title="Copy to clipboard" aria-label="copy">
					<IconButton
						tabIndex={-1}
						aria-label="copy password to clipboard"
						onClick={onClickCopyToClipboard}
						edge="end"
					>
						<AssignmentIcon />
					</IconButton>
				</Tooltip>
			</InputAdornment>
		)
	}
	return (
		// <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
		// 	<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
		// 	<OutlinedInput
		// 		id="outlined-adornment-password"
		// 		type={values.showPassword ? 'text' : 'password'}
		// 		value={values.password}
		// 		onChange={handleChange('password')}
		// 		endAdornment={
		// 			<InputAdornment position="end">
		// 				<IconButton
		// 					aria-label="toggle password visibility"
		// 					onClick={handleClickShowPassword}
		// 					onMouseDown={handleMouseDownPassword}
		// 					edge="end"
		// 				>
		// 					{values.showPassword ? <Visibility /> : <VisibilityOff />}
		// 				</IconButton>
		// 			</InputAdornment>
		// 		}
		// 		labelWidth={70}
		// 	/>
		// </FormControl>
		<DrawerTextField 
			{...props}
			type={_type}
			autoComplete="new-password"
			InputProps={_inputProps}
		/>
	)
}


export const DrawerDetails = ({children}) => {
	const [expanded, setExpanded] = React.useState(false);

	const toggleExpanded = React.useCallback(() => {
		setExpanded(!expanded);
	}, [expanded]);

	const classes = useClassesFormStyle();

	const showHideText = React.useMemo(() => expanded ? "Less details..." : "More details...", [expanded]);

	return (
		<>
      <Accordion className={classes.detailsRoot} expanded={expanded} onChange={toggleExpanded} elevation={0} variant="elevation">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
					{/* <Link onClick={toggleExpanded} variant="h6" component="h3" className={classes.field}>{showHideText}</Link> */}
          <Typography className={classes.secondaryHeading} variant="h6" component="h3">{showHideText}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
					<Grid container>
						{children}
					</Grid>
        </AccordionDetails>
      </Accordion>
		</>
	)
}

const dangerTheme = createTheme({
  palette: {
    primary: red,
  },
});

const warningTheme = createTheme({
  palette: {
    primary: orange,
  },
});

const ProtectedMessage = ({manualConfirmation}) => {
	return (
		<>
			This is a protected action. Please enter <strong>{manualConfirmation}</strong> to unlock it.
		</>
	)
}

export const DrawerDialogYesNo = ({manualConfirmation, title, action, details, open, onClose, onAction}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const _action = action ?? "Yes";
	const _title = title ?? action ?? "Are you sure?";
	const _details = details ?? "Please confirm that you want to perform the action.";

	const _onAction = (event) => {
		onClose()
		onAction(event)
	}

	const [confirmation, setConfirmation] = React.useState("");

	const isProtected = React.useMemo(() => !_.isNil(manualConfirmation), [manualConfirmation]);

	const disabled = React.useMemo(() => {
		if (_.isEmpty(manualConfirmation)) {
			return false
		}
		return !_.isEqual(manualConfirmation, confirmation)
	}, [manualConfirmation, confirmation]);

	const autoFocusProtect = React.useMemo(() => isProtected, [isProtected]);
	const autoFocusCancel = React.useMemo(() => !autoFocusProtect, [autoFocusProtect]);

	const onInputConfirmation = e => setConfirmation(e.target.value);

  return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{_title}</DialogTitle>
				<DialogContent>
					{_details}
					{isProtected && <DrawerConfirmMessage alert={<ProtectedMessage manualConfirmation={manualConfirmation} />} severity="warning" />}
					{isProtected && <TextField 
						size="medium"
						variant="outlined"
						// variant="filled"
						// variant="standard"
						// className={classes.field} 
						fullWidth
						// margin="none"
						// margin="dense"
						error
						margin="normal"
						id="confirmation" 
						name="confirmation" 
						label="Unlock protected action here"
						autoFocus={autoFocusProtect} 
						autoComplete="off" 
						required 
						value={confirmation} 
						onInput={onInputConfirmation} 
					/>}
				</DialogContent>
        <DialogActions>
          <Button autoFocus={autoFocusCancel} onClick={onClose} color="secondary">Cancel</Button>
          <Button disabled={disabled} onClick={_onAction} color="primary">{_action}</Button>
        </DialogActions>
      </Dialog>
  );
}

// todo: let DrawerButton inherit/compose DrawerButtonConfirm
export const DrawerButton = ({ action, children, ...props }) => {
	const classes = useClassesFormStyle();

	const _action = _.isNil(action) ? "" : action;
	return (
		<Button
			fullWidth
			variant="contained"
			className={classes.button}
			{...props}
		>
			{_action}
			{children}
		</Button>
	)
}

// https://v4.mui.com/components/buttons/#upload-button
export const DrawerButtonUpload = ({accept, multiple, onClick, ...props}) => {
	const _multiple = multiple ?? false;
	const _accept = accept ?? "image/*";
	const onChange = (event) => {
		onClick(event);
	}
	return (
		<DrawerButton component="label" {...props}>
			<input hidden accept={_accept} multiple={_multiple} type="file" onChange={onChange} />
		</DrawerButton>
	)
}

{/* <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label> */}

export const DrawerActions = ({children}) => {
	const classes = useClassesFormStyle();
	const variant = "outlined"
	// const variant = "contained"
	// const variant = "text"
	// const size = "large"
	const size = "medium"
	// const size = "small"
	return (
		<ButtonGroup size={size} fullWidth className={classes.actions} variant={variant} color="primary">{children}</ButtonGroup>
	)
}

export const DrawerConfirmMessage = ({message, alert, severity}) => {
	return (
		<>
				{message && <DialogContentText>{message}</DialogContentText>}
				{alert && <DrawerAlert severity={severity}>{alert}</DrawerAlert>}
		</>
	)
}

export const DrawerButtonConfirm = ({ manualConfirmation, details, action, title, theme, themePreset, onClick, children, ...props }) => {

	const baseTheme = useTheme();

	const _themePreset = _.isNil(themePreset) ? "base" : themePreset;

	const _dangerTheme = _.isEqual(_themePreset, "danger") ? dangerTheme : null;
	const _warningTheme = _.isEqual(_themePreset, "warning") ? warningTheme : null;

	const _theme = theme ?? _dangerTheme ?? _warningTheme ?? baseTheme;

  const [open, setOpen] = React.useState(false);

	const _onClick = () => {
		setOpen(true)
	}

	const _onClose = () => {
		setOpen(false);
	}

	const _onAction = (event) => {
		// console.warn('DrawerButtonConfirm: _onAction', { event });
		onClick && onClick(event);
	}

	const _action = action ?? null;

	const _details = details ?? null;

	return (
		<ThemeProvider theme={_theme}>
			<DrawerButton
				onClick={_onClick}
				{...props}
			>
				{_action}
				{children}
			</DrawerButton>
			<DrawerDialogYesNo manualConfirmation={manualConfirmation} onAction={_onAction} details={_details} action={_action} title={title} open={open} onClose={_onClose} />
		</ThemeProvider>
	)
}

export const DrawerButtonDanger = ({ children, ...props }) => {
	return (
		// <DrawerButtonConfirm theme={dangerTheme} {...props}>{children}</DrawerButtonConfirm>
		<DrawerButtonConfirm themePreset="danger" {...props}>{children}</DrawerButtonConfirm>
	)
}

export const DrawerButtonWarning = ({ children, ...props }) => {
	return (
		// <DrawerButtonConfirm theme={warningTheme} {...props}>{children}</DrawerButtonConfirm>
		<DrawerButtonConfirm themePreset="warning" {...props}>{children}</DrawerButtonConfirm>
	)
}



export const DisplayField = ({title, value, unit}) => {

	const classes = useClassesFormStyle();

	const toggleDistance = useCallbackToggleDistance();

	const unitSystem = useUnitSystem();

	// text, number, distance, time, duration
	const _unit = unit ?? 'number';

	let _value = value ?? 'N/A';
	if (!_.isNil(value)) {
		if (_unit === 'duration') {
			_value = formatDuration(value);
		}
		else if (_unit === 'distance') {
			// formatDistance(_.defaultTo(res.tripDistance, 0) / 1000.0, res.unitSystem)
			_value = formatDistance(value / 1000, unitSystem);
			// _value = formatDistance(value);
		}
	}

	// const _align = _unit === 'text' ? 'left' : 'right';

	if (_unit === 'distance') {
		return (
			<>
				<Typography gutterBottom variant="caption" className={classes.secondaryHeading}>{title}</Typography>
				<Tooltip title="Click to toggle unit system" placement="top-start">
					<Link className={classes.cell} onClick={toggleDistance} gutterBottom variant="h6" component="h3">{_value}</Link>
				</Tooltip>
			</>
		)
	}
	return (
		<>
			<Typography gutterBottom variant="caption" className={classes.secondaryHeading}>{title}</Typography>
			<Typography className={classes.cell} gutterBottom variant="h6" component="h3">{_value}</Typography>
		</>
	)
}



// https://v4.mui.com/customization/typography/#font-family
// https://mui.com/customization/theming/#unstable-createmuistrictmodetheme-options-args-theme
// https://reactjs.org/docs/strict-mode.html#warning-about-deprecated-finddomnode-usage
// https://github.com/mui/material-ui/issues/27039
// https://mui.com/customization/theming/#unstable-createmuistrictmodetheme-options-args-theme
// https://blog.logrocket.com/3-ways-to-add-custom-fonts-to-your-material-ui-project/
// https://v4.mui.com/customization/components/#dynamic-css
// https://v4.mui.com/customization/components/#theme-variables
export default responsiveFontSizes(createTheme({
	// spacing: 4,
	// https://v4.mui.com/customization/palette/
	// https://v4.mui.com/customization/default-theme/?expand-path=$.palette
	// https://v4.mui.com/customization/color/#picking-colors
	// https://v4.mui.com/customization/color/#2014-material-design-color-palettes
	palette: {
		// Used by `getContrastText()` to maximize the contrast between
		// the background and the text.
		contrastThreshold: 3,
		// Used by the functions below to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
		// background: {
		// 	paper: `linear-gradient(-45deg, ${indigo['A700']} 60%, ${indigo[900]} 60%)`,
		// 	default: `linear-gradient(-45deg, ${indigo['A700']} 60%, ${indigo[900]} 60%)`,
		// },
		primary: {
			// main: indigo['A400'],
			main: indigo['A700'],
			// https://stackoverflow.com/questions/52256578/support-for-gradient-directly-in-theme-configurations-in-material-ui
			// mainGradientAppBar: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
			// mainGradientAppBar: `linear-gradient(-45deg, ${indigo['A700']} 60%, ${indigo[900]} 60%)`,
			// mainGradientAppBar: `linear-gradient(-45deg, ${indigo['A700']} 60%, ${indigo[900]} 60%)`,
			// mainGradientAppBar: `linear-gradient(-45deg, ${indigo['A700']} 10%, ${indigo[900]} 10%)`,
			// mainGradientAppBar: `linear-gradient(-45deg, ${indigo['A700']} 150px, ${indigo[900]} 150px)`,
			mainGradientAppBar: `linear-gradient(-45deg, #FFFFFF 135px, ${indigo[900]} 135px)`,
			// mainGradientAppBar: `linear-gradient(-45deg, ${indigo[800]} 60%, ${indigo[900]} 60%)`,
			// mainGradientAppBar: `linear-gradient(-45deg, ${blue['A700']} 60%, ${indigo[900]} 60%)`,
			// mainGradientAppBar: `linear-gradient(-45deg, ${blue['A100']} 60%, ${indigo[900]} 60%)`,
			// mainGradientAppBar: `linear-gradient(-45deg, ${indigo['A700']} 60%, #FFFFFF 60%)`,
			mainGradientLogin: `linear-gradient(-45deg, #FFFFFF 60%, ${indigo[900]} 60%)`,
			badge: indigo['A700'],
		},
		secondary: {
			// main: indigo[50],
			main: indigo[900],
		},
		// primary: {
		//   light: '#757ce8',
		//   main: '#3f50b5',
		//   dark: '#002884',
		//   contrastText: '#fff',
		// },
		// secondary: {
		//   light: '#ff7961',
		//   main: '#f44336',
		//   dark: '#ba000d',
		//   contrastText: '#000',
		// },
	},
	typography: {
		// fontSize: 12,
		// see App.css
		// https://fonts.google.com/
		// https://fonts.google.com/share?selection.family=Chilanka
		// https://fonts.google.com/share?selection.family=Nunito
		// https://fonts.google.com/share?selection.family=Ubuntu%20Condensed
		fontFamily: fontFamily1,
		h6: {
			fontFamily: fontFamily2,
			fontSize: 16,
		},
		h5: {
			fontFamily: fontFamily2,
		},
		button: {
			fontFamily: fontFamily2,
		}
	}
}));
