import React from "react";

// https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
export const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);

	// return React.useMemo(() => ref.current ?? {}, [ref]);
  return ref.current;
}
