import { axiosPostRequest } from './api-request';

function vehiclesAll(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Vehicles fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function vehicle(id) {
	try {
		const body = {
			id: id
		}
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle/get`, body)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Vehicle fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function create(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle/create`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Vehicle create failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function deleteVehicle(data) {
	try {
		const body = { id: data };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle/delete`, body)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Vehicle deletion failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function update(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle/update`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Vehicle update failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

function update_role(data) {
	try {
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle/update-role`, data)
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Vehicle role update failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export {
	vehiclesAll,
	vehicle,
	create,
	deleteVehicle,
	update,
	update_role,
};
