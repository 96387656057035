import _ from 'lodash';

// https://trello.com/c/LZ9JpGYF
// https://www.w3schools.com/colors/colors_names.asp
// const onlineFillColor = "#5e92f3";
const onlineFillColor = "#005cb2";
const activeStrokeColor = "#005cb2";
// const activeStrokeColor = "#5e92f3";
const activeStrokeWeight = 2;
const stoppedStrokeWeight = 1;
const defaultFinishFill = "LightCoral";
const defaultFinishStroke = "IndianRed";
const defaultFinishStrokeWeight = 3;
const defaultStartFill = "DeepSkyBlue";
const defaultStartStroke = "DodgerBlue";
const defaultStartStrokeWeight = 3;
const offlineFillColor = "SlateGrey";
const offlineStrokeColor = "Black";

// https://freesvg.org/vector-clip-art-of-checkered-flag-pictogram
// https://stackoverflow.com/questions/46403305/rotate-svg-marker-with-multiple-paths-using-google-maps-javascript
// http://svgicons.sparkk.fr/
// https://developers.google.com/maps/documentation/javascript/examples/marker-modern
// https://fontawesome.com/v5/icons/flag-checkered?s=solid
// https://fontawesome.com/icons/location-pin?s=solid
// The fontawesome copy-as-svg option works nicely.
// see useDriverIcon and useEventIcon

const createDriverLocationIcon = (opts) => _.defaults(opts, {
	// https://fontawesome.com/icons/signs-post?s=solid
	// viewBox="0 0 512 512"
	// path: "M223.1 32C223.1 14.33 238.3 0 255.1 0C273.7 0 288 14.33 288 32H441.4C445.6 32 449.7 33.69 452.7 36.69L500.7 84.69C506.9 90.93 506.9 101.1 500.7 107.3L452.7 155.3C449.7 158.3 445.6 160 441.4 160H63.1C46.33 160 31.1 145.7 31.1 128V64C31.1 46.33 46.33 32 63.1 32L223.1 32zM480 320C480 337.7 465.7 352 448 352H70.63C66.38 352 62.31 350.3 59.31 347.3L11.31 299.3C5.065 293.1 5.065 282.9 11.31 276.7L59.31 228.7C62.31 225.7 66.38 223.1 70.63 223.1H223.1V191.1H288V223.1H448C465.7 223.1 480 238.3 480 255.1V320zM255.1 512C238.3 512 223.1 497.7 223.1 480V384H288V480C288 497.7 273.7 512 255.1 512z", 
	// https://fontawesome.com/icons/location-arrow?s=solid
	// viewBox="0 0 448 512
	// path: "M285.6 444.1C279.8 458.3 264.8 466.3 249.8 463.4C234.8 460.4 223.1 447.3 223.1 432V256H47.1C32.71 256 19.55 245.2 16.6 230.2C13.65 215.2 21.73 200.2 35.88 194.4L387.9 50.38C399.8 45.5 413.5 48.26 422.6 57.37C431.7 66.49 434.5 80.19 429.6 92.12L285.6 444.1z",
	// scale: 0.03,
	path: "M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z", 
	scale: 1.0,
	anchor: { x: 12, y: 12 },
	origin: { x: 0, y: 0 },
	labelOrigin: { x: 0, y: 0 },
	label: null,
	fillColor: onlineFillColor,
	strokeColor: activeStrokeColor,
	strokeWeight: 1,
	strokeOpacity: 5,
	fillOpacity: 1,
});


export const mapIcons = {
	start: { 
		fillColor: defaultStartFill,
		strokeColor: defaultStartStroke,
		scale: 0.065,
		origin: { x: 0, y: 0 },
		label: null,
		path: "M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z",
		anchor: { x: 192, y: 512 }, // viewBox="0 0 384 512"
		labelOrigin: { x: 192, y: 200 },
		strokeWeight: defaultStartStrokeWeight,
		strokeOpacity: 1,
		fillOpacity: 1,
	},
	finish: { 
		scale: 0.055,
		origin: { x: 0, y: 0 },
		path: "M64 496C64 504.8 56.75 512 48 512h-32C7.25 512 0 504.8 0 496V32c0-17.75 14.25-32 32-32s32 14.25 32 32V496zM476.3 0c-6.365 0-13.01 1.35-19.34 4.233c-45.69 20.86-79.56 27.94-107.8 27.94c-59.96 0-94.81-31.86-163.9-31.87C160.9 .3055 131.6 4.867 96 15.75v350.5c32-9.984 59.87-14.1 84.85-14.1c73.63 0 124.9 31.78 198.6 31.78c31.91 0 68.02-5.971 111.1-23.09C504.1 355.9 512 344.4 512 332.1V30.73C512 11.1 495.3 0 476.3 0z", // viewBox="0 0 512 512"
		// viewBox="0 0 576 512"
		// viewBox="0 0 384 512"
		anchor: { x: 0, y: 512 },
		labelOrigin: { x: 256, y: 200 },
		// label: "B",
		label: null,
		fillColor: defaultFinishFill,
		strokeColor: defaultFinishStroke,
		strokeWeight: defaultFinishStrokeWeight,
		strokeOpacity: 1,
		fillOpacity: 1,
	},
	unknown: { 
		path: "M12,2C8.14,2 5,5.14 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9C19,5.14 15.86,2 12,2M12.88,15.75H11.13V14H12.88M12.88,12.88H11.13C11.13,10.04 13.75,10.26 13.75,8.5A1.75,1.75 0 0,0 12,6.75A1.75,1.75 0 0,0 10.25,8.5H8.5A3.5,3.5 0 0,1 12,5A3.5,3.5 0 0,1 15.5,8.5C15.5,10.69 12.88,10.91 12.88,12.88Z", 
		scale: 1.5,
		anchor: { x: 6, y: 6 },
		origin: { x: 0, y: 0 },
		labelOrigin: { x: 0, y: 0 },
		label: null,
		fillColor: onlineFillColor,
		strokeColor: activeStrokeColor,
		strokeWeight: 1,
		strokeOpacity: 1,
		fillOpacity: 1,
	},
};

// https://trello.com/c/EnF0vDIb/491-live-view-vehicle-trip-status
// https://trello.com/c/TxUzcxSv
// https://trello.com/c/ipPHjAXT
// https://trello.com/c/TxUzcxSv
export const getDriverLocationIcon = (deviceStatus, tripStatus) => {

	// driver_location_offline_* are always grey body, but with different border:
	// driver_location_offline_active
	// driver_location_offline_inactive
	// driver_location_offline_stopped

	// driver_location_expired_* icons are not shown:
	// driver_location_expired_active
	// driver_location_expired_inactive
	// driver_location_expired_stopped

	// driver_location_online_* are always blue:
	// driver_location_online_active
	// driver_location_online_inactive
	// driver_location_online_stopped

	const deviceOffline = _.isEqual(deviceStatus, 'offline');
	const tripActive = _.isEqual(tripStatus, 'active');

	// console.warn('getDriverLocationIcon', {
	// 	deviceStatus, tripStatus
	// });

	const fillColor = deviceOffline ? offlineFillColor : onlineFillColor;
	// const fillColor = offlineFillColor;
	const strokeColor = tripActive ? activeStrokeColor : offlineStrokeColor;

	// const strokeColor = offlineStrokeColor;
	const strokeWeight = tripActive ? activeStrokeWeight : stoppedStrokeWeight;

	return createDriverLocationIcon({
		fillColor,
		strokeColor,
		strokeWeight,
	});

}
