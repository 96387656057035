// import './App.css';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import {useLogger} from 'react-use';

import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { setPreferences, useCurrentUserCanViewCourses, useTokenStore, useUserId, useUserStore } from 'modules/useTokenStore';

import ReleaseNotes from 'pages/release-notes';

import { UsersPage } from './users-page/users-page';
import { UserGroupsPage } from './user-groups-page/user-groups-page';
import { VehiclesPage } from './vehicles-page/vehicles-page';
import { VehicleGroupsPage } from './vehicle-groups-page/vehicle-groups-page';
import { LiveView } from './live-view/live-view';
import { DevicesPage } from './devices-page/devices-page';
import { NavigationBar } from 'components/navigation-bar/navigation-bar';
import { QRPage } from 'driver-pages/qr-page/qr-page';
import { TrainingPage } from 'driver-pages/training-page/training-page';
import { Preferences } from 'pages/preferences/preferences';
import { BreadcrumbList } from 'components/breadcrumbs/breadcrumbs';
import { Connector } from 'modules/mqttHooks';
import { useMqttOptions, useNavPaths } from 'modules/useGlobalStore';
import { useClassesFormStyle } from 'theme/theme';

const styles = makeStyles((theme) => ({
	clientEntryRoot: {
		display: "flex",
		height: '100vh',
		with: '100vw'
	},
	// https://trello.com/c/NLqb5jmX
	clientEntryBody: {
		width: '100%',
		height: '100%',
		padding: theme.spacing(9, 2, 2, 2), // 9 is the space of the appbar
		margin: 0,
	},
	// https://trello.com/c/NLqb5jmX
	fullPage: {
		padding: 0,
		margin: theme.spacing(-9, -2, -2, -2)
	},
}));

// https://trello.com/c/NLqb5jmX
const FullPage = ({children}) => {
	const classes = useClassesFormStyle();
	return (
		<div className={classes.fullPage}>
			{children}
		</div>
	)
}

// note: if the options change, then the entire page will be refreshed (which is not ideal)
// note: the Connector root is required so that children can use useMqttState and useSubscription
// note: when trying to implement manual reconnect by creating new options, this causes unnecessary refreshes
const brokerUrl = `${process.env.REACT_APP_EDGE_SERVICE_MQTT_URL}`;
const parserMethod = msg => msg  // msg is Buffer

const ConnectorModule = (props) => {
	const option = useMqttOptions();
	// useLogger('ConnectorModule', props); // enable for debugging
	return (
	<Connector
		brokerUrl={brokerUrl}
		parserMethod={parserMethod}
		options={option}
	>
		{props.children}
	</Connector>
	)
}

export const ClientEntry = () => {

	const token = useTokenStore(state => state.token);
	const role = useUserStore(state => state.role);
	const { path } = useRouteMatch();

	const userId = useUserId();

	const paths = useNavPaths(path);

	const altPaths = React.useMemo(() => ({
		'Profile': `${path}/users/${userId}`,
		// 'Account': `${path}/users/${userId}/edit`,
		'Preferences': `${path}/preferences`,
	}), [path, userId]);

	const classes = styles();

	if (!role || !token || token.length < 1) {
		return (
			<Redirect to="/login" />
		)
	} else if (role === "5DTAdmin") {
		return (
			<Redirect to="/admin" />
		)
	}

	return (
		<ConnectorModule>
			<div className={classes.clientEntryRoot}>
				<NavigationBar paths={paths} altPaths={altPaths} />
				<main className={classes.clientEntryBody}>
					<BreadcrumbList />
					<Switch>
						<Route path={`${path}`} exact >
							<Redirect to={`${path}/live-view`} />
						</Route>
						<Route path={`${path}/live-view`} >
							{/* <CssBaseline> */}
							<FullPage>
								<LiveView />
							</FullPage>
							{/* </CssBaseline> */}
						</Route>
						<Route path={`${path}/demo`} >
							<PreferenceSetComponent />
						</Route>
						<Route path={`${path}/users`} >
							<UsersPage />
						</Route>
						<Route path={`${path}/user-groups`} >
							<UserGroupsPage />
						</Route>
						<Route path={`${path}/vehicles`} >
							<VehiclesPage />
						</Route>
						<Route path={`${path}/vehicle-groups`} >
							<VehicleGroupsPage />
						</Route>
						<Route path={`${path}/devices`} >
							<DevicesPage />
						</Route>
						{/* <Route path={`${path}/training-management`} >
						<TrainingManagementPage />
					</Route> */}
						<Route path={`${path}/training`} >
							<TrainingPage />
						</Route>
						<Route path={`${path}/qr-code`} >
							<QRPage />
						</Route>
						<Route path={`${path}/preferences`} >
							<Preferences />
						</Route>
						<Route path={`${path}/release-notes`} component={ReleaseNotes} />
						<Route>
							<h1>NOT FOUND</h1>
						</Route>
					</Switch>
				</main>
			</div>
		</ConnectorModule>
	)
}

const PreferenceSetComponent = () => {
	const history = useHistory();
	useEffect(() => {
		setPreferences({ event_icon_size: 50, driver_icon_size: 2.5, path_size: 4 })
		setTimeout(() => {
			history.push('/dashboard/live-view');
		}, 500)
	}, [])

	return (<></>);
}
