import React from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import { DrawerActions, DrawerAlert, DrawerButton, DrawerButtonConfirm, DrawerConfirmMessage, DrawerHelp, DrawerTextField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useCreateCompany } from 'services/api';

const helpText = `To register a new company, please provide the company name.`;

const RegisterMessage = ({companyName}) => {
	return (
		<DrawerConfirmMessage 
			// alert="If you are transferring the device to another company, then this will unlink the vehicle and driver currently linked to the device." 
			message={`Are you sure that you want to register a new company called '${companyName}'.`} 
			severity="warning" 
		/>
	)
}

export const AdminCompanyCreate = () => {

	const classes = useClassesFormStyle();

	const history = useHistory();

  const [formData, setFormData] = React.useState({
		companyName: '',
		alertSeverity: 'info',
		alertMessage: '',
		loading: false,
	});

	const isSubmitDisabled = React.useMemo(() => {
		return _.isEqual(formData.loading, true) || _.isEmpty(formData.companyName.trim())
	}, [formData])

	const createCompany = useCreateCompany({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `Successfully registered new company '${p.name}'.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not register company: ${p.message}`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setFormData(state => ({
				...state, 
				alertMessage: 'Form incomplete. Please provide the missing information.', 
				alertSeverity: 'error'
			}));
			return
		}

		setFormData(state => ({
			...state, 
			loading: true, 
			alertMessage: 'Registering new company...', 
			alertSeverity: 'info'
		}));

		createCompany.mutate({
			name: formData.companyName,
		});
	}, [createCompany, formData, isSubmitDisabled]);

	const onClickClose = (event) => {
		event.preventDefault();
		history.goBack();
	}

	const onInputCompanyName = e => setFormData(state => ({...state, companyName: e.target.value}));

	return (
		<span classes={classes.body}>
			<DrawerTitle>Register Company</DrawerTitle>
			<DrawerHelp alert={formData.alertMessage} severity={formData.alertSeverity}>{helpText}</DrawerHelp>
			{/* <DrawerAlert variant="filled" severity={formData.alertSeverity}>{formData.alertMessage}</DrawerAlert> */}
			<DrawerTextField
				required
				id="company_name"
				label="Company Name"
				autoFocus={true}
				autoComplete="off"
				value={formData.companyName}
				onInput={onInputCompanyName}
			/>
			<DrawerActions>
				<DrawerButtonConfirm details={<RegisterMessage companyName={formData.companyName} />} title="Register New Company?" action="Register" color="primary" onClick={onSubmit} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} disabled={formData.loading} />
			</DrawerActions>
		</span>
	)
}
