// createCriteria(1, 20, false, null, { device: deviceId });
export const createCriteria = (s, n, flipOrder, orderBy, searchCriteria) => ({
  s: s ?? 1,
  n: n ?? 20,
  flipOrder: flipOrder ?? false,
  orderBy: orderBy ?? null,
  searchCriteria: searchCriteria ?? null,
});

// createPageCriteria(1, 20, false, null, {device: deviceId });
// p is the page offset from 0
// n is the number of rows per page
// https://trello.com/c/DZqvtj6w
export const createPageCriteria = (p, n, flipOrder, orderBy, searchCriteria) => 
  createCriteria(1+(p*n), n, flipOrder, orderBy, searchCriteria);

// generateDefaultCriteria({}, {})
export const generateDefaultCriteria = (defaultCriteria, fixedCriteria) => {
    return {
      ...createCriteria(1, 20, false, "", null),
      ...(defaultCriteria ?? {}),
      ...(fixedCriteria ?? {}),
	};
}

export const generateDefaultSearchCriteria = (defaultSearchCriteria) => {
  return { 
    text: '',
    ...(defaultSearchCriteria ?? {})
    };
}

// generateDefaultSearchCriteriaSkeleton will use the input
// defaultSearchCriteriaSkeleton, and then filter out the allowedSearchCriteria.
export const generateDefaultSearchCriteriaSkeleton = (defaultSearchCriteriaSkeleton, allowedSearchCriteria) => {
  const generatedDefaultSearchCriteriaSkeleton = [];
	if (allowedSearchCriteria) {
		Object.entries(allowedSearchCriteria).map(([key, value]) => {
      const defaultCriteria = defaultSearchCriteriaSkeleton[key];
      generatedDefaultSearchCriteriaSkeleton.push(defaultCriteria);
		});
	} else {
		defaultSearchCriteriaSkeleton && Object.entries(defaultSearchCriteriaSkeleton).map(([key, value]) => {
			generatedDefaultSearchCriteriaSkeleton.push(value);
		});
	}
	return generatedDefaultSearchCriteriaSkeleton;
}

// generateCriteria({s, n, orderBy, flipOrder, searchCriteria: {}}, {})
export const generateCriteria = (tableCriteria, fixedCriteria) => {
  return {
    ...createCriteria(tableCriteria.s, tableCriteria.n, tableCriteria.flipOrder, tableCriteria.orderBy, tableCriteria?.searchCriteria ?? null),
    ...(fixedCriteria ?? {}),
  }
}

export const generateSearchCriteria = (tableSearchCriteria, allowedSearchCriteria, fixedSearchCriteria) => {
    const generatedSearchCriteria = {};
    tableSearchCriteria && Object.entries(tableSearchCriteria).map(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
            generatedSearchCriteria[key] = value;
        }
    });
    allowedSearchCriteria && Object.entries(allowedSearchCriteria).map(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
            generatedSearchCriteria[key] = value;
        }
    });
    fixedSearchCriteria && Object.entries(fixedSearchCriteria).map(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
            generatedSearchCriteria[key] = value;
        }
    });
    return generatedSearchCriteria;
}

export const generateColumns = (tableColumns, allowedColumns) => {
    const generatedColumns = [];
    allowedColumns && allowedColumns.forEach(column => {
        tableColumns[column] && generatedColumns.push(tableColumns[column])
    });
    if (generatedColumns.length === 0) {
        tableColumns && Object.entries(tableColumns).map(([_, value]) => {
            generatedColumns.push(value)
        });
    }
    return generatedColumns
}
