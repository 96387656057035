import "./lesson.css";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserStore } from "modules/useTokenStore";
import { getIdentity } from "services/user";
import { useIsMounted } from "shared-hooks/useIsMounted";
import { REQUEST_ABORT_REASON } from "shared-functions/shared-functions";

export const Lesson = () => {

  const isMounted = useIsMounted();
	const { courseId, lessonId } = useParams();
	const name = useUserStore(state => state.name);
	const surname = useUserStore(state => state.surname);
	const [userId, setUserId] = useState(null);
	const [coursePath, setCoursePath] = useState(null);
	const [actor, setActor] = useState(null)

	useEffect(() => {
		const [cancelSource, getIdentityPromise] = getIdentity()
        let isActive = true;
        getIdentityPromise
			.then((response) => {
                if (!isMounted() || !isActive) return;
				if (!response.success) return;
				setUserId(response.result.id);
			})
			.catch((error) => {
                if (!isMounted() || !isActive) return;
				console.log(error);
			})
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
        }
	}, []); // eslint-disable-line

	useEffect(() => {
		setActor({
			account: {
				homePage: 'https://drivevue.5dt.ai',
				name: userId,
			},
			name: `${name} ${surname}`
		})
	}, [userId, name, surname]);

	useEffect(() => {
		if (!courseId || !lessonId || !actor || !name || !surname) {
			setCoursePath(null)
		}
		const _coursePath = `${process.env.REACT_APP_WEB_SERVICE_URL}/v1/courses/${courseId}/${lessonId}/index_lms.html`
			+ `?endpoint=${process.env.REACT_APP_LRS_SERVICE_URL}/data/xAPI`
			+ `&auth=Basic ${process.env.REACT_APP_LRS_TOKEN}`
			+ '&actor='+JSON.stringify(actor)
			+ "&activity_platform=DriveVue"
			+ `&activity_id=http://${lessonId}`;
		setCoursePath(_coursePath);
	}, [courseId, lessonId, actor, name, surname]);

	return (
		coursePath && <iframe
			style={ {height: '100%', width: '100%'} }
			src={coursePath} 
			allowFullScreen={true}
			title="Some Title" />
	);
}
