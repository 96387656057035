// https://react-query.tanstack.com/guides/important-defaults
// https://react-query.tanstack.com/guides/mutations
// https://tkdodo.eu/blog/mastering-mutations-in-react-query

import React from 'react';

import _ from 'lodash';

import {
   useQuery,
   useQueries,
	 useMutation,
	 useQueryClient
 } from 'react-query';

import { trips, trip } from "services/trip";
import { users, user, getProfilePicture, createProfilePicture, userUpdate, userCreate, userList, userPasswordReset } from "services/user";
import { vehiclesAll, vehicle } from "services/vehicle";
import { userGroups } from "services/user-group";
import { vehicleGroups } from "services/vehicle-group";
import { create as vehicleGroupCreate } from 'services/vehicle-group';
import { createUserGroup as userGroupCreate } from 'services/user-group';
import { deviceAll, deviceCreate, deviceUpdate, fetchDeviceBySerialNumber, fetchDevicesLive } from "services/device";
import { alertGet, alertsAll } from 'services/alert';
import { companyAll, companyCreate, companyGet, companyUpdate, companyFeatures } from './company';
import { generateEventObject, generatePositionObject } from 'shared-functions/shared-functions';
import { DateTime } from 'luxon';
import { fetchTripsStats } from './statistics';

export function maxN() {
	// 
	// return Number.MAX_SAFE_INTEGER // dvapi often fails for this value
	return 999999
}

export async function updateUserProfilePicture(userId, image) {
	const [, request] = createProfilePicture(userId, image);
	const response = await request;
	if (_.isEqual(response.success, false)) {
		throw new Error(response.message ?? `Could not create profile picture.`);
	}
	return response.result ?? {
		id: userId,
		image: image,
	};
}

export async function fetchUserPicture(userId) {
	console.log('fetchUserPicture', userId);
	const [, request] = getProfilePicture(userId);
	const response = await request;
	if (_.isEqual(response.success, false)) {
		throw new Error(response.message ?? `Could not fetch user profile picture: ${userId}.`);
	}
	return response.result;
}

export async function fetchTripAlerts(tripId, opts) {
	const [, request] = alertsAll({
		s: 1,
		// n: 100,
		n: maxN(),
		includeLocationData: true,
		includeSnapshot: true,
		searchCriteria: {
			trip_id: tripId
		},
		...(opts ?? {}),
	});
	const response = await request;
	console.warn('fetchTripAlerts', {tripId, opts, response});
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? `Could not fetch trip alerts: ${tripId}.`);
	}
	const result = response.result ?? null;
	if (result) {
		// https://trello.com/c/IAvmYVva
		// console.warn('fetchTripAlerts before modifications', [ ...result.alerts ]);
		result.alerts = _.orderBy((result.alerts ?? []).map((p, i) => generateEventObject(p, i)), [p => p.timestamp], ['asc']);
		// console.warn('fetchTripAlerts after modifications', [ ...result.alerts ]);
	}
	return result;
}

export async function fetchTripDetails(tripId, opts) {
	const o = {
		withDevice: false,
		withVehicle: false,
		withDriver: false,
		withAlerts: false,
		withAlertSnapshots: false,
		...(opts ?? {}),
	}

	const trip = await fetchTrip(tripId)
	if (_.isNil(trip?.path) || _.isEmpty(trip?.path)) {
		// todo: perhaps it should be okay for a trip to have a nil or empty path?
		// todo: if we throw an error here then useTripSummary fails
		console.warn('fetchTripDetails: trip has nil or empty path', tripId, trip);
		// throw an error so that react-query attempts a refresh of the data
		// throw new Error(`trip has nil or empty path ${tripId}`)
	}
	if (_.isNil(trip?.device_serial_number) || _.isEmpty(trip?.device_serial_number)) {
		console.warn('fetchTripDetails: trip has nil or empty device_serial_number', tripId, trip);
		// throw an error so that react-query attempts a refresh of the data
		// throw new Error(`trip has nil or empty device_serial_number ${tripId}`)
	}
	if (_.isNil(trip?.driver_id) || _.isEmpty(trip?.driver_id) || _.isEqual(trip?.driver_id, "unknown")) {
		console.warn('fetchTripDetails: trip has nil, empty or unknown driver_id', tripId, trip);
		// throw an error so that react-query attempts a refresh of the data
		// throw new Error(`trip has nil or empty driver_id ${tripId}`)
	}
	if (_.isNil(trip?.vehicle_id) || _.isEmpty(trip?.vehicle_id) || _.isEmpty(trip?.vehicle_id, "unknown")) {
		console.warn('fetchTripDetails: trip has nil, empty or unknown vehicle_id', tripId, trip);
		// throw an error so that react-query attempts a refresh of the data
		// throw new Error(`trip has nil or empty vehicle_id ${tripId}`)
	}

	// todo: consider throwing an error if trip does not provide additional info
	// (such as device, vehicle, and driver info). React-query will then try to
	// re-execute the query at a later stage.

	let device = null;
	if (o.withDevice) {
		device = await fetchDevice(trip?.device_serial_number ?? null);
	}

	let vehicle = null;
	if (o.withVehicle) {
		vehicle = await fetchVehicle(trip?.vehicle_id ?? null);
	}

	let driver = null;
	if (o.withDriver) {
		driver = await fetchUser(trip?.driver_id ?? null)
	}

	let alerts = null;
	if (o.withAlerts) {
		alerts = await fetchTripAlerts(tripId, {
			includeLocationData: true,
			includeSnapshot: o.withAlertSnapshots,
		});
	}

	console.warn('fetchTripDetails', {tripId, o, trip})

	return {
		...trip,
		device: device,
		vehicle: vehicle,
		trip: trip,
		driver: driver,
		alerts: alerts?.alerts ?? [],
	}
}

export async function fetchAlert(alertId) {
	console.log('fetchAlert', alertId);
	const [, request] = alertGet({
		id: alertId,
		includeSnapshot: true,
		includeLocationData: true,
	});
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? `Could not fetch alert: ${alertId}.`);
	}
	// todo: convert response dates to proper Date object
	return response.result;
}

// todo: consider removing invalid positions from trip path
export async function fetchTrip(id) {
	console.log('fetchTrip', id);
	const response = await trip(id);
	// For some reason trips can exist in the database, but they have no body set,
	// which is not useful in our program, so detect that as an error.
	// if (_.isEqual(response.success, false) || _.isNil(response.result)) {
	if (_.isEqual(response.success, false)) {
	// if (_.isEqual(response.success, false)) {
		console.warn('fetchTrip', id, response);
		throw new Error(`Error while fetching trip: ${id} (${response.message}).`);
	}
	const result = response.result ?? null;
	if (_.isNil(result)) {
		console.warn('fetchTrip', id, response);
		throw new Error(`Trip has no details: ${id}.`);
	}
	// console.warn('fetchTrip: before modification', id,  { ...result });
	// https://stackoverflow.com/questions/522251/whats-the-difference-between-iso-8601-and-rfc-3339-date-formats
	// https://ijmacd.github.io/rfc3339-iso8601/
	// formatting:
	// start_time: 2022-05-05T17:42:03.125Z (i.e. with Z aka UTC)
	// path[x].location_time: "2022-05-05T17:42:03.125734" (i.e. without Z aka local - but this is a bug, it should be parsed as UTC)
	result.start_time = !_.isNil(result?.start_time) ? DateTime.fromISO(result.start_time, { zone: 'utc' }) : null;
	result.end_time = !_.isNil(result?.end_time) ? DateTime.fromISO(result.end_time, { zone: 'utc' }) : null;
	// https://trello.com/c/IAvmYVva
  // dbFormat: path[x].location_time = 2022-05-05T17:26:39.594894 [Thu May 05 2022 17:26:39 GMT+0200 (South Africa Standard Time)]
  // liveFormat: message?.message_timestamp/location_time = 2022-05-05T17:35:13.125Z [Thu May 05 2022 19:35:13 GMT+0200 (South Africa Standard Time)]
	// luxon: DateTime.fromISO('2016-05-25T09:08:34.123')
	result.path = _.orderBy((result.path ?? []).map((p, i) => generatePositionObject(p, `db-${i}`)), [p => p.timestamp], ['asc']);
	// console.warn('fetchTrip: after modification', id,  { ...result });
	return result;
}

export async function fetchDevice(serialNumber) {
	return await fetchDeviceBySerialNumber(serialNumber);
}

export async function fetchVehicle(id) {
	console.log('fetchVehicle', id);
	const [, request] = vehicle(id);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? `Could not fetch vehicle: ${id}.`);
	}
	return response.result;
}

export async function fetchUser(id) {
	console.log('fetchUser', id);
	const [, request] = user(id);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? `Could not fetch user: ${id}.`);
	}
	return response.result;
}

// export async function fetchDeviceList(serialNumberList) {
// 	return await Promise.all(serialNumberList.map((s) => fetchDevice(s)));
// }

export async function fetchDevices(criteria) {
	console.warn('fetchDevices', {criteria});
	const [, request] = deviceAll(criteria);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? 'Could not fetch devices.');
	}
	return response.result;
}

export async function fetchTrips(criteria) {
	console.warn('fetchTrips', {criteria});
	const [, request] = trips(criteria);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? 'Could not fetch trips.');
	}
	return response.result;
}

export async function fetchCompanies(criteria) {
	return await companyAll(criteria);
}

export async function fetchCompany(companyId) {
	return await companyGet(companyId);
}

export async function fetchUsers(criteria) {
	// const [, request] = users(criteria);
	// const response = await request;
	// if (_.isEqual(response.success, false) || _.isNil(response.result)) {
	// 	throw new Error(response.message ?? 'Could not fetch users.');
	// }
	// return response.result;
	return await userList(criteria);
}

export async function fetchVehicles(criteria) {
	console.warn('fetchVehicles', { criteria });
	const [, request] = vehiclesAll(criteria);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? 'Could not fetch vehicles.');
	}
	return response.result;
}

export async function fetchUserGroups(criteria) {
	const [, request] = userGroups(criteria);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? 'Could not fetch user groups.');
	}
	return response.result;
}

export async function fetchVehicleGroups(criteria) {
	const [, request] = vehicleGroups(criteria);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? 'Could not fetch vehicle groups.');
	}
	return response.result;
}

export async function createVehicleGroup(criteria) {
	const [, request] = vehicleGroupCreate(criteria);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? 'Could not create vehicle group.');
	}
	return response.result;
}

export async function createUserGroup(criteria) {
	const [, request] = userGroupCreate(criteria);
	const response = await request;
	if (_.isEqual(response.success, false) || _.isNil(response.result)) {
		throw new Error(response.message ?? 'Could not create user group.');
	}
	return response.result;
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, tripCount, tripList } = useFetchDeviceList(serialNumberList);
// export function useFetchDeviceList(serialNumberList) {
// 	const queryState = useQuery(['device-list', serialNumberList], async () => await fetchDeviceList(serialNumberList), { keepPreviousData : true });
// 	console.log('useFetchDeviceList', serialNumberList, queryState)
// 	return queryState;
// }

// const { isLoading, isError, error, data, isFetching, isPreviousData, tripCount, tripList } = useFetchDevices(criteria);
export function useFetchDevices(criteria) {
	// console.log('useFetchDevices', criteria);
	const queryState = useQuery(['devices', criteria], async () => await fetchDevices(criteria), { 
		// keepPreviousData : true 
		keepPreviousData : false 
	});
	// currently_online may need to be calculated differently, perhaps by the web-app, by matching it with the global live map data
	// currently_online shouldn't be decided by the device, but maybe by the backend API, or even the web-app
	const { data: queryData } = queryState;
	const deviceCount = React.useMemo(() => queryData?.count ?? 0, [queryData]);
	const deviceList = React.useMemo(() => queryData?.devices?.map((device) => ({
		...device,
		last_online_time: _.isNil(device.last_online_time) ? null : DateTime.fromISO(device.last_online_time, { zone: 'utc' }),
	})) ?? [], [queryData]);
	return { ...queryState, deviceCount, deviceList }
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, rowCount, rowList } = useFetchCompanies(criteria);
export function useFetchCompanies(criteria) {
	const queryState = useQuery(['companies', criteria], async () => await fetchCompanies(criteria), { 
		// keepPreviousData : true 
		keepPreviousData : false 
	});
	const { data: queryData } = queryState;
	const rowCount = React.useMemo(() => queryData?.count ?? 0, [queryData]);
	const rowList = React.useMemo(() => queryData?.companies ?? [], [queryData]);
	return { ...queryState, rowCount, rowList }
}

export function useFetchCompanyFeatures(companyId) {
	return useQuery(['company-features', companyId], async () => await companyFeatures(companyId), { 
		// keepPreviousData : true 
		keepPreviousData : false 
	});
}

// useFetchCompanyOptions provides a rowOptions output that is compatible with
// mui4 Autocomplete.
export function useFetchCompanyOptions() {
	const queryState = useQuery(['companies-options'], async () => {
		return await fetchCompanies({
			// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
			// n defaults to 20 if the request doesn't provide it
			n: Number.MAX_SAFE_INTEGER
		})
	}, { 
		// keepPreviousData : true 
		// keepPreviousData : false 
	});
	const { data: queryData } = queryState;
	const rowOptions = React.useMemo(() => {
		return _.defaultTo(queryData?.companies, []).map(c => ({ 
			value: c.id, 
			label: c.name }))
	}, [queryData]);
	return { ...queryState, rowOptions }
}

export const createVehicleOptionNone = () => {
	return {
		value: "00000000-0000-0000-0000-000000000000", 
		label: "None",
	}
}

export const createVehicleOption = (id, name, registration_number) => {
	return {
		value: id, 
		label: `${name} (${registration_number})`
	}
}

// see dvdb user_role:
// ('1fde9189-94c0-4743-bd6a-10e1b6f5ab98', 0), -- 5DTAdmin
// ('f06d7a38-d5c3-4399-b8bd-7cf8daa49a53', 1), -- ClientAdmin
// ('b758ca0a-bbc6-4c4a-bdb9-3e5e5e97c7af', 2), -- Owner
// ('3c26b32a-f92b-48de-aa02-e67f5e50aa34', 3), -- OperationalUser
// ('e3b0367f-813f-49a7-9af2-3b6a21baf9e5', 3), -- HRUser
// ('61f18acf-60a6-4806-8c42-dc8d28d163f5', 4) -- Driver
const roleOptions = [{
		value: '5DTAdmin',
		label: '5DTAdmin',
		level: 0,
	}, {
		value: 'ClientAdmin',
		label: 'ClientAdmin',
		level: 1,
	}, {
		value: 'Owner',
		label: 'Owner',
		level: 2
	}, {
		value: 'OperationalUser',
		label: 'OperationalUser',
		level: 3,
	}, {
		value: 'HRUser',
		label: 'HRUser',
		level: 3,
	}, {
		value: 'Driver',
		label: 'Driver',
		level: 4,
	},
];

// useFetchVehicleOptions provides a rowOptions output that is compatible with
// mui4 Autocomplete.
export function useFetchRoleOptions() {
	return React.useMemo(() => ({
		rowOptions: roleOptions,
	}), [roleOptions]);
}

// useFetchVehicleOptions provides a rowOptions output that is compatible with
// mui4 Autocomplete.
export function useFetchVehicleOptions() {
	const queryState = useQuery(['vehicles-options'], async () => {
		return await fetchVehicles({
			// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
			// n defaults to 20 if the request doesn't provide it
			n: Number.MAX_SAFE_INTEGER
		})
	}, { 
		// keepPreviousData : true 
		// keepPreviousData : false 
	});
	const { data: queryData } = queryState;
	const rowOptions = React.useMemo(() => {
		return _.concat(_.defaultTo(queryData?.vehicles, []).map(c => createVehicleOption(c.id, c.name, c.registration_number)), createVehicleOptionNone())
	}, [queryData]);
	return { ...queryState, rowOptions }
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, tripCount, tripList } = useFetchTrips(criteria);
export function useFetchTrips(criteria) {
	const queryState = useQuery(['trips', criteria], async () => await fetchTrips(criteria), { 
		// keepPreviousData : true 
		keepPreviousData : false 
	});
	const { data: queryData } = queryState;
	const tripCount = React.useMemo(() => queryData?.count ?? 0, [queryData]);
	const tripList = React.useMemo(() => queryData?.trips ?? [], [queryData]);
	return { ...queryState, tripCount, tripList }
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, tripCount, tripList } = useFetchTripsStats();
// useFetchTripsStats({device_id: 'bla}, {staleTime: 0})
export function useFetchTripsStats(params, opts) {
	// console.log('useFetchTripsStats', criteria);
  // todo: change the query timeout of this query, so that it can refresh more often
	// https://react-query.tanstack.com/reference/useQuery
	return useQuery(
		// todo: opts should be passed here
		['trips-stats', params], 
		async () => await fetchTripsStats(params), { 
			// keepPreviousData: Defaults to false. If set, any previous data will be
			// kept when fetching new data because the query key changed.
			// keepPreviousData : true,
			keepPreviousData : false,
			// staleTime: The time in milliseconds after data is considered stale.
			// This value only applies to the hook it is defined on. If set to
			// Infinity, the data will never be considered stale. Defaults to 0.
			// staleTime: Infinity,
			// cacheTime: The time in milliseconds that unused/inactive cache data
			// remains in memory. When a query's cache becomes unused or inactive,
			// that cache data will be garbage collected after this duration. When
			// different cache times are specified, the longest one will be used. If
			// set to Infinity, will disable garbage collection. Defaults to 5 * 60 *
			// 1000 (5 minutes) or Infinity during SSR
			// cacheTime: Infinity,
			// refetchInterval: If set to a number, all queries will continuously
			// refetch at this frequency in milliseconds. If set to a function, the
			// function will be executed with the latest data and query to compute a
			// frequency
			// refetchInterval: false,
			// refetchInterval: 3000,
			// refetchIntervalInBackground: If set to true, queries that are set to
			// continuously refetch with a refetchInterval will continue to refetch
			// while their tab/window is in the background.
			// refetchIntervalInBackground: true,
			..._.defaultTo(opts, {}),
		}
	);
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, tripCount, tripList } = useFetchDevicesLive();
export function useFetchDevicesLive(opts) {
	// console.log('useFetchDevicesLive', criteria);
  // todo: change the query timeout of this query, so that it can refresh more often
	// https://react-query.tanstack.com/reference/useQuery
	const queryState = useQuery(
		['devices-live'], 
		async () => await fetchDevicesLive(), { 
			// keepPreviousData: Defaults to false. If set, any previous data will be
			// kept when fetching new data because the query key changed.
			// keepPreviousData : true,
			keepPreviousData : false,
			// staleTime: The time in milliseconds after data is considered stale.
			// This value only applies to the hook it is defined on. If set to
			// Infinity, the data will never be considered stale. Defaults to 0.
			// staleTime: Infinity,
			// cacheTime: The time in milliseconds that unused/inactive cache data
			// remains in memory. When a query's cache becomes unused or inactive,
			// that cache data will be garbage collected after this duration. When
			// different cache times are specified, the longest one will be used. If
			// set to Infinity, will disable garbage collection. Defaults to 5 * 60 *
			// 1000 (5 minutes) or Infinity during SSR
			// cacheTime: Infinity,
			// refetchInterval: If set to a number, all queries will continuously
			// refetch at this frequency in milliseconds. If set to a function, the
			// function will be executed with the latest data and query to compute a
			// frequency
			// refetchInterval: false,
			// refetchInterval: 3000,
			// refetchIntervalInBackground: If set to true, queries that are set to
			// continuously refetch with a refetchInterval will continue to refetch
			// while their tab/window is in the background.
			// refetchIntervalInBackground: true,
			..._.defaultTo(opts, {}),
		}
	);
	// currently_online may need to be calculated differently, perhaps by the web-app, by matching it with the global live map data
	// currently_online shouldn't be decided by the device, but maybe by the backend API, or even the web-app
	const { data: queryData } = queryState;
	const deviceCount = React.useMemo(() => queryData?.count ?? 0, [queryData]);
	const deviceList = React.useMemo(() => {
		// console.warn('useFetchDevicesLive:', queryData)
		return queryData?.devices?.map((device) => ({
			...device,
			last_online_time: _.isNil(device.last_online_time) ? null : DateTime.fromISO(device.last_online_time, { zone: 'utc' }),
		})) ?? []
	}, [queryData]);
	return { ...queryState, deviceCount, deviceList }
}

export const QUERY_NAME_TRIP_DETAILS_FULL = 'trip-details-full';
export const QUERY_NAME_TRIP_DETAILS_BASIC = 'trip-details-basic';

export function clearFetchTripDetails(queryClient, tripId) {
	if (_.isNil(tripId)) {
		console.warn('clearFetchTripDetails: not clearing trip cache because no tripId provided');
		return
	}
	if (_.isNil(queryClient)) {
		console.warn('clearFetchTripDetails: not clearing trip cache because no queryClient provided', tripId);
		return
	}
	console.log('clearFetchTripDetails:', tripId);
      // see useFetchTripListDetails, useFetchTripDetails
      // https://react-query.tanstack.com/reference/QueryClient#queryclientinvalidatequeries
      // queryClient.invalidateQueries([QUERY_NAME_TRIP_DETAILS_FULL, `${tripId}`], {
      //   refetchActive: false,
      // });
	queryClient.removeQueries([QUERY_NAME_TRIP_DETAILS_BASIC, `${tripId}`], {
		exact: true
	});
	queryClient.removeQueries([QUERY_NAME_TRIP_DETAILS_FULL, `${tripId}`], {
		exact: true
	});
}

// trips that are in progress, may need to be refetched, but trips that are done, don't have to be refetched
// const { isLoading, isError, error, data, isFetching, isPreviousData } = useFetchTripDetails(tripId);
export function useFetchTripDetails(tripId) {
	return useQuery(
		[QUERY_NAME_TRIP_DETAILS_FULL, tripId], 
		async () => {
			// console.warn('useFetchTripDetails', { tripId });
			return _.isNil(tripId) ? null : await fetchTripDetails(tripId, {
				withAlerts: true,
			})
		}, {
			// keepPreviousData somehow refetches older trips
			// keepPreviousData : true, 
			keepPreviousData : false, 
			// staleTime: Infinity,
			staleTime: 0,
		},
	);
}

// https://react-query.tanstack.com/guides/parallel-queries
// https://react-query.tanstack.com/guides/dependent-queries
// https://react-query.tanstack.com/reference/useQueries
// https://github.com/tannerlinsley/react-query/issues/2991
// https://github.com/tannerlinsley/react-query/pull/2992
// https://github.com/tannerlinsley/react-query/issues/3049
// https://github.com/tannerlinsley/react-query/issues/2923
// see useFetchTripDetails
export const useFetchTripListDetails = (tripList) => useQueries(tripList.map(tripId => ({
    queryKey: [QUERY_NAME_TRIP_DETAILS_BASIC, tripId],
    queryFn: async () => tripId ? await fetchTripDetails(tripId, {
		}) : null,
		// keepPreviousData : true, 
    // https://react-query.tanstack.com/reference/useQuery
    // staleTime: Infinity,
    // cacheTime: Infinity,
  })));

// trips that are in progress, may need to be refetched, but trips that are done, don't have to be refetched
// const { isLoading, isError, error, data, isFetching, isPreviousData } = useFetchTrip(tripId);
export function useFetchTrip(tripId) {
	return useQuery(['trip', tripId], async () => tripId ? await fetchTrip(tripId) : null, { 
		// keepPreviousData : true, 
		keepPreviousData : false, 
		staleTime: Infinity,
		cacheTime: 5*60*1000,
	});
}

export function invalidateFetchDeviceFocus(queryClient) {
	queryClient.invalidateQueries(['device-focus']);
}

// useFetchDeviceFocus is a query that only stores data for the currently
// selected device.
export function useFetchDeviceFocus(deviceSerialNumber, opts) {
	return useQuery(['device-focus'], 
		async () => {
			return _.isNil(deviceSerialNumber) ? null : await fetchDeviceBySerialNumber(deviceSerialNumber)
		}, _.defaults(opts, { 
		// keepPreviousData : true, 
		keepPreviousData : false, 

		// staleTime: The time in milliseconds after data is considered stale. This
		// value only applies to the hook it is defined on. If set to Infinity, the
		// data will never be considered stale. Defaults to 0.
		staleTime: 0,

		// cacheTime: The time in milliseconds that unused/inactive cache data
		// remains in memory. When a query's cache becomes unused or inactive, that
		// cache data will be garbage collected after this duration. When different
		// cache times are specified, the longest one will be used. If set to
		// Infinity, will disable garbage collection. Defaults to 5 * 60 * 1000 (5
		// minutes) or Infinity during SSR
		cacheTime: 5*60*1000,

		refetchInterval: 3000,
	}));
}

export function useFetchDevice(deviceSerialNumber, opts) {
	return useQuery(['device', deviceSerialNumber], 
		async () => {
			return _.isNil(deviceSerialNumber) ? null : await fetchDeviceBySerialNumber(deviceSerialNumber)
		}, _.defaults(opts, { 
		// keepPreviousData : true, 
		keepPreviousData : false, 
		staleTime: Infinity,
		cacheTime: 5*60*1000,
	}));
}

// const { isLoading, isError, error, data, isFetching, isPreviousData } = useFetchAlert(alertId);
export function useFetchAlert(alertId) {
	return useQuery(['alert', alertId], async () => alertId ? await fetchAlert(alertId) : null, {	
		// keepPreviousData: true,
		keepPreviousData: false,
		staleTime: Infinity,
		cacheTime: Infinity,
	});
}

// const { isLoading, isError, error, data, isFetching, isPreviousData } = useFetchTripAlerts(tripId);
// todo: consider removing invalid positions from trip alerts
export function useFetchTripAlerts(tripId) {
	return useQuery(['trip-alerts', tripId], async () => {
		console.warn('useFetchTripAlerts', {tripId})
		return _.isNil(tripId) ? null : await fetchTripAlerts(tripId)
	}, {	
		// keepPreviousData: true,
		keepPreviousData: false,
		staleTime: Infinity,
		cacheTime: Infinity,
	});
}

// const { isLoading, isError, error, data, isFetching, isPreviousData } = useFetchUser(userId);
export function useFetchUser(userId) {
	return useQuery(['user', userId], async () => userId ? await fetchUser(userId) : null, { 
		// keepPreviousData : true, 
		keepPreviousData : false, 
		staleTime: Infinity, // how long should data remain valid before it is automatically updated?
		cacheTime: 5*60*1000, // how long should the data remain in the cache?
	});
}

// const { isLoading, isError, error, data, isFetching, isPreviousData } = useFetchCompany(userId);
export function useFetchCompany(companyId) {
	return useQuery(['company', companyId], async () => companyId ? await fetchCompany(companyId) : null, { 
		// keepPreviousData : true, 
		keepPreviousData : false, 
		staleTime: Infinity, // how long should data remain valid before it is automatically updated?
		cacheTime: 5*60*1000, // how long should the data remain in the cache?
	});
}

// const { isLoading, isError, error, data, isFetching, isPreviousData } = useFetchUserPicture(userId);
export function useFetchUserPicture(userId) {
	return useQuery(['user-picture', userId], async () => userId ? await fetchUserPicture(userId) : null, { 
		// keepPreviousData : true, 
		keepPreviousData : false, 
		staleTime: Infinity, // how long should data remain valid before it is automatically updated?
		cacheTime: 5*60*1000, // how long should the data remain in the cache?
	});
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, userCount, userList } = useFetchUsers(criteria);
export function useFetchUsers(criteria) {
	const queryState = useQuery(['users', criteria], async () => await fetchUsers(criteria), { 
		// keepPreviousData : true 
		keepPreviousData : false 
	});
	const { data: queryData } = queryState;
	const userCount = React.useMemo(() => queryData?.count ?? 0, [queryData]);
	const userList = React.useMemo(() => queryData?.users ?? [], [queryData]);
	return { ...queryState, userCount, userList }
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, vehicleCount, vehicleList } = useFetchVehicles(criteria);
export function useFetchVehicles(criteria) {
	const queryState = useQuery(['vehicles', criteria], async () => await fetchVehicles(criteria), { 
		// keepPreviousData : true 
		keepPreviousData : false 
	});
	const { data: queryData } = queryState;
	const vehicleCount = React.useMemo(() => queryData?.count ?? 0, [queryData]);
	const vehicleList = React.useMemo(() => queryData?.vehicles ?? [], [queryData]);
	return { ...queryState, vehicleCount, vehicleList }
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, userGroupCount, userGroupList } = useFetchUserGroups(criteria);
export function useFetchUserGroups(criteria) {
	const queryState = useQuery(['user-groups', criteria], async () => await fetchUserGroups(criteria), { 
		// keepPreviousData : true 
		keepPreviousData : false 
	});
	const { data: queryData } = queryState;
	const userGroupCount = React.useMemo(() => queryData?.count ?? 0, [queryData]);
	const userGroupList = React.useMemo(() => queryData?.groups ?? [], [queryData]);
	return { ...queryState, userGroupCount, userGroupList }
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, userGroupCount, userGroupList } = useFetchVehicleGroups(criteria);
export function useFetchVehicleGroups(criteria) {
	const queryState = useQuery(['vehicle-groups', criteria], async () => await fetchVehicleGroups(criteria), { 
		// keepPreviousData : true 
		keepPreviousData : false 
	});
	const { data: queryData } = queryState;
	const vehicleGroupCount = React.useMemo(() => queryData?.count ?? 0, [queryData]);
	const vehicleGroupList = React.useMemo(() => queryData?.groups ?? [], [queryData]);
	return { ...queryState, vehicleGroupCount, vehicleGroupList }
}

export function useCreateVehicleGroup(callbacks) {
	const queryClient = useQueryClient();
	const mutation = useMutation(async (criteria) => await createVehicleGroup(criteria), {
		onSuccess: async (response) => {
			queryClient.invalidateQueries('vehicle-groups');
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useCreateUserGroup(callbacks) {
	const queryClient = useQueryClient();
	const mutation = useMutation(async (criteria) => await createUserGroup(criteria), {
		onSuccess: async (response) => {
			queryClient.invalidateQueries('user-groups');
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useUpdateDevice(callbacks) {
	const queryClient = useQueryClient();
	// https://react-query-v3.tanstack.com/reference/useMutation#_top
	// https://tkdodo.eu/blog/mastering-mutations-in-react-query
	const mutation = useMutation(async (data) => await deviceUpdate(data), {
		..._.defaultTo(callbacks, {}),
		onSuccess: (response) => {
			queryClient.invalidateQueries('devices');
			queryClient.invalidateQueries(['device', response.serial_number]);
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useCreateDevice(callbacks) {
	const queryClient = useQueryClient();
	// https://react-query-v3.tanstack.com/reference/useMutation#_top
	// https://tkdodo.eu/blog/mastering-mutations-in-react-query
	const mutation = useMutation(async (data) => await deviceCreate(data), {
		..._.defaultTo(callbacks, {}),
		onSuccess: (response) => {
			queryClient.invalidateQueries('devices');
			queryClient.invalidateQueries(['device', response.serial_number]);
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useCreateUser(callbacks) {
	const queryClient = useQueryClient();
	// https://react-query-v3.tanstack.com/reference/useMutation#_top
	// https://tkdodo.eu/blog/mastering-mutations-in-react-query
	const mutation = useMutation(async (data) => await userCreate(data), {
		..._.defaultTo(callbacks, {}),
		onSuccess: (response) => {
			queryClient.invalidateQueries('users');
			queryClient.invalidateQueries(['user', response.id]);
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useChangePicture(callbacks) {
	const queryClient = useQueryClient();
	// https://react-query-v3.tanstack.com/reference/useMutation#_top
	// https://tkdodo.eu/blog/mastering-mutations-in-react-query
	const mutation = useMutation(async (data) => await updateUserProfilePicture(data.id, data.image), {
		..._.defaultTo(callbacks, {}),
		onSuccess: (response) => {
			// console.warn('useChangePicture', {response})
			queryClient.invalidateQueries(['user', response.id]);
			queryClient.invalidateQueries(['user-picture', response.id]);
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useResetPassword(callbacks) {
	// https://react-query-v3.tanstack.com/reference/useMutation#_top
	// https://tkdodo.eu/blog/mastering-mutations-in-react-query
	const mutation = useMutation(async (data) => await userPasswordReset(data), {
		..._.defaultTo(callbacks, {}),
		onSuccess: (response) => {
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useUpdateUser(callbacks) {
	const queryClient = useQueryClient();
	// https://react-query-v3.tanstack.com/reference/useMutation#_top
	// https://tkdodo.eu/blog/mastering-mutations-in-react-query
	const mutation = useMutation(async (data) => await userUpdate(data), {
		..._.defaultTo(callbacks, {}),
		onSuccess: (response) => {
			queryClient.invalidateQueries('users');
			queryClient.invalidateQueries(['user', response.id]);
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useCreateCompany(callbacks) {
	const queryClient = useQueryClient();
	// https://react-query-v3.tanstack.com/reference/useMutation#_top
	// https://tkdodo.eu/blog/mastering-mutations-in-react-query
	const mutation = useMutation(async (data) => await companyCreate(data), {
		..._.defaultTo(callbacks, {}),
		onSuccess: (response) => {
			queryClient.invalidateQueries('companies');
			// queryClient.invalidateQueries(['company', response.serial_number]);
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

export function useUpdateCompany(callbacks) {
	const queryClient = useQueryClient();
	// https://react-query-v3.tanstack.com/reference/useMutation#_top
	// https://tkdodo.eu/blog/mastering-mutations-in-react-query
	const mutation = useMutation(async (data) => await companyUpdate(data), {
		..._.defaultTo(callbacks, {}),
		onSuccess: (response) => {
			queryClient.invalidateQueries('companies');
			queryClient.invalidateQueries(['company', response.id]);
			_.invoke(callbacks, 'onSuccess', response);
		},
	});
	return mutation;
}

// const { isLoading, isError, error, data, isFetching, isPreviousData, vehicleCount, vehicleList } = useFetchVehiclesFull(criteria);
export function useFetchVehiclesFull(criteria) {

	const { vehicleList, ...vehicleRest } = useFetchVehicles(criteria);

	const deviceCriteria = React.useMemo(() => ({}), []);
	const { deviceList } = useFetchDevices(deviceCriteria);

	const vehicleDeviceMap = React.useMemo(() => deviceList
		.filter((d) => d.linked)
		.reduce((a, v) => ({ ...a, [v.vehicle_id]: v}), {}), [deviceList])

	const fullVehicles = React.useMemo(() => vehicleList.map((v) => {
		const device = vehicleDeviceMap[v.id] ?? {};
		return {
			...v,
			device: device,
			device_currently_online: device?.currently_online,
		}
	}), [vehicleDeviceMap, vehicleList]);

	return React.useMemo(() => ({
		...vehicleRest, vehicleList: fullVehicles
	}), [vehicleRest, fullVehicles]);
}
