import './view-container.css';
import React, { useEffect, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { useScreenType } from 'shared-hooks/useScreenTypes';

const styles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		'& > .tiny-info-view': {
			margin: theme.spacing(1),
		},
	},
	subroot: {
		display: 'flex',
		flexDirection: 'row',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

export const MultiViewContainer = ({ tinyChild, viewChild, rightChild, minified }) => {

	const screenType = useScreenType();
	const [showTiny, setShowTiny] = useState('tiny-info-shown');
	const [showInfo, setShowInfo] = useState('');
	const [showAdditionalInfo, setShowAdditionalInfo] = useState('additional-info-shown');
	const [additionInfoHeight, setAdditionInfoHeight] = useState('additional-info-normal');
	const classes = styles();

	useEffect(() => {
		if (screenType !== "phone" && !minified) {
			setShowTiny('tiny-info-hidden');
			setShowInfo('info-shown');
			setShowAdditionalInfo('additional-info-shown');
			setAdditionInfoHeight('additional-info-normal');
		} else if (screenType === "phone" && !minified) {
			setShowTiny('tiny-info-hidden');
			setShowInfo('info-shown');
			setShowAdditionalInfo('additional-info-hidden');
			setAdditionInfoHeight('additional-info-shorter');
		} else {
			setShowTiny('tiny-info-shown');
			setShowInfo('info-hidden');
			setShowAdditionalInfo('additional-info-shown');
			setAdditionInfoHeight('additional-info-shorter');
		}
	}, [screenType, minified]);

	return (
		<div className={classes.root}>
			<Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={`tiny-info-view ${showTiny}`}  >
				{tinyChild}
			</Box>
			<div className={classes.subroot} >
				<Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={`info-view ${showInfo}`}  >
					{viewChild}
				</Box>
				<Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={`additional-info-view ${showAdditionalInfo} ${additionInfoHeight}`} >
					{rightChild}
				</Box>
			</div>
		</div>
	);
}

export const SingleViewContainer = ({ children }) => {

    return (
        <div className={"single-view-root"}>
            <div className={"single-view-container-padding"} />
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"single-view"}  >
                <div className={"single-view-container"}>
                    { children }
                </div>
            </Box>
            <div className={"container-padding"} />
        </div>
    )
}
