import './vehicle-group-manage-participants.css';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box, Button, makeStyles } from '@material-ui/core';

import { manageVehicleGroupParticipants, vehicleGroupOwners, vehicleGroupVehicles, vehicleGroupViewers } from 'services/vehicle-group';

import { ClientSideTable } from 'components/table/client-side-table';
import { VehicleTable } from 'components/data-tables/vehicle-data-tables';
import { UserTable } from 'components/data-tables/user-data-tables';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';

const styles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
}));

export const VehicleGroupVehicleManagement = () => {

	const isMounted = useIsMounted();
	const vehicleGroupManagementCancelSourceRef = useRef(null);
	const [currentUsers, setCurrentUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState({});
    const [removeUsers, setRemoveUsers] = useState({});
    const classes = styles();
	const { vehicleGroupId } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!vehicleGroupId) return;
		const [cancelSource, vehicleGroupVehiclesAllPromise] = vehicleGroupVehicles({
			s: 1,
			n: 999,
			id: vehicleGroupId,
		})
		let isActive = true;
		vehicleGroupVehiclesAllPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success) return;
				setCurrentUsers(response.result.vehicles);
			})
			.catch((error) => {
				if (!isMounted() || !isActive) return;
				console.log(error);
			});
		return () => {
			isActive = false;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [])

	const handleUserAdd = (row) => {
		const addedUserId = row.data.id;
		setRemoveUsers((_state) => { return {..._state, [addedUserId]: undefined} } );
		setAddedUsers((_state) => { return {..._state, [addedUserId]: true} } );
		for (let i = 0; i < currentUsers.length; ++i) {
			let vehicle = currentUsers[i];
			if (vehicle.id === addedUserId) return;
		}
		setCurrentUsers((_users) => [row.data, ..._users]);
	}

	const handleUserRemove = (row) => {
		const removedUserId = row.data.id;
		setAddedUsers((_state) => { return {..._state, [removedUserId]: undefined} } );
		setRemoveUsers((_state) => { return {..._state, [removedUserId]: true} } );
		for (let i = 0; i < currentUsers.length; ++i) {
			let vehicle = currentUsers[i];
			if (vehicle.id === removedUserId) {
				setCurrentUsers((_users) => {
					const index = _users.findIndex((element) => { return element.id === removedUserId});
					if (index > 0)
						return [..._users.slice(0, index), ..._users.slice(index+1)];
					else if (index === 0) 
						return [..._users.slice(index+1)];
				});
			}
		}
	}

	const handleSaveChanges = () => {
		if (vehicleGroupManagementCancelSourceRef.current) return;
		const _add = [];
		const _remove = [];
		Object.entries(addedUsers).map(([key, value]) => {
			value && _add.push(key);
		})
		Object.entries(removeUsers).map(([key, value]) => {
			value && _remove.push(key);
		})
		const [cancelSource, vehicleGroupManagePromise] = manageVehicleGroupParticipants(vehicleGroupId, _add, _remove, 'vehicle')
		vehicleGroupManagementCancelSourceRef.current = cancelSource;
		vehicleGroupManagePromise
			.then((response) => {
				if (!isMounted()) return;
				vehicleGroupManagementCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((error) => {
				if (!isMounted()) return;
				vehicleGroupManagementCancelSourceRef.current = null;
				console.log(error);
			})
	}

	const handleDiscardChanges = () => {
		history.goBack();
	}

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "make", headerName: "Make", width: 200 },
	];

	const searchCriteriaSkeleton = [
		// { field: "text", header: "Search" },
		{ field: "name", header: "Name" },
		{ field: "make", header: "Make" },
		// { field: "company_name", header: "Company Name" },
	];

	// Searching Users variables
	const defaultSearchCriteria = {
		name: '',
		make: '',
	}
	const allowedSearchCriteria = {
		name: '',
		make: '',
	}

	const searchCriteria = {
		name: '',
		make: '',
	}

    return (
        <div className={classes.root}>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"vehicle-search"}  >
                <h1>Search Vehicles</h1>
				<VehicleTable
					searchCriteria={searchCriteria}
					defaultSearchCriteria={defaultSearchCriteria}
					allowedSearchCriteria={allowedSearchCriteria}

					onRowClick={ handleUserAdd } />
            </Box>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"vehicle-diff"}  >
                <h1>Vehicles</h1>
				<Button variant="contained" color="primary" onClick={ handleSaveChanges }>Save</Button> 
				<Button variant="contained" color="primary" onClick={ handleDiscardChanges }>Discard</Button> 
                <ClientSideTable
                    columns={columns}
                    rows={currentUsers}
                    searchCriteriaSkeleton={searchCriteriaSkeleton}
                    defaultTableCriteria={{}}
                    rowClick={ handleUserRemove } />
            </Box>
        </div>
    );
}

export const VehicleGroupOwnerManagement = () => {

	const isMounted = useIsMounted();
	const vehicleGroupOwnerManagementCancelSourceRef = useRef(null);
	const [currentUsers, setCurrentUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState({});
    const [removeUsers, setRemoveUsers] = useState({});
    const classes = styles();
	const { vehicleGroupId } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!vehicleGroupId) return;
		const [cancelSource, vehicleGroupOwnersPromise] = vehicleGroupOwners({
			s: 1,
			n: 999,
			id: vehicleGroupId,
		})
		let isActive = true;
		vehicleGroupOwnersPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;
				setCurrentUsers(response.result.owners);
			})
			.catch((error) => {
				if (!isMounted() || !isActive) return;
				console.log(error);
			});
		return () => {
			isActive = false;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [])

	const handleUserAdd = (row) => {
		const addedUserId = row.data.id;
		setRemoveUsers((_state) => { return {..._state, [addedUserId]: undefined} } );
		setAddedUsers((_state) => { return {..._state, [addedUserId]: true} } );
		for (let i = 0; i < currentUsers.length; ++i) {
			let vehicle = currentUsers[i];
			if (vehicle.id === addedUserId) return;
		}
		setCurrentUsers((_users) => [row.data, ..._users]);
	}

	const handleUserRemove = (row) => {
		const removedUserId = row.data.id;
		setAddedUsers((_state) => { return {..._state, [removedUserId]: undefined} } );
		setRemoveUsers((_state) => { return {..._state, [removedUserId]: true} } );
		for (let i = 0; i < currentUsers.length; ++i) {
			let vehicle = currentUsers[i];
			if (vehicle.id === removedUserId) {
				setCurrentUsers((_users) => {
					const index = _users.findIndex((element) => { return element.id === removedUserId});
					if (index > 0)
						return [..._users.slice(0, index), ..._users.slice(index+1)];
					else if (index === 0) 
						return [..._users.slice(index+1)];
				});
			}
		}
	}

	const handleSaveChanges = () => {
		if (vehicleGroupOwnerManagementCancelSourceRef.current) return;
		const _add = [];
		const _remove = [];
		Object.entries(addedUsers).map(([key, value]) => {
			value && _add.push(key);
		})
		Object.entries(removeUsers).map(([key, value]) => {
			value && _remove.push(key);
		})
		const [cancelSource, vehicleGroupOwnerManagmentPromise] = manageVehicleGroupParticipants(vehicleGroupId, _add, _remove, 'owner')
		vehicleGroupOwnerManagementCancelSourceRef.current = cancelSource;
		vehicleGroupOwnerManagmentPromise
			.then((response) => {
				if (!isMounted()) return;
				vehicleGroupOwnerManagementCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((error) => {
				if (!isMounted()) return;
				vehicleGroupOwnerManagementCancelSourceRef.current = null;
				console.log(error);
			})
	}

	const handleDiscardChanges = () => {
		history.goBack();
	}

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
	];

	const searchCriteriaSkeleton = [
		// { field: "text", header: "Search" },
		{ field: "name", header: "Name" },
		{ field: "surname", header: "Surname" },
		{ field: "company_name", header: "Company Name" },
	];

	// Searching Users variables
	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}
	const allowedSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

    return (
        <div className={classes.root}>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"vehicle-search"}  >
                <h1>Search Users</h1>
				<UserTable
					searchCriteria={searchCriteria}
					defaultSearchCriteria={defaultSearchCriteria}
					allowedSearchCriteria={allowedSearchCriteria}

					onRowClick={ handleUserAdd } />
            </Box>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"vehicle-diff"}  >
                <h1>Owners</h1>
				<Button variant="contained" color="primary" onClick={ handleSaveChanges }>Save</Button> 
				<Button variant="contained" color="primary" onClick={ handleDiscardChanges }>Discard</Button> 
                <ClientSideTable
                    columns={columns}
                    rows={currentUsers}
                    searchCriteriaSkeleton={searchCriteriaSkeleton}
                    defaultTableCriteria={{}}
                    rowClick={ handleUserRemove } />
            </Box>
        </div>
    );
}

export const VehicleGroupViewerManagement = () => {

	const isMounted = useIsMounted();
	const vehicleGroupViewerManagementCancelSourceRef = useRef(null);
	const [currentUsers, setCurrentUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState({});
    const [removeUsers, setRemoveUsers] = useState({});
    const classes = styles();
	const { vehicleGroupId } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!vehicleGroupId) return;
		const [cancelSource, vehicleGroupGetPromise] = vehicleGroupViewers({
			s: 1,
			n: 999,
			id: vehicleGroupId,
		})
		let isActive = true;
		vehicleGroupGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;
				setCurrentUsers(response.result.viewers);
			})
			.catch((error) => {
				if (!isMounted() || !isActive) return;
				console.log(error);
			});
		return () => {
			isActive = false;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [])

	const handleUserAdd = (row) => {
		const addedUserId = row.data.id;
		setRemoveUsers((_state) => { return {..._state, [addedUserId]: undefined} } );
		setAddedUsers((_state) => { return {..._state, [addedUserId]: true} } );
		for (let i = 0; i < currentUsers.length; ++i) {
			let vehicle = currentUsers[i];
			if (vehicle.id === addedUserId) return;
		}
		setCurrentUsers((_users) => [row.data, ..._users]);
	}

	const handleUserRemove = (row) => {
		const removedUserId = row.data.id;
		setAddedUsers((_state) => { return {..._state, [removedUserId]: undefined} } );
		setRemoveUsers((_state) => { return {..._state, [removedUserId]: true} } );
		for (let i = 0; i < currentUsers.length; ++i) {
			let vehicle = currentUsers[i];
			if (vehicle.id === removedUserId) {
				setCurrentUsers((_users) => {
					const index = _users.findIndex((element) => { return element.id === removedUserId});
					if (index > 0)
						return [..._users.slice(0, index), ..._users.slice(index+1)];
					else if (index === 0) 
						return [..._users.slice(index+1)];
				});
			}
		}
	}

	const handleSaveChanges = () => {
		if (vehicleGroupViewerManagementCancelSourceRef.current) return;
		const _add = [];
		const _remove = [];
		Object.entries(addedUsers).map(([key, value]) => {
			value && _add.push(key);
		})
		Object.entries(removeUsers).map(([key, value]) => {
			value && _remove.push(key);
		})
		const [cancelSource, vehicleGroupViewerManagementPromise] = manageVehicleGroupParticipants(vehicleGroupId, _add, _remove, 'viewer')
		vehicleGroupViewerManagementCancelSourceRef.current = cancelSource;
		vehicleGroupViewerManagementPromise
			.then((response) => {
				if (!isMounted()) return;
				vehicleGroupViewerManagementCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((error) => {
				if (!isMounted()) return;
				vehicleGroupViewerManagementCancelSourceRef.current = null;
				console.log(error);
			})
	}

	const handleDiscardChanges = () => {
		history.goBack();
	}

	const columns = [
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "surname", headerName: "Surname", width: 200 },
	];

	const searchCriteriaSkeleton = [
		// { field: "text", header: "Search" },
		{ field: "name", header: "Name" },
		{ field: "surname", header: "Surname" },
		{ field: "company_name", header: "Company Name" },
	];

	// Searching Users variables
	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}
	const allowedSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

    return (
        <div className={classes.root}>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"vehicle-search"}  >
                <h1>Search Users</h1>
				<UserTable
					searchCriteria={searchCriteria}
					defaultSearchCriteria={defaultSearchCriteria}
					allowedSearchCriteria={allowedSearchCriteria}

					onRowClick={ handleUserAdd } />
            </Box>
            <Box boxShadow={3} borderRadius="borderRadius" elevation={3} className={"vehicle-diff"}  >
                <h1>Viewers</h1>
				<Button variant="contained" color="primary" onClick={ handleSaveChanges }>Save</Button> 
				<Button variant="contained" color="primary" onClick={ handleDiscardChanges }>Discard</Button> 
                <ClientSideTable
                    columns={columns}
                    rows={currentUsers}
                    searchCriteriaSkeleton={searchCriteriaSkeleton}
                    defaultTableCriteria={{}}
                    rowClick={ handleUserRemove } />
            </Box>
        </div>
    );
}
