import React from 'react';

import _ from 'lodash';

import { useHistory } from 'react-router-dom';

import { DrawerActions, DrawerButton, DrawerButtonConfirm, DrawerConfirmMessage, DrawerHelp, DrawerSecretField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useResetPassword } from 'services/api';

const _titleText = `Reset Password`;
const _helpText = `Use this form to reset a user password.`;

const SaveMessage = () => {
	return (
		<DrawerConfirmMessage 
			alert="The user will no longer be able to login using their old password." 
			message="Are you sure you want to reset the user's password." 
			severity="warning" 
		/>
	)
}

const _EditNewPassword = ({formData, setFormData, ...props}) => {
	const onInput = e => setFormData(state => ({...state, newPassword: e.target.value}));
	return (
		<DrawerSecretField id="new_password" label="New Password" value={formData?.newPassword} onInput={onInput} onChange={onInput} required {...props} />
	)
}

const _EditConfirmPassword = ({formData, setFormData, ...props}) => {
	const onInput = e => setFormData(state => ({...state, confirmPassword: e.target.value}));
	const error = React.useMemo(() => !_.isEqual(formData.newPassword, formData.confirmPassword), [formData]);
	return (
		<DrawerSecretField id="confirm_password" label="Confirm Password" value={formData?.confirmPassword} onInput={onInput} onChange={onInput} error={error} required {...props} />
	)
}

const _ResetPassword = ({userId}) => {

	const classes = useClassesFormStyle();

  const [formData, setFormData] = React.useState({
		userId: userId,
		newPassword: '',
		confirmPassword: '',
		alertSeverity: 'info',
		alertMessage: '',
		loading: false,
	});

	const history = useHistory();

	const _resetPassword = useResetPassword({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `Password successfully reset.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not reset user password: ${p.message}`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const disabled = React.useMemo(() => formData.loading 
		|| _.isEmpty(formData.newPassword)
		|| _.isEmpty(formData.confirmPassword)
		|| !_.isEqual(formData.newPassword, formData.confirmPassword), [formData])

	const onClickClose = (event) => {
		event?.preventDefault();
		history.goBack();
	}

	const onSubmit = (event) => {
		event?.preventDefault();
		console.warn('resetting password')
		setFormData(state => ({
			...state, 
			loading: true, 
			alertMessage: 'Resetting user password...', 
			alertSeverity: 'info'
		}));
		_resetPassword.mutate({
			id: formData.userId,
			password: formData.confirmPassword,
		});
	}

	return (
		<form noValidate classes={classes.body}>
			<DrawerTitle>{_titleText}</DrawerTitle>
			<DrawerHelp alert={formData.alertMessage} severity={formData.alertSeverity}>{_helpText}</DrawerHelp>
			<_EditNewPassword formData={formData} setFormData={setFormData} autoFocus={true} />
			<_EditConfirmPassword formData={formData} setFormData={setFormData} />
			<DrawerActions>
				<DrawerButtonConfirm themePreset="danger" details={<SaveMessage />} title="Reset password?" action="Reset Password" color="primary" onClick={onSubmit} disabled={disabled} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} />
			</DrawerActions>
		</form>
	)
}

export default ({userId}) => {
	return (
		<_ResetPassword userId={userId} />
	)
}
