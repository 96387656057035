import { axiosPostRequest, apiGet } from './api-request';

export async function fetchTripsStats(params) {
	const response = await apiGet(`/v1/trips-stats`, {
    params
  })
	const result = response?.result ?? null;
	console.warn('fetchTripsStats', {
    params,
		result
	});
	return result;
}

// User Statistics
export function userStatsPredominantEvents(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/user/alerts/predominant`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Predominant Events fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function userStatsAverageEventsPerHour(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/user/alerts/average/ph`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Avg events per hour fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function userStatsAverageEventsPerDistance(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/user/alerts/average/pd`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Avg events per distance fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

// User Group Statistics
export function userGroupStatsPredominantEvents(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/group/alerts/predominant`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Predominant Events fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function userGroupStatsAverageEventsPerHour(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/group/alerts/average/ph`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Avg events per hour fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function userGroupStatsAverageEventsPerDistance(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/group/alerts/average/pd`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Avg events per distance fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function userGroupStatsTotalTrips(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/group/trips/total`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Total trips fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function userGroupStatsAvgTripData(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/group/trips/metrics/average`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Avg trip data fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function userGroupStatsMinTripData(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/group/trips/metrics/minimum`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Min trip data fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}

export function userGroupStatsMaxTripData(id, data) {
	try {
		const body = { ...data, id: id };
		return axiosPostRequest(`${process.env.REACT_APP_WEB_SERVICE_URL}/v1/vehicle-statistics/group/trips/metrics/maximum`, body);
	} catch (e) {
        if (e.response) {
            throw new Error(e.response.data.message || 'Max trip data fetch failed');
        } else {
            throw new Error('Something went wrong...');
        }
	}
}
