import React from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import Button from '@material-ui/core/Button';

import { DrawerActions, DrawerAlert, DrawerButton, DrawerButtonConfirm, DrawerConfirmMessage, DrawerHelp, DrawerTextField, DrawerTitle, useClassesFormStyle } from 'theme/theme';
import { useCreateDevice } from 'services/api';
import { CompanySelect } from 'components/autocomplete/company-select';

const helpText = `To register a new device, please provide the device serial number and company name.`;

const RegisterMessage = () => {
	return (
		<DrawerConfirmMessage 
			// alert="If you are transferring the device to another company, then this will unlink the vehicle and driver currently linked to the device." 
			message={`Are you sure that you want to register a new device?`} 
			severity="warning" 
		/>
	)
}

export const AdminDeviceCreate = () => {

	const classes = useClassesFormStyle();

	const history = useHistory();

  const [formData, setFormData] = React.useState({
		serialNumber: '',
		companyOption: null,
		alertSeverity: 'info',
		alertMessage: '',
		loading: false,
	});

	const isSubmitDisabled = React.useMemo(() => {
		return _.isEqual(formData.loading, true) || _.isEmpty(formData.serialNumber.trim()) || _.isNil(formData.companyOption)
	}, [formData])

	const createDevice = useCreateDevice({
		onSuccess: (p) => {
			setFormData(state => ({...state, alertMessage: `Successfully registered new device '${p.serial_number}' for company '${p.company_name}'.`, alertSeverity: "success"}));
		},
		onError: (p) => {
			setFormData(state => ({...state, alertMessage: `Could not register device: ${p.message}`, alertSeverity: "error"}));
		},
		onSettled: () => {
			setFormData(state => ({...state, loading: false}));
		}
	});

	const onSubmit = React.useCallback((event) => {
    event.preventDefault();
		if (isSubmitDisabled) {
			setFormData(state => ({
				...state, 
				alertMessage: 'Form incomplete. Please provide the missing information.', 
				alertSeverity: 'error'
			}));
			return
		}

		setFormData(state => ({
			...state, 
			loading: true, 
			alertMessage: 'Registering new device...', 
			alertSeverity: 'info'
		}));

		// console.warn('createDevice:onSubmit', {
		// 	formData
		// })

		createDevice.mutate({
			serial_number: formData.serialNumber,
			company_id: formData.companyOption.value,
		});
	}, [createDevice, formData, isSubmitDisabled]);

	const onClickClose = () => {
		history.goBack();
	}

	const onInputSerialNumber = e => setFormData(state => ({...state, serialNumber: e.target.value}));

	const onChangeCompany = (data) => {
		setFormData(state => ({...state, companyOption: data}))
	};

	return (
		<form noValidate onSubmit={onSubmit} classes={classes.body}>
			<DrawerTitle>Register Device</DrawerTitle>
			<DrawerHelp alert={formData.alertMessage} severity={formData.alertSeverity}>{helpText}</DrawerHelp>
			{/* <DrawerHelp>{formData.alertMessage}</DrawerHelp> */}
			{/* <DrawerAlert variant="filled" severity={formData.alertSeverity}>{formData.alertMessage}</DrawerAlert> */}
			<CompanySelect 
				value={formData.companyOption} 
				onChange={onChangeCompany} 
				autoFocus={true}
			/>
			<DrawerTextField
				required
				id="serial_number"
				label="Device Serial Number"
				autoComplete="off"
				value={formData.serialNumber}
				onInput={onInputSerialNumber}
			/>
			<DrawerActions>
				<DrawerButtonConfirm details={<RegisterMessage />} title="Register New Device?" action="Register" color="primary" onClick={onSubmit} />
				<DrawerButton action="Close" color="secondary" onClick={onClickClose} disabled={formData.loading} />
			</DrawerActions>
		</form>
	)
}
