// import './device-edit.css';
import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EditView } from 'components/edit-view/edit-view';

import { deviceAssignCompany } from 'services/device';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { useFetchDevice } from 'services/api';

export const AdminDeviceEdit = () => {

	const isMounted = useIsMounted();
    const deviceAssignCompanyCancelSourceRef = useRef(null);
	const { id } = useParams();
	const history = useHistory();

	const { data: deviceData } = useFetchDevice(id);

	const handleEditSave = (data) => {
        if (deviceAssignCompanyCancelSourceRef.current) return;
		const [cancelSource, deviceAssignCompanyPromise] = deviceAssignCompany({ ...data, serial_number: id })
        deviceAssignCompanyCancelSourceRef.current = cancelSource;
		deviceAssignCompanyPromise
            .then((response) => {
                if (!isMounted()) return
                deviceAssignCompanyCancelSourceRef.current = null;
				console.log(response)
				if (!response.success) return;
				history.goBack();
			})
			.catch((err) => {
                if (!isMounted()) return
                deviceAssignCompanyCancelSourceRef.current = null;
				console.log(err)
			});
		// handleView();
	}

	const handleEditCancel = () => {
		history.goBack();
	}

	const editSkeleton = [
		{ key: 1, property: "company_id", header: "Company ID", required: true },
	];

	return (<>
		{
			deviceData && <EditView
				skeleton={editSkeleton}
				data={deviceData}
				isDefaultData={true}
				
				onSave={ handleEditSave }
				onCancel={ handleEditCancel } />
		}
	</>);
}
