import './tiny-info-view.css';
import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const TinyInfoView = ({ skeleton, data, onEdit, onDelete, onMaximize }) => {

	const handleEdit = () => {
		onEdit && onEdit();
	}

	const handleMaximize = () => {
		onMaximize && onMaximize(false);
	}

	// const handleDelete = () => {
	// 	onDelete && onDelete();
	// }

	return (
		<div className={"tiny-info-container"}>
			{
				onEdit &&
				<IconButton aria-label="edit" onClick={ handleEdit }>
					<EditIcon />
				</IconButton>
			}
			{/* {
				onDelete &&
				<IconButton aria-label="edit" onClick={ handleDelete }>
					<DeleteIcon />
				</IconButton>
			} */}
			{
				onMaximize &&
				<IconButton aria-label="maximize" onClick={ handleMaximize }>
					<ExpandMoreIcon />
				</IconButton>
			}
			{
				data
				?	skeleton.map((obj, index) => {
						return (
							<div key={`${obj.property}-${index}`} className={"tiny-info-section"} >
								{/* HEADER */}
								{
									obj.header ? <h6 className={"tiny-info-header"} >{obj.header}</h6> : <></>
								}
								{/* VALUE */}
								{
									obj.formatValue
									? obj.formatValue(data[obj.property], index)
									: <div className={"tiny-info-content"} >{data[obj.property]?.toString()}</div>
								}
							</div>
						)
					})
				:	skeleton.map((obj, index) => {
						return (
							<div key={`skeleton-${obj.property}-${index}`}  className={"tiny-info-section"} >
								{/* HEADER */}
								{
									obj.header ? <h6 className={"tiny-info-header"} >{obj.header}</h6> : <></>
								}
								{/* VALUE */}
								{
									obj.skeleton
									? obj.skeleton(index)
									: <Skeleton />
								}
							</div>
						)
					})
			}
		</div>
	);
}
