import React from 'react';
import { useClassesDrawerStyle } from 'theme/theme';

// https://v4.mui.com/api/drawer/#drawer-api
// https://v4.mui.com/components/drawers/
import Drawer from '@material-ui/core/Drawer';

export const CrudDrawer =  ({children}) => {
  const classes = useClassesDrawerStyle();

	// const { path, url } = useRouteMatch();
	// const history = useHistory();
	// console.warn('_AdminDeviceCreate', {
	// 	path, url
	// })

	// todo: this drawer should only be closed by the inner component
	const onClose = () => {
		// console.warn('_AdminDeviceCreate:onClose');
		// history.goBack();
		// history.push(`${url}`);

		// history.push(`.`);
	}

	return (
    <Drawer
      variant="temporary"
      // variant="persistent"
      // variant="permanent"
      anchor="right"
      classes={{
				paper: classes.rightDrawerPaper
			}}
      open={true}
      onClose={onClose}
    >
      {/* <Toolbar /> */}
			{/* <Typography /> */}
      <div className={classes.drawerContainer}>
				{children}
			</div>
    </Drawer>
	)
}