// import './dialogs.css';
import React from 'react';

import clsx from 'clsx';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { Link, useHistory } from 'react-router-dom';

import { CircularProgress, Toolbar, Typography, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, createStyles } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PhotoIcon from '@material-ui/icons/Photo';

import { doHideAbout, useVersionUpdateInfo, useProductConfiguration, useProductCopyright, useProductReleaseDate, useProductVersion, useShowAbout } from 'modules/useGlobalStore';
import { useUserRoot } from 'modules/useTokenStore';

// https://v4.mui.com/styles/api/#makestyles-styles-options-hook
// https://v4.mui.com/styles/api/#createstyles-styles-styles
const useStyles = makeStyles((theme) => createStyles({
	dialogContentRoot: {
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
  imageLoading: {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: '50%',
    marginLeft: -34, // size/2, e.g. 68/e
  },
	imageContentLoading: {
    filter: 'grayscale(100%)',
  },
	imageContent: {
		[theme.breakpoints.up("md")]: {
		    width: '60vw'
		},
		[theme.breakpoints.down("sm")]: {
        height: '60vh'
		}
  },
  imageDiv : {
    position: 'relative',
    display: 'inline-block'
  },
  overlay: {
    position: 'absolute',
    left: 0,
    zIndex: 5
  },
}));

export const GenericDialog = ({ title, info, open, onClose }) => {

    const handleCloseClicked = () => {
        onClose(false);
    }

    const handleAcceptClicked = () => {
        onClose(true);
    }

	return (
        <Dialog onClose={ onClose } aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {info}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseClicked } color="primary">
                    Cancel
                </Button>
                <Button onClick={ handleAcceptClicked } color="primary" autoFocus>
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const ErrorDialog = ({ title, info, open, onClose }) => {

	return (
        <Dialog onClose={ onClose } aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {info}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onClose } color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

// https://trello.com/c/Su2gotma
// https://v4.mui.com/components/dialogs/#scrolling-long-content
export const AboutDialog = () => {
  const showAbout = useShowAbout();
  const productConfiguration = useProductConfiguration();
  const productReleaseDate = useProductReleaseDate();
  const productCopyright = useProductCopyright();
  const roleRoot = useUserRoot();
  const newUrl = `${roleRoot}/release-notes`;
  const history = useHistory();

  const { productVersion } = useVersionUpdateInfo();

  const onClickWhatsNew = (event) => {
    event?.preventDefault();
    history.push(newUrl)
    doHideAbout();
  }

  return (
    <Dialog onClose={doHideAbout} open={showAbout}>
        <DialogTitle align="center">DriveVUE</DialogTitle>
        <DialogContent>
            <DialogContentText align="center">{productVersion}</DialogContentText>
            <DialogContentText align="center">{productConfiguration}</DialogContentText>
            <DialogContentText align="center">{productReleaseDate}</DialogContentText>
            <DialogContentText align="center">{productCopyright}</DialogContentText>
            <DialogContentText align="center">✨ <Link to={newUrl} onClick={onClickWhatsNew}>What's New...</Link></DialogContentText>
        </DialogContent>
        <Button onClick={doHideAbout} color="primary" autoFocus>Ok</Button>
    </Dialog>
  );
}

const DialogTitleWithClose = (props) => {
  const { children, title, onClose } = props;

  // const classes = useStyles();

  // https://v4.mui.com/api/dialog-title/#css

  // const toolbarStyle = React.useMemo(() =>({
  //   justifyContent: "space-between",
  // }), [classes]);

  return (
    <>
      <Toolbar variant="dense" disableGutters style={{justifyContent: "space-between"}}>
        <IconButton disabled={true}>
          <ImageSearchIcon />
        </IconButton>
        <Typography variant="h6">{title}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Toolbar>
      {children ?? null}
    </>
  );
};

// https://trello.com/c/t9N7sjha
// 1) Scale as much as possible vertically
// 2) Or show native resolution (without vertical scroll)
// https://www.npmjs.com/package/react-responsive-pinch-zoom-pan
// https://morioh.com/p/bca9c144354c
// https://github.com/strateos/react-map-interaction
// https://github.com/ethanselzer/react-image-magnify
// https://github.com/LeoNero/react-img-zoom
// https://github.com/malaman/react-image-zoom
// https://github.com/gerhardsletten/react-pinch-zoom-pan
// https://github.com/chrvadala/react-svg-pan-zoom
// https://github.com/rpearce/image-zoom
// https://github.com/bradstiff/react-responsive-pinch-zoom-pan
// https://github.com/prc5/react-zoom-pan-pinch
// https://github.com/sylvaindubus/react-prismazoom
export const ImageDialog = ({onClose, open, loading, src, alt, children}) => {
  const classes = useStyles();

  // https://v4.mui.com/components/transitions/#fade

  const dgRoot = React.useMemo(() =>({
    root: classes.dialogContentRoot
  }), [classes]);

	const imgClass = clsx(classes.imageContent, {
		[classes.imageContentLoading]: loading,
	});

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
        <DialogTitleWithClose title={alt} onClose={onClose}>
          {children}
        </DialogTitleWithClose>
        <DialogContent classes={dgRoot}>
          {loading && <CircularProgress size={68} className={classes.imageLoading} />}
          <TransformWrapper initialScale={1} limitToBounds={true} centerZoomedOut={true} centerOnInit={true}>
            <TransformComponent>
              <img className={imgClass} src={src} alt={alt} />
            </TransformComponent>
          </TransformWrapper>
        </DialogContent>
    </Dialog>
  );
}

export const MediaDialog = ({onClose, open, loading, imgSrc, videoSrc, alt, children, showVideo, toggleVideo, autoPlay}) => {
  const classes = useStyles();

  // https://v4.mui.com/components/transitions/#fade

  const dgRoot = React.useMemo(() =>({
    root: classes.dialogContentRoot
  }), [classes]);

	const imgClass = clsx(classes.imageContent, {
		[classes.imageContentLoading]: loading,
	});

  const overlayClass = clsx(classes.overlay);

  const divClass = clsx(classes.imageDiv);

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
        <DialogTitleWithClose title={alt} onClose={onClose}>
          {children}
        </DialogTitleWithClose>
        <DialogContent classes={dgRoot}>
          {loading && <CircularProgress size={68} className={classes.imageLoading} />}
          <TransformWrapper initialScale={1} limitToBounds={true} centerZoomedOut={true} centerOnInit={true}>
            <TransformComponent>
            {
              showVideo?
                videoSrc?
                  <>
                    <div className={overlayClass}>
                    {
                      imgSrc?
                        <IconButton aria-label="play" className="btn btn-default pull-left" onClick={toggleVideo}>
                          <PhotoIcon />
                        </IconButton>
                      : <></>
						        }
                    </div>
                    <video controls playsInline autoPlay={autoPlay} muted
                      src={videoSrc} 
                      className={imgClass}
                      style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                    />					
                  </>
                : <></>
              :
                imgSrc?
                  <>
                    <div className={overlayClass}>
                    {
                      videoSrc?
                        <IconButton aria-label="play" className="btn btn-default pull-left" onClick={toggleVideo}>
                          <PlayArrowIcon />
                        </IconButton>
                      : <></>
                    }
                    </div>
                    <img 
                      className={imgClass} 
                      src={imgSrc} 
                      alt={alt}
                      style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                    />
                  </>
                : <></>
            }
            </TransformComponent>
          </TransformWrapper>
        </DialogContent>
    </Dialog>
  );
}

export const VideoDialog = ({onClose, open, loading, src, alt, children}) => {
  const classes = useStyles();

  // https://v4.mui.com/components/transitions/#fade

  const dgRoot = React.useMemo(() =>({
    root: classes.dialogContentRoot
  }), [classes]);

	const imgClass = clsx(classes.imageContent, {
		[classes.imageContentLoading]: loading,
	});

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
        <DialogTitleWithClose title={alt} onClose={onClose}>
          {children}
        </DialogTitleWithClose>
        <DialogContent classes={dgRoot}>
          {loading && <CircularProgress size={68} className={classes.imageLoading} />}
          <TransformWrapper initialScale={1} limitToBounds={true} centerZoomedOut={true} centerOnInit={true}>
            <TransformComponent>
              <video controls playsInline autoPlay muted
                className={imgClass} 
                src={src} 
                alt={alt}
                style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                />
            </TransformComponent>
          </TransformWrapper>
        </DialogContent>
    </Dialog>
  );
}
