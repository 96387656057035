// import './vehicle-create.css';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { EditView } from 'components/edit-view/edit-view';

import { create } from 'services/vehicle';
import { useIsMounted } from 'shared-hooks/useIsMounted';

export const VehicleCreate = () => {

    const isMounted = useIsMounted();
    const vehicleCreateCancelSourceRef = useRef(null);
	const history = useHistory();

	const handleCreate = (data) => {
        if (vehicleCreateCancelSourceRef.current) return;
		const [cancelSource, vehicleCreatePromise] = create(data)
        vehicleCreateCancelSourceRef.current = cancelSource;
		vehicleCreatePromise
            .then((response) => {
                if (!isMounted()) return;
                vehicleCreateCancelSourceRef.current = null;
                if (!response.success || !response.result);
				history.goBack();
			})
			.catch((err) => {
                if (!isMounted()) return;
                vehicleCreateCancelSourceRef.current = null;
				console.log(err)
			});
	}

	const handleCreateCancel = () => {
		history.goBack();
	}

	const createSkeleton = [
		// { key: 0, property: "profile_image" },
		{ key: 1, property: "make", header: "Make", required: true },
		{ key: 2, property: "model", header: "Model", required: true },
		{ key: 3, property: "colour", header: "Colour", required: true },
		{
			key: 4,
			property: "type",
			header: "Type",
			type: "selection",
			values: [
				{ value: "sedan", text: "Sedan" },
				{ value: "truck", text: "Truck" },
				{ value: "pickup", text: "Pickup" },
			],
			required: true
		},
		{ key: 5, property: "registration_number", header: "Reg Nr.", required: true },
		{ key: 6, property: "name", header: "Name", required: true },
	];

	return (
		<EditView
			skeleton={createSkeleton}
			data={ {make: '', model: '', colour: '', type: 'sedan', registration_number: '', company_name: '' } } 
			isDefaultData={true}
			onSave={ handleCreate }
			onCancel={ handleCreateCancel } />
	);
}
