import "./data-tables.css";
import React, { useCallback, useEffect, useState } from 'react';

import { generateDefaultCriteria, generateDefaultSearchCriteria, generateDefaultSearchCriteriaSkeleton, generateCriteria, generateSearchCriteria } from "./data-table-functions";
import { getCourseLessons, getCourses } from "services/training-management";

import { ServerSideTable } from 'components/table/table';
import { getUserCourses, getUserLessons } from "services/course";
import { useIsMounted } from "shared-hooks/useIsMounted";
import { WARN_USE_EFFECT, WARN_USE_EFFECT_CLEAN } from "shared-functions/shared-functions";

export const AdminCourseTable = ({ defaultCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, null),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		text: { field: "text", header: "Search" },
		name: { field: "title", header: "Title" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, null),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [cancelSource, coursesGetPromise] = getCourses(data)
		let isActive = true;
		coursesGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				if (!response.success) return;
				const courses = response.result.courses;
				setTotal(parseInt(response.result.count));
				setData(courses);
			})
			.catch((err) => {
				setLoading(false);
			});
		return () => {
			console.log(WARN_USE_EFFECT_CLEAN);
			// isActive = false;
			// cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "title", headerName: "Title", width: 200 },
		{ field: "num_lessons", headerName: "Total Lessons", width: 200 },
		{ field: "is_default", headerName: "Default", width: 150 },
		{ field: "created_on", headerName: "Created On", width: 250 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const AdminLessonsTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		// text: { field: "text", header: "Search" },
		// name: { field: "title", header: "Title" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [cancelSource, courseLessonsGetPromise] = getCourseLessons(data)
		let isActive = true;
		courseLessonsGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				if (!response.success) return;
				const courses = response.result;
				setTotal(parseInt(courses.length));
				setData(courses);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err)
				setLoading(false);
			});
		return () => {
			console.log(WARN_USE_EFFECT_CLEAN);
			// isActive = false;
			// cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED)
		}
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "title", headerName: "Title", width: 400 },
		{ field: "num_slides", headerName: "Total Slides", width: 150 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const ClientAdminCourseTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		// text: { field: "text", header: "Search" },
		// name: { field: "title", header: "Title" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [userCoursesGetCancelSource, userCoursesGetPromise] = getUserCourses(data)
		let isActive = true;
		userCoursesGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;
				setLoading(false);
				const courses = response.result.map((value, _) => {
					return {
						...value,
						progress: value.progress !== null ? `${(value.progress * 100).toFixed(2)}%` : 'Course not started',
						score: value.score !== null ? `${(value.score * 100).toFixed(2)}%` : 'Course not started',
					}
				});

				setTotal(parseInt(response.result.count));
				setData(courses);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log("error:", err)
				setLoading(false);
			});
		return () => {
			console.log(WARN_USE_EFFECT_CLEAN);
			// isActive = false;
			// userCoursesGetCancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "title", headerName: "Title", width: 250 },
		{ field: "num_lessons", headerName: "Total Lessons", width: 150 },
		{ field: "progress", headerName: "Progress", width: 130 },
		{ field: "score", headerName: "Score", width: 150 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export const ClientAdminLessonsTable = ({ defaultCriteria, fixedCriteria, allowedSearchCriteria, defaultSearchCriteria, onRowClick }) => {

	const isMounted = useIsMounted();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState();
	const [tableCriteria, setTableCriteria] = useState({
		...generateDefaultCriteria(defaultCriteria, fixedCriteria),
		searchCriteria: generateDefaultSearchCriteria(defaultSearchCriteria)
	});
	const [searchCriteriaSkeleton] = useState(generateDefaultSearchCriteriaSkeleton({
		// text: { field: "text", header: "Search" },
		// name: { field: "title", header: "Title" },
		// company_name: { field: "company_name", header: "Company Name" },
	}, allowedSearchCriteria));

	useEffect(() => {
		console.log(WARN_USE_EFFECT);
		setLoading(true);
		const data = {
			...generateCriteria(tableCriteria, fixedCriteria),
			searchCriteria: generateSearchCriteria(tableCriteria.searchCriteria, allowedSearchCriteria)
		};
		const [userLessonsGetCancelToken, userLessonsGetPromise] = getUserLessons(data)
		let isActive = true;
		userLessonsGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setLoading(false);
				if (!response.success || !response.result) return;

				const lessons = response.result.lessons.map((value, _) => {
					return {
						...value,
						progress: value.progress !== null ? `${(value.progress * 100).toFixed(2)}%` : 'Lesson not started',
						score: value.score !== null ? `${(value.score * 100).toFixed(2)}%` : 'Not completed',
					}
				});
				setTotal(parseInt(lessons.length));
				setData(lessons);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err)
				setLoading(false);
			});
		return () => {
			console.log(WARN_USE_EFFECT_CLEAN);
			// isActive = false;
			// userLessonsGetCancelToken.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [ tableCriteria ])

	const handleRowClick = (data) => {
		onRowClick && onRowClick(data);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const columns = [
		{ field: "title", headerName: "Title", width: 400 },
		{ field: "num_slides", headerName: "Total Slides", width: 130 },
		{ field: "progress", headerName: "Progress", width: 150 },
		{ field: "score", headerName: "Score", width: 150 },
	];

	return (
		<div className={"table-container"}>
			<ServerSideTable
				columns={columns}
				rows={data}
				loading={loading}
				total={total}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}
