import './user-tabs.css';
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';

import _ from 'lodash';

import 'date-fns';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

import { UserAlerts } from './user-alerts/user-alerts';
import { UserTripTable } from './user-trips/user-trips';
import { CustomTabPanel } from '../tab-panel';
import { TrainingStats, UserStatsAverageEventsPerDistance, UserStatsAverageEventsPerHour, UserStatsPredominantEvents, UserStatsPredominantEventsStatic } from 'components/statistics/user-statistics';
import { UserGroupEventStatistics, UserGroupStatistics } from '../user-group-tabs/user-group-tabs';
import { useIsCurrentUserDriver, usePreferenceStore } from 'modules/useTokenStore';
import { DateRange } from 'components/date-range/date-range';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';
import { SimpleClientSideTable } from 'components/table/client-side-table';
import { getUserCourses } from 'services/course';
import { historyPushBreadcrumb, useLinkToWithBreadcrumb, useRoleCanViewOwnTrips, useRoleFeatureCheck } from 'modules/useGlobalStore';
import { useFetchTrips, useFetchTripsStats, useFetchUser } from 'services/api';
import { createPageCriteria } from 'components/data-tables/data-table-functions';
import { DisplayField, useClassesFormStyle } from 'theme/theme';

function a11yProps(index) {
	return {
		value: `${index}`,
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

// TODO: this could be improved


const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '100%',
		// backgroundColor: theme.palette.background.paper,
	},
}));

export const UserTabs = ({ userId }) => {

	const [value, setValue] = useState('0');
	const classes = useStyles();

	const handleChange = (event, newValue) => {
		value !== newValue && setValue(newValue);
	};

  const featureCheck = useRoleFeatureCheck('role_can_view_own_courses');

	return (
		<div className={classes.root}>
			{/* <AppBar position="static"> */}
				<Tabs value={value} onChange={ handleChange } variant="scrollable" scrollButtons="on" aria-label="simple tabs">
					<Tab label="Statistics" {...a11yProps(0)} />
					<Tab label="Event Statistics" {...a11yProps(1)} />
					<Tab label="Trips" {...a11yProps(2)} />
					{featureCheck && <Tab label="Training" {...a11yProps(3)} />}
					<Tab label="Events" {...a11yProps(4)} />
				</Tabs>
			{/* </AppBar> */}
			<CustomTabPanel value={value} index={'0'}>
				<UserStatistics userId={userId} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={'1'}>
				<UserEventStatistics userId={userId} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={'2'}>
				<UserTripTable userId={userId} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={'3'}>
				<TrainingStats userId={userId} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={'4'}>
				<UserAlerts userId={userId} />
			</CustomTabPanel>
		</div>
	);
}

const _UserTripsStats = ({ userId, startDate, endDate }) => {

	// todo: consider including the average trip speed, minimum trip speed, maximum trip speed, median trip speed
	// todo: consider adding stats: average hourly: trips, alerts, duration, distance
	// todo: consider adding stats: average daily: trips, alerts, duration, distance
	// todo: consider adding stats: average weekly: trips, alerts, duration, distance
	// todo: consider adding stats: average monthly: trips, alerts, duration, distance
	// todo: consider adding stats: average yearly: trips, alerts, duration, distance
	// todo: show date of the oldest trip
	// todo: show date of the latest trip
	const { data: tripsStats } = useFetchTripsStats({ 
		driver_id: userId, 
		start_time_min: startDate, 
		start_time_max: endDate 
	});

	const tripsTotal = tripsStats?.trips_count;
	const tripsAlertsTotal = tripsStats?.trips_alerts_total;
	const tripsAlertsAverage = tripsStats?.trips_alerts_average;
	const tripsAlertsMinimum = tripsStats?.trips_alerts_minimum;
	const tripsAlertsMaximum = tripsStats?.trips_alerts_maximum;
	const tripsDurationTotal = tripsStats?.trips_duration_total;
	const tripsDurationAverage = tripsStats?.trips_duration_average;
	const tripsDurationMinimum = tripsStats?.trips_duration_minimum;
	const tripsDurationMaximum = tripsStats?.trips_duration_maximum;
	const tripsDistanceTotal = tripsStats?.trips_distance_total;
	const tripsDistanceAverage = tripsStats?.trips_distance_average;
	const tripsDistanceMinimum = tripsStats?.trips_distance_minimum;
	const tripsDistanceMaximum = tripsStats?.trips_distance_maximum;

	return (
		<Grid container>
			<Grid item xs={12}>
				<DisplayField title="Total Trips" value={tripsTotal} unit="number" />
			</Grid>
			<Grid item xs={2}>
				<DisplayField title="Total Alerts" value={tripsAlertsTotal} unit="number" />
			</Grid>
			<Grid item xs={5}>
				<DisplayField title="Total Duration" value={tripsDurationTotal} unit="duration" />
			</Grid>
			<Grid item xs={5}>
				<DisplayField title="Total Distance" value={tripsDistanceTotal} unit="distance" />
			</Grid>
			<Grid item xs={2}>
				<DisplayField title="Average Alerts" value={tripsAlertsAverage} unit="number" />
			</Grid>
			<Grid item xs={5}>
				<DisplayField title="Average Duration" value={tripsDurationAverage} unit="duration" />
			</Grid>
			<Grid item xs={5}>
				<DisplayField title="Average Distance" value={tripsDistanceAverage} unit="distance" />
			</Grid>
			<Grid item xs={2}>
				<DisplayField title="Least Alerts" value={tripsAlertsMinimum} unit="number" />
			</Grid>
			<Grid item xs={5}>
				<DisplayField title="Fastest Duration" value={tripsDurationMinimum} unit="duration" />
			</Grid>
			<Grid item xs={5}>
				<DisplayField title="Shortest Distance" value={tripsDistanceMinimum} unit="distance" />
			</Grid>
			<Grid item xs={2}>
				<DisplayField title="Most Alerts" value={tripsAlertsMaximum} unit="number" />
			</Grid>
			<Grid item xs={5}>
				<DisplayField title="Longest Duration" value={tripsDurationMaximum} unit="duration" />
			</Grid>
			<Grid item xs={5}>
				<DisplayField title="Greatest Distance" value={tripsDistanceMaximum} unit="distance" />
			</Grid>
		</Grid>
	)
}


export const UserStatistics = ({ userId }) => {

	const [compareToGroup, setCompareToGroup] = useState(false);
	const [now] = useState(new Date());
	const [startTime, setStartTime] = useState(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
	const [endTime, setEndTime] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));
	const [userGroupId, setUserGroupId] = useState('');
	const unitSystem = usePreferenceStore(state => state.preferences.unit_system);

	const { data: userData } = useFetchUser(userId);

	const handleStartDateChange = (start) => {
		setStartTime(start)
	}

	const handleEndDateChange = (end) => {
		setEndTime(end)
	}

	const handleCompareToGroup = () => {
		setCompareToGroup(true);
	}

	const handleUserGroupChanged = (params) => {
		setUserGroupId(params.target.value)
	}

	const classes = useClassesFormStyle();

	const isDriverRole = useIsCurrentUserDriver();

	return (
		<>
			<span className={classes.flexRoot}>
				<div className={classes.flex1}>
					<div>
						<DateRange
							defaultStartDate={startTime}
							defaultEndDate={endTime}
							onStartDateChange={handleStartDateChange}
							onEndDateChange={handleEndDateChange} />
					</div>
					{!isDriverRole && <Button variant='contained' color='primary' onClick={handleCompareToGroup} >Compare to Group</Button>}
					<_UserTripsStats userId={userId} startDate={startTime} endDate={endTime} />
				</div>
				{
					compareToGroup &&
					<div style={{ flex: 1 }}>
						<FormControl style={{ minWidth: 150 }}>
							<InputLabel>User Group</InputLabel>
							<Select
								value={userGroupId}
								label="User Group"
								autoWidth
								disabled={!userData || userData.groups.length < 1}
								onChange={handleUserGroupChanged}
							>
								{
									userData && userData.groups.map((value, _) => {
										return <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
									})
								}
							</Select>
						</FormControl>

						<UserGroupStatistics userGroupId={userGroupId} unitSystem={unitSystem} startDate={startTime} endDate={endTime} />
					</div>
				}
			</span>
		</>
	);
}

export const UserStatisticsMini = ({ userId }) => {

	const { url } = useRouteMatch();

	const linkTo = useLinkToWithBreadcrumb(`${url}/statistics`);

	const classes = useClassesFormStyle();

	// container justify="space-between"
	return (
		<div className={classes.miniRoot}>
			<Link to={linkTo}><Typography variant="button">Trip Statistics</Typography></Link>
			<_UserTripsStats userId={userId} />
		</div>
	);
}

export const UserEventStatistics = ({ userId }) => {

	// console.log('UserEventStatistics', userId);

	const [compareToGroup, setCompareToGroup] = useState(false);
	const [now] = useState(new Date());
	const [startTime, setStartTime] = useState(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
	const [endTime, setEndTime] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));
	const [userGroupId, setUserGroupId] = useState('');
	const unitSystem = usePreferenceStore(state => state.preferences.unit_system);

	const { data: userData } = useFetchUser(userId);

	const handleStartDateChange = (start) => {
		setStartTime(start)
	}

	const handleEndDateChange = (end) => {
		setEndTime(end)
	}

	const handleCompareToGroup = () => {
		setCompareToGroup(true);
	}

	const handleUserGroupChanged = (params) => {
		setUserGroupId(params.target.value)
	}

	return (
		<>
			<span style={ { display: 'flex' } }>
				<div style={ { flex: 1 } }>
					<div>
						<DateRange
							defaultStartDate={startTime}
							defaultEndDate={endTime}
							onStartDateChange={ handleStartDateChange }
							onEndDateChange={ handleEndDateChange } />
					</div>
					<Button variant='contained' color='primary' onClick={ handleCompareToGroup } >Compare to Group</Button>
					<UserStatsPredominantEvents userId={userId} startDate={startTime} endDate={endTime} />
					<UserStatsAverageEventsPerHour userId={userId} startDate={startTime} endDate={endTime} />
					<UserStatsAverageEventsPerDistance userId={userId} unitSystem={unitSystem} startDate={startTime} endDate={endTime} />
				</div>
				{
					compareToGroup &&
					<div style={ { flex: 1 } }>
						<FormControl style={{ minWidth: 150 }}>
							<InputLabel>User Group</InputLabel>
							<Select
								value={userGroupId}
								label="User Group"
								autoWidth
								disabled={!userData || userData.groups.length < 1}
								onChange={ handleUserGroupChanged }
							>
								{
									userData && userData.groups.map((value, _) => {
										return <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
									})
								}
							</Select>
						</FormControl>
							
						<UserGroupEventStatistics userGroupId={userGroupId} unitSystem={unitSystem} startDate={startTime} endDate={endTime} />
					</div>
				}
			</span>
		</>
	);
}

export const UserEventStatisticsMini = ({ userId }) => {

	// console.log('UserEventStatisticsMini', userId);

	const { url } = useRouteMatch();

		const linkTo = useLinkToWithBreadcrumb(`${url}/event-statistics`);

	return (
		<>
            <div style={{ height: '100%', padding: 10 }}>
                <Link to={linkTo}><Typography variant="button">Event Statistics</Typography></Link>
                <UserStatsPredominantEventsStatic userId={userId} totalEvents={5} />
            </div> 
		</>
	);
}

// tripsMiniColumns is defined outside the component so that it doesn't cause a
// re-render when instantiated.
const tripsMiniColumns = [
	{ field: 'start_time', headerName: 'Start Time', type: 'dateTime', width: 100 },
	{ field: 'vehicle_name', headerName: 'Vehicle Alias', width: 100 },
	{ field: 'vehicle_registration_number', headerName: 'Reg Nr', width: 100 },
	{ 
		field: 'distance', headerName: 'Distance', width: 150, type: 'distance',
		valueGetter: (params) => _.round(params.value / 1000.0, 1),
	},
	{ 
		field: 'duration', headerName: 'Duration', width: 150, type: 'duration',
		valueGetter: (params) => params.value,
	},
	{ field: 'alert_count', headerName: 'Alerts', width: 150, type: 'number' },
	// { field: 'live', headerName: 'Live', width: 100 },
];


export const TripsMini = ({ userId }) => {
	// console.log('TripsMini', userId)

	// const featureCheck = useRoleFeatureCheck('role_can_view_own_trips');
	const featureCheck = useRoleCanViewOwnTrips();

	const { tripList, isFetching } = useFetchTrips(createPageCriteria(0, 5, true, 'start_time', {
		driver_id: userId
	}));

    const history = useHistory();
    const { url } = useRouteMatch();

    const handleRowClick = (data) => {
        // console.log('TripsMini', data);
				historyPushBreadcrumb(history, `${url}/trips/${data.id}`)
    }

		const linkTo = useLinkToWithBreadcrumb(`${url}/trips`);

		if (!featureCheck) {
			return null
		}

    return (
        <>
            <div style={{ height: '100%', padding: 10 }}>
                <Link to={linkTo}><Typography variant="button">Trips</Typography></Link>
                <SimpleClientSideTable
                    columns={tripsMiniColumns}
                    rows={tripList}
                    loading={isFetching}
                    rowClick={ handleRowClick } />
            </div>
        </>
    )
}

export const TrainingMini = ({ userId }) => {

		const featureCheck = useRoleFeatureCheck('role_can_view_own_courses');

    const isMounted = useIsMounted();
    const [trainingData, setTrainingData] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { url } = useRouteMatch();

    useEffect(() => {
		setLoading(true);
		const data = {
            user_id: userId
		};
		const [userCoursesGetCancelSource, userCoursesGetPromise] = getUserCourses(data)
		let isActive = true;
		userCoursesGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) return;
				setLoading(false);
				const courses = response.result.map((value, _) => {
					return {
						...value,
						progress: value.progress !== null ? `${(value.progress * 100).toFixed(2)}%` : 'Course not started',
						score: value.score !== null ? `${(value.score * 100).toFixed(2)}%` : 'Course not started',
					}
				});

				// setTotal(parseInt(response.result.count));
				setTrainingData(courses);
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log("error:", err)
				setLoading(false);
			});
		return () => {
			isActive = false;
			userCoursesGetCancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
    }, [userId]) // eslint-disable-line

    const handleRowClick = (data) => {
        history.push(`${url}/training/${data.id}`);
    }

		const linkTo = useLinkToWithBreadcrumb(`${url}/training`);

		if (!featureCheck) {
			return null
		}

    return (
        <>
            <div style={{ height: '100%', padding: 10 }}>
                <Link to={linkTo}><Typography variant="button">Training</Typography></Link>
                <SimpleClientSideTable
                    columns={[
                        { field: 'title', headerName: 'Title', width: 100 },
                        { field: 'progress', headerName: 'Progress', width: 100 },
                        { field: 'score', headerName: 'Score', width: 100 },
                    ]}
                    rows={trainingData}
                    loading={loading}
                    rowClick={ handleRowClick } />
            </div>
        </>
    )
}
