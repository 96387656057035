import './table.css';
import React, { useEffect, useState } from 'react';

import { DataGrid } from '@material-ui/data-grid';
import { Filter } from './filter/filter';
import { createPageCriteria } from 'components/data-tables/data-table-functions';
import { useUpdateBookmark } from 'modules/useTokenStore';

export const ServerSideTable = (props) => {

	const { bookmarkId, columns, rows, loading, total, searchCriteriaSkeleton, defaultTableCriteria, criteriaChanged, rowClick } = props;

	const [page, setPage] = useState(defaultTableCriteria && defaultTableCriteria.page ? defaultTableCriteria.page : 1);
	const [count, setCount] = useState(defaultTableCriteria && defaultTableCriteria.count ? defaultTableCriteria.count : 20);
	const [orderBy, setOrderBy] = useState(defaultTableCriteria && defaultTableCriteria.orderBy ? defaultTableCriteria.orderBy : "");
	const [flipOrder, setFlipOrder] = useState(defaultTableCriteria && defaultTableCriteria.flipOrder ? defaultTableCriteria.flipOrder : false);
	const [sortModel, setSortModel] = useState([ { field: orderBy, sort: flipOrder ? 'desc' : 'asc' } ])

	const [searchCriteria, setSearchCriteria] = useState((defaultTableCriteria && defaultTableCriteria.searchCriteria) ?? {});

	const rowCount = React.useMemo(() => parseInt(`${total}`) ?? 0, [total]);


	const tableCriteria = React.useMemo(() => {
		return createPageCriteria(page-1, count, flipOrder, orderBy, searchCriteria);
	}, [page, count, orderBy, flipOrder, searchCriteria]);

	const bookmarkCriteria = React.useMemo(() => {
		return { page, count, orderBy, flipOrder }
	}, [page, count, orderBy, flipOrder]);

	useUpdateBookmark(bookmarkId, bookmarkCriteria);

	useEffect(() => {
		criteriaChanged(tableCriteria);
	}, [tableCriteria]);

	const handleRowClick = (param) => {
		rowClick({ data: param.row });
	}
	const handlePageChange = (index) => {
		setPage(index+1)
	}
	const handlePageSizeChange = (size) => {
		setCount(size)
	}
	const handleSortModelChange = (sort) => {
		const model = sort?.length > 0 ? sort[0] : null;
		setOrderBy(model ? model.field : "");
		setFlipOrder(model?.sort === "desc" ? true : false);
		setSortModel(model ? [
			{ 
				field: model.field,
				sort: model.sort
			}
		] : []);
	}
	const handleFilterModelChange = (param) => {
		// console.log(param);
	}

	// If the search criteria has changed, give the backend an opportunity to use
	// the new values.
	const handleSearchCriteriaChange = React.useCallback((param) => {
		// todo: consider using lodash deep compare
		if (JSON.stringify(param) !== JSON.stringify(searchCriteria)) {
			// Only set the search criteria if they have changed.
			setSearchCriteria(param);
		}
	}, [searchCriteria, setSearchCriteria]);

	// console.log('TABLE', props)

	// DataGrid reference:
	// https://github.com/mui/mui-x/tree/v4.0.0-alpha.37
	// https://github.com/mui/mui-x/blob/v4.0.0-alpha.37/packages/grid/data-grid/src/DataGrid.tsx
	// https://v4.mui.com/api/data-grid/grid-col-def/#main-content
	// https://v4.mui.com/components/data-grid/columns/#render-cell
	// https://v4.mui.com/components/data-grid/columns/#column-types

	return (
		<div style={ { height: '100%', width: '100%' } } >
			<div style={ { display: 'flex', height: '100%' } } >
				<div style={{ flexGrow: 1 }} >
					<Filter
						searchCriteria={searchCriteriaSkeleton}
						defaultSearchCriteria={defaultTableCriteria.searchCriteria}

						setSearchCriteria={ handleSearchCriteriaChange }
						/>
					<DataGrid
            style={ { height: '100% !important'} }
						rows={rows}
						page={page-1}
						columns={columns}
						loading={loading}
						pageSize={count}
						rowCount={rowCount}
						sortModel={sortModel}
						density={"compact"}
						paginationMode={"server"}
						sortingMode={"server"}
						filteringMode={"server"}
						rowsPerPageOptions={ [5, 10, 20, 50, 100] }
						disableColumnMenu={true}

						onRowClick={ handleRowClick } 
						onPageChange={ handlePageChange }
						onPageSizeChange={ handlePageSizeChange } 
						onFilterModelChange={ handleFilterModelChange } 
						onSortModelChange={ handleSortModelChange } />
				</div>
			</div>
		</div>
	);
}
