import React from 'react';

import _ from 'lodash';

import { createVehicleOption, createVehicleOptionNone, useFetchVehicleOptions } from 'services/api';
import { BaseSelect } from './base-select';

// const defaultVehicle = null;
const defaultVehicle = createVehicleOptionNone();

export const vehicleOptionFromDeviceData = (deviceData) => {
	return _.isNil(deviceData) || _.isEqual(deviceData?.vehicle_linked ?? false, false) ? defaultVehicle : createVehicleOption(deviceData?.vehicle_id, deviceData?.vehicle_name, deviceData?.vehicle_registration_number);
}

export const VehicleSelect = ({onChange, value, help, placeholder, autoFocus, label, ...props}) => {
	const { rowOptions: options } = useFetchVehicleOptions();
	const _label = label ?? "Vehicle";
	return (
		<BaseSelect 
			id="vehicle_description" 
			label={_label}
			severity="warning" 
			defaultOption={defaultVehicle} 
			options={options} 
			value={value} 
			onChange={onChange}
			help={help}
			placeholder={placeholder}
			autoFocus={autoFocus}
			{...props}
		/>
	)
}
