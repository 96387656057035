import "./lessons.css";
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CourseTable } from "components/data-tables/driver-lessons-tables";

export const Lessons = () => {

	const { url } = useRouteMatch();
	const history = useHistory();

	const handleClick = (courseId, lessonId) => {
		history.push(`${url}/${courseId}/${lessonId}`);
	}

	return (
		<div className={"lessons-list-container"}>
			<h1>Courses</h1>
			<CourseTable

				onClick={ handleClick } />
		</div>
	);
}
