import './user-group-tabs.css';
import React, { useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';

import { UserGroupOwnerParticipants, UserGroupUserParticipants, UserGroupViewerParticipants } from './user-list/user-list';
import { CustomTabPanel } from '../tab-panel';
import { UserGroupStatsAverageEventsPerDistance, UserGroupStatsAverageEventsPerHour, UserGroupStatsAverageTripMetrics, UserGroupStatsMaximumTripMetrics, UserGroupStatsMinimumTripMetrics, UserGroupStatsPredominantEvents, UserGroupStatsTotalTrips } from 'components/statistics/user-group-statistics';
import { TrainingStats } from 'components/statistics/user-statistics';
import { DateRange } from 'components/date-range/date-range';
import { useRoleFeatureCheck } from 'modules/useGlobalStore';

function a11yProps(index) {
	return {
		value: `${index}`,
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		// backgroundColor: theme.palette.background.paper,
	},
	tabButtons: {
	}
}));

export const UserGroupTabs = ({ userGroup, userGroupId }) => {

	const classes = useStyles();

	const [value, setValue] = useState('0');

	const handleChange = (event, newValue) => {
		setValue(`${newValue}`);
	};

  const featureCheck = useRoleFeatureCheck('role_can_view_own_courses');

	// console.log(userGroup && userGroup, userGroup&&userGroup.is_owner === 'true');

	return (
		<div className={classes.root}>
			{/* <AppBar position="static"> */}
				<Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="on" aria-label="simple tabs">
					<Tab className={classes.tabButtons} label="Statistics" {...a11yProps(0)} />
					<Tab className={classes.tabButtons} label="Event Statistics" {...a11yProps(1)} />
					{/* <TrainingTab index={2} /> */}
					{/* <Tab className={classes.tabButtons} label="Trips" {...a11yProps(2)} /> */}
					{featureCheck && <Tab className={classes.tabButtons} label="Training" {...a11yProps(2)} />}
					<Tab className={classes.tabButtons} label="Users" {...a11yProps(3)} />
					<Tab className={classes.tabButtons} label="Owners" {...a11yProps(4)} />
					<Tab className={classes.tabButtons} label="Viewers" {...a11yProps(5)} />
					{/* <Tab className={classes.tabButtons} label="Events" {...a11yProps(7)} /> */}
				</Tabs>
			{/* </AppBar> */}
			<CustomTabPanel value={value} index={'0'}>
				<UserGroupStatistics userGroupId={userGroupId} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={'1'}>
				<UserGroupEventStatistics userGroupId={userGroupId} />
			</CustomTabPanel>
			{/* <CustomTabPanel value={value} index={2}>
				Trips
			</CustomTabPanel> */}
			{/* <TrainingTabPanel value={value} index={2} userGroupId={userGroupId} /> */}
			<CustomTabPanel value={value} index={'2'}>
				<TrainingStats userGroupId={userGroupId} />
			</CustomTabPanel>
			{/* <CustomTabPanel value={value} index={4}> */}
			<CustomTabPanel value={value} index={'3'}>
				<UserGroupUserParticipants canEdit={userGroup && userGroup.is_owner === 'true'} userGroupId={userGroupId} />
			</CustomTabPanel>
			{/* <CustomTabPanel value={value} index={'5'}> */}
			<CustomTabPanel value={value} index={'4'}>
				<UserGroupOwnerParticipants canEdit={userGroup && userGroup.is_owner === 'true'} userGroupId={userGroupId} />
			</CustomTabPanel>
			{/* <CustomTabPanel value={value} index={'6'}> */}
			<CustomTabPanel value={value} index={'5'}>
				<UserGroupViewerParticipants canEdit={userGroup && userGroup.is_owner === 'true'} userGroupId={userGroupId} />
			</CustomTabPanel>
			{/* <CustomTabPanel value={value} index={'7'}>
				Events
			</CustomTabPanel> */}
		</div>
	);
}

export const UserGroupEventStatistics = ({ userGroupId, unitSystem, startDate, endDate }) => {

	// console.log('UserGroupEventStatistics')

	const [now] = useState(new Date());
	const [startTime, setStartTime] = useState(startDate ? startDate : new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
	const [endTime, setEndTime] = useState(new Date(endDate ? endDate : now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));

	const handleStartDateChange = (start) => {
		setStartTime(start)
	}

	const handleEndDateChange = (end) => {
		setEndTime(end)
	}

	return (
		<>
			{
				(!startDate || !endDate) && <div>
					<DateRange
						defaultStartDate={startTime}
						defaultEndDate={endTime}
						onStartDateChange={ handleStartDateChange }
						onEndDateChange={ handleEndDateChange } />
				</div>
			}
			<UserGroupStatsPredominantEvents userGroupId={userGroupId} startDate={startTime} endDate={endTime} />
			<UserGroupStatsAverageEventsPerHour userGroupId={userGroupId} startDate={startTime} endDate={endTime} />
			<UserGroupStatsAverageEventsPerDistance userGroupId={userGroupId} unitSystem={unitSystem} startDate={startTime} endDate={endTime} />
		</>
	)
}

export const UserGroupStatistics = ({ userGroupId, startDate, endDate }) => {

	const [now] = useState(new Date());
	const [startTime, setStartTime] = useState(startDate ? startDate : new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
	const [endTime, setEndTime] = useState(new Date(endDate ? endDate : now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));

	const handleStartDateChange = (start) => {
		setStartTime(start)
	}

	const handleEndDateChange = (end) => {
		setEndTime(end)
	}

	return (
		<>
			{
				(!startDate || !endDate) && <div>
					<DateRange
						defaultStartDate={startTime}
						defaultEndDate={endTime}
						onStartDateChange={ handleStartDateChange }
						onEndDateChange={ handleEndDateChange } />
				</div>
			}
			<UserGroupStatsTotalTrips userGroupId={userGroupId} startDate={startTime} endDate={endTime} />
			<UserGroupStatsAverageTripMetrics userGroupId={userGroupId} startDate={startTime} endDate={endTime} />
			<UserGroupStatsMinimumTripMetrics userGroupId={userGroupId} startDate={startTime} endDate={endTime} />
			<UserGroupStatsMaximumTripMetrics userGroupId={userGroupId} startDate={startTime} endDate={endTime} />
		</>
	)
}
