// import './user-create.css';
import React from 'react';

import { CreateUserView } from 'components/edit-view/edit-view';

export const UserCreate = () => {

	return (
		<CreateUserView />
	);
}
