import "./admin-devices.css";
import React, { useCallback, useState } from 'react';
import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';

import Button from "@material-ui/core/Button";

import { ServerSideTable } from 'components/table/table';
import { createCriteria } from "components/data-tables/data-table-functions";
import { useFetchDevices } from "services/api";
import { toDisplayTimeLong } from "shared-functions/shared-functions";

import { AdminDevice } from 'admin-pages/admin-devices-page/admin-device/admin-device';
import { AdminDeviceCreate } from "admin-pages/admin-devices-page/admin-device-create/admin-device-create";
import { AdminDeviceEdit } from "admin-pages/admin-devices-page/admin-device-edit/admin-device-edit";

import { CrudDrawer } from "components/drawers/crud-drawer";

const columns = [
	{ field: "serial_number", headerName: "Serial", width: 300 },
	{ field: "last_online_time", headerName: "Last Online", width: 200, 
		valueFormatter: (params) => {
			return toDisplayTimeLong(params.value);
		}
	},
	{ field: "vehicle_name", headerName: "Vehicle", width: 200 },
	// { field: "status", headerName: "Status", width: 150 },
	{ field: "company_name", headerName: "Company", width: 150 },
];

// todo: 5DTAdmin should probably see all devices, even those marked as deleted/archived, so that they can be unarchived
const AdminDevicesDefault = () => {
	// console.warn('AdminDevicesDefault')

	const [tableCriteria, setTableCriteria] = useState(createCriteria(1, 20, false, "", { 
			text: "",
			serial_number: "",
			// status: "",
			company: ""
		}));
	const { path, url } = useRouteMatch();
	const history = useHistory();

	const { deviceCount, deviceList, isFetching } = useFetchDevices(tableCriteria);

	const handleRowClick = (data) => {
		// console.warn('AdminDevicesDefault', { path, url, data });
		history.push(`${path}/${data.data.serial_number}`);
	}

	const handleCriteriaChanged = useCallback((data) => {
		setTableCriteria(data);
	}, [setTableCriteria]);

	const handleCreate = () => {
		history.push(`${path}/create`);
	}

	const searchCriteriaSkeleton = [
		{ field: "text", header: "Search" },
		// { field: "serial_number", header: "Serial" },
		// { field: "currently_online", header: "Online" },
		// { field: "company", header: "Company" },
	];

	return (
		<div className={"device-table-container"}>
			<h1>Devices</h1>
			<Button variant="contained" color="primary" onClick={ handleCreate }>Register</Button>
			<ServerSideTable
				columns={columns}
				rows={deviceList}
				loading={isFetching}
				total={deviceCount}
				searchCriteriaSkeleton={searchCriteriaSkeleton}
				defaultTableCriteria={tableCriteria}

				rowClick={ handleRowClick }
				criteriaChanged={ handleCriteriaChanged }
			/>
		</div>
	);
}

export default () => {
	const { path } = useRouteMatch();
	// console.warn('AdminDevices', {
	// 	path, url
	// })

	return (
		<>
			<Switch>
				<Route path={path} exact>
					<AdminDevicesDefault />
				</Route>
				<Route path={`${path}/create`}>
					<AdminDevicesDefault />
					<CrudDrawer>
						<AdminDeviceCreate />
					</CrudDrawer>
				</Route>
				<Route path={`${path}/:id`} exact >
					<AdminDevicesDefault />
					<CrudDrawer>
						<AdminDevice />
					</CrudDrawer>
				</Route>
				<Route path={`${path}/:id/edit`} exact >
					<AdminDeviceEdit />
				</Route>
			{/* <Route path={`${path}/create`} exact component={_AdminDeviceCreate}/> */}
			{/* <Route path={`${path}/create`} exact>
				<_AdminDeviceCreate />
			</Route> */}
			</Switch>
		</>
	)
}
