import './qr-page.css';
import React, { useEffect, useState } from 'react';

import QRCode from 'qrcode.react';
import { getIdentity } from 'services/user';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';

export const QRPage = () => {

  const isMounted = useIsMounted();
	const [qrCode, setQrCode] = useState(null);
	const [qrCodeError, setQrCodeError] = useState(false);

	useEffect(() => {
        setQrCodeError(false);
		const [cancelSource, getIdentityPromise] = getIdentity()
        let isActive = true;
        getIdentityPromise
			.then((response) => {
                if (!isMounted() || !isActive) return;
				if (!response.success || !response.result) {
                    setQrCodeError(true);
                    return;
                }
				setQrCode(JSON.stringify(response.result));
			})
			.catch((err) => {
                if (!isMounted() || !isActive) return;
                setQrCodeError(true);
				console.log(err);
			});
        return () => {
            isActive = false;
            cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
        }
	}, []); // eslint-disable-line
	
	return (
        <div className={'qr-code-container'}>
            {
                !qrCodeError
                ?
                    qrCode
                    ? 
                        <>
                            <h3 className={'qr-code-header'}>Present the following QR Code to the DriveVUE device:</h3>
                            <QRCode
                                className={'qr-code'}
                                value={qrCode}
                                size={512}
                                bgColor="#FFFFFF"
                                fgColor="#000000"
                                level="L"
                                includeMargin={false}
                                />
                        </>
                    : "Loading..."
                : "An error occured while retrieving your QR Code. If this persist, logout and try again."
            }
        </div>
	)
}
