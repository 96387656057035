import React from 'react';

import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import { LiveDriverMarker } from './live-global-events/live-global-events';
import { MapAlert, MapEventSelected } from './map-alerts/map-alert';
import { MapPath } from './map-paths/map-path';

const MAP_STYLES = [
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
]

export const MapContainer = ({ center, zoom, height, children, onLoad }) => {

  const containerStyle = React.useMemo(() => ({
    width: '100%',
    height: height ? height : '100%'
  }), [height]);

  const handleLoad = () => {
    console.log('Google Map Component, Loaded')
  }

  const handleError = (error) => {
    console.log('Google Map Component, Error: ', error)
  }

  const handleUnmount = () => {
    console.log('Google Map Component, Unmounted')
  }

  return (
    <LoadScriptNext
      googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN}`}
      onLoad={handleLoad}
      onError={handleError}
      onUnmount={handleUnmount}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={{
          fullscreenControl: false,
          // zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          styles: MAP_STYLES
        }}

        onLoad={onLoad}
      >
        {children}
      </GoogleMap>
    </LoadScriptNext>
  );
}

export const StaticMapContainer = ({ center, zoom, height, onLoad }) => {

  const containerStyle = {
    width: '100%',
    // height: height ? height : 250
    height: '100%',
    minHeight: 250,
  };

  const x = 0.00036;
  const y = -0.00032;

  const handleLoad = () => {
    console.log('Google Map Component, Loaded')
  }

  const handleError = (error) => {
    console.log('Google Map Component, Error: ', error)
  }

  const handleUnmount = () => {
    console.log('Google Map Component, Unmounted')
  }

  return (
    <LoadScriptNext
      googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN}`}
      onLoad={handleLoad}
      onError={handleError}
      onUnmount={handleUnmount}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: MAP_STYLES,
          draggable: false,
          zoomable: false
        }}

        onLoad={onLoad}
      >
        <LiveDriverMarker position={{ lat: -25.7470155, lng: 28.2725573 }} />
        <MapEventSelected
          type="speeding"
          position={{ lat: -25.749155, lng: 28.2747073 }} />
        <MapAlert
          type="speeding"
          position={{ lat: -25.7450155, lng: 28.2725573 }} />
        <MapPath path={[
          { lng: 28.272607 - x, lat: -25.746531 - y },
          { lng: 28.272645 - x, lat: -25.7465 - y },
          { lng: 28.272343 - x, lat: -25.746182 - y },
          { lng: 28.2723 - x, lat: -25.746084 - y },
          { lng: 28.2723 - x, lat: -25.746084 - y },
          { lng: 28.272279 - x, lat: -25.74599 - y },
          { lng: 28.272341 - x, lat: -25.7456 - y },
          { lng: 28.272486 - x, lat: -25.745399 - y },
          { lng: 28.272486 - x, lat: -25.745399 - y },
          { lng: 28.272835 - x, lat: -25.745061 - y },
          { lng: 28.27306 - x, lat: -25.744967 - y },
          { lng: 28.27306 - x, lat: -25.744967 - y },
          { lng: 28.273722 - x, lat: -25.744713 - y },
          { lng: 28.273722 - x, lat: -25.744713 - y },
          { lng: 28.273766 - x, lat: -25.744697 - y },
          { lng: 28.2737 - x, lat: -25.744477 - y },
          { lng: 28.273703 - x, lat: -25.743942 - y },
          { lng: 28.273703 - x, lat: -25.743942 - y },
          { lng: 28.273704 - x, lat: -25.743902 - y },
          { lng: 28.274617 - x, lat: -25.743978 - y },
          { lng: 28.27517 - x, lat: -25.744007 - y },
          { lng: 28.275371 - x, lat: -25.744025 - y },
          { lng: 28.276063 - x, lat: -25.744017 - y },
          { lng: 28.276063 - x, lat: -25.744017 - y },
          { lng: 28.276134 - x, lat: -25.744016 - y },
          { lng: 28.276088 - x, lat: -25.744485 - y },
          { lng: 28.275969 - x, lat: -25.745063 - y },
          { lng: 28.275818 - x, lat: -25.745518 - y },
          { lng: 28.275654 - x, lat: -25.74588 - y },
          { lng: 28.275589 - x, lat: -25.746074 - y },
          { lng: 28.275294 - x, lat: -25.746683 - y },
          { lng: 28.274761 - x, lat: -25.747696 - y },
          { lng: 28.274778 - x, lat: -25.747701 - y },
          { lng: 28.274778 - x, lat: -25.747701 - y },
          { lng: 28.274412 - x, lat: -25.747569 - y },
          { lng: 28.27426 - x, lat: -25.747551 - y },
          { lng: 28.274151 - x, lat: -25.747558 - y },
          { lng: 28.274061 - x, lat: -25.747587 - y },
          { lng: 28.27358 - x, lat: -25.747836 - y },
          { lng: 28.273305 - x, lat: -25.747996 - y },
          { lng: 28.273305 - x, lat: -25.747996 - y },
          { lng: 28.271147 - x, lat: -25.74924 - y },
          { lng: 28.270338 - x, lat: -25.749776 - y },
          { lng: 28.270338 - x, lat: -25.749776 - y },
          { lng: 28.270065 - x, lat: -25.749956 - y },
          { lng: 28.269605 - x, lat: -25.750195 - y },
          { lng: 28.26916 - x, lat: -25.749449 - y },
          { lng: 28.269212 - x, lat: -25.749427 - y },
          { lng: 28.269212 - x, lat: -25.749427 - y },
          { lng: 28.270399 - x, lat: -25.748918 - y },
          { lng: 28.270439 - x, lat: -25.748883 - y },
          { lng: 28.270439 - x, lat: -25.748883 - y },
          { lng: 28.270457 - x, lat: -25.748822 - y },
          { lng: 28.270409 - x, lat: -25.74874 - y },
          { lng: 28.269761 - x, lat: -25.748098 - y },
          { lng: 28.269395 - x, lat: -25.748258 - y },
        ]} />
      </GoogleMap>
    </LoadScriptNext>
  );
}
