import './user-group-edit.css';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import { userGroup, updateUserGroup } from 'services/user-group';

import { EditView } from 'components/edit-view/edit-view';
import { useIsMounted } from 'shared-hooks/useIsMounted';
import { REQUEST_ABORT_REASON } from 'shared-functions/shared-functions';

export const UserGroupEdit = () => {

	const isMounted = useIsMounted();
	const updateUserGroupCancelSourceRef = useRef(null);
	const { userGroupId } = useParams();
	const [groupData, setGroupData] = useState();
	const history = useHistory();
	const { url } = useRouteMatch();

	useEffect(() => {
		if (!userGroupId) return;
		const [cancelSource, userGroupGetPromise] = userGroup(userGroupId)
		let isActive = true;
		userGroupGetPromise
			.then((response) => {
				if (!isMounted() || !isActive) return;
				setGroupData(response.result);
				history.replace(url, { [userGroupId]: `${response.result.name}`})
			})
			.catch((err) => {
				if (!isMounted() || !isActive) return;
				console.log(err);
			});
		return () => {
			isActive = true;
			cancelSource.cancel(REQUEST_ABORT_REASON.CANCELLED);
		}
	}, [userGroupId]);

	const handleEditSave = (data) => {
		if (updateUserGroupCancelSourceRef.current) return;
		const [cancelSource, updateUserGroupPromise] = updateUserGroup({ ...data, id: userGroupId })
		updateUserGroupCancelSourceRef.current = cancelSource;
		updateUserGroupPromise
			.then((response) => {
				if (!isMounted()) return;
				updateUserGroupCancelSourceRef.current = null;
				if (!response.success) return;
				history.goBack();
			})
			.catch((err) => {
				if (!isMounted()) return;
				updateUserGroupCancelSourceRef.current = null;
				console.log(err)
			});
	}

	const handleEditCancel = () => {
		history.goBack();
	}

	const editSkeleton = [
		{ key: 1, property: "name", header: "NAME:", required: true },
		// { key: 2, property: "company_name", header: "COMPANY:" },
		// { key: 3, property: "num_users", header: "NUMBER OF USERS:" },
		// { key: 4, property: "users", header: "USERS:" },
		// { key: 4, property: "owners", header: "OWNERS:" },
		// { key: 4, property: "viewers", header: "VIEWERS:" },
	];

	return (<>
		{
			groupData && <EditView
				skeleton={editSkeleton}
				data={groupData}
				confirmTitle='Edit User Group?'
				confirmDescription={`You are about to edit the ${groupData.name} group.`}
				
				onSave={ handleEditSave }
				onCancel={ handleEditCancel } />
		}
	</>);
}
