import { useEffect } from "react";

export const useDebounce = (handler, delay) => {
	useEffect(() => {
		const timeout = setTimeout(() => {
			handler();
		}, delay);
		return () => {
			clearTimeout(timeout);
		}
	}, [delay, handler])
}
