import './vehicle-list.css';
import React from 'react';

import { Button } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { VehicleGroupOwnersTable, VehicleGroupVehiclesTable, VehicleGroupViewersTable } from 'components/data-tables/vehicle-data-tables';

export const VehicleGroupVehicleParticipants = ({ canEdit, vehicleGroupId }) => {

	const history = useHistory();
	const { url } = useRouteMatch();

	const handleEdit = () => {
		history.push(`${url}/vehicle-management`);
	}

	const defaultSearchCriteria = {
		name: '',
		registration_number: '',
	}

	const searchCriteria = {
		name: '',
		registration_number: '',
	}

	const defaultCriteria = {
		id: vehicleGroupId,
	}

	const fixedCriteria = {
		id: vehicleGroupId,
	}

	return <>
		{ canEdit && <Button variant="contained" color="primary" onClick={ handleEdit }>Edit</Button> }
		<VehicleGroupVehiclesTable
			defaultCriteria={defaultCriteria}
			fixedCriteria={fixedCriteria}
			searchCriteria={searchCriteria}
			defaultSearchCriteria={defaultSearchCriteria} />
	</>
}

export const VehicleGroupOwnerParticipants = ({ canEdit, vehicleGroupId }) => {

	const history = useHistory();
	const { url } = useRouteMatch();

	const handleEdit = () => {
		history.push(`${url}/owner-management`);
	}

	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

	const defaultCriteria = {
		id: vehicleGroupId,
	}

	const fixedCriteria = {
		id: vehicleGroupId,
	}

	return <>
		{ canEdit && <Button variant="contained" color="primary" onClick={ handleEdit }>Edit</Button> }
		<VehicleGroupOwnersTable
			defaultCriteria={defaultCriteria}
			fixedCriteria={fixedCriteria}
			searchCriteria={searchCriteria}
			defaultSearchCriteria={defaultSearchCriteria} />
	</>
}

export const VehicleGroupViewerParticipants = ({ canEdit, vehicleGroupId }) => {

	const history = useHistory();
	const { url } = useRouteMatch();
	const handleEdit = () => {
		history.push(`${url}/viewer-management`);
	}

	const defaultSearchCriteria = {
		name: '',
		surname: '',
	}

	const searchCriteria = {
		name: '',
		surname: '',
	}

	const defaultCriteria = {
		id: vehicleGroupId,
	}

	const fixedCriteria = {
		id: vehicleGroupId,
	}

	return <>
		{ canEdit && <Button variant="contained" color="primary" onClick={ handleEdit }>Edit</Button> }
		<VehicleGroupViewersTable
			defaultCriteria={defaultCriteria}
			fixedCriteria={fixedCriteria}
			searchCriteria={searchCriteria}
			defaultSearchCriteria={defaultSearchCriteria} />
	</>
}
