import React from 'react';

import _ from 'lodash';

import { Typography, IconButton, Tooltip, Toolbar, makeStyles, createStyles } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { useGoogleMap } from '@react-google-maps/api';

import { setSelectedEventNext, setSelectedEventPrev, setSelectedEventLast, setSelectedEventFirst, useSelectedEventIndex } from 'modules/useGlobalStore';


const useStyles = makeStyles((theme) => createStyles({
	toolbar: {
    alignItems: 'center',
    justifyContent: 'center',
    // width: '100%',
  },
}));

// outlined button variant also looks good

const PanEventControl = ({event}) => {

  const map = useGoogleMap();

  const onClick = React.useCallback(() => {
    if (!_.isNil(event?.markerPos)) {
      map.panTo(event.markerPos);
    }
  }, [map, event]);

	return (
		<Tooltip title='Pan to event Location'>
			<IconButton onClick={onClick}>
				<MyLocationIcon />
			</IconButton>
		</Tooltip>
	);
}

const EventIndexControl = ({eventList}) => {

  const eventIndex = useSelectedEventIndex();
  const eventNum = React.useMemo(() => eventIndex+1, [eventIndex]);

  const eventLength = React.useMemo(() => _.size(eventList), [eventList]);

	return (
    <Typography noWrap>{eventNum}/{eventLength}</Typography>
	);
}

const LastEventControl = ({eventList}) => {

  const onClick = React.useCallback(() => {
    setSelectedEventLast(eventList);
  }, [setSelectedEventLast, eventList]);

  const eventIndex = useSelectedEventIndex();

  const disabled = React.useMemo(() => _.size(eventList) === eventIndex+1, [eventIndex, eventList]);

  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <SkipNextIcon />
    </IconButton>
  );
}


const NextEventControl = ({eventList, eventIndex}) => {

  const onClick = React.useCallback(() => {
    setSelectedEventNext(eventList, eventIndex);
  }, [setSelectedEventNext, eventList, eventIndex]);

	// console.log('NextEventControl', eventList, eventIndex);

  // const onClick = () => {
  //   setSelectedEventNext(eventList, eventIndex);
  // }

  const disabled = React.useMemo(() => _.size(eventList) === eventIndex+1, [eventList, eventIndex]);

  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <NavigateNextIcon />
    </IconButton>
  );
}

const FirstEventControl = ({eventList}) => {

  const onClick = React.useCallback(() => {
    setSelectedEventFirst(eventList);
  }, [setSelectedEventFirst, eventList]);

  const eventIndex = useSelectedEventIndex();

  const disabled = React.useMemo(() => eventIndex === 0, [eventIndex]);

  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <SkipPreviousIcon />
    </IconButton>
  );
}

const BackEventControl = ({eventList, eventIndex}) => {

  const onClick = React.useCallback(() => {
    setSelectedEventPrev(eventList, eventIndex);
  }, [setSelectedEventPrev, eventList, eventIndex]);

	// console.log('BackEventControl', eventList, eventIndex);

  // const onClick = () => {
  //   setSelectedEventPrev(eventList, eventIndex);
  // };

  const disabled = React.useMemo(() => eventIndex === 0, [eventIndex])

  return (
    <IconButton disabled={disabled} onClick={onClick}>
      <NavigateBeforeIcon />
    </IconButton>
  );
}

export const EventToolbar = ({eventList, eventIndex, event }) => {
	const classes = useStyles();
  return (
    <Toolbar className={classes.toolbar} disableGutters variant="dense">
      <PanEventControl event={event} />
      <FirstEventControl eventList={eventList} />
      <BackEventControl eventList={eventList} eventIndex={eventIndex} />
      <EventIndexControl eventList={eventList} />
      <NextEventControl eventList={eventList} eventIndex={eventIndex} />
      <LastEventControl eventList={eventList} />
    </Toolbar>
  )
}
