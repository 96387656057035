// import './App.css';
import React, { useState } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useTokenStore, useUserId, useUserStore } from 'modules/useTokenStore';

import { NavigationBar } from 'components/navigation-bar/navigation-bar';
import { AdminDevicesPage } from './admin-devices-page/admin-devices-page';
import { AdminCompanyPage } from './admin-company-page/admin-company-page';
import AdminUsersPage from './admin-users-page';
import { AdminTrainingManagementPage } from './admin-training-management-page/admin-training-management-page';
import { Preferences } from 'pages/preferences/preferences';
import { BreadcrumbList } from 'components/breadcrumbs/breadcrumbs'

import ReleaseNotes from 'pages/release-notes';
import { useNavPaths } from 'modules/useGlobalStore';

const styles = makeStyles((theme) => ({
	root: {
		display: "flex"
	},
	contentM: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 0, 2, 0),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(0, 2, 2, 2),
	},
}));

const getAltPaths = (path, role) => {
	const paths = {
		//'Profile': `${path}/profile`,
		'Preferences': `${path}/preferences`,
	};
	return paths
}

export const AdminEntry = () => {

	const token = useTokenStore(state => state.token);
	const role = useUserStore(state => state.role);
	const { path } = useRouteMatch();

	const paths = useNavPaths(path);

	const [altPaths] = useState(getAltPaths(path, role));
	const classes = styles();

	if (!role || !token || token.lenght < 1) {
		return (
			<Redirect to="/login" />
		)
	} else if (role !== "5DTAdmin") {
		return (
			<Redirect to="/dashboard" />
		)
	}
	
	return (
		<div className={classes.root}>
			<NavigationBar paths={paths} altPaths={altPaths}/>
			<main className={classes.content}>
				<div className={classes.contentM} />
				<BreadcrumbList />
				<Switch>
					<Route path={`${path}`} exact >
						<Redirect to={`${path}/devices`} />
					</Route>
					<Route path={`${path}/devices`} >
						<AdminDevicesPage />
					</Route>
					<Route path={`${path}/companies`} >
						<AdminCompanyPage />
					</Route>
					<Route path={`${path}/users`} >
						<AdminUsersPage />
					</Route>
					<Route path={`${path}/training-management`} >
						<AdminTrainingManagementPage />
					</Route>
					<Route path={`${path}/preferences`} >
						<Preferences />
					</Route>
					<Route path={`${path}/release-notes`} component={ReleaseNotes} />
					<Route>
						<h1>NOT FOUND</h1>
					</Route>
				</Switch>
			</main>
		</div>
	)
}
