import './vehicle-group-create.css';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCreateVehicleGroup } from 'services/api';

import { EditView } from 'components/edit-view/edit-view';

export const VehicleGroupCreate = () => {
	
	const history = useHistory();

	const handleCreateCancel = React.useCallback(() => {
		history.goBack();
	}, [history]);

	const mutation = useCreateVehicleGroup({
		onSuccess: () => handleCreateCancel()
	});

	const handleCreate = React.useCallback((data) => {
		mutation.mutate(data);
	}, [mutation]);

	const createSkeleton = [
		{ key: 1, property: "name", header: "Vehicle Group Name", required: true },
	];

	return (
		<>
			<div className={"container-padding"} />
				<EditView
					skeleton={createSkeleton}
					data={ {name: ''} } 
					isDefaultData={true}
					onSave={ handleCreate }
					onCancel={ handleCreateCancel } />
			<div className={"container-padding"} />
		</>
	);
}
