import React from 'react';
import { Polyline } from '@react-google-maps/api';
import { usePreferenceStore } from 'modules/useTokenStore';

export const MapPath = ({ path }) => {
	// console.log('MapPath', path);

	// const preferences = usePreferenceStore((state) => state.preferences);

	const pathSize = usePreferenceStore((state) => state.preferences.path_size);

	const pathSafe = React.useMemo(() => {
		return path ?? [];
	}, [path]);

	// console.log('MapPath', pathSafe, pathSize);

	return (
		<Polyline
			options={
				{
					strokeColor: '#004c8c',
					strokeOpacity: 1,
					strokeWeight: pathSize ? pathSize : 2,
					fillColor: '#FF0000',
					fillOpacity: 0.35,
					clickable: false,
					draggable: false,
					editable: false,
					visible: true,
					// radius: 30000,
					paths: path,
					zIndex: 1
				}
			}
			path={pathSafe} />
	);
}
