import React from 'react';

import _ from 'lodash';

import { ClientSideTable } from 'components/table/client-side-table';
import { useLiveDeviceTripListSummary, setSelectedDeviceTrip } from 'modules/useGlobalStore';

import { makeStyles } from '@material-ui/core';

import { createTheme, darken, lighten } from '@material-ui/core/styles';
import { toDisplayTimeRelShort } from 'shared-functions/shared-functions';
import { useBookmark } from 'modules/useTokenStore';

// https://github.com/mui/mui-x/issues/417
// Look at issue above for ideas on presenting long rows.
// https://v4.mui.com/api/data-grid/grid-col-def/#gridcoldef-interface
const columns = [
	{
		field: "vehicleAlias",
		headerName: "Vehicle",
		description: 'The vehicle name',
		width: 180,
		type: 'string',
	},
	{
		field: "vehicleRegistrationNumber",
		headerName: "Registration",
		description: 'The vehicle registration number',
		width: 180,
		type: 'string',
	},
	{
		field: "driverFullName",
		headerName: "Driver",
		width: 180,
	},
	// {
	// 	field: "driver_description",
	// 	headerName: "Vehicle",
	// 	width: 250,
	// },
	// {
	// 	field: "event_count",
	// 	headerName: "Events",
	// 	type: "number",
	// },
	{
		field: "tripDuration",
		headerName: "Duration",
		width: 150,
		type: "string",
	},
	{
		field: "tripDistance",
		headerName: "Distance",
		width: 120,
		type: "string",
	},
	{
		field: "tripAlertCount",
		headerName: "Alerts",
		type: "number",
	},
	{
		field: "deviceStatus",
		headerName: "Device",
		width: 120,
		type: "string",
	},
	{
		field: "tripStatus",
		headerName: "Trip",
		width: 120,
		type: "string",
	},
	{
		field: "lastActivity",
		headerName: "Activity",
		type: "string",
		width: 140,
	},
];

const sortModel = [{field: 'vehicleAlias', sort: 'asc'}];

function getThemePaletteMode(palette) {
  return palette.type || palette.mode;
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const getBackgroundColor = (color) =>
      getThemePaletteMode(theme.palette) === 'dark'
        ? darken(color, 0.6)
        : lighten(color, 0.6);

    const getHoverBackgroundColor = (color) =>
      getThemePaletteMode(theme.palette) === 'dark'
        ? darken(color, 0.5)
        : lighten(color, 0.5);

		// https://v4.mui.com/customization/palette/#palette-colors
		// const onlineColor = theme.palette.info.main;
		// const offlineColor = theme.palette.success.main;
		const offlineColor = theme.palette.action.disabled;
		const expiredColor = theme.palette.warning.main;

    return {
      root: {
				width: '100%',
				height: '100%',
				// /* Allows the MUI grid rows to expand to child height */
				// '& .MuiDataGrid-viewport': {
				// 	maxHeight: "fit-content !important"
				// },
				// '& .MuiDataGrid-row': {
				// 	maxHeight: "fit-content !important"
				// },
				// '& .MuiDataGrid-renderingZone': {
				// 	maxHeight: "fit-content !important"
				// },

				// /* Allows the MUI grid rows to expand to child height */
				// '& .MuiDataGrid-cell': {
				// 	maxHeight: "fit-content !important",
				// 	overflow: "auto",
				// 	whiteSpace: "initial !important",
				// 	lineHeight: "16px !important",
				// 	display: "flex !important",
				// 	alignItems: "center",
				// 	paddingTop: "10px !important",
				// 	paddingBottom: "10px !important",
				// },
        '& .dv-device-trip-tab--online': {
          // backgroundColor: getBackgroundColor(onlineColor),
          // '&:hover': {
          //   backgroundColor: getHoverBackgroundColor(onlineColor),
          // },
        },
        '& .dv-device-trip-tab--offline': {
					fontStyle: 'italic',
					// fontWeight: 'bold',
					textDecoration: 'line-through',
					// textDecoration: 'overline',
					// textDecoration: 'underline',
          backgroundColor: getBackgroundColor(offlineColor),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(offlineColor),
          },
        },
        '& .dv-device-trip-tab--expired': {
          backgroundColor: getBackgroundColor(expiredColor),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(expiredColor),
          },
        },
      },
    };
  },
  { defaultTheme },
);

// bookmarkId is a unique id for this component: https://www.uuidgenerator.net/
const bookmarkId = "42f1c4d8-7874-4f0c-b335-8af032cc63aa";

export const DeviceLiveTripTab = () => {

	const trips = useLiveDeviceTripListSummary();

	// console.warn('DeviceLiveTripTab', {trips});

	const handleRowClick = (row) => {
    // console.warn('DeviceLiveTripTab:handleRowClick', {row});
		setSelectedDeviceTrip(
			row?.data?.deviceSn ?? null, 
			row?.data?.tripId ?? null,
			row?.data?.driverId ?? null,
			row?.data?.vehicleId ?? null,
		);
	}

	// https://v4.mui.com/api/data-grid/data-grid/#css
	// https://mui.com/components/data-grid/style/#styling-rows
	// https://v4.mui.com/components/data-grid/style/#styling-rows
  const classes = useStyles();

	const getRowClassName = React.useCallback((params) => {
		// console.warn('DeviceLiveTripTab:getRowClassName', {
		// 	params
		// });
		const status = _.toLower(params.getValue(params.id, 'deviceStatus') ?? 'online');
		return `dv-device-trip-tab--${status}`
	})

	const bookmarkSortModel = useBookmark(bookmarkId, sortModel);

	return (
		<div className={classes.root}>
		<ClientSideTable
			// classes={newClasses}
			// className={classes.root}
			sortModel={bookmarkSortModel}
			columns={columns}
			getRowClassName={getRowClassName}
			bookmarkId={bookmarkId}
			rows={trips}
			rowClick={handleRowClick}
			/>
		</div>
	)
}
